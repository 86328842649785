import React, { createContext, useContext, useState } from 'react';
import { useQuery } from 'react-query';

import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';

export interface StatusProps {
  code: string;
  status: string;
  status_type: 'O' | 'C' | 'X';
}

interface StatusContextData {
  statusList?: StatusProps[];
  sortedStatusList?: StatusProps[];
  isLoading: boolean;
  updateSortedStatusList: (data: StatusProps[], environmentId: number) => void;
  resetSortedKanbanList: () => void;
}

export const StatusContext = createContext<StatusContextData>(
  {} as StatusContextData,
);

const StatusProvider: React.FC = ({ children }) => {
  const { environment } = useEnvironment();

  const [sortedStatusList, setSortedStatusList] = useState<
    StatusProps[] | undefined
  >(undefined);

  const { data: statusList, isLoading, refetch } = useQuery(
    [`statusList`, environment?.id],
    async () => {
      if (!environment) {
        return undefined;
      }
      const response = await api.get(`zc/config/${environment.id}/`);
      const { data } = response;
      const statusListResponse: StatusProps[] = data.status;

      const sortedStatusListAlreadyExists = getSortedStatusList(data.id);

      if (
        sortedStatusListAlreadyExists &&
        statusListResponse.length === sortedStatusListAlreadyExists?.length
      ) {
        setSortedStatusList(sortedStatusListAlreadyExists);
      } else {
        setSortedStatusList(data.status);
      }

      return statusListResponse;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const updateSortedStatusList = (data: StatusProps[], envId: number) => {
    setSortedStatusList(() => data);

    const sortedStatusListLocalStorage = localStorage.getItem(
      '@ZapForm:kanbanSortedStatusList',
    );

    let formattedStatusListLocalStorage = {};
    if (sortedStatusListLocalStorage) {
      formattedStatusListLocalStorage = JSON.parse(
        sortedStatusListLocalStorage,
      );
    }

    localStorage.setItem(
      '@ZapForm:kanbanSortedStatusList',
      JSON.stringify({ ...formattedStatusListLocalStorage, [envId]: data }),
    );
  };

  const getSortedStatusList = (envId: number): StatusProps[] | undefined => {
    const sortedStatusListLocalStorage = localStorage.getItem(
      '@ZapForm:kanbanSortedStatusList',
    );

    if (sortedStatusListLocalStorage) {
      const formattedStatusListLocalStorage = JSON.parse(
        sortedStatusListLocalStorage,
      );

      return formattedStatusListLocalStorage[envId];
    }

    return undefined;
  };

  const resetSortedKanbanList = () => {
    const sortedStatusListLocalStorage = localStorage.getItem(
      '@ZapForm:kanbanSortedStatusList',
    );

    if (sortedStatusListLocalStorage && environment) {
      const formattedStatusListLocalStorage = JSON.parse(
        sortedStatusListLocalStorage,
      );

      delete formattedStatusListLocalStorage[environment.id];

      localStorage.setItem(
        '@ZapForm:kanbanSortedStatusList',
        JSON.stringify(formattedStatusListLocalStorage),
      );

      setSortedStatusList(() => environment.status);
      refetch();
    }
  };

  return (
    <StatusContext.Provider
      value={{
        statusList,
        sortedStatusList,
        isLoading,
        updateSortedStatusList,
        resetSortedKanbanList,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};
function useStatus(): StatusContextData {
  const context = useContext(StatusContext);

  if (!context) {
    throw new Error('useStatus must be used within an StatusProvider');
  }

  return context;
}

export { StatusProvider, useStatus };
