import React, { useEffect, useState } from 'react';
import {
  AreaChartOutlined,
  BranchesOutlined,
  MessageOutlined,
  MobileOutlined,
  NotificationOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, MenuProps, Menu, Row, Tooltip, Badge } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { BiBot } from 'react-icons/bi';
import Logo from '~/assets/logo.svg';
import { useUnreadMessages } from '~/hooks/messages/unreadMessages';
import { useUser } from '~/hooks/user';
import { ParamsProps } from '~/@types/params';
import { useDevice } from '~/hooks/device';

type MenuItem = Required<MenuProps>['items'][number];

interface IGetItem {
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  type?: 'group';
}

const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const history = useHistory();

  const { environmentId } = useParams<ParamsProps>();

  const { user } = useUser();
  const { device } = useDevice();
  const { totalUnreadMessages } = useUnreadMessages();

  const getItem = ({ label, key, icon, children, type }: IGetItem) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      onClick: () => {
        history.push(`/c/${environmentId}/${key}`);
      },
    } as MenuItem;
  };

  const items: MenuItem[] = [
    getItem({
      label: intl.get('workflow.title'),
      key: 'workflow',
      icon: (
        <Badge
          count={totalUnreadMessages}
          style={{ top: '9px', right: '-5px', lineHeight: '20px' }}
        >
          <BranchesOutlined />
        </Badge>
      ),
    }),
    getItem({
      label: intl.get('contact.title_plural'),
      key: 'contacts',
      icon: (
        <Badge
          count={totalUnreadMessages}
          style={{ top: '9px', right: '-5px', lineHeight: '20px' }}
        >
          <TeamOutlined />
        </Badge>
      ),
    }),
    getItem({
      label: intl.get('chatbot.title'),
      key: 'chatbot/forms',
      icon: <BiBot />,
    }),
    getItem({
      label: intl.get('responsible.title_plural'),
      key: 'responsibles',
      icon: <UserOutlined />,
    }),
    getItem({
      label: intl.get('campaign.title_plural'),
      key: 'campaigns',
      icon: <NotificationOutlined />,
    }),
    getItem({
      label: intl.get('reports.title_plural'),
      key: 'reports',
      icon: <AreaChartOutlined />,
    }),
  ];

  if (user && user.apps.includes('zapchamado.generate_qr')) {
    items.push(
      getItem({
        label: intl.get('device.title'),
        key: 'device',
        icon: <MobileOutlined />,
      }),
    );
  }

  if (
    user &&
    user.apps.includes('zapchamado.generate_qr') &&
    device &&
    device.isWhatsAppOfficial
  ) {
    items.push(
      getItem({
        label: intl.get('message_templates.title'),
        key: 'messagetemplates',
        icon: <MessageOutlined />,
      }),
    );
  }

  const { pathname } = useLocation();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    const newSelectedKeys = items
      .filter(item => item && pathname.includes(String(item.key)))
      .map(item => String(item?.key));

    setSelectedKeys(newSelectedKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Sider collapsed>
      <Row
        align="middle"
        justify="center"
        style={{ height: '55px', marginBottom: '1rem' }}
      >
        <Tooltip title="ZapForm" placement="right">
          <img src={Logo} alt="Logo ZapForm" style={{ height: '45px' }} />
        </Tooltip>
      </Row>
      <Menu
        selectedKeys={selectedKeys}
        mode="inline"
        theme="dark"
        items={items}
      />
    </Sider>
  );
};

export default Sidebar;
