import React, { memo } from 'react';

import {
  AttendanceProvider,
  useAttendance,
} from '~/hooks/attendances/attendance';
import FormAttendanceView from '../../molecules/FormAttendanceView';
import { Container } from './styles';

interface ModalFinishAttendanceProps {
  attendanceId: number;
}

const ModalFinishAttendance: React.FC<ModalFinishAttendanceProps> = ({
  attendanceId,
}) => {
  return (
    <AttendanceProvider attendanceId={attendanceId}>
      <ModalFinishAttendanceRender />
    </AttendanceProvider>
  );
};

const ModalFinishAttendanceRender: React.FC = () => {
  const { isLoading, attendance } = useAttendance();
  if (isLoading || !attendance) {
    return <>Loading</>;
  }
  return (
    <Container>
      <FormAttendanceView
        questions={attendance.order}
        isEnabledNewFields={false}
      />
    </Container>
  );
};

export default memo(ModalFinishAttendance, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
