import { MessageOutlined, SendOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Button, Modal, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';

import {
  IMessageTemplateDTO,
  useMessageTemplates,
} from '~/hooks/device/messageTemplates';
import PreviewMessageTemplate from '~/components/MessageTemplates/molecules/PreviewMessageTemplate';
import { useMessages } from '~/hooks/messages/messages';

const SendMessageTemplate: React.FC = () => {
  const { listMessageTemplate, isLoading } = useMessageTemplates();
  const { messagesList } = useMessages();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = () => {
    closeModal();
  };

  const columns: ColumnsType<IMessageTemplateDTO> = [
    {
      title: 'Nome do modelo',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mensagem',
      dataIndex: 'components',
      key: 'components',
      render: (_, { components, language }) => (
        <PreviewMessageTemplate data={components} language={language} />
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: () => (
        <Popconfirm
          title="Enviar modelo de mensagem"
          description="Tem certeza que  deseja enviar esse modelo de mensagem ?"
          onConfirm={onSubmit}
          okText="Sim"
          cancelText="Não"
        >
          <Button size="large" type="primary" icon={<SendOutlined />}>
            Enviar
          </Button>
        </Popconfirm>
      ),
    },
  ];

  if (isLoading || !listMessageTemplate) {
    return (
      <Button
        icon={<MessageOutlined />}
        shape="circle"
        size="large"
        type="text"
        loading
      />
    );
  }

  const forattedModelTemplates = listMessageTemplate.filter(
    item => item.status === 'APPROVED',
  );

  const isLastMessageNotSent = !!messagesList.find(
    item => item.msg_dir === 'o' && item.msg_status === 'w',
  );

  return (
    <>
      <Tooltip
        title={
          isLastMessageNotSent || isLastMessageNotSent
            ? 'Envie um modelo de mensagem para abrir conversa'
            : 'Enviar modelo de mensagem'
        }
        defaultOpen={!!isLastMessageNotSent}
      >
        <Button
          icon={<MessageOutlined />}
          shape="circle"
          size="large"
          type={isLastMessageNotSent ? 'primary' : 'text'}
          onClick={showModal}
          danger={!!isLastMessageNotSent}
        />
      </Tooltip>
      <Modal
        title="Modelos de mensagens"
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        width="60vw"
        footer={[]}
      >
        <Table
          dataSource={forattedModelTemplates}
          columns={columns}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default SendMessageTemplate;
