import React, { useState } from 'react';
import { Modal, Card, Button } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import TextComposerBroadcast from '../TextComposerBroadcast';
import { useWorkflow } from '~/hooks/workflow/workflow';
// import { Container } from './styles';

const BroadcastMessageModal: React.FC = () => {
  const { selectedCards } = useWorkflow();
  const [isVisible, setIsVisible] = useState(false);

  const handleShowModal = () => {
    setIsVisible(true);
  };

  const handleCloseModal = () => {
    setIsVisible(false);
  };

  return (
    <div>
      <Button
        type="link"
        icon={<NotificationOutlined />}
        onClick={handleShowModal}
      >
        {intl.get('modal_broadcast_message.send_message')}
      </Button>
      <Modal
        open={isVisible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        centered
        footer={null}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Card.Meta
            avatar={<NotificationOutlined />}
            title={intl.get('modal_broadcast_message.title', {
              total: selectedCards.length,
            })}
            description={intl.get('modal_broadcast_message.description')}
          />

          <TextComposerBroadcast />
        </div>
      </Modal>
    </div>
  );
};

export default BroadcastMessageModal;
