/* eslint-disable import/no-duplicates */
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import intl from 'react-intl-universal';
import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { ParamsProps } from '~/@types/params';
import { useLocales } from '../locales';

export interface BotFormResponseProps {
  id: number;
  name: string;
  is_active: boolean;
  start_msg: string;
  start_cmd: string;
  end_msg: string;
  end_cmd: string;
  response_timeout: string;
  msg_timeout: string;
  last_updated: Date;
  allow_web: boolean;
  language: string;
}

export interface BotFormProps {
  id: number;
  name: string;
  is_active: boolean;
  start_msg: string;
  start_cmd: string;
  end_msg: string;
  end_cmd: string;
  response_timeout: dayjs.Dayjs;
  msg_timeout: string;
  last_updated: Date;
  lastUpdatedFormatted: string;
  allow_web: boolean;
  language: string;
}

export interface UpdateBotFormProps {
  name: string;
  is_active?: boolean;
  start_msg?: string;
  start_cmd?: string;
  end_msg?: string;
  end_cmd?: string;
  response_timeout?: string;
  msg_timeout?: string;
  last_updated?: Date;
  allow_web?: boolean;
  language?: string;
}

interface BotFormContextData {
  form?: BotFormProps;
  updateBotForm: (data: UpdateBotFormProps) => void;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
}
const BotFormContext = createContext<BotFormContextData>(
  {} as BotFormContextData,
);

const BotFormProvider: React.FC = ({ children }) => {
  const { currentLocale } = useLocales();
  const { environment } = useEnvironment();

  const { botFormId } = useParams<ParamsProps>();

  const formatterDate = (value: Date) => {
    return format(value, 'dd/MM/yyyy HH:mm:ss aa', {
      locale: currentLocale === 'pt-BR' ? ptBR : es,
    });
  };

  const { data: form, isLoading, refetch, isError } = useQuery(
    [`formBot${botFormId}`, environment?.id, botFormId],
    async () => {
      if (!environment) {
        return undefined;
      }
      const response = await api.get<BotFormResponseProps>(
        `/zf/form/${botFormId}/`,
      );

      const { data } = response;

      const formFormatted: BotFormProps = {
        ...data,
        response_timeout: dayjs(data.response_timeout, 'HH:mm:ss'),
        lastUpdatedFormatted: formatterDate(new Date(data.last_updated)),
      };
      return formFormatted;
    },
  );

  const updateBotForm = async (data: UpdateBotFormProps) => {
    try {
      await api.put(`/zf/form/${form?.id}/`, {
        ...data,
        name: data.name ? data.name : form?.name,
      });

      refetch();
    } catch (error) {
      toast.error(intl.get('chatbot.update.error_update'));
    }
  };

  return (
    <BotFormContext.Provider
      value={{
        form,
        isLoading,
        isError,
        refetch,
        updateBotForm,
      }}
    >
      {children}
    </BotFormContext.Provider>
  );
};
function useBotForm(): BotFormContextData {
  const context = useContext(BotFormContext);

  if (!context) {
    throw new Error('useBotForm must be used within an BotFormProvider');
  }

  return context;
}

export { BotFormProvider, useBotForm };
