import React from 'react';
import {
  UserOutlined,
  LogoutOutlined,
  DownOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';

import { Menu, Avatar, Dropdown, Divider, Skeleton, Tag } from 'antd';

import { Link, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';

import { Container, Header, Settings, User, Children } from './styles';
import { useAuth } from '~/hooks/auth';
import SelectEnvironment from './SelectEnvironment';
import { stringToHslColor } from '~/util/stringToHslColor';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import { useUser } from '~/hooks/user';

import ChangeLocale from '../../atoms/ChangeLocale';
import DeviceStatus from './DeviceStatus';
import ModalSupportZapForm from './ModalSupportZapForm';
import ModalNewAttendance from '../../molecules/ModalNewAttendance';
import Notifications from './Notifications';
import { ParamsProps } from '~/@types/params';
import { useDevice } from '~/hooks/device';

interface NavbarProps {
  title?: string;
}
const Navbar: React.FC<NavbarProps> = ({ title, children }) => {
  const { environmentId } = useParams<ParamsProps>();
  const { signOut } = useAuth();
  const { user, isLoading } = useUser();
  const { device } = useDevice();

  return (
    <Container>
      <Header>
        <div>
          <b>{title}</b>
        </div>
        <Divider type="vertical" />
        <Children>{children}</Children>
      </Header>

      <Settings>
        <ModalNewAttendance />

        <Divider type="vertical" />
        {device && device.isWhatsAppOfficial && (
          <Tag icon={<WhatsAppOutlined />} color="#459B61">
            API Oficial
          </Tag>
        )}
        <DeviceStatus />

        <Notifications />

        <Divider type="vertical" />

        <SelectEnvironment />

        <Divider type="vertical" />

        <ChangeLocale />
        <ModalSupportZapForm />

        <Divider type="vertical" />

        <User>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link to={`/c/${environmentId}/account`}>
                    {intl.get('profile.title')}
                  </Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  key="2"
                  icon={<LogoutOutlined />}
                  onClick={signOut}
                  danger
                >
                  {intl.get('buttons.exit')}
                </Menu.Item>
              </Menu>
            }
            placement="bottomLeft"
          >
            <div className="avatar">
              {isLoading || !user ? (
                <Skeleton.Avatar active shape="circle" />
              ) : (
                <Link to={`/c/${environmentId}/account`}>
                  <Avatar
                    alt={user.name}
                    style={{
                      background: stringToHslColor(user.name),
                    }}
                  >
                    {createLetterAvatar(user.name)}
                  </Avatar>
                </Link>
              )}
              <DownOutlined style={{ marginLeft: '0.25rem' }} />
            </div>
          </Dropdown>
        </User>
      </Settings>
    </Container>
  );
};

export default Navbar;
