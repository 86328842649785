import { MessageOutlined } from '@ant-design/icons';
import { Select, Button, Tooltip, Drawer, Typography } from 'antd';
import intl from 'react-intl-universal';
import React, { useState } from 'react';
import BaseField from '~/components/global/atoms/BaseField';
import { useAttendance } from '~/hooks/attendances/attendance';
import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { useStatus } from '~/hooks/status/status';
import { SelectOptions } from '~/@types/fields';

const DrawerAttendance: React.FC = () => {
  const {
    attendance,
    isLoading,
    updateResponsible,
    moveAttendance,
  } = useAttendance();
  const { isLoading: isLoadingStatus, statusList } = useStatus();
  const {
    isLoading: isLoadingResponsiblesList,
    responsiblesList,
  } = useResponsibles();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };
  const onClose = () => {
    setIsDrawerVisible(false);
  };

  if (
    isLoading ||
    isLoadingStatus ||
    !statusList ||
    !attendance ||
    isLoadingResponsiblesList ||
    !responsiblesList
  ) {
    return <Button type="text" size="large" loading />;
  }

  const formattedResponsiblesList: SelectOptions[] = responsiblesList.map(
    responsible => ({
      name: responsible.name,
      label: responsible.name,
      value: responsible.id,
    }),
  );

  return (
    <div>
      <Tooltip title={intl.get('chat.title_bar.attendance_information')}>
        <Button
          type="text"
          size="large"
          icon={<MessageOutlined />}
          onClick={() => showDrawer()}
        />
      </Tooltip>
      <Drawer
        title={intl.get('chat.title_bar.attendance_information')}
        placement="left"
        onClose={onClose}
        open={isDrawerVisible}
        width="350px"
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <BaseField
          label={intl.get('attendance.id')}
          value={String(attendance.id)}
          copyable
        />

        <BaseField
          label={intl.get('attendance.order_summary')}
          name="order_summary"
          copyable
          value={attendance.order_summary}
        />

        <BaseField label={intl.get('attendance.status.title')}>
          <Select
            size="large"
            value={attendance.status?.code}
            onChange={value => {
              moveAttendance(value);
            }}
            style={{ width: '100%' }}
          >
            {statusList.map(state => (
              <Select.Option key={state.code} value={`${state.code}`}>
                {state.code} - {state.status}
              </Select.Option>
            ))}
          </Select>
        </BaseField>

        <BaseField label={intl.get('attendance.responsible.title')}>
          <Select
            value={attendance.responsible?.id}
            onChange={value => {
              updateResponsible(Number(value));
            }}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            placeholder={intl.get(
              'attendance.filters.responsible_for_the_service_placeholder',
            )}
            filterSort={(optionA, optionB) =>
              optionA?.name
                .toLowerCase()
                .localeCompare(optionB.name.toLowerCase())
            }
            options={formattedResponsiblesList}
          />
        </BaseField>

        <BaseField
          label={intl.get('attendance.creation_date')}
          value={attendance.time_createdFormatted}
        />

        <BaseField
          label={intl.get('attendance.creation_date_there')}
          value={attendance.time_createdThere}
        />

        <BaseField
          label={intl.get('attendance.date_of_status_change')}
          value={attendance.time_statusFormatted}
        />

        <BaseField label={intl.get('attendance.deadline')}>
          <Typography.Paragraph>
            {attendance.time_deadlineFormatted || 'Sem prazo'}
          </Typography.Paragraph>
        </BaseField>

        <BaseField label={intl.get('attendance.deadline_expiration')}>
          <Typography.Paragraph
            style={{
              color:
                attendance.time_howIsTimeout === 'timeout'
                  ? 'red'
                  : attendance.time_howIsTimeout === 'insideTime'
                  ? '#faad14'
                  : 'none',
            }}
          >
            {attendance.time_timeoutFormatted ||
              intl.get('attendance.no_deadline')}
          </Typography.Paragraph>
        </BaseField>
      </Drawer>
    </div>
  );
};

export default DrawerAttendance;
