import React, { useState } from 'react';
import { Card, Divider } from 'antd';
import intl from 'react-intl-universal';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useBotmFormQuestions } from '~/hooks/bot/botFormQuestions';

import { Container } from './styles';
import QuestionItem from '../QuestionItem';

const QuestionsList: React.FC = () => {
  const {
    totalQuestions,
    questionsList,
    isLoading,
    changeQuestionOrder,
  } = useBotmFormQuestions();

  const [questionOpened, setQuestionOpened] = useState(1);

  const changeQuestionOpened = (value: number) => {
    setQuestionOpened(value);
  };

  const onDragEnd = async (result: DropResult) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    changeQuestionOrder(result.source.index, result.destination.index);
  };

  if (isLoading || !questionsList) {
    return <Card loading />;
  }

  return (
    <>
      <Divider orientation="center">
        {intl.get('chatbot.question.counter', { total: totalQuestions })}
      </Divider>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <Container {...provided.droppableProps} ref={provided.innerRef}>
              {questionsList.map((question, index) => (
                <QuestionItem
                  key={question.order}
                  order={index}
                  data={question}
                  onChangeQuestionOpened={changeQuestionOpened}
                  questionOpened={questionOpened}
                />
              ))}
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default QuestionsList;
