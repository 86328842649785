import { Image, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import ReactPlayer from 'react-player';

import Paragraph from 'antd/lib/typography/Paragraph';

import { useParams } from 'react-router-dom';
import { StopOutlined } from '@ant-design/icons';
import Document from '~/components/global/atoms/Document';
import { MessageProps } from '~/hooks/messages/messages';
import { Container } from './styles';
import Address from '~/components/global/atoms/Address';
import { formatterMessageText } from '~/util/formatMessageText';
import api from '~/services/api';
import { ParamsProps } from '~/@types/params';
import { useContact } from '~/hooks/contacts/contact';
import VCards from '../VCards';

interface QuotedMessageProps {
  messageId: number;
}

const QuotedMessage: React.FC<QuotedMessageProps> = ({ messageId }) => {
  const { contact } = useContact();
  const { environmentId } = useParams<ParamsProps>();
  const [message, setMessage] = useState<MessageProps | null>(null);
  const getMessage = useCallback(async () => {
    if (!environmentId || !contact || !messageId) return;
    const { data } = await api.get<MessageProps>(
      `/zc/${environmentId}/client/${contact.id}/message/${messageId}/`,
    );
    setMessage(data);
  }, [environmentId, contact, messageId]);
  useEffect(() => {
    getMessage();
  }, [getMessage, environmentId, contact, messageId]);
  if (!message)
    return (
      <Container>
        <Skeleton active paragraph={{ rows: 2 }} />
      </Container>
    );
  const isVCards =
    !message.deleted &&
    message.msg_type === 'ch' &&
    message.msg_data.text.includes('#VCARD@;');
  return (
    <Container dir={message.msg_dir}>
      <div className="name">
        {message.msg_dir === 'i' ? contact?.name : intl.get('message.you')}
      </div>
      {message.deleted ? (
        <i style={{ color: 'var(--gray-9)' }}>
          <StopOutlined /> {intl.get('chat.message_list.message.deleted')}
        </i>
      ) : (
        <>
          {
            {
              ch: isVCards ? (
                <VCards data={message.msg_data.text} />
              ) : (
                <Paragraph>
                  {formatterMessageText(message.msg_data.text)}
                </Paragraph>
              ),
              im: (
                <>
                  <Image
                    src={message.msg_data.url}
                    alt={intl.get('chat.message_list.message.image')}
                  />

                  {message.msg_data.description && (
                    <Paragraph>
                      {formatterMessageText(message.msg_data.description)}
                    </Paragraph>
                  )}
                </>
              ),
              au: (
                <ReactPlayer
                  controls
                  width="100%"
                  height="60px"
                  url={message.msg_data.url}
                />
              ),
              pt: (
                <ReactPlayer
                  controls
                  width="100%"
                  height="60px"
                  url={message.msg_data.url}
                />
              ),
              do: <Document url={message.msg_data.url} />,
              lo: (
                <Address
                  lat={message.msg_data.lat}
                  lng={message.msg_data.lng}
                />
              ),
              vi: (
                <ReactPlayer controls width="100%" url={message.msg_data.url} />
              ),
            }[message.msg_type]
          }
        </>
      )}
    </Container>
  );
};

export default QuotedMessage;
