import React from 'react';

import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';
import {
  formatCategory,
  formatRejectedReason,
  formatStatus,
} from './formatTexts';

import PreviewMessageTemplate from '../PreviewMessageTemplate';
import {
  IMessageTemplateDTO,
  useMessageTemplates,
} from '~/hooks/device/messageTemplates';
import ModalEditMessageTemplate from '../ModalEditMessageTemplate';

interface IListMessageTemplates {
  data: IMessageTemplateDTO[];
}

const ListMessageTemplates: React.FC<IListMessageTemplates> = ({ data }) => {
  const { deleteMessageTemplate } = useMessageTemplates();
  const columns: ColumnsType<IMessageTemplateDTO> = [
    {
      title: 'Nome do modelo',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mensagem',
      dataIndex: 'components',
      key: 'components',
      render: (_, { components, language }) => (
        <PreviewMessageTemplate data={components} language={language} />
      ),
    },
    {
      title: 'Categoria',
      dataIndex: 'category',
      key: 'category',
      render: (_, { category }) => <>{formatCategory(category)}</>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => <>{formatStatus(status)}</>,
    },
    {
      title: 'Violações',
      dataIndex: 'rejected',
      key: 'rejected',
      render: (_, { rejected_reason }) => (
        <>{formatRejectedReason(rejected_reason)}</>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, messageTemplate) => {
        const { name, status } = messageTemplate;
        if (name === 'hello_world') return <></>;
        return (
          <div style={{ display: 'flex', gap: '1rem' }}>
            {(status === 'APPROVED' ||
              status === 'REJECTED' ||
              status === 'PAUSED') && (
              <ModalEditMessageTemplate data={messageTemplate} />
            )}
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                deleteMessageTemplate.mutateAsync(name);
              }}
              loading={deleteMessageTemplate.isLoading}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table dataSource={data} columns={columns} pagination={false} />
    </>
  );
};

export default ListMessageTemplates;
