/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CloseOutlined,
  SendOutlined,
  SmileOutlined,
  SmileTwoTone,
} from '@ant-design/icons';
import { Button, Input } from 'antd';
import Picker, { IEmojiData } from 'emoji-picker-react';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useBroadcastMessages } from '~/hooks/messages/broadcastMessages';
import { useWorkflow } from '~/hooks/workflow/workflow';
import { Container, Wrapper } from './styles';

const TextComposerBroadcast: React.FC = () => {
  const { selectedCards } = useWorkflow();
  const { sendBroadcastMessages, isLoading } = useBroadcastMessages();

  const [isPickEmojiVisible, setIsPickEmojiVisible] = useState(false);

  const inputRef = React.useRef<any>(null);
  const [messageText, setMessageText] = useState('');

  const onEmojiClick = (event: React.MouseEvent, emojiObject: IEmojiData) => {
    setMessageText(messageTextOld => `${messageTextOld}${emojiObject.emoji}`);
    setIsPickEmojiVisible(false);
  };

  const handleSendMessage = () => {
    sendBroadcastMessages(selectedCards, messageText);
    setMessageText('');
  };

  return (
    <Wrapper>
      {isPickEmojiVisible ? (
        <div style={{ position: 'absolute', marginTop: '-325px' }}>
          <Picker
            onEmojiClick={onEmojiClick}
            preload
            groupNames={{
              smileys_people: intl.get('text_composer.emojis.smileys_people'),
              animals_nature: intl.get('text_composer.emojis.animals_nature'),
              food_drink: intl.get('text_composer.emojis.food_drink'),
              travel_places: intl.get('text_composer.emojis.travel_places'),
              activities: intl.get('text_composer.emojis.activities'),
              objects: intl.get('text_composer.emojis.objects'),
              symbols: intl.get('text_composer.emojis.symbols'),
              flags: intl.get('text_composer.emojis.flags'),
              recently_used: intl.get('text_composer.emojis.recently_used'),
            }}
            groupVisibility={{
              recently_used: false,
            }}
          />
        </div>
      ) : (
        ''
      )}
      <Container>
        <>
          {isPickEmojiVisible && (
            <Button
              size="large"
              type="text"
              icon={<CloseOutlined />}
              onClick={() => {
                setIsPickEmojiVisible(false);
              }}
            />
          )}

          <Button
            size="large"
            type="text"
            shape="circle"
            icon={isPickEmojiVisible ? <SmileTwoTone /> : <SmileOutlined />}
            value={messageText}
            onClick={() => {
              setIsPickEmojiVisible(!isPickEmojiVisible);
            }}
          />

          <Input.TextArea
            size="large"
            className="inputText"
            placeholder={intl.get('text_composer.type_a_message')}
            autoSize={{ minRows: 2, maxRows: 8 }}
            ref={inputRef}
            value={messageText}
            onChange={e => {
              setMessageText(e.target.value);
            }}
            onKeyDown={event => {
              if (event.keyCode === 13 && !event.shiftKey) {
                event.preventDefault();

                handleSendMessage();
              }
            }}
          />
        </>
      </Container>

      <Button
        size="large"
        type="primary"
        block
        loading={isLoading}
        icon={<SendOutlined />}
        onClick={() => {
          handleSendMessage();
        }}
        disabled={!messageText}
      >
        {isLoading
          ? intl.get('text_composer.sending')
          : intl.get('text_composer.send')}
      </Button>
    </Wrapper>
  );
};

export default TextComposerBroadcast;
