/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, SmileOutlined, SmileTwoTone } from '@ant-design/icons';
import { Button, Input } from 'antd';
import Picker, { IEmojiData } from 'emoji-picker-react';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useCampaign } from '~/hooks/campaigns/campaign';
import { Container, Wrapper } from './styles';

const TextComposerCampaigns: React.FC = () => {
  const { message, setNewMessage } = useCampaign();
  const [isPickEmojiVisible, setIsPickEmojiVisible] = useState(false);

  const inputRef = React.useRef<any>(null);

  const onEmojiClick = (event: React.MouseEvent, emojiObject: IEmojiData) => {
    setNewMessage(`${message} ${emojiObject.emoji} `);
    setIsPickEmojiVisible(false);
  };

  const handleSetMessage = (newMessage: string) => {
    setNewMessage(newMessage);
  };

  return (
    <Wrapper>
      {isPickEmojiVisible ? (
        <div style={{ position: 'absolute', marginTop: '-325px' }}>
          <Picker
            onEmojiClick={onEmojiClick}
            preload
            groupNames={{
              smileys_people: intl.get('text_composer.emojis.smileys_people'),
              animals_nature: intl.get('text_composer.emojis.animals_nature'),
              food_drink: intl.get('text_composer.emojis.food_drink'),
              travel_places: intl.get('text_composer.emojis.travel_places'),
              activities: intl.get('text_composer.emojis.activities'),
              objects: intl.get('text_composer.emojis.objects'),
              symbols: intl.get('text_composer.emojis.symbols'),
              flags: intl.get('text_composer.emojis.flags'),
              recently_used: intl.get('text_composer.emojis.recently_used'),
            }}
            groupVisibility={{
              recently_used: false,
            }}
          />
        </div>
      ) : (
        ''
      )}
      <Container>
        <>
          {isPickEmojiVisible && (
            <Button
              size="large"
              type="text"
              icon={<CloseOutlined />}
              onClick={() => {
                setIsPickEmojiVisible(false);
              }}
            />
          )}

          <Button
            size="large"
            type="text"
            shape="circle"
            icon={isPickEmojiVisible ? <SmileTwoTone /> : <SmileOutlined />}
            value={message || ''}
            onClick={() => {
              setIsPickEmojiVisible(!isPickEmojiVisible);
            }}
          />

          <Input.TextArea
            size="large"
            className="inputText"
            placeholder={intl.get('campaign.message.type_campaign_message')}
            autoSize={{ minRows: 3, maxRows: 10 }}
            ref={inputRef}
            value={message || ''}
            onChange={e => {
              handleSetMessage(e.target.value);
            }}
          />
        </>
      </Container>
    </Wrapper>
  );
};

export default TextComposerCampaigns;
