/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext } from 'react';
import { format, formatDistance, differenceInHours, isBefore } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { ParamsProps } from '~/@types/params';
import {
  AttendanceProps,
  AttendanceResponseProps,
  FormFieldProps,
} from '~/@types/attendance';
import { FieldProps } from '~/@types/fields';
import { useLocales } from '../locales';
import formatPhoneNumber from '~/util/formatPhoneNumber';

interface AttendanceContextData {
  attendance?: AttendanceProps;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  updateAttendance: (title: string, value: string) => void;
  updateAttendanceFormOrder: (order: any) => Promise<void>;
  moveAttendance: (statusCode: string) => void;
  updateResponsible: (responsibleId: number) => void;
  sendNote: (note: string) => Promise<void>;
  newFormField: (title: string, typeField: FormFieldProps) => Promise<void>;
}

const { confirm } = Modal;

export const AttendanceContext = createContext<AttendanceContextData>(
  {} as AttendanceContextData,
);

interface AttendanceProviderProps {
  attendanceId?: number;
}
const AttendanceProvider: React.FC<AttendanceProviderProps> = ({
  children,
  attendanceId: externalAttendanceId,
}) => {
  const { currentLocale } = useLocales();
  const { environment, refetch: refetchEnvironment } = useEnvironment();
  const {
    attendanceId: paramAttendanceId,
    environmentId,
  } = useParams<ParamsProps>();
  const attendanceId = externalAttendanceId || paramAttendanceId;

  const formatterDate = (value: Date) => {
    return format(value, 'dd/MM/yyyy HH:mm:ss aa', {
      locale: currentLocale === 'pt-BR' ? ptBR : es,
    });
  };

  const {
    data: attendance,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery(
    [
      `attendance${attendanceId}`,
      attendanceId,
      externalAttendanceId,
      environment?.id,
    ],
    async () => {
      if (!environment) {
        return undefined;
      }
      const response = await api.get(
        `zc/${environment.id}/order/${attendanceId}/`,
      );

      const { data } = response;

      const attendenceFormatted = await formatterAttendance(data);

      return attendenceFormatted;
    },
  );

  const formatterAttendance = async (
    data: AttendanceResponseProps,
  ): Promise<AttendanceProps> => {
    const howIsTimeout = ():
      | 'timeout'
      | 'insideTime'
      | 'distantTime'
      | undefined => {
      if (!data.time_timeout) {
        return undefined;
      }
      return isBefore(new Date(data.time_timeout), new Date())
        ? 'timeout'
        : differenceInHours(new Date(data.time_timeout), new Date()) <= 24
        ? 'insideTime'
        : 'distantTime';
    };

    const status_historyFormatted = data.status_history.map(status_history => {
      return {
        ...status_history,
        time_createdFormatted: formatterDate(
          new Date(status_history.time_created),
        ),
      };
    });

    const attencandeResponse: AttendanceProps = {
      id: data.id,
      idFormatted: `#${data.id}`,
      order_summary: data.order_summary,
      order: data.order,
      time_created: data.time_created,
      time_createdFormatted: formatterDate(new Date(data.time_created)),
      time_createdThere: formatDistance(
        new Date(data.time_created),
        new Date(),
        {
          includeSeconds: true,
          addSuffix: true,
          locale: currentLocale === 'pt-BR' ? ptBR : es,
        },
      ),
      time_status: data.time_status,
      time_statusFormatted: formatterDate(new Date(data.time_status)),
      time_deadline: data.time_timeout,
      time_deadlineFormatted:
        data.time_timeout && formatterDate(new Date(data.time_timeout)),
      time_howIsTimeout: howIsTimeout(),
      time_timeoutFormatted:
        data.time_timeout &&
        formatDistance(new Date(data.time_timeout), new Date(), {
          includeSeconds: true,
          addSuffix: true,
          locale: currentLocale === 'pt-BR' ? ptBR : es,
        }),
      responsible: {
        ...data.location,
        id_name: `#${data.location.id} - ${data.location.name}`,
      },
      contact: {
        ...data.client,
        number_formatted: formatPhoneNumber(data.client.number),
        id_name: `#${data.client.id} - ${data.client.name}`,
      },
      status: {
        ...data.status,
        code_status: `${data.status.code} - ${data.status.status}`,
      },
      status_history: status_historyFormatted.reverse(),
    };
    return attencandeResponse;
  };

  const updateAttendance = async (title: string, value: string) => {
    if (attendance?.order.title === value) {
      return;
    }
    try {
      await api.put(`/zc/${environmentId}/order/${attendanceId}/`, {
        client: attendance?.contact.id,
        location: attendance?.responsible.id,
        order: { [title]: value },
      });

      refetch();
    } catch (error) {
      toast.error(intl.get('attendance.update.error_update'));
    }
  };

  const newFormField = async (title: string, typeField: FormFieldProps) => {
    if (!attendance) return;
    const formattedTitle = `${title} 🔅`;
    let value: any = '';
    switch (typeField) {
      case 'number':
        value = 0;
        break;
      case 'boolean':
        value = 'True';
        break;
      case 'image':
        value = { url: '', _type: 'image' };
        break;
      case 'audio':
        value = { url: '', _type: 'audio' };
        break;
      case 'video':
        value = { url: '', _type: 'video' };
        break;
      default:
        break;
    }

    try {
      await api.patch(`/zc/${environmentId}/order/${attendanceId}/`, {
        client: attendance.contact.id,
        location: attendance.responsible.id,
        order: { ...attendance.order, [formattedTitle]: value },
      });

      refetch();
    } catch (error) {
      toast.error(intl.get('attendance.update.error_update_field_form'));
    }
  };

  const updateAttendanceFormOrder = async (order: FieldProps[]) => {
    if (!attendance) {
      return;
    }

    try {
      await api.put(`/zc/${environmentId}/order/${attendanceId}/`, {
        client: attendance.contact.id,
        location: attendance.responsible.id,
        order,
      });

      refetch();
    } catch (error) {
      toast.error(intl.get('attendance.update.error_update_field_form'));
    }
  };

  const moveAttendance = (statusCode: string) => {
    confirm({
      title: intl.get('attendance.update.confirm_status_change', { total: 1 }),
      icon: <ExclamationCircleOutlined />,
      okText: intl.get('buttons.yes'),
      okType: 'danger',
      cancelText: intl.get('buttons.no'),
      onOk: async () => {
        try {
          await api.post(
            `/zc/${environmentId}/order/${attendanceId}/change_status/`,
            {
              next_status_code: statusCode,
            },
          );
          refetch();
          refetchEnvironment();
        } catch (error) {
          toast.error(intl.get('attendance.update.error_change_status'));
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel() {},
    });
  };

  const sendNote = async (note: string) => {
    try {
      const { data } = await api.get<AttendanceResponseProps>(
        `/zc/${environmentId}/order/${attendanceId}/`,
      );

      await api.post(
        `/zc/${environmentId}/order/${attendanceId}/change_status/`,
        {
          next_status_code: data.status.code,
          next_status_data: {
            source: 'change',
            message: note,
          },
        },
      );
      refetch();
    } catch (error) {
      toast.error(intl.get('log.error_sending'));
    }
  };

  const updateResponsible = async (responsibleId: number) => {
    try {
      await api.put(`/zc/${environmentId}/order/${attendanceId}/`, {
        location: responsibleId,
        client: attendance?.contact.id,
      });
      refetch();
      refetchEnvironment();
    } catch (error) {
      toast.error(intl.get('attendance.update.error_update_responsible'));
    }
  };

  return (
    <AttendanceContext.Provider
      value={{
        attendance,
        isLoading,
        isFetching,
        isError,
        updateAttendance,
        updateAttendanceFormOrder,
        moveAttendance,
        updateResponsible,
        sendNote,
        newFormField,
      }}
    >
      {children}
    </AttendanceContext.Provider>
  );
};
function useAttendance(): AttendanceContextData {
  const context = useContext(AttendanceContext);

  if (!context) {
    throw new Error('useAttendance must be used within an AttendanceProvider');
  }

  return context;
}

export { AttendanceProvider, useAttendance };
