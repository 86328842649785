import styled from 'styled-components';
import backgroundChat from '~/assets/images/chat/background.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - var(--global-header-height));
  position: relative;
  overflow: hidden;
  background: url(${backgroundChat}) rgb(174, 216, 199) !important;
  /* background-repeat: repeat; */
  /* background-color: rgb(174, 216, 199); */
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.5rem;
  flex: 0 0 350px;
  max-width: 350px;
  gap: 0.5rem;
`;

export const RowChatList = styled.div`
  overflow: auto;
  flex: 1 1 auto;
  overflow: hidden;
  background: #fff;
  border-radius: var(--radius);
`;

export const Alert = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
