import styled from 'styled-components';

interface ContainerProps {
  dir?: 'i' | 'o';
}

export const Container = styled.div<ContainerProps>`
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 0.5rem;
  border-radius: 2px;
  padding: 0.25rem;
  border-left: 3px solid ${props => (props.dir === 'i' ? '#00a761' : '#49b9ee')};
  max-width: 450px;
  max-height: 150px;
  overflow: auto;
  .name {
    color: ${props => (props.dir === 'i' ? '#00a761' : '#49b9ee')};
    font-weight: bold;
    padding-bottom: 0.5rem;
  }
`;
