import { Pagination, Table } from 'antd';
import intl from 'react-intl-universal';
import React, { memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { Container } from './styles';
import { ParamsProps } from '~/@types/params';

const ResponsiblesList: React.FC = () => {
  const { environmentId } = useParams<ParamsProps>();
  const history = useHistory();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: intl.get('responsible.name'),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: intl.get('responsible.address'),
      dataIndex: 'address',
      key: 'address',
      width: '40%',
    },
  ];

  const {
    responsiblesList,
    pageSize,
    moreResponsibles,
    totalResponsibles,
    isLoading,
    isFetching,
  } = useResponsibles();

  if (isLoading) {
    return <Table loading />;
  }

  return (
    <Container>
      <Table
        columns={columns}
        scroll={{
          y: 'calc(100vh - 260px)',
        }}
        pagination={false}
        loading={isFetching}
        dataSource={responsiblesList}
        bordered
        rowKey="id"
        onRow={row => {
          return {
            onClick: () => {
              history.push(`/c/${environmentId}/responsibles/${row.id}`);
            },
          };
        }}
      />
      <Pagination
        defaultCurrent={1}
        pageSize={pageSize}
        onChange={(page: number, pageSizeDefault?: number) => {
          moreResponsibles(page, pageSizeDefault || pageSize);
        }}
        showSizeChanger
        total={totalResponsibles}
      />
    </Container>
  );
};

export default memo(ResponsiblesList, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
