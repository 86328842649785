import React, { useState } from 'react';
import { Steps, Button, Card, Typography, Result, Alert } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import CampaignFilters from './Filters';
import { Container, StepContent, StepAction } from './styles';
import { useCampaign } from '~/hooks/campaigns/campaign';
import TextComposerCampaigns from './TextComposerCampaigns';

const { Step } = Steps;

const Campaign: React.FC = () => {
  const history = useHistory();
  const [step, setStep] = useState(0);

  const {
    totalContacts,
    message,
    sendCampaign,
    isLoading,
    isSent,
    isValidateFilters,
  } = useCampaign();

  const next = () => {
    setStep(step + 1);
  };

  const prev = () => {
    setStep(step - 1);
  };

  const steps = [
    {
      title: intl.get('campaign.filter.title'),
      description: '',
    },
    {
      title: intl.get('campaign.message.title'),
      description: '',
    },
    {
      title: intl.get('campaign.completion.title'),
      description: '',
    },
  ];

  return (
    <Container>
      <Card bodyStyle={{ textAlign: 'center' }}>
        <Typography.Title level={3}>
          {intl.get('campaign.campaign_reach')}
        </Typography.Title>

        {!totalContacts && totalContacts === 0 ? (
          <div>
            <Typography.Title level={4}>
              {intl.get('campaign.out_of_reach')}
            </Typography.Title>
            <Alert
              message={intl.get('campaign.range_alert')}
              type="info"
              showIcon
            />
          </div>
        ) : (
          <div>
            <Typography.Title level={4}>
              {intl.get('campaign.counter_reach', { total: totalContacts })}
            </Typography.Title>
          </div>
        )}
      </Card>
      <Card>
        <Steps current={step}>
          {steps.map(item => (
            <Step
              key={item.title}
              title={item.title}
              description={item.description}
            />
          ))}
        </Steps>
      </Card>

      <div>
        <StepContent visible={step === 0}>
          <CampaignFilters />
          <StepAction>
            <div />

            <Button
              type="primary"
              onClick={() => next()}
              size="large"
              disabled={totalContacts === 0 || !isValidateFilters}
            >
              {intl.get('buttons.next')}
            </Button>
          </StepAction>
        </StepContent>

        <StepContent visible={step === 1}>
          <Card title={intl.get('campaign.message.campaign_message')}>
            <TextComposerCampaigns />
          </Card>
          <StepAction>
            <Button onClick={() => prev()} size="large">
              {intl.get('buttons.return')}
            </Button>

            <Button
              type="primary"
              onClick={() => next()}
              size="large"
              disabled={!(message && message !== '')}
            >
              {intl.get('buttons.next')}
            </Button>
          </StepAction>
        </StepContent>

        <StepContent visible={step === 2}>
          {isSent === null ? (
            <Result
              icon={<NotificationOutlined />}
              title={intl.get('campaign.completion.alert_info.title')}
              subTitle={intl.get('campaign.completion.alert_info.description')}
              extra={
                <Button
                  type="primary"
                  onClick={() => sendCampaign()}
                  size="large"
                  loading={isLoading}
                >
                  {intl.get('campaign.completion.alert_info.send')}
                </Button>
              }
            />
          ) : (
            <>
              {isSent ? (
                <Result
                  status="success"
                  title={intl.get('campaign.completion.alert_success.title')}
                  subTitle={intl.get(
                    'campaign.completion.alert_success.description',
                  )}
                  extra={[
                    <Button key="newCampaign" onClick={() => history.go(0)}>
                      {intl.get('campaign.completion.alert_success.new')}
                    </Button>,
                  ]}
                />
              ) : (
                <Result
                  status="error"
                  title={intl.get('campaign.completion.alert_error.title')}
                  subTitle={intl.get(
                    'campaign.completion.alert_error.description',
                  )}
                />
              )}
            </>
          )}

          <StepAction>
            <Button onClick={() => prev()} size="large">
              {intl.get('buttons.return')}
            </Button>

            <div />
          </StepAction>
        </StepContent>
      </div>
    </Container>
  );
};

export default Campaign;
