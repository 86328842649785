import { SettingOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Select, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import React, { useEffect, useState } from 'react';

import { useCallCenter, SettingsProps } from '~/hooks/callCenter/callCenter';

const { Option, OptGroup } = Select;

const ChatSettings: React.FC = () => {
  const { settings, setNewSettings, resetSettings } = useCallCenter();
  const [form] = Form.useForm();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    form.setFieldsValue(settings);
  }, [settings, form]);

  const showDrawer = () => {
    setIsVisible(true);
  };
  const onClose = () => {
    setIsVisible(false);
  };

  const onFieldsChange = (allValues: SettingsProps) => {
    setNewSettings(allValues);
  };

  return (
    <>
      <Tooltip title={intl.get('chat.settings.title')}>
        <Button
          type="text"
          size="large"
          icon={<SettingOutlined />}
          onClick={showDrawer}
        />
      </Tooltip>
      <Drawer
        title={intl.get('chat.settings.title')}
        placement="top"
        height="100%"
        closable
        onClose={onClose}
        open={isVisible}
        getContainer={false}
        style={{ position: 'absolute' }}
        footer={
          <Button
            type="link"
            onClick={() => {
              resetSettings();
            }}
            block
          >
            {intl.get('buttons.reset_settings')}
          </Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onValuesChange={(changedValues, allValues) =>
            onFieldsChange(allValues)
          }
          initialValues={settings}
        >
          <Form.Item label={intl.get('chat.settings.title_card')} name="title">
            <Select mode="multiple" style={{ width: '100%' }} showArrow>
              <OptGroup label={intl.get('attendance.contact.title')}>
                <Option value="contact.avatar">
                  {intl.get('attendance.contact.avatar')}
                </Option>
                <Option value="contact.number">
                  {intl.get('attendance.contact.number')}
                </Option>
                <Option value="attendance.status">
                  {intl.get('attendance.status.code_and_title')}
                </Option>
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.get('chat.settings.sub_titles_card')}
            name="subTitles"
          >
            <Select mode="multiple" style={{ width: '100%' }} showArrow>
              <OptGroup label={intl.get('attendance.title')}>
                <Option value="attendance.order_summary">
                  {intl.get('attendance.order_summary')}
                </Option>
                <Option value="attendance.time_created">
                  {intl.get('attendance.creation_date')}
                </Option>
                <Option value="attendance.time_createdThere">
                  {intl.get('attendance.creation_date_there')}
                </Option>
                <Option value="attendance.time_status">
                  {intl.get('attendance.date_of_status_change')}
                </Option>
                <Option value="attendance.time_deadline">
                  {intl.get('attendance.deadline')}
                </Option>
                <Option value="attendance.time_timeout">
                  {intl.get('attendance.deadline_expiration')}
                </Option>
              </OptGroup>

              <OptGroup label={intl.get('attendance.responsible.title')}>
                <Option value="responsible.name">
                  {intl.get('attendance.responsible.name')}
                </Option>
                <Option value="responsible.address">
                  {intl.get('attendance.responsible.address')}
                </Option>
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item label={intl.get('sort_by.title')}>
            <Input.Group compact>
              <Form.Item name={['sortBy', 'sort']}>
                <Select>
                  <Option value="asc">{intl.get('sort_by.asc')}</Option>
                  <Option value="desc">{intl.get('sort_by.desc')}</Option>
                </Select>
              </Form.Item>
              <Form.Item name={['sortBy', 'by']}>
                <Select
                  style={{ width: '100%' }}
                  placeholder={intl.get('sort_by.title')}
                >
                  <Option value="last_msg">
                    {intl.get('sort_by.last_msg')}
                  </Option>
                  <Option value="name">{intl.get('sort_by.name')}</Option>
                  <Option value="number_unread_msgs">
                    {intl.get('sort_by.number_unread_msgs')}
                  </Option>
                  <Option value="id">{intl.get('sort_by.id')}</Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Form>
        <Button type="primary" onClick={onClose} block>
          {intl.get('buttons.save_settings')}
        </Button>
      </Drawer>
    </>
  );
};

export default ChatSettings;
