import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import ModalAttendance from '~/components/global/organisms/ModalAttendance';
import { useAttendance } from '~/hooks/attendances/attendance';
import { ParamsProps } from '~/@types/params';

const ShowAttendance: React.FC = () => {
  const history = useHistory();
  const { environmentId } = useParams<ParamsProps>();
  const { isError } = useAttendance();
  if (isError) {
    toast.error(intl.get('attendance.not_found.title'));
    history.push(`/c/${environmentId}/workflow/`);
    return <></>;
  }
  return (
    <>
      <ModalAttendance />
    </>
  );
};

export default ShowAttendance;
