import React, { memo } from 'react';
import { Descriptions, Divider, Spin } from 'antd';
import intl from 'react-intl-universal';

import { LoadingOutlined } from '@ant-design/icons';
import { useAttendance } from '~/hooks/attendances/attendance';
import { Container, Footer, Header, Loading, Wreaper } from './styles';
import Logo from '~/assets/logo.svg';
import FormAttendanceView from '~/components/global/molecules/FormAttendanceView';
import AttendanceLog from '~/components/global/molecules/AttendanceLog';
import { useEnvironment } from '~/hooks/environments/environment';

const ComponentToPrint: React.FC = () => {
  const { attendance, isLoading, isError } = useAttendance();
  const { environment, isLoading: isLoadingEnvironment } = useEnvironment();

  if (isLoading || !attendance || isLoadingEnvironment || !environment) {
    return (
      <Loading>
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: '5rem' }} spin />}
        />
      </Loading>
    );
  }
  if (isError) {
    return <>Error</>;
  }

  return (
    <Wreaper>
      <Header>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <img src={Logo} alt="Logo ZapForm" style={{ height: '45px' }} />
          ZapForm
        </div>
        <Divider type="vertical" />
        <div>{environment.name}</div>
      </Header>
      <Container>
        <Descriptions
          title={`${intl.get('attendance.title')} - #${attendance.id}`}
          layout="vertical"
          bordered
          size="small"
        >
          <Descriptions.Item label="Status">
            {attendance.status.code_status}
          </Descriptions.Item>
          <Descriptions.Item label={intl.get('responsible.title')}>
            {attendance.responsible.name}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Descriptions layout="vertical" bordered column={4} size="small">
          <Descriptions.Item label={intl.get('modal_attendance.start')}>
            {attendance.time_createdFormatted}
          </Descriptions.Item>
          <Descriptions.Item label={intl.get('attendance.creation_date_there')}>
            {attendance.time_createdThere}
          </Descriptions.Item>
          <Descriptions.Item label={intl.get('modal_attendance.status_change')}>
            {attendance.time_statusFormatted}
          </Descriptions.Item>
          <Descriptions.Item label={intl.get('modal_attendance.deadline')}>
            {attendance.time_deadlineFormatted ||
              intl.get('modal_attendance.no_deadline')}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Descriptions
          title={intl.get('modal_attendance.contact_information')}
          layout="vertical"
          bordered
          size="small"
        >
          <Descriptions.Item label={intl.get('contact.id')}>
            {attendance.contact.id}
          </Descriptions.Item>
          <Descriptions.Item label={intl.get('contact.name')}>
            {attendance.contact.name}
          </Descriptions.Item>
          <Descriptions.Item label={intl.get('contact.number')}>
            {attendance.contact.number_formatted}
          </Descriptions.Item>
        </Descriptions>

        {attendance.order_summary && (
          <>
            <br />
            <Descriptions layout="vertical" bordered size="small">
              <Descriptions.Item label={intl.get('attendance.order_summary')}>
                {attendance.order_summary}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}

        <br />
        <Descriptions
          title={intl.get('modal_attendance.answered_form')}
          bordered
        />
        {attendance.order ? (
          <FormAttendanceView questions={attendance.order} />
        ) : (
          intl.get('modal_attendance.no_answered')
        )}

        <br />
        <Descriptions
          title={intl.get('modal_attendance.attendance_log')}
          bordered
        >
          <Descriptions.Item>
            <AttendanceLog />
          </Descriptions.Item>
        </Descriptions>
      </Container>
      <Footer>
        <div>Relatório gerado por www.zapform.com.br</div>
      </Footer>
    </Wreaper>
  );
};

export default memo(ComponentToPrint, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
