import React from 'react';
import ModalNewResponsible from './ModalNewResponsible';
import ResponsiblesList from './ResponsiblesList';

import { Container } from './styles';

const Responsibles: React.FC = () => {
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <ModalNewResponsible />
      </div>

      <ResponsiblesList />
    </Container>
  );
};

export default Responsibles;
