import React, { memo } from 'react';
import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import { useAttendance } from '~/hooks/attendances/attendance';
import { Container, Line, Label, Loading, Title, SubTitle } from './styles';

import { useEnvironment } from '~/hooks/environments/environment';

type IForm = {
  [key in
    | '01. NF:'
    | '02. CLIENTE:'
    | '03. CONTATO CLIENTE:'
    | '04. LOJA:'
    | '05. VENDEDOR:'
    | '06. CONTATO VENDEDOR:'
    | '07. ENDEREÇO DESTINO:'
    | '08. TIPO DE ENTREGA:'
    | '09. MÉTODO DE PAGAMENTO:'
    | '10. FRETE:'
    | '11. OBSERVAÇÃO:']: string;
};
const ComponentToPrint589: React.FC = () => {
  const { attendance, isLoading, isError } = useAttendance();
  const { environment, isLoading: isLoadingEnvironment } = useEnvironment();

  if (isLoading || !attendance || isLoadingEnvironment || !environment) {
    return (
      <Loading>
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: '5rem' }} spin />}
        />
      </Loading>
    );
  }
  if (isError) {
    return <>Error</>;
  }

  const order = attendance.order as IForm;
  // const nf = attendance.order['01. NF'];
  // console.log(attendance.order);
  return (
    <Container>
      <Title>ETIQUETA ENCOMENDA</Title>
      <Line>
        <Label>Nº PROTOCOLO:</Label>
        {attendance.id}
      </Line>
      {/* Destinatario */}
      <Line>
        <SubTitle>DESTINATÁRIO</SubTitle>
      </Line>
      <Line>
        <Label>DESTINATÁRIO:</Label>
        {order['02. CLIENTE:']}
      </Line>
      <Line>
        <Label>CONTATO:</Label>
        {order['03. CONTATO CLIENTE:']}
      </Line>
      <Line>
        <Label>ENDEREÇO DESTINO:</Label>
        {order['07. ENDEREÇO DESTINO:']}
      </Line>
      <Line>
        <SubTitle>REMETENTE</SubTitle>
      </Line>
      <Line>
        <Label>LOJA:</Label>
        {order['04. LOJA:']}
      </Line>
      <Line>
        <Label>VENDEDOR:</Label>
        {order['05. VENDEDOR:']}
      </Line>
      <Line>
        <Label>CONTATO VENDEDOR:</Label>
        {order['06. CONTATO VENDEDOR:']}
      </Line>
      <Line>
        <Label>CIDADE:</Label>Manaus - AM
      </Line>
      <Line>
        <SubTitle>DEMAIS INFORMAÇÕES</SubTitle>
      </Line>

      <Line>
        <Label>NF:</Label>
        {order['01. NF:']}
      </Line>
      <Line />
      <Line>
        <Label>TIPO DE ENTREGA:</Label>
        {order['08. TIPO DE ENTREGA:']}
      </Line>
      <Line>
        <Label>MÉTODO DE PAGAMENTO:</Label>
        {order['09. MÉTODO DE PAGAMENTO:']}
      </Line>
      <Line>
        <Label>FRETE:</Label>
        {order['10. FRETE:']}
      </Line>
      <Line>
        <Label>OBSERVAÇÃO:</Label>
        {order['11. OBSERVAÇÃO:']}
      </Line>
    </Container>
  );
};

export default memo(ComponentToPrint589, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
