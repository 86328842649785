import React from 'react';

import { Button } from 'antd';
import ComponentToPrint from './ComponentToPrint';
import { AttendanceProvider } from '~/hooks/attendances/attendance';
import { Container, Header, Wrapper } from './styles';

const WorkflowAttendancePrint: React.FC = () => {
  document.title = 'ZapForm - Relatório de Atendimento';

  const handlePrint = () => {
    window.print();
  };
  return (
    <Wrapper>
      <div className="no-print">
        <Header>
          <Button onClick={() => handlePrint()}>Imprimir</Button>
        </Header>
      </div>
      <Container>
        <AttendanceProvider>
          <ComponentToPrint />
        </AttendanceProvider>
      </Container>
    </Wrapper>
  );
};

export default WorkflowAttendancePrint;
