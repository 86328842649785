import React from 'react';

import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';
import { EnvironmentsProvider } from '~/hooks/environments/environments';
import { EnvironmentProvider } from '~/hooks/environments/environment';
import { StatusProvider } from '~/hooks/status/status';
import { ResponsiblesProvider } from '~/hooks/responsibles/responsibles';
import { UserProvider } from './user';
import { UnreadMessagesProvider } from '~/hooks/messages/unreadMessages';
import { DeviceProvider } from './device';
import queryClient from '~/services/queryClient';

const AppProvider: React.FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <UserProvider>
      <EnvironmentsProvider>
        <EnvironmentProvider>
          <DeviceProvider>
            <UnreadMessagesProvider>
              <StatusProvider>
                <ResponsiblesProvider listAll>{children}</ResponsiblesProvider>
              </StatusProvider>
            </UnreadMessagesProvider>
          </DeviceProvider>
        </EnvironmentProvider>
      </EnvironmentsProvider>
    </UserProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>
);

export default AppProvider;
