/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState } from 'react';
import intl from 'react-intl-universal';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { FiltersProps } from '~/@types/attendances';
import { useWorkflow } from '~/hooks/workflow/workflow';
import { ParamsProps } from '~/@types/params';

type GenerateReportProps = (format?: 'json' | 'csv' | 'excel') => Promise<void>;
interface ReportContextData {
  filters: FiltersProps;
  isLoading: boolean;
  listReports: Array<any>;
  reportUrl: string | null;
  generateReport: GenerateReportProps;
  setNewFilters: (filters: FiltersProps) => void;
  totalAttendances: number | null;
  cleanReport: () => void;
}

export const ReportContext = createContext<ReportContextData>(
  {} as ReportContextData,
);

const ReportProvider: React.FC = ({ children }) => {
  const { environment } = useEnvironment();
  const { environmentId } = useParams<ParamsProps>();
  const { selectedCards } = useWorkflow();

  const [listReports, setListReports] = useState([]);
  const [reportUrl, setReportUrl] = useState(null);

  const [reportId, setReportId] = useState<number | null>(null);
  const [filters, setFilters] = useState<FiltersProps>({} as FiltersProps);
  const [format, setFormat] = useState<'json' | 'csv' | 'excel' | null>(null);

  const [totalAttendances, setTotalAttendances] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useQuery(
    [`report`, reportId],
    async () => {
      if (!isLoading || format === null) {
        return;
      }

      await getReport();
    },
    {
      refetchInterval: 5000,
    },
  );

  const getReport = async () => {
    if (!environment) {
      return;
    }
    try {
      const response = await api.post(`/zc/${environment.id}/order/report/`, {
        format,
        timezone: 'America/Sao_Paulo',
        ...(reportId && { report_id: reportId }),
        ...(filters.contact && { filter_client__in: filters.contact }),
        ...(filters.responsible && {
          filter_location__in: filters.responsible,
        }),
        ...((filters.attendanceId || selectedCards) && {
          filter_id__in: filters.attendanceId || selectedCards.toString(),
        }),
        ...(filters.attendanceIdStart && {
          filter_id__gt: filters.attendanceIdStart,
        }),
        ...(filters.attendanceIdEnd && {
          filter_id__lte: filters.attendanceIdEnd,
        }),
        ...(filters.attendance && {
          filter_order__icontains: filters.attendance,
        }),
        ...(filters.statusCode && {
          filter_status__code__in: filters.statusCode,
        }),
        ...(filters.expired === true && {
          expired: true,
        }),
        ...(filters.timeCreated && {
          filter_time_created__gt: filters.timeCreated[0],
          filter_time_created__lte: filters.timeCreated[1],
        }),
        ...(filters.timeStatus && {
          filter_time_status__gt: filters.timeStatus[0],
          filter_time_status__lte: filters.timeStatus[1],
        }),
        ...(filters.timeLastUpdated && {
          filter_time_last_updated__gt: filters.timeLastUpdated[0],
          filter_time_last_updated__lte: filters.timeLastUpdated[1],
        }),
      });

      const { data } = response;

      if (data.finished) {
        reportFinished(data);
        return;
      }

      setReportId(data.report_id);
    } catch (err) {
      setIsLoading(false);
      toast.error(intl.get('reports.create.error_generate'));
    }
  };

  const generateReport: GenerateReportProps = async (formatReport = 'json') => {
    setIsLoading(true);

    setFormat(formatReport);
  };

  const reportFinished = async (data: any) => {
    setReportId(null);
    setFormat(null);

    if (format === 'json') {
      setListReports(data.report.data);
    } else {
      setReportUrl(data.report_url);
    }

    await getTotalAttendances();
    setIsLoading(false);
  };

  const cleanReport = () => {
    setReportUrl(null);
    setTotalAttendances(null);
    setListReports([]);
  };

  const setNewFilters = (newFilters: FiltersProps) => {
    setFilters(() => newFilters);
  };

  const getTotalAttendances = async () => {
    const response = await api.get(`/zc/${environmentId}/order/`, {
      params: {
        offset: 0,
        limit: 1,
        ...(filters.contact && { client: filters.contact }),
        ...(filters.attendance && { order: filters.attendance.slice(0, 100) }),
        ...(filters.statusCode && {
          status: filters.statusCode,
        }),
        ...(filters.responsible && {
          location: filters.responsible,
        }),
        ...(filters.attendanceId && {
          order_list: filters.attendanceId,
        }),
        ...(filters.expired === true && {
          expired: true,
        }),
        ...(filters.timeCreated && {
          time_created__gt: filters.timeCreated[0],
          time_created__lte: filters.timeCreated[1],
        }),
        ...(filters.timeStatus && {
          time_status__gt: filters.timeStatus[0],
          time_status__lte: filters.timeStatus[1],
        }),
        ...(filters.timeLastUpdated && {
          time_last_updated__gt: filters.timeLastUpdated[0],
          time_last_updated__lte: filters.timeLastUpdated[1],
        }),
        ...(filters.attendanceIdStart && {
          id__gt: filters.attendanceIdStart,
        }),
        ...(filters.attendanceIdEnd && {
          id__lte: filters.attendanceIdEnd,
        }),
      },
    });

    const { data } = response;
    setTotalAttendances(data.count);
  };

  return (
    <ReportContext.Provider
      value={{
        filters,
        listReports,
        reportUrl,
        generateReport,
        isLoading,
        totalAttendances,
        setNewFilters,
        cleanReport,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
function useReport(): ReportContextData {
  const context = useContext(ReportContext);

  if (!context) {
    throw new Error('useReport must be used within an ReportProvider');
  }

  return context;
}

export { ReportProvider, useReport };
