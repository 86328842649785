import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
// import { useHistory } from 'react-router-dom';
import ChatMessage from '~/components/global/organisms/ChatMessage';
import { useAttendance } from '~/hooks/attendances/attendance';
import { BotProvider } from '~/hooks/bot/bot';
import { ContactProvider } from '~/hooks/contacts/contact';
import { MessagesProvider } from '~/hooks/messages/messages';
import ChatTitleBar from '../ChatTitleBar';

import { Container } from './styles';

const Chat: React.FC = () => {
  // const history = useHistory();
  const { isLoading, attendance } = useAttendance();

  useEffect(() => {
    if (attendance) {
      localStorage.setItem(
        '@ZapForm:lastOpenAttendance',
        String(attendance.id),
      );
    }
  }, [attendance]);

  const { getRootProps } = useDropzone();

  if (isLoading) {
    return (
      <Container {...getRootProps({ className: 'dropzone' })}>
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 48, margin: '4rem' }} spin />
          }
        />
      </Container>
    );
  }

  if (!attendance) {
    localStorage.removeItem('@ZapForm:lastOpenAttendance');
    return <></>;
  }
  return (
    <Container {...getRootProps({ className: 'dropzone' })}>
      <ContactProvider
        contactId={attendance?.contact.id}
        attendanceId={attendance?.id}
      >
        <BotProvider contactId={attendance?.contact.id}>
          <MessagesProvider>
            <ChatTitleBar />
            <ChatMessage
              isBlocked={
                attendance.status.status_type === 'C' ||
                attendance.status.status_type === 'X'
              }
            />
          </MessagesProvider>
        </BotProvider>
      </ContactProvider>
    </Container>
  );
};

export default Chat;
