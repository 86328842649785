import React from 'react';
import intl from 'react-intl-universal';
import { ResponsibleProvider } from '~/hooks/responsibles/responsible';
import ResponsibleComponent from '~/components/Responsible';
import Navbar from '~/components/global/organisms/Navbar';

// import { Container } from './styles';

const Responsible: React.FC = () => {
  return (
    <>
      <Navbar title={intl.get('responsible.title_plural')} />

      <ResponsibleProvider>
        <ResponsibleComponent />
      </ResponsibleProvider>
    </>
  );
};

export default Responsible;
