import React from 'react';
import intl from 'react-intl-universal';
import BotFormscomponent from '~/components/BotForms';
import Navbar from '~/components/global/organisms/Navbar';
import { BotFormsProvider } from '~/hooks/bot/botForms';

import { Container } from './styles';

const BotForms: React.FC = () => {
  return (
    <>
      <Navbar title={intl.get('chatbot.title')} />
      <Container>
        <BotFormsProvider>
          <BotFormscomponent />
        </BotFormsProvider>
      </Container>
    </>
  );
};

export default BotForms;
