import React from 'react';
import intl from 'react-intl-universal';
import {
  Collapse,
  Form,
  Button,
  Input,
  Switch,
  Select,
  Divider,
  Typography,
  Tag,
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Draggable } from 'react-beautiful-dnd';
import {
  useBotmFormQuestions,
  UpdateQuestionProps,
  QuestionProps,
  ChangeQuestionStatusProps,
} from '~/hooks/bot/botFormQuestions';

const { Panel } = Collapse;

interface QuestionItem {
  data: QuestionProps;
  order: number;
  onChangeQuestionOpened: (value: number) => void;
  questionOpened: number;
}

const QuestionItem: React.FC<QuestionItem> = ({
  data,
  order,
  onChangeQuestionOpened,
  questionOpened,
}) => {
  const { updateQuestion, changeQuestionStatus } = useBotmFormQuestions();

  const handleSubmit = (
    values: UpdateQuestionProps,
    question: QuestionProps,
  ) => {
    const dataUpdated: UpdateQuestionProps = {
      ...values,
      order: question.order,
      id: question.id,
    };

    updateQuestion(dataUpdated);
  };

  const handleChangeQuestionStatus = (value: boolean) => {
    const formattedData: ChangeQuestionStatusProps = {
      id: data.id,
      name: data.name,
      text: data.text,
      order: data.order,
      is_active: value,
    };

    changeQuestionStatus(formattedData);
  };

  const showTypeTag = (value: string) => {
    switch (value) {
      case 'TX':
        return <Tag>{intl.get('chatbot.question.types.text')}</Tag>;
      case 'NB':
        return <Tag>{intl.get('chatbot.question.types.numeric')}</Tag>;
      case 'DT':
        return <Tag>{intl.get('chatbot.question.types.date')}</Tag>;
      case 'CP':
        return <Tag>{intl.get('chatbot.question.types.zip_code')}</Tag>;
      case 'YN':
        return <Tag>{intl.get('chatbot.question.types.true_and_false')}</Tag>;
      case 'LS':
        return <Tag>{intl.get('chatbot.question.types.list')}</Tag>;
      case 'LC':
        return <Tag>{intl.get('chatbot.question.types.location')}</Tag>;
      case 'IM':
        return <Tag>{intl.get('chatbot.question.types.image')}</Tag>;
      case 'VI':
        return <Tag>{intl.get('chatbot.question.types.video')}</Tag>;
      case 'AU':
        return <Tag>{intl.get('chatbot.question.types.audio')}</Tag>;
      case 'DO':
        return <Tag>{intl.get('chatbot.question.types.document')}</Tag>;
      case 'SI':
        return <Tag>{intl.get('chatbot.question.types.assign')}</Tag>;
      case 'SK':
        return <Tag>{intl.get('chatbot.question.types.no_reply')}</Tag>;
      default:
        return <Tag>{value}</Tag>;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  return (
    <Draggable key={order} draggableId={String(order)} index={order}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          <Collapse
            accordion
            collapsible="header"
            activeKey={questionOpened}
            className="site-collapse-custom-collapse"
            onChange={value => onChangeQuestionOpened(Number(value))}
          >
            <Panel
              key={order + 1}
              header={
                <>
                  <Button
                    type="text"
                    size="large"
                    icon={<MenuOutlined />}
                    style={{ cursor: 'grab' }}
                    {...provided.dragHandleProps}
                  />

                  <Typography.Text style={{ fontSize: '1.25rem' }}>
                    {order + 1}°
                  </Typography.Text>
                  <Typography style={{ whiteSpace: 'pre-line' }}>
                    {data.text}
                  </Typography>
                </>
              }
              className="site-collapse-custom-panel"
              showArrow={false}
              extra={
                <>
                  {showTypeTag(data.type)}
                  <Switch
                    onChange={value => handleChangeQuestionStatus(value)}
                    defaultChecked={data.is_active}
                    checkedChildren={intl.get('ui.actived')}
                    unCheckedChildren={intl.get('ui.disabled')}
                  />
                </>
              }
            >
              <Form
                key={data.id}
                layout="horizontal"
                labelCol={{ span: 6 }}
                initialValues={data}
                onFinish={values => {
                  handleSubmit(values, data);
                }}
              >
                <Form.Item label="Tipo de pergunta" name="type">
                  <Select placeholder="Selecione tipo de questão">
                    <Select.Option value="TX">
                      {intl.get('chatbot.question.types.text')}
                    </Select.Option>
                    <Select.Option value="NB">
                      {intl.get('chatbot.question.types.numeric')}
                    </Select.Option>
                    <Select.Option value="DT">
                      {intl.get('chatbot.question.types.date')}
                    </Select.Option>
                    <Select.Option value="CP">
                      {intl.get('chatbot.question.types.zip_code')}
                    </Select.Option>
                    <Select.Option value="YN">
                      {intl.get('chatbot.question.types.true_and_false')}
                    </Select.Option>
                    <Select.Option value="LC">
                      {intl.get('chatbot.question.types.location')}
                    </Select.Option>
                    <Select.Option value="IM">
                      {intl.get('chatbot.question.types.image')}
                    </Select.Option>
                    <Select.Option value="VI">
                      {intl.get('chatbot.question.types.video')}
                    </Select.Option>
                    <Select.Option value="AU">
                      {intl.get('chatbot.question.types.audio')}
                    </Select.Option>
                    <Select.Option value="DO">
                      {intl.get('chatbot.question.types.document')}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={intl.get('chatbot.question.name')}
                  name="name"
                >
                  <Input
                    placeholder={intl.get('chatbot.question.name_placeholder')}
                  />
                </Form.Item>

                <Form.Item
                  label={intl.get('chatbot.question.question_message')}
                  name="text"
                >
                  <Input.TextArea
                    placeholder={intl.get(
                      'chatbot.question.question_message_placeholder',
                    )}
                    autoSize
                  />
                </Form.Item>

                <Divider>
                  {intl.get('chatbot.question.validation.title')}
                </Divider>
                <Form.Item
                  label={intl.get('chatbot.question.validation.error_message')}
                  name="validation_text"
                  extra={intl.get(
                    'chatbot.question.validation.error_message_info',
                  )}
                >
                  <Input.TextArea
                    placeholder={intl.get(
                      'chatbot.question.validation.error_message_placeholder',
                    )}
                    autoSize
                  />
                </Form.Item>
                <Button type="primary" htmlType="submit" size="large" block>
                  {intl.get('buttons.save')}
                </Button>
              </Form>
            </Panel>
          </Collapse>
        </div>
      )}
    </Draggable>
  );
};

export default QuestionItem;
