import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;

  background: #f5f5f5;
  border-radius: var(--radius);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
export const Actions = styled.div`
  display: flex;
  border-top: 1px solid #ccc;
  padding-top: 0.5rem;
`;
