import {
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { Alert, Button, Card, Form, Input, Skeleton, Space, Tag } from 'antd';
import React from 'react';
import QRCode from 'qrcode.react';
import intl from 'react-intl-universal';
import { Link, useHistory } from 'react-router-dom';

import { PageHeader } from '@ant-design/pro-components';
import { useDevice, IResponsiblePhone } from '~/hooks/device';
import BaseField from '../global/atoms/BaseField';

import { Column, Container } from './styles';
import DeviceStatusTag from '../global/atoms/DeviceStatusTag';
import ModalDeviceScreenShot from './molecules/ModalDeviceScreenShot';
import ResetDeviceButton from './molecules/ResetDeviceButton';
import RecreateDeviceButton from './molecules/RecreateDeviceButton';
import PhoneInput from '../global/molecules/PhoneInput';

const Device: React.FC = () => {
  const {
    device,
    isLoading,
    refetch,
    isFetching,
    updateResponsibles,
  } = useDevice();

  const [form] = Form.useForm();
  const history = useHistory();

  if (isLoading) {
    return (
      <Card>
        <Skeleton paragraph={{ rows: 1 }} />
      </Card>
    );
  }

  if (!device) {
    return <Card>Nada encontrado</Card>;
  }

  const handleRefreshDevice = () => {
    refetch();
  };

  const handleUpdateResponsibles = (data: IResponsiblePhone[]) => {
    updateResponsibles(data);
  };

  const formattedTags = [<DeviceStatusTag value={device.status} />];

  if (device.isWhatsAppOfficial) {
    formattedTags.push(
      <Tag icon={<WhatsAppOutlined />} color="#459B61">
        API Oficial
      </Tag>,
    );
  }

  return (
    <>
      <Container>
        <PageHeader
          ghost={false}
          onBack={() => history.goBack()}
          title={device.info?.pushname}
          subTitle={device.name}
          tags={formattedTags}
          extra={
            device.isWhatsAppOfficial
              ? []
              : [
                  <ModalDeviceScreenShot clientId={device.id} />,
                  <ResetDeviceButton
                    clientId={device.id}
                    disabled={device.disabledOptions}
                  />,
                  <RecreateDeviceButton
                    clientId={device.id}
                    disabled={device.disabledOptions}
                  />,
                ]
          }
        />

        <div style={{ display: 'flex', gap: '2rem' }}>
          <Column>
            <Card title={intl.get('device.instability_alert.title')}>
              <Form
                form={form}
                layout="vertical"
                initialValues={device}
                onFinish={values =>
                  handleUpdateResponsibles(values.responsiblePhones)
                }
              >
                <Form.Item
                  label={intl.get(
                    'device.instability_alert.contacts_to_be_notified.title',
                  )}
                  tooltip={{
                    title: intl.get(
                      'device.instability_alert.contacts_to_be_notified.info',
                    ),
                  }}
                >
                  <Form.List name="responsiblePhones">
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            size="large"
                            style={{ display: 'flex', marginBottom: 8 }}
                            align="center"
                          >
                            <Form.Item
                              {...restField}
                              label={intl.get(
                                'device.instability_alert.contacts_to_be_notified.name',
                              )}
                              name={[name, 'name']}
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input
                                placeholder={intl.get(
                                  'device.instability_alert.contacts_to_be_notified.name_placeholder',
                                )}
                              />
                            </Form.Item>

                            <PhoneInput
                              {...restField}
                              form={form}
                              keyList="responsiblePhones"
                              name={[name, 'phone']}
                            />

                            <Form.Item
                              {...restField}
                              label="Email"
                              name={[name, 'email']}
                              rules={[
                                {
                                  type: 'email',
                                },
                              ]}
                            >
                              <Input placeholder="joao@email.com" />
                            </Form.Item>

                            <Button
                              danger
                              type="primary"
                              icon={<DeleteOutlined />}
                              onClick={() => remove(name)}
                            />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            {intl.get(
                              'device.instability_alert.contacts_to_be_notified.add_contact',
                            )}
                          </Button>

                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {intl.get('buttons.save')}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Column>
          <Column style={{ flex: '1 1 25%' }}>
            <div
              style={{ display: 'flex', gap: '2rem', flexDirection: 'column' }}
            >
              {device.status === 'QRCODE' && device.qrcode && (
                <Card
                  title={intl.get('device.status.qrcode')}
                  bodyStyle={{ display: 'flex', justifyContent: 'center' }}
                  extra={
                    <Button
                      type="primary"
                      icon={<SyncOutlined />}
                      onClick={handleRefreshDevice}
                      loading={isFetching}
                    >
                      {intl.get('buttons.update')}
                    </Button>
                  }
                >
                  <QRCode value={device.qrcode} size={240} />
                </Card>
              )}
              <Card title={intl.get('device.device_data.title')}>
                {device.info === undefined ? (
                  <Alert
                    message={intl.get(
                      'device.device_data.erros.not_device_data',
                    )}
                    type="warning"
                  />
                ) : (
                  <>
                    <BaseField
                      label={intl.get('device.device_data.name_in_device')}
                      value={device.info.pushname}
                    />

                    {device.info.wid !== undefined && (
                      <BaseField
                        label={intl.get('device.device_data.name_in_device')}
                      >
                        <Link
                          to={{
                            pathname: `https://wa.me/${device.info.wid.user}`,
                          }}
                          target="_blank"
                        >
                          {device.info.wid.user}
                        </Link>
                      </BaseField>
                    )}
                    <BaseField
                      label={intl.get('device.device_data.platform')}
                      value={device.info.platform}
                    />
                  </>
                )}
                {device.wwebVersion && (
                  <BaseField
                    label={intl.get('device.device_data.version')}
                    value={device.wwebVersion}
                  />
                )}
              </Card>
            </div>
          </Column>
        </div>
      </Container>
    </>
  );
};

export default Device;
