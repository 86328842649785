import styled from 'styled-components';

export const Wrapper = styled.div`
  @media print {
    .no-print {
      display: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid #449b63;
  margin-bottom: 1rem;
  background: #d9e8e3;
  /* position: absolute;
  bottom: 0px;
  width: 100vw; */
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 378px;
  padding: 10px;
  min-height: 566px;
  background-color: #fff;
  @media print {
    img {
      display: block;
    }

    video::-webkit-media-controls {
      display: none;
    }
    @page {
      size: 100mm 150mm;
      margin: 0;
    }
  }
`;
