/* eslint-disable import/no-duplicates */
import React, { createContext, useContext, useState } from 'react';

import { useQuery } from 'react-query';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import intl from 'react-intl-universal';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { useBotForm } from './botForm';
import { useLocales } from '../locales';

interface QuestionsResponseProps {
  count: number;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      name: string;
      text: string;
      type: string;
      order: number;
      validation_text: string;
      is_active: boolean;
      last_updated: Date;
      form_id: number;
    },
  ];
}

export interface QuestionProps {
  id: number;
  name: string;
  text: string;
  type: string;
  order: number;
  validation_text: string;
  is_active: boolean;
  last_updated: Date;
  last_formattedUpdated: string;
  form_id: number;
}

export interface UpdateQuestionProps {
  id: number;
  name: string;
  text: string;
  type?: string;
  order: number;
  validation_text?: string;
}

export interface ChangeQuestionStatusProps {
  id: number;
  name: string;
  text: string;
  order: number;
  is_active: boolean;
}

export interface ChangeQuestionOrderProps {
  id: number;
  name: string;
  text: string;
  order: number;
}

interface BotFormQuestionsContextData {
  totalQuestions: number;
  isLoading: boolean;
  isFetching: boolean;
  questionsList?: QuestionProps[];
  updateQuestion: (data: UpdateQuestionProps) => void;
  changeQuestionStatus: (data: ChangeQuestionStatusProps) => void;
  changeQuestionOrder: (index: number, destination: number) => void;
}

export const BotFormQuestionsContext = createContext<BotFormQuestionsContextData>(
  {} as BotFormQuestionsContextData,
);

const BotFormQuestionsProvider: React.FC = ({ children }) => {
  const { currentLocale } = useLocales();
  const { form } = useBotForm();

  const [totalQuestions, setTotalQuestions] = useState(0);

  const formatterDate = (value: Date) => {
    return format(value, 'dd/MM/yyyy HH:mm:ss aa', {
      locale: currentLocale === 'pt-BR' ? ptBR : es,
    });
  };

  const { data: questionsList, isLoading, refetch, isFetching } = useQuery(
    [`questionsListFormBot${form?.id}`, form?.id],

    async () => {
      const response = await api.get<QuestionsResponseProps>(
        `/zf/${form?.id}/question/`,
        { params: { limit: 500 } },
      );
      const { data } = response;

      const formattedQuestion: QuestionProps[] = data.results.map(question => {
        return {
          last_formattedUpdated: formatterDate(new Date(question.last_updated)),
          ...question,
        };
      });

      setTotalQuestions(data.count);
      return formattedQuestion;
    },
  );

  const updateQuestion = async (data: UpdateQuestionProps) => {
    try {
      await api.put(`/zf/${form?.id}/question/${data.id}/`, data);
      refetch();
    } catch (error) {
      toast.error(intl.get('chatbot.update.error_update_question'));
    }
  };

  const changeQuestionStatus = async (data: ChangeQuestionStatusProps) => {
    try {
      await api.put(`/zf/${form?.id}/question/${data.id}/`, data);
    } catch (error) {
      toast.error(intl.get('chatbot.update.error_update_question_status'));
    }
    refetch();
  };

  const updateQuestionOrder = async (data: ChangeQuestionOrderProps) => {
    try {
      await api.put(`/zf/${form?.id}/question/${data.id}/`, data);
    } catch (error) {
      toast.error(intl.get('chatbot.update.error_update_question_order'));
    }
  };

  const changeQuestionOrder = async (
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    if (!questionsList) {
      return;
    }
    // Radjustment
    let questionIndexReorder = {
      ...questionsList[sourceIndex],
      order: 901 + Math.floor(Math.random() * 100),
    };
    await updateQuestionOrder(questionIndexReorder);

    // Destination
    const questionDestinationReorder = {
      ...questionsList[destinationIndex],
      order: sourceIndex,
    };
    await updateQuestionOrder(questionDestinationReorder);

    // Source
    questionIndexReorder = {
      ...questionsList[sourceIndex],
      order: destinationIndex,
    };

    await updateQuestionOrder(questionIndexReorder);
    refetch();
  };

  return (
    <BotFormQuestionsContext.Provider
      value={{
        totalQuestions,
        isFetching,
        isLoading,
        questionsList,
        updateQuestion,
        changeQuestionStatus,
        changeQuestionOrder,
      }}
    >
      {children}
    </BotFormQuestionsContext.Provider>
  );
};
function useBotmFormQuestions(): BotFormQuestionsContextData {
  const context = useContext(BotFormQuestionsContext);

  if (!context) {
    throw new Error(
      'useBotmFormQuestions must be used within an BotFormQuestionsProvider',
    );
  }

  return context;
}

export { BotFormQuestionsProvider, useBotmFormQuestions };
