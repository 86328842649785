/* eslint-disable import/no-duplicates */
import { format, formatDistance, differenceInHours, isBefore } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import api from '~/services/api';

import { useEnvironment } from '~/hooks/environments/environment';
import { useCallCenter } from './callCenter';

import {
  AttendancesItemProps,
  AttendancesResponseProps,
} from '~/@types/attendances';
import { useLocales } from '~/hooks/locales';
import formatPhoneNumber from '~/util/formatPhoneNumber';

export interface ILastMessage {
  me: boolean;
  type: 'ch' | 'im' | 'vi' | 'au' | 'pt' | 'do' | 'lo';
  text?: string;
  timeFormatted: string;
}
export type IAttendancesItem = AttendancesItemProps;

interface ChatAttendancesContextData {
  attendancesList: IAttendancesItem[];
  totalAttendances: number | null;
  hasMore: boolean;
  moreAttendances: () => void;
  isLoading: boolean;
  isFetching: boolean;
}
const ChatAttendancesContext = createContext<ChatAttendancesContextData>(
  {} as ChatAttendancesContextData,
);

const ChatAttendancesProvider: React.FC = ({ children }) => {
  const { currentLocale } = useLocales();
  const [attendancesList, setAttendancesList] = useState<IAttendancesItem[]>(
    [],
  );
  const [totalAttendances, setTotalAttendances] = useState<number | null>(null);
  const defaultLimit = 10;
  const [limit, setLimit] = useState(10);
  const [hasMore, setHasMore] = useState(false);

  const { environment } = useEnvironment();

  const { filters, settings } = useCallCenter();

  const formatterDate = (value: Date) => {
    return format(value, 'dd/MM/yyyy HH:mm:ss aa', {
      locale: currentLocale === 'pt-BR' ? ptBR : es,
    });
  };

  const formatSorting = (): string => {
    if (!settings.sortBy) {
      return '';
    }
    let result = '';
    result += settings.sortBy.sort === 'asc' ? '' : '-';
    result += settings.sortBy.by;

    return result;
  };

  const { isLoading, isFetching } = useQuery(
    [
      `chatAttendances`,
      limit,
      environment?.id,
      filters,
      filters.statusCode,
      settings,
    ],
    async () => {
      if (!environment) {
        return;
      }

      const response = await api.get(`/zc/${environment.id}/order/`, {
        params: {
          offset: 0,
          limit,
          ...(settings.sortBy && { order_by: formatSorting() }),
          ...(filters.attendanceId && { id: filters.attendanceId }),
          ...(filters.contact && { client: filters.contact }),
          ...(filters.statusCode &&
            filters.statusCode !== 'null' && {
              status: filters.statusCode,
            }),
          ...(filters.responsible && { location: filters.responsible }),
          ...(filters.attendance && {
            order: filters.attendance.slice(0, 100),
          }),
          ...(filters.expired === true && {
            expired: true,
          }),
          ...(filters.timeCreated && {
            time_created__gt: filters.timeCreated[0],
            time_created__lte: filters.timeCreated[1],
          }),
          ...(filters.timeStatus && {
            time_status__gt: filters.timeStatus[0],
            time_status__lte: filters.timeStatus[1],
          }),
          ...(filters.timeLastUpdated && {
            time_last_updated__gt: filters.timeLastUpdated[0],
            time_last_updated__lte: filters.timeLastUpdated[1],
          }),
        },
      });

      const { data } = response;
      formatterAttendance(data);
      setHasMore(!!data.next);
      setTotalAttendances(data.count);
    },
    {
      refetchInterval: 10000,
    },
  );

  const formatterAttendance = (data: AttendancesResponseProps) => {
    const attendancesResponse = data.results.map(attendance => {
      const howIsTimeout = ():
        | 'timeout'
        | 'insideTime'
        | 'distantTime'
        | undefined => {
        if (!attendance.time_timeout) {
          return undefined;
        }

        if (isBefore(new Date(attendance.time_timeout), new Date())) {
          return 'timeout';
        }

        if (
          differenceInHours(new Date(attendance.time_timeout), new Date()) <= 24
        ) {
          return 'insideTime';
        }

        return 'distantTime';
      };
      const attendanceResponse: IAttendancesItem = {
        id: attendance.id,
        order_summary: attendance.order_summary,
        time_created: attendance.time_created,
        time_createdFormatted: formatterDate(new Date(attendance.time_created)),
        time_createdThere: formatDistance(
          new Date(attendance.time_created),
          new Date(),
          {
            includeSeconds: true,
            addSuffix: true,
            locale: currentLocale === 'pt-BR' ? ptBR : es,
          },
        ),
        time_status: attendance.time_status,
        time_statusFormatted: formatterDate(new Date(attendance.time_status)),
        time_deadline: attendance.time_timeout,
        time_deadlineFormatted:
          attendance.time_timeout &&
          formatterDate(new Date(attendance.time_timeout)),
        time_howIsTimeout: howIsTimeout(),
        time_timeoutFormatted:
          attendance.time_timeout &&
          formatDistance(new Date(attendance.time_timeout), new Date(), {
            includeSeconds: true,
            addSuffix: true,
            locale: currentLocale === 'pt-BR' ? ptBR : es,
          }),
        responsible: {
          ...attendance.location,
          id_name: `#${attendance.location.id} - ${attendance.location.name}`,
        },
        contact: {
          ...attendance.client,
          number_formatted: formatPhoneNumber(attendance.client.number),
          id_name: `#${attendance.client.id} - ${attendance.client.name}`,
        },
        status: {
          ...attendance.status,
          code_status: `${attendance.status.code} - ${attendance.status.status}`,
        },
      };

      return attendanceResponse;
    });

    setAttendancesList(attendancesResponse);
  };

  const moreAttendances = useCallback(() => {
    setLimit(limitOld => limitOld + defaultLimit);
  }, []);

  return (
    <ChatAttendancesContext.Provider
      value={{
        attendancesList,
        totalAttendances,
        hasMore,
        moreAttendances,
        isLoading,
        isFetching,
      }}
    >
      {children}
    </ChatAttendancesContext.Provider>
  );
};
function useChatAttendances(): ChatAttendancesContextData {
  const context = useContext(ChatAttendancesContext);

  if (!context) {
    throw new Error(
      'useChatAttendances must be used within an ChatAttendancesProvider',
    );
  }

  return context;
}

export { ChatAttendancesProvider, useChatAttendances };
