import styled from 'styled-components';

export const Container = styled.div`
  .site-layout-content {
  }
  .site-layout {
    min-height: 100vh;
    background: #f2f2f2;
  }
`;
