import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ParamsProps } from '~/@types/params';

const ValidationBillApp: React.FC = () => {
  const { phoneNumberToBill } = useParams<ParamsProps>();
  window.location.replace(`bill://validate-phone/${phoneNumberToBill}`);
  return (
    <div
      style={{
        margin: '1rem auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <LoadingOutlined style={{ fontSize: 84 }} spin />
    </div>
  );
};

export default ValidationBillApp;
