import { Avatar, Typography, Card, Skeleton, Table } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import BaseField from '~/components/global/atoms/BaseField';

import { useResponsible } from '~/hooks/responsibles/responsible';
import { AttendancesProvider } from '~/hooks/attendances/attendances';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import { stringToHslColor } from '~/util/stringToHslColor';

import { Container, Divider, Profile } from './styles';

import ModalDeleteResponsible from './ModalDeleteResponsible';
import { ListProvider } from '~/hooks/workflow/list';
import AttendancesTable from '~/components/global/molecules/AttendancesTable';
import ModalNewAttendance from '../global/molecules/ModalNewAttendance';
import { ParamsProps } from '~/@types/params';

const { Text } = Typography;

const Responsible: React.FC = () => {
  const history = useHistory();
  const { environmentId } = useParams<ParamsProps>();
  const {
    responsible,
    updateResponsibleField,
    isLoading,
    isError,
  } = useResponsible();

  if (isError) {
    toast.error(intl.get('responsible.not_found.title'));
    history.push(`/c/${environmentId}/responsibles/`);
    return <></>;
  }

  if (isLoading || !responsible) {
    return (
      <Container>
        <Card>
          <Skeleton avatar paragraph={{ rows: 1 }} />
        </Card>
        <Divider>
          <Card loading style={{ width: '100%' }} />
          <Card loading style={{ width: '100%' }} />
        </Divider>

        <Card>
          <Table locale={{ emptyText: ' ' }} loading />
        </Card>
      </Container>
    );
  }

  return (
    <Container>
      <Card>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            justifyContent: 'space-between',
          }}
        >
          <Profile>
            <Avatar
              size="large"
              style={{
                backgroundColor: stringToHslColor(responsible.name),
              }}
            >
              {createLetterAvatar(responsible.name)}
            </Avatar>
            <div style={{ marginLeft: '0.5rem' }}>
              <div style={{ width: '160px' }}>
                <Text>{responsible.name}</Text>
              </div>
            </div>
          </Profile>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <ModalNewAttendance
              type="responsible"
              responsibleId={responsible.id}
            />
            <ModalDeleteResponsible />
          </div>
        </div>
      </Card>
      <Divider>
        <Card style={{ width: '100%' }} loading={isLoading}>
          <BaseField
            label={intl.get('responsible.name')}
            name="name"
            value={responsible.name}
            editable
            onChange={field => updateResponsibleField(field)}
          />
        </Card>
        <Card style={{ width: '100%' }} loading={isLoading}>
          {responsible.address ? (
            <BaseField
              label={intl.get('responsible.address')}
              name="address"
              value={responsible.address}
              editable
              onChange={field => updateResponsibleField(field)}
            />
          ) : (
            intl.get('responsible.empty')
          )}
        </Card>
      </Divider>
      <Card title={intl.get('attendance.title_plural')} loading={isLoading}>
        {!isLoading && responsible && (
          <ListProvider>
            <AttendancesProvider responsible={responsible} disabledSort>
              <AttendancesTable />
            </AttendancesProvider>
          </ListProvider>
        )}
      </Card>
    </Container>
  );
};

export default Responsible;
