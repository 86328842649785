import { Button, Drawer, Image, Skeleton, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';

import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { WhatsAppOutlined } from '@ant-design/icons';
import { useContact } from '~/hooks/contacts/contact';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import { stringToHslColor } from '~/util/stringToHslColor';
import { Avatar } from '../../atoms/Avatar';
import { Wrapper, Container } from './styles';
import BaseField from '../../atoms/BaseField';
import DeleteContactModal from '../DeleteContactModal';

const { Text } = Typography;

const ContactInfo: React.FC = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };
  const onClose = () => {
    setIsDrawerVisible(false);
  };

  const { isLoading, contact, updateContactField } = useContact();
  if (isLoading || !contact) {
    return (
      <>
        <Skeleton.Avatar active size={32} />
        <Skeleton.Input active />
      </>
    );
  }
  return (
    <>
      <Wrapper>
        {contact.address === '' ? (
          <Avatar
            size={32}
            style={{
              backgroundColor: stringToHslColor(contact.name),
            }}
          >
            {createLetterAvatar(contact.name)}
          </Avatar>
        ) : (
          <Avatar
            size="large"
            src={<Image src={contact.address} preview={{ mask: ' ' }} />}
          />
        )}
        <Tooltip title={contact.name}>
          <Container onClick={() => showDrawer()}>
            <Text
              ellipsis={{ tooltip: contact.name }}
              style={{ marginBottom: 0 }}
            >
              {contact.name}
            </Text>

            <Text ellipsis>{contact.number_formatted}</Text>
          </Container>
        </Tooltip>
      </Wrapper>
      <Drawer
        title={intl.get('chat.title_bar.contact_information')}
        placement="left"
        onClose={onClose}
        open={isDrawerVisible}
        width="350px"
        getContainer={false}
        footer={<DeleteContactModal />}
        style={{ position: 'absolute' }}
      >
        <BaseField label={intl.get('contact.id')} copyable value={contact.id} />

        <BaseField
          label={intl.get('contact.name')}
          name="name"
          value={contact.name}
          editable
          onChange={field => {
            updateContactField(field);
          }}
        />

        <BaseField
          type="phone"
          label={intl.get('contact.number')}
          name="number"
          value={contact.number_formatted}
          editable
          copyable
          onChange={field => {
            updateContactField(field);
          }}
        />

        <Link
          to={{
            pathname: `https://wa.me/${contact.number}`,
          }}
          target="_blank"
        >
          <Button type="primary" size="large" block icon={<WhatsAppOutlined />}>
            {intl.get('contact.open_conversation_whatsapp')}
          </Button>
        </Link>
      </Drawer>
    </>
  );
};

export default ContactInfo;
