import React from 'react';
import intl from 'react-intl-universal';
import Navbar from '~/components/global/organisms/Navbar';
import Report from '~/components/Reports';
import { Container } from './styles';

const Reports: React.FC = () => {
  return (
    <>
      <Navbar title={intl.get('reports.title_plural')} />
      <Container>
        <Report />
      </Container>
    </>
  );
};

export default Reports;
