import { FilterOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin, Tag } from 'antd';
import React from 'react';

import intl from 'react-intl-universal';
import { useQuery } from 'react-query';
import { useEnvironment } from '~/hooks/environments/environment';
import { useWorkflow } from '~/hooks/workflow/workflow';
import api from '~/services/api';
import isEmpty from '~/util/isEmpty';

const CounterTotalFiltered: React.FC = () => {
  const { environment, totalAttendances } = useEnvironment();
  const { filters } = useWorkflow();
  const { data: totalFiltered, isLoading } = useQuery(
    ['totalFilteredInWorkflow', environment, filters],
    async (): Promise<number | undefined> => {
      if (!environment) {
        return undefined;
      }
      try {
        const response = await api.get(`/zc/${environment.id}/order/`, {
          params: {
            offset: 0,
            limit: 1,
            ...(filters.attendanceId && { id: filters.attendanceId }),
            ...(filters.contact && {
              client: filters.contact,
            }),
            ...(filters.responsible && { location: filters.responsible }),
            ...(filters.attendance && {
              order: filters.attendance.slice(0, 100),
            }),
            ...(filters.expired === true && {
              expired: true,
            }),
            ...(filters.timeCreated && {
              time_created__gt: filters.timeCreated[0],
              time_created__lte: filters.timeCreated[1],
            }),
            ...(filters.timeStatus && {
              time_status__gt: filters.timeStatus[0],
              time_status__lte: filters.timeStatus[1],
            }),
            ...(filters.timeLastUpdated && {
              time_last_updated__gt: filters.timeLastUpdated[0],
              time_last_updated__lte: filters.timeLastUpdated[1],
            }),
            ...(filters.attendanceIdStart && {
              id__gt: filters.attendanceIdStart,
            }),
            ...(filters.attendanceIdEnd && {
              id__lte: filters.attendanceIdEnd,
            }),
          },
        });

        const { data } = response;

        return data.count;
      } catch (error) {
        return undefined;
      }
    },
  );

  if (isLoading) {
    return (
      <>
        <Spin indicator={<LoadingOutlined spin />} />
      </>
    );
  }
  // if (isFetching) {
  //   return <>isFetching</>;
  // }
  if (isEmpty(filters) && totalAttendances === totalFiltered) {
    return <></>;
  }
  return (
    <>
      {totalFiltered ? (
        <Tag icon={<FilterOutlined />} color="error">
          {intl.get('attendance.counter_filtered', { total: totalFiltered })}
        </Tag>
      ) : null}
    </>
  );
};

export { CounterTotalFiltered };
