import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 0.5rem;
  background: #fff;
  border-radius: var(--radius);
  border-bottom: var(--border-bottom);

  .ant-tabs-nav {
    margin: 0;
  }
`;
