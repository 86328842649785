/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Dropdown,
  Menu,
  Space,
} from 'antd';
import intl from 'react-intl-universal';
import React, { useState, useEffect } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { useStatus } from '~/hooks/status/status';
import { useCampaign } from '~/hooks/campaigns/campaign';
import isEmpty from '~/util/isEmpty';
import { Field } from './styles';
import { FiltersProps } from '~/@types/attendances';
import { SelectOptions } from '~/@types/fields';

type FieldsOptions = Array<
  | 'status'
  | 'contact'
  | 'responsible'
  | 'attendance.status'
  | 'attendance.attendanceId'
  | 'attendance.attendanceIdTo'
  | 'attendance.attendance'
  | 'attendance.timeCreated'
  | 'attendance.timeLastUpdated'
  | 'attendance.timeStatus'
>;

const Filters: React.FC = () => {
  const {
    isLoading: isLoadingResponsiblesList,
    responsiblesList,
  } = useResponsibles();
  const { statusList } = useStatus();
  const {
    isLoadingTotalContacts,
    setNewFilters,
    isValidateFilters,
    onIsValidateFilters,
    filters,
  } = useCampaign();

  const [form] = Form.useForm();

  const [fieldsVisible, setFieldsVisible] = useState<FieldsOptions>([]);
  const [filtersOld, setFiltersOld] = useState<FiltersProps>(
    {} as FiltersProps,
  );

  const handleCleanFilters = () => {
    form.resetFields();
  };

  const handleAddFieldVisible = (e: any) => {
    const field = e.key;
    setFieldsVisible(fieldsVisibleOld => {
      return [...fieldsVisibleOld, field];
    });
  };

  const handleRemoveFieldVisible = (fieldVisibleRemoved: string) => {
    const newFieldsVisible = fieldsVisible.filter(
      field => field !== fieldVisibleRemoved,
    );

    setFieldsVisible(newFieldsVisible);
  };

  const newFiltersFormmated = (newFilters: FiltersProps): FiltersProps => {
    const newFiltersFormatted = {
      ...newFilters,
      ...(newFilters.timeCreated && {
        timeCreated: [
          dayjs.utc(newFilters.timeCreated[0]).startOf('day').toISOString(),
          dayjs.utc(newFilters.timeCreated[1]).endOf('day').toISOString(),
        ],
      }),
      ...(newFilters.timeLastUpdated && {
        timeLastUpdated: [
          dayjs.utc(newFilters.timeLastUpdated[0]).startOf('day').toISOString(),
          dayjs.utc(newFilters.timeLastUpdated[1]).endOf('day').toISOString(),
        ],
      }),
      ...(newFilters.timeStatus && {
        timeStatus: [
          dayjs.utc(newFilters.timeStatus[0]).startOf('day').toISOString(),
          dayjs.utc(newFilters.timeStatus[1]).endOf('day').toISOString(),
        ],
      }),
    };

    return newFiltersFormatted;
  };

  const handleSubmitFilters = async (newFilters: FiltersProps) => {
    const newFiltersFormatted = newFiltersFormmated(newFilters);
    setNewFilters(newFiltersFormatted);
  };

  const handleSubmitFiltersOld = async (newFilters: FiltersProps) => {
    const newFiltersFormatted = newFiltersFormmated(newFilters);
    setFiltersOld(newFiltersFormatted);
  };

  useEffect(() => {
    if (JSON.stringify(filtersOld) === JSON.stringify(filters)) {
      onIsValidateFilters(true);
    } else {
      onIsValidateFilters(false);
    }
  }, [filtersOld, filters, fieldsVisible, onIsValidateFilters]);

  useEffect(() => {
    if (fieldsVisible.length !== Object.entries(filters).length) {
      setNewFilters({});
    }
  }, [fieldsVisible]);

  if (!statusList || isLoadingResponsiblesList || !responsiblesList) {
    return (
      <div>
        <Card loading />
      </div>
    );
  }

  const formattedResponsiblesList: SelectOptions[] = responsiblesList.map(
    responsible => ({
      name: responsible.name,
      label: responsible.name,
      value: responsible.id,
    }),
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        height: '100%',
      }}
    >
      <div>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu onClick={e => handleAddFieldVisible(e)}>
              <Menu.ItemGroup title={intl.get('contact.title')}>
                <Menu.Item
                  key="contact"
                  disabled={!!fieldsVisible.includes('contact')}
                >
                  {intl.get('campaign.filters.contact_information')}
                </Menu.Item>
              </Menu.ItemGroup>

              <Menu.ItemGroup title={intl.get('responsible.title')}>
                <Menu.Item
                  key="responsible"
                  disabled={!!fieldsVisible.includes('responsible')}
                >
                  {intl.get('campaign.filters.responsible_for_the_service')}
                </Menu.Item>
              </Menu.ItemGroup>

              <Menu.ItemGroup title={intl.get('attendance.title')}>
                <Menu.Item
                  key="attendance.status"
                  disabled={!!fieldsVisible.includes('attendance.status')}
                >
                  {intl.get('campaign.filters.status')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.attendanceId"
                  disabled={!!fieldsVisible.includes('attendance.attendanceId')}
                >
                  {intl.get('campaign.filters.attendance_ids')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.attendanceIdTo"
                  disabled={
                    !!fieldsVisible.includes('attendance.attendanceIdTo')
                  }
                >
                  {intl.get('campaign.filters.id_of_calls_between_2_values')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.attendance"
                  disabled={!!fieldsVisible.includes('attendance.attendance')}
                >
                  {intl.get('campaign.filters.search_in_the_attendance')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.timeCreated"
                  disabled={!!fieldsVisible.includes('attendance.timeCreated')}
                >
                  {intl.get('campaign.filters.created_between')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.timeLastUpdated"
                  disabled={
                    !!fieldsVisible.includes('attendance.timeLastUpdated')
                  }
                >
                  {intl.get('campaign.filters.latest_changes_between')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.timeStatus"
                  disabled={!!fieldsVisible.includes('attendance.timeStatus')}
                >
                  {intl.get('campaign.filters.status_changes_between')}
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>
          }
        >
          <Button type="primary" icon={<PlusOutlined />} block size="large">
            {intl.get('buttons.add_filters')}
          </Button>
        </Dropdown>
      </div>

      {!isEmpty(fieldsVisible) && (
        <Card title={intl.get('campaign.filter.filter_by')}>
          <Form
            form={form}
            layout="vertical"
            onValuesChange={(changedValues, allValues) =>
              handleSubmitFiltersOld(allValues)
            }
            onFinish={values => {
              handleSubmitFilters(values);
            }}
          >
            {fieldsVisible.includes('contact') && (
              <Field>
                <Form.Item
                  label={intl.get('campaign.filters.contact_information')}
                  name="contact"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <Input
                    placeholder={intl.get(
                      'campaign.filters.contact_information_placeholder',
                    )}
                  />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() => handleRemoveFieldVisible('contact')}
                />
              </Field>
            )}

            {fieldsVisible.includes('responsible') && (
              <Field>
                <Form.Item
                  label={intl.get(
                    'campaign.filters.responsible_for_the_service',
                  )}
                  name="responsible"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder={intl.get(
                      'attendance.filters.responsible_for_the_service_placeholder',
                    )}
                    filterSort={(optionA, optionB) =>
                      optionA?.name
                        .toLowerCase()
                        .localeCompare(optionB.name.toLowerCase())
                    }
                    options={formattedResponsiblesList}
                  />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() => handleRemoveFieldVisible('responsible')}
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.status') && (
              <Field>
                <Form.Item
                  label={intl.get('campaign.filters.status')}
                  name="statusCode"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <Select
                    placeholder={intl.get(
                      'campaign.filters.status_placeholder',
                    )}
                  >
                    {statusList.map(status => (
                      <Select.Option key={status.code} value={status.code}>
                        {status.code} - {status.status}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() => handleRemoveFieldVisible('attendance.status')}
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.attendanceId') && (
              <Field>
                <Form.Item
                  label={intl.get('campaign.filters.attendance_ids')}
                  name="attendanceId"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <Input placeholder="Ex. 145, 175, 186" />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleRemoveFieldVisible('attendance.attendanceId')
                  }
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.attendanceIdTo') && (
              <Field>
                <Form.Item
                  label={intl.get('campaign.filters.id_of_calls_from')}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="attendanceIdStart"
                    style={{
                      display: 'inline-block',
                      width: 'calc(40% - 8px)',
                    }}
                  >
                    <Input type="number" placeholder="Ex. 145" />
                  </Form.Item>
                  <Form.Item
                    style={{
                      display: 'inline-block',
                      textAlign: 'center',
                      width: '12%',
                    }}
                  >
                    {intl.get('campaign.filters.until')}
                  </Form.Item>
                  <Form.Item
                    name="attendanceIdEnd"
                    style={{
                      display: 'inline-block',
                      width: 'calc(40% - 8px)',
                      margin: '0 8px',
                    }}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            !value ||
                            getFieldValue('attendanceIdStart') < value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              intl.get(
                                'campaign.filters.enter_value_greater_than_initial',
                              ),
                            ),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input type="number" placeholder="Ex. 186" />
                  </Form.Item>
                  <Button
                    type="default"
                    shape="circle"
                    size="small"
                    icon={<MinusOutlined />}
                    onClick={() =>
                      handleRemoveFieldVisible('attendance.attendanceIdTo')
                    }
                  />
                </Form.Item>
              </Field>
            )}

            {fieldsVisible.includes('attendance.attendance') && (
              <Field>
                <Form.Item
                  label={intl.get('campaign.filters.search_in_the_attendance')}
                  name="attendance"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <Input
                    placeholder={intl.get(
                      'campaign.filters.search_in_the_attendance_placeholder',
                    )}
                  />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleRemoveFieldVisible('attendance.attendance')
                  }
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.timeCreated') && (
              <Field>
                <Form.Item
                  label={intl.get('campaign.filters.created_between')}
                  name="timeCreated"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <DatePicker.RangePicker
                    format={value => `${value.format('DD/MM/YYYY')}`}
                    allowClear={false}
                    presets={[
                      {
                        label: intl.get('date.today'),
                        value: [dayjs().startOf('day'), dayjs().endOf('day')],
                      },
                      {
                        label: intl.get('date.this_week'),
                        value: [dayjs().startOf('week'), dayjs().endOf('week')],
                      },
                      {
                        label: intl.get('date.this_month'),
                        value: [
                          dayjs().startOf('month'),
                          dayjs().endOf('month'),
                        ],
                      },
                    ]}
                  />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleRemoveFieldVisible('attendance.timeCreated')
                  }
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.timeLastUpdated') && (
              <Field>
                <Form.Item
                  label={intl.get('campaign.filters.latest_changes_between')}
                  name="timeLastUpdated"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <DatePicker.RangePicker
                    format={value => `${value.format('DD/MM/YYYY')}`}
                    allowClear={false}
                    presets={[
                      {
                        label: intl.get('date.today'),
                        value: [dayjs().startOf('day'), dayjs().endOf('day')],
                      },
                      {
                        label: intl.get('date.this_week'),
                        value: [dayjs().startOf('week'), dayjs().endOf('week')],
                      },
                      {
                        label: intl.get('date.this_month'),
                        value: [
                          dayjs().startOf('month'),
                          dayjs().endOf('month'),
                        ],
                      },
                    ]}
                  />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleRemoveFieldVisible('attendance.timeLastUpdated')
                  }
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.timeStatus') && (
              <Field>
                <Form.Item
                  label={intl.get('campaign.filters.status_changes_between')}
                  name="timeStatus"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <DatePicker.RangePicker
                    format={value => `${value.format('DD/MM/YYYY')}`}
                    allowClear={false}
                    presets={[
                      {
                        label: intl.get('date.today'),
                        value: [dayjs().startOf('day'), dayjs().endOf('day')],
                      },
                      {
                        label: intl.get('date.this_week'),
                        value: [dayjs().startOf('week'), dayjs().endOf('week')],
                      },
                      {
                        label: intl.get('date.this_month'),
                        value: [
                          dayjs().startOf('month'),
                          dayjs().endOf('month'),
                        ],
                      },
                    ]}
                  />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleRemoveFieldVisible('attendance.timeStatus')
                  }
                />
              </Field>
            )}

            <Space
              direction="vertical"
              style={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}
            >
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                loading={isLoadingTotalContacts}
                disabled={isValidateFilters}
              >
                {intl.get('buttons.save_filters')}
              </Button>
              <Button
                type="text"
                onClick={() => {
                  handleCleanFilters();
                }}
                block
              >
                {intl.get('buttons.clean_filters')}
              </Button>
            </Space>
          </Form>
        </Card>
      )}
    </div>
  );
};

export default Filters;
