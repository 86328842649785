import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Tag } from 'antd';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import {
  IMessageTemplateComponentsDTO,
  IMessageTemplateDTO,
} from '~/hooks/device/messageTemplates';
import { useEnvironment } from '~/hooks/environments/environment';
import { ICategory } from '~/pages/MessageTemplates/NewMessageTemplate';
import apiWhatsApp from '~/services/apiWhatsApp';

export interface IEditMessageTemplate {
  category: ICategory;
  header: string | undefined;
  body: string;
  footer: string | undefined;
}

export interface IEditMessageTemplateToSend {
  category: 'UTILITY' | 'MARKETING' | 'AUTHENTICATION';
  components: IMessageTemplateComponentsDTO;
}

interface IModalEditMessageTemplate {
  data: IMessageTemplateDTO;
}

const ModalEditMessageTemplate: React.FC<IModalEditMessageTemplate> = ({
  data,
}) => {
  const { instanceId, isLoading: isLoadingEnvironment } = useEnvironment();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const headerValue = data.components.find(item => item.type === 'HEADER');
  const bodyValue = data.components.find(item => item.type === 'BODY');
  const footerValue = data.components.find(item => item.type === 'FOOTER');

  const [messageTemplate, setMessageTemplate] = useState<IEditMessageTemplate>({
    category: data.category,
    header: headerValue ? headerValue.text : undefined,
    body: bodyValue ? bodyValue.text : '',
    footer: footerValue ? footerValue.text : undefined,
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const editMessageTemplate = useMutation(
    async (editedMessageTemplate: IEditMessageTemplateToSend) => {
      if (!instanceId) {
        return;
      }
      await apiWhatsApp
        .put(
          `/clients/${instanceId}/templates/${data.id}`,
          editedMessageTemplate,
        )
        .then(() => {
          toast.success('Modelo de mensagem atualizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao atualizar modelo de mensagem');
        });
    },
    {
      onSuccess: () => {
        closeModal();
      },
    },
  );

  const onFinish = ({
    category,
    header,
    body,
    footer,
  }: IEditMessageTemplate) => {
    const editedMessageTemplate: IEditMessageTemplateToSend = {
      category,
      components: [],
    };
    if (header) {
      editedMessageTemplate.components.push({
        type: 'HEADER',
        text: header,
        format: 'TEXT',
      });
    }
    if (footer) {
      editedMessageTemplate.components.push({
        type: 'FOOTER',
        text: footer,
      });
    }
    editedMessageTemplate.components.push({
      type: 'BODY',
      text: body,
    });

    editMessageTemplate.mutateAsync(editedMessageTemplate);
  };

  // const [form] = Form.useForm();
  // useEffect(() => {
  //   form.setFieldsValue(messageTemplate);
  //   console.log(messageTemplate);
  // }, [isModalOpen]);

  if (isLoadingEnvironment) {
    return (
      <>
        <Button icon={<EditOutlined />} loading />
      </>
    );
  }

  return (
    <>
      <Button onClick={showModal} icon={<EditOutlined />} />
      <Modal
        title="Editar modelo de mensagem"
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[]}
      >
        <Form
          // form={form}
          initialValues={messageTemplate}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label={
              <>
                Cabeçalho <Tag style={{ marginLeft: '0.25rem' }}>Opcional</Tag>
              </>
            }
            name="header"
          >
            <Input
              maxLength={60}
              showCount
              placeholder="Insira o texto"
              onChange={e =>
                setMessageTemplate(oldValue => ({
                  ...oldValue,
                  components: { ...oldValue, header: e.target.value },
                }))
              }
            />
          </Form.Item>

          <Form.Item label="Corpo" name="body" required>
            <Input.TextArea
              maxLength={1024}
              placeholder="Insira o texto"
              showCount
              rows={6}
              required
              onChange={e =>
                setMessageTemplate(oldValue => ({
                  ...oldValue,
                  components: { ...oldValue, body: e.target.value },
                }))
              }
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                Rodapé <Tag>Opcional</Tag>
              </>
            }
            name="footer"
          >
            <Input
              maxLength={60}
              showCount
              placeholder="Insira o texto"
              onChange={e =>
                setMessageTemplate(oldValue => ({
                  ...oldValue,
                  components: { ...oldValue, footer: e.target.value },
                }))
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              loading={editMessageTemplate.isLoading}
            >
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalEditMessageTemplate;
