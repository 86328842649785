import styled from 'styled-components';

export const Wrapper = styled.div`
  @media print {
    .no-print {
      display: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid #449b63;
  background: #d9e8e3;
`;

export const Container = styled.div`
  @media print {
    img {
      display: block;
    }

    video::-webkit-media-controls {
      display: none;
    }
  }
`;
