import React from 'react';

import { BackTop, Layout } from 'antd';

import { Container } from './styles';
import Sidebar from '~/components/global/organisms/Sidebar';

import AppProvider from '~/hooks';
import SEO from '~/components/global/atoms/SEO';
import ConpassTour from '~/components/global/molecules/ConpassTour';

const { Content } = Layout;

interface DefaultLayoutProps {
  isPrint?: boolean;
}
const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  isPrint = false,
}) => {
  if (isPrint) {
    return (
      <>
        <AppProvider>{children}</AppProvider>
      </>
    );
  }
  return (
    <Container>
      <AppProvider>
        <ConpassTour />
        <Layout>
          <SEO />
          <Sidebar />
          <Layout className="site-layout">
            <Content className="site-layout-content">{children}</Content>
            <BackTop />
          </Layout>
        </Layout>
      </AppProvider>
    </Container>
  );
};

export default DefaultLayout;
