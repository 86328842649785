import React, { useState } from 'react';

import { SyncOutlined } from '@ant-design/icons';
import { Button, notification, Tooltip } from 'antd';
import { AxiosError } from 'axios';
import intl from 'react-intl-universal';
import { useMutation } from 'react-query';
import apiWhatsApp from '~/services/apiWhatsApp';
import queryClient from '~/services/queryClient';

interface ResetDeviceButtonProps {
  clientId: string;
  disabled?: boolean;
}

const ResetDeviceButton: React.FC<ResetDeviceButtonProps> = ({
  clientId,
  disabled = false,
}) => {
  const [isTimeout, setIsTimeout] = useState(false);

  const resetDevice = useMutation(
    async () => {
      const deviceRestarted = await apiWhatsApp
        .put(`/clients/${clientId}/reset`, {})
        .then(response => {
          const { data } = response;
          notification.success({
            message: intl.get('device.reset_device.successfully'),
          });

          return data;
        })
        .catch(error => {
          const { response } = error as AxiosError;

          if (response) {
            const { data } = response;

            if (data.message === 'ERR_WAPP_NOT_INITIALIZED') {
              notification.error({
                message: intl.get('device.reset_device.error'),
                description: intl.get('device.reset_device.error_desc'),
              });
            }
          }

          return undefined;
        });

      return deviceRestarted;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(`device`);
      },
      onError: () => {
        queryClient.refetchQueries(`device`);
      },
    },
  );

  const handleSubmit = () => {
    setIsTimeout(true);
    resetDevice.mutateAsync();
    setTimeout(() => {
      setIsTimeout(false);
    }, 1000 * 20);
  };

  return (
    <>
      {disabled ? (
        <Tooltip title="Aguarde...">
          <Button type="primary" icon={<SyncOutlined />} loading disabled>
            {intl.get('device.reset_device.title')}
          </Button>
        </Tooltip>
      ) : (
        <Button
          type="primary"
          icon={<SyncOutlined />}
          onClick={handleSubmit}
          loading={resetDevice.isLoading || isTimeout}
        >
          {intl.get('device.reset_device.title')}
        </Button>
      )}
    </>
  );
};

export default ResetDeviceButton;
