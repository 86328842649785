/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from 'react';

import { useAttendance } from '~/hooks/attendances/attendance';
import QuestionsView from './components/QuestionsView';
import {
  formatterQuestions,
  formatterSendQuestions,
  updateQuestion,
} from './utils';

import { Container } from './styles';
import NewQuestion from './components/NewQuestion';

interface FormAttendanceViewProps {
  isBlocked?: boolean;
  questions: any[];
  isChildren?: boolean;
  isEnabledNewFields?: boolean;
}

const FormAttendanceView: React.FC<FormAttendanceViewProps> = ({
  isBlocked,
  questions,
  isEnabledNewFields,
}) => {
  const { updateAttendanceFormOrder, isLoading } = useAttendance();
  const [questionsFormatted, setQuestionsFormatted] = useState({});

  useEffect(() => {
    setQuestionsFormatted(formatterQuestions(questions));
  }, [questions, isLoading]);

  const updateAndSendField = (obj: any) => {
    let title = null;
    let newValue = null;
    Object.entries(obj).map(field => {
      title = field[0];
      newValue = field[1];
    });

    const questionUpdated = updateQuestion(questionsFormatted, title, newValue);

    const questionsFormattedToSend = formatterSendQuestions(questionUpdated);
    updateAttendanceFormOrder(questionsFormattedToSend);
  };

  return (
    <Container>
      {isEnabledNewFields || !isBlocked ? <NewQuestion /> : null}
      <QuestionsView
        isBlocked={isBlocked}
        questions={questionsFormatted}
        updateAndSendField={updateAndSendField}
      />
    </Container>
  );
};

export default FormAttendanceView;
