import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 1rem;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
