import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useResponsible } from '~/hooks/responsibles/responsible';
// import { Container } from './styles';

const ModalDeleteResponsible: React.FC = () => {
  const { deleteResponsible } = useResponsible();

  const handleDeleteResponsible = () => {
    deleteResponsible();
  };

  return (
    <div>
      <Tooltip title={intl.get('responsible.delete.title')}>
        <Popconfirm
          title={intl.get('responsible.delete.confirm_message')}
          onConfirm={handleDeleteResponsible}
          okText={intl.get('responsible.delete.yes')}
          cancelText={intl.get('responsible.delete.no')}
        >
          <Button size="large" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      </Tooltip>
    </div>
  );
};

export default ModalDeleteResponsible;
