import { FileSearchOutlined } from '@ant-design/icons';
import { Button, Tooltip, Drawer } from 'antd';
import intl from 'react-intl-universal';
import React, { useState } from 'react';
import AttendanceLog from '~/components/global/molecules/AttendanceLog';

import { useAttendance } from '~/hooks/attendances/attendance';

const DrawerAttendanceLog: React.FC = () => {
  const { attendance, isLoading } = useAttendance();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };
  const onClose = () => {
    setIsDrawerVisible(false);
  };

  if (isLoading || !attendance) {
    return <Button type="text" size="large" loading />;
  }
  return (
    <div>
      <Tooltip title={intl.get('chat.title_bar.attendance_log')}>
        <Button
          type="text"
          size="large"
          icon={<FileSearchOutlined />}
          onClick={() => showDrawer()}
        />
      </Tooltip>
      <Drawer
        title={intl.get('chat.title_bar.attendance_log')}
        placement="left"
        onClose={onClose}
        open={isDrawerVisible}
        width="350px"
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <AttendanceLog />
      </Drawer>
    </div>
  );
};

export default DrawerAttendanceLog;
