import React, { memo } from 'react';
import intl from 'react-intl-universal';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { Alert, Spin, Modal } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import isEmpty from '~/util/isEmpty';
import Panel from './organisms/Panel';
import Column from './organisms/Column';
import { useEnvironment } from '~/hooks/environments/environment';
import { AttendancesByStatusProvider } from '~/hooks/attendances/attendancesByStatus';

import { Container, Wrapper } from './styles';

import { useWorkflow } from '~/hooks/workflow/workflow';
import { useStatus } from '~/hooks/status/status';

const { confirm } = Modal;

const Kanban: React.FC = () => {
  const { environment, isLoading } = useEnvironment();
  const {
    isLoading: isLoadingStatusList,
    statusList,
    sortedStatusList,
    updateSortedStatusList,
  } = useStatus();
  const { moveCard } = useWorkflow();

  const handleMoveCard = (dropResult: DropResult) => {
    const attendanceId = dropResult.draggableId;
    const statusCode = dropResult.destination?.droppableId;

    if (statusCode && statusCode && statusList) {
      const newStatus = statusList.find(status => status.code === statusCode);
      if (!newStatus) return;
      confirm({
        title: intl.get('attendance.update.confirm_status_change', {
          total: 1,
        }),
        icon: <ExclamationCircleOutlined />,
        okText: intl.get('buttons.yes'),
        okType: 'danger',
        cancelText: intl.get('buttons.no'),
        onOk() {
          moveCard(attendanceId, newStatus);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {},
      });
    }
  };

  const handleMoveColumn = (dropResult: DropResult) => {
    if (!environment || !sortedStatusList) {
      return;
    }

    const { destination, source } = dropResult;

    if (destination) {
      const newStatusList = sortedStatusList;
      const column = sortedStatusList[source.index];

      newStatusList.splice(source.index, 1);
      newStatusList.splice(destination.index, 0, column);

      updateSortedStatusList(newStatusList, environment.id);
    }
  };

  if (
    isLoading ||
    !environment ||
    isLoadingStatusList ||
    !statusList ||
    !sortedStatusList
  ) {
    return (
      <Wrapper style={{ padding: '4rem' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isEmpty(environment.status) ? (
        <div style={{ padding: '1rem' }}>
          <Alert
            message={intl.get('workflow.kanban.no_status_created')}
            type="warning"
          />
        </div>
      ) : (
        <>
          <Panel />

          <DragDropContext
            onDragEnd={dropResult => {
              if (dropResult.type === 'card') {
                handleMoveCard(dropResult);
              }
              if (dropResult.type === 'column') {
                handleMoveColumn(dropResult);
              }
            }}
          >
            <Droppable
              droppableId="all-columns"
              type="column"
              direction="horizontal"
            >
              {providedKanban => (
                <Container
                  {...providedKanban.droppableProps}
                  ref={providedKanban.innerRef}
                >
                  {sortedStatusList.map((status, index) => (
                    <div key={status.code}>
                      <AttendancesByStatusProvider status={status.code}>
                        <Column index={index} status={status} />
                      </AttendancesByStatusProvider>
                    </div>
                  ))}
                  {providedKanban.placeholder}
                </Container>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </Wrapper>
  );
};

export default memo(Kanban, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
