import styled, { css } from 'styled-components';

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Value = styled.div`
  width: 100%;
`;

interface ContainerProps {
  small?: boolean;
}

const smallLayout = css`
  h5 {
    font-size: 0.9rem;
    margin: 0;
  }

  div.ant-typography-secondary {
    margin-bottom: 0.25rem;
  }
  font-size: 0.8rem;
  margin-bottom: 0rem;
`;
export const Container = styled.div<ContainerProps>`
  width: 100%;
  .options {
    display: none;
  }
  :hover {
    .options {
      display: block;
    }
  }
  ${props => props.small && smallLayout}
`;
