import styled from 'styled-components';

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;
export const Line = styled.div``;
export const Title = styled.h1`
  font-size: 1.1rem;
  margin-left: 0.25rem;
`;
export const SubTitle = styled.h1`
  font-size: 0.9rem;
  margin-top: 0.75rem;
  margin-left: 0.25rem;
`;
export const Label = styled.span`
  font-size: 0.75rem;
  font-weight: bolder;
  text-transform: uppercase;
  margin-right: 0.5rem;
`;
