import styled from 'styled-components';

export const Container = styled.div`
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;

  z-index: 9;
  background: var(--gray-9);
  padding: 0.5rem 1rem;
  color: #fff !important;
  width: calc(100% - 55px);

  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);

  button {
    color: #fff;
  }

  > div {
    display: flex;
    gap: 0.5rem;
  }
`;
