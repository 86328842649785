import styled from 'styled-components';

interface StepContentProps {
  visible: boolean;
}
export const Container = styled.div`
  max-width: 70vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

export const StepContent = styled.div<StepContentProps>`
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  gap: 1rem;
`;

export const StepAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
`;
