import React from 'react';
import intl from 'react-intl-universal';
import { useHistory, useParams } from 'react-router-dom';

import { Image, Tag } from 'antd';
import ImgWorkChat from '~/assets/work_chat.svg';

import { ContactProvider } from '~/hooks/contacts/contact';
import ChatPanel from '~/components/Contacts/organisms/ChatPanel';
import { ParamsProps } from '~/@types/params';
import { ContactsProvider } from '~/hooks/contacts/contacts';

import Navbar from '~/components/global/organisms/Navbar';
import { useEnvironment } from '~/hooks/environments/environment';
import { WhatsAppWebProvider } from '~/hooks/contacts/whatsAppWeb';
import ChatList from '~/components/Contacts/organisms/ChatList';
import Chat from '~/components/Contacts/organisms/Chat';

import { Wrapper, ContainerLeft, Alert, RowChatList } from './styles';

const Contacts: React.FC = () => {
  const { contactId } = useParams<ParamsProps>();

  const { environmentId } = useParams<ParamsProps>();

  const lastOpenContact = localStorage.getItem('@ZapForm:lastOpenContact');

  const history = useHistory();

  if (!contactId && lastOpenContact) {
    history.push(`/c/${environmentId}/contacts/${lastOpenContact}`);
  }

  const { isLoading, totalContacts } = useEnvironment();

  return (
    <>
      <WhatsAppWebProvider>
        <Navbar title={intl.get('contact.title_plural')}>
          {!isLoading && (
            <Tag>
              {intl.get('whatsapp_web.list.counter', {
                total: totalContacts,
              })}
            </Tag>
          )}
        </Navbar>
        <Wrapper>
          <ContactsProvider>
            <ContainerLeft>
              <ChatPanel />
              <RowChatList>
                <ChatList />
              </RowChatList>
            </ContainerLeft>
            {contactId ? (
              <ContactProvider>
                <Chat />
              </ContactProvider>
            ) : (
              <Alert>
                <Image src={ImgWorkChat} width="40%" preview={false} />
              </Alert>
            )}
          </ContactsProvider>
        </Wrapper>
      </WhatsAppWebProvider>
    </>
  );
};

export default Contacts;
