import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
  height: 300px;
  overflow: auto;
`;
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
  cursor: pointer;

  &:hover {
    background: rgb(0 154 99 / 25%);
  }
`;
