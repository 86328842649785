import React, { useState } from 'react';
import { DatePicker, Form } from 'antd';
import { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface RangePickerWithLimitProps {
  label: string;
  name: string;
}
export const RangePickerWithLimit: React.FC<RangePickerWithLimitProps> = ({
  label,
  name,
}) => {
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 31;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 31;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  return (
    <Form.Item
      label={label}
      name={name}
      style={{
        display: 'inline-block',
        width: '100%',
      }}
    >
      <RangePicker
        format={val => `${val.format('DD/MM/YYYY')}`}
        value={dates || value}
        disabledDate={disabledDate}
        onCalendarChange={val => setDates(val)}
        onChange={val => setValue(val)}
        onOpenChange={onOpenChange}
      />
    </Form.Item>
  );
};
