import styled from 'styled-components';

interface WrapperProps {
  isSelected: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
  cursor: pointer;
  position: relative;

  width: 100%;
  background: #fff;
  box-shadow: 0 1px 2px 1px rgb(0 0 0 / 6%);
  border: 1px solid
    ${props => (props.isSelected ? ' var(--primary-color)' : '#fff')};
  border-radius: var(--radius);
  padding-bottom: 0.5rem;

  .options {
    display: none;
  }

  :hover {
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);

    .options {
      display: flex;
      border: 1px solid
        ${props => (props.isSelected ? 'var(--primary-color)' : '#fff')};
      width: calc(100% + 2px);
      left: -1px;
      border-top: 0;
    }
  }
`;

export const Container = styled.div`
  padding: 0.5rem 0.5rem 0 0.5rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Options = styled.div`
  width: 100%;
  cursor: default;
  position: absolute;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem;

  .ant-checkbox {
    top: 0;
  }

  z-index: 5;
  border-radius: 0px 0px var(--radius) var(--radius);
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 20%);
  padding: 0.25rem 0.5rem;
`;
