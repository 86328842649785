import styled from 'styled-components';

export const Container = styled.div`
  flex: 0 0 55px;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  justify-content: flex-end;
  background: #fff;
  border-radius: var(--radius);
  border-bottom: var(--border-bottom);
`;

export const ContainerTabs = styled.div`
  padding: 0 0.5rem;
  background: #fff;
  border-radius: var(--radius);
  border-bottom: var(--border-bottom);

  .ant-tabs-nav {
    margin: 0;
  }
`;
