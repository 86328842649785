/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import { Button, Collapse, Image, Tooltip, Typography } from 'antd';
import React from 'react';

import {
  CaretRightOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import intl from 'react-intl-universal';
import Address from '~/components/global/atoms/Address';

import BaseField from '~/components/global/atoms/BaseField';
import { formatterLabel } from '../utils';
import { useAttendance } from '~/hooks/attendances/attendance';

interface QuestionsViewProps {
  isBlocked?: boolean;
  questions: any;
  updateAndSendField: any;
}

const QuestionsView: React.FC<QuestionsViewProps> = ({
  isBlocked,
  questions,
  updateAndSendField,
}) => {
  const { isFetching } = useAttendance();
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Image.PreviewGroup>
          {Object.entries(questions).map((question: any, questionIndex) => {
            if (Array.isArray(question[1])) {
              return (
                <div className="form-question" key={questionIndex}>
                  <Typography.Title
                    ellipsis
                    level={5}
                    style={{ marginBottom: '0' }}
                  >
                    {formatterLabel(question[0])}
                  </Typography.Title>
                  <Collapse
                    defaultActiveKey={['0']}
                    className="site-collapse-custom-collapse"
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                  >
                    {question[1].map(
                      (questionChildren: any, questionChildrenindex) => {
                        const isDisabledRow = !!String(
                          Object.values(questionChildren)[0],
                        ).includes('#Disabled -');

                        return (
                          <Collapse.Panel
                            header={
                              <span>
                                {formatterLabel(
                                  Object.keys(questionChildren)[0],
                                )}
                                :{` `}
                                {String(
                                  Object.values(questionChildren)[0],
                                ).replace('#Disabled - ', '')}
                              </span>
                            }
                            key={questionChildrenindex}
                            className={isDisabledRow ? 'disabled' : ''}
                            extra={
                              isDisabledRow ? (
                                <Tooltip title={intl.get('ui.disabled')}>
                                  <Button
                                    type="primary"
                                    shape="circle"
                                    loading={isFetching}
                                    onClick={event => {
                                      event.stopPropagation();
                                      const valueField = String(
                                        Object.values(questionChildren)[0],
                                      ).replace('#Disabled - ', '');
                                      const field = {
                                        [Object.keys(
                                          questionChildren,
                                        )[0]]: valueField,
                                      };

                                      updateAndSendField(field);
                                    }}
                                    icon={<CloseOutlined />}
                                    danger
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title={intl.get('ui.actived')}>
                                  <Button
                                    type="primary"
                                    shape="circle"
                                    loading={isFetching}
                                    onClick={event => {
                                      event.stopPropagation();
                                      const valueField = Object.values(
                                        questionChildren,
                                      )[0];
                                      const field = {
                                        [Object.keys(
                                          questionChildren,
                                        )[0]]: `#Disabled - ${valueField}`,
                                      };

                                      updateAndSendField(field);
                                    }}
                                    icon={<CheckOutlined />}
                                  />
                                </Tooltip>
                              )
                            }
                          >
                            <QuestionsView
                              isBlocked={isBlocked}
                              questions={questionChildren}
                              updateAndSendField={updateAndSendField}
                            />
                          </Collapse.Panel>
                        );
                      },
                    )}
                  </Collapse>
                </div>
              );
            }

            if (
              typeof question[1] === 'string' &&
              question[1].includes('OptionList')
            ) {
              const [, value, ...selectOptions] = question[1].split(';');
              return (
                <BaseField
                  key={questionIndex}
                  label={formatterLabel(question[0])}
                  value={value}
                  valueOptions={selectOptions}
                  name={question[0]}
                  type="select"
                  editable={!isBlocked}
                  onChange={field => {
                    updateAndSendField(field);
                  }}
                  ellipsis={false}
                />
              );
            }
            if (question[1]?._type === 'location') {
              return (
                <BaseField
                  key={questionIndex}
                  label={formatterLabel(question[0])}
                  ellipsis={false}
                >
                  <Address
                    address={question[1].text}
                    lat={question[1].lat}
                    lng={question[1].lng}
                  />
                </BaseField>
              );
            }

            if (
              question[1]?._type === 'image' ||
              question[1]?._type === 'document' ||
              question[1]?._type === 'audio' ||
              question[1]?._type === 'video'
            ) {
              return (
                <BaseField
                  key={questionIndex}
                  label={formatterLabel(question[0])}
                  value={question[1]}
                  url={question[1].url}
                  name={question[0]}
                  type={question[1]?._type}
                  editable={!isBlocked}
                  onChange={field => {
                    updateAndSendField(field);
                  }}
                  ellipsis={false}
                />
              );
            }

            if (typeof question[1] === 'object') {
              return (
                <BaseField
                  key={questionIndex}
                  label={formatterLabel(question[0])}
                  value=""
                  name={question[0]}
                  editable={!isBlocked}
                  onChange={field => {
                    updateAndSendField(field);
                  }}
                  ellipsis={false}
                />
              );
            }

            return (
              <BaseField
                key={questionIndex}
                label={formatterLabel(question[0])}
                value={question[1]}
                name={question[0]}
                editable={!isBlocked}
                onChange={field => {
                  updateAndSendField(field);
                }}
                ellipsis={false}
              />
            );
          })}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

export default QuestionsView;
