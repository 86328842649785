import {
  DownOutlined,
  LeftOutlined,
  PrinterOutlined,
  RightOutlined,
  ShareAltOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Modal,
  Card,
  Typography,
  Button,
  Dropdown,
  Select,
  MenuProps,
  Space,
} from 'antd';
import React, { memo, useState } from 'react';
import intl from 'react-intl-universal';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

import { TiPrinter } from 'react-icons/ti';
import BaseField from '~/components/global/atoms/BaseField';
import { useAttendance } from '~/hooks/attendances/attendance';
import { useStatus } from '~/hooks/status/status';
import AttendanceLog from '~/components/global/molecules/AttendanceLog';
import FormAttendanceView from '~/components/global/molecules/FormAttendanceView';
import { Container, Header, Content, Column } from './styles';
import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { SelectOptions } from '~/@types/fields';
import { ContactProvider } from '~/hooks/contacts/contact';
import Chat from '~/components/Contacts/organisms/Chat';
import NewAttendanceLog from '../../molecules/NewAttendanceLog';
import { ParamsProps } from '~/@types/params';
import DrawerContactAttendances from '../DrawerContactAttendances';

interface ModalAttendanceProps {
  isChildren?: boolean;
  onCloseModalAttendance?: () => void;
}

const { Paragraph } = Typography;
const ModalAttendance: React.FC<ModalAttendanceProps> = ({
  onCloseModalAttendance,
  isChildren = false,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { environmentId } = useParams<ParamsProps>();

  const { statusList } = useStatus();
  const {
    isLoading: isLoadingResponsiblesList,
    responsiblesList,
  } = useResponsibles();

  const {
    attendance,
    isLoading,
    isError,
    moveAttendance,
    updateResponsible,
  } = useAttendance();

  const [isLogCollapsed, setIsLogCollapsed] = useState(false);
  const [isChatCollapsed, setIsChatCollapsed] = useState(false);

  const handleCloseModal = () => {
    if (!isChildren && pathname.includes('workflow')) {
      history.push(`/c/${environmentId}/workflow`);
      return;
    }
    if (onCloseModalAttendance) {
      onCloseModalAttendance();
    }
  };

  const handleMoveAttendance = (statusCode: string) => {
    moveAttendance(statusCode);
  };

  const handleUpdateResponsible = (responsibleId: number) => {
    updateResponsible(responsibleId);
  };

  const handleCopyLink = () => {
    copy(
      `https://${window.location.host}/c/${environmentId}/workflow/${attendance?.id}`,
    );
    toast.success(intl.get('ui.link_copied_successfully'));
  };

  if (isError) {
    toast.error(intl.get('attendance.not_found.title'));
    return <></>;
  }

  if (
    isLoading ||
    !attendance ||
    !statusList ||
    isLoadingResponsiblesList ||
    !responsiblesList
  ) {
    return (
      <Modal open centered footer={null}>
        <Card loading bordered={false} />
      </Modal>
    );
  }

  const formattedResponsiblesList: SelectOptions[] = responsiblesList.map(
    responsible => ({
      name: responsible.name,
      label: responsible.name,
      value: responsible.id,
    }),
  );

  const getWidthCollumn = (type: 'form' | 'chat' | 'others'): string => {
    if (type === 'form') {
      if (isChatCollapsed && isLogCollapsed) {
        return '95%';
      }
      if (isChatCollapsed) {
        return '80%';
      }
      if (isLogCollapsed) {
        return '50%';
      }
      return '40%';
    }

    if (type === 'chat') {
      if (isChatCollapsed) {
        return '0px';
      }
      if (isLogCollapsed) {
        return '50%';
      }
      return '35%';
    }

    if (type === 'others') {
      if (isLogCollapsed) {
        return '0px';
      }
      if (isChatCollapsed) {
        return '20%';
      }
      return '20%';
    }

    return 'auto';
  };

  return (
    <Modal
      open
      centered
      width="100vw"
      onOk={() => handleCloseModal()}
      onCancel={() => handleCloseModal()}
      footer={null}
      bodyStyle={{ padding: '0' }}
      className="modal-attendance"
    >
      <Container>
        <Header>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '0.5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '0.5rem',
                width: '100%',
              }}
            >
              <Typography.Title
                level={3}
                style={{ margin: '0', flex: '0 0 auto' }}
              >
                {`#${attendance.id}`}
              </Typography.Title>

              <BaseField label="Status">
                <Dropdown
                  menu={{
                    items: statusList.map((status, index) => {
                      return {
                        key: index,
                        label: `${status.code} - ${status.status}`,
                        onClick: () => handleMoveAttendance(status.code),
                      };
                    }) as MenuProps['items'],
                  }}
                >
                  <Button block>
                    <Space
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {attendance.status?.status}
                      <DownOutlined
                        style={{ color: '#00000040', fontSize: '12px' }}
                      />
                    </Space>
                  </Button>
                </Dropdown>
              </BaseField>
              <BaseField label={intl.get('responsible.title')}>
                <Select
                  value={attendance.responsible?.id}
                  onChange={value => {
                    handleUpdateResponsible(Number(value));
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder={intl.get(
                    'attendance.filters.responsible_for_the_service_placeholder',
                  )}
                  filterSort={(optionA, optionB) =>
                    optionA?.name
                      .toLowerCase()
                      .localeCompare(optionB.name.toLowerCase())
                  }
                  options={formattedResponsiblesList}
                  style={{ width: '100%', marginBottom: '0' }}
                />
              </BaseField>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '1rem',
                paddingRight: '50px',
              }}
            >
              <Link
                to={{
                  pathname: `/c/${environmentId}/contacts/${attendance.contact.id}`,
                }}
                target="_blank"
                style={{ marginTop: '22px' }}
              >
                <Button block icon={<UserOutlined />}>
                  {intl.get('modal_attendance.view_contact')}
                </Button>
              </Link>

              <ContactProvider contactId={attendance.contact.id}>
                <DrawerContactAttendances typeIcon="graph" />
              </ContactProvider>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '0.5rem',
              justifyContent: 'space-between',
            }}
          >
            <BaseField
              label={intl.get('modal_attendance.start')}
              value={attendance.time_createdFormatted}
            />

            <BaseField
              label={intl.get('attendance.creation_date_there')}
              value={attendance.time_createdThere}
            />

            <BaseField
              label={intl.get('modal_attendance.status_change')}
              value={attendance.time_statusFormatted}
            />

            <BaseField label={intl.get('modal_attendance.deadline')}>
              <Typography.Paragraph>
                {attendance.time_deadlineFormatted ||
                  intl.get('modal_attendance.no_deadline')}
              </Typography.Paragraph>
            </BaseField>

            <BaseField
              label={intl.get('modal_attendance.deadline_expiration')}
              small
            >
              <Typography.Paragraph
                style={{
                  color:
                    attendance.time_howIsTimeout === 'timeout'
                      ? 'red'
                      : attendance.time_howIsTimeout === 'insideTime'
                      ? '#faad14'
                      : 'none',
                }}
              >
                {attendance.time_timeoutFormatted ||
                  intl.get('modal_attendance.no_deadline_expiration')}
              </Typography.Paragraph>
            </BaseField>

            <Link
              to={{
                pathname: `/c/${environmentId}/workflow/${attendance.id}/print`,
              }}
              target="_blank"
            >
              <Button icon={<PrinterOutlined />} loading={isLoading} />
            </Link>

            <div>
              <Button
                icon={<ShareAltOutlined />}
                onClick={() => handleCopyLink()}
              />
            </div>
          </div>
        </Header>
        <Content style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Column style={{ width: getWidthCollumn('form') }}>
            <div style={{ overflow: 'scroll' }}>
              <Typography.Title level={4}>
                {intl.get('modal_attendance.answered_form')}
              </Typography.Title>
              {attendance.order ? (
                <>
                  <FormAttendanceView
                    questions={attendance.order}
                    isBlocked={
                      attendance.status.status_type === 'C' ||
                      attendance.status.status_type === 'X'
                    }
                  />
                </>
              ) : (
                intl.get('modal_attendance.no_answered')
              )}
            </div>
            {environmentId === '589' ? (
              <Link
                to={{
                  pathname: `/c/${environmentId}/workflow/${attendance.id}/print/custom`,
                }}
                target="_blank"
              >
                <Button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<TiPrinter size="1.4rem" />}
                  loading={isLoading}
                  block
                  size="large"
                >
                  Imprimir etiqueta
                </Button>
              </Link>
            ) : null}
          </Column>
          <Button
            onClick={() => {
              setIsChatCollapsed(!isChatCollapsed);
            }}
            size="small"
            style={{
              height: '100%',
              padding: '0',
            }}
          >
            {isChatCollapsed ? <LeftOutlined /> : <RightOutlined />}
          </Button>
          <Column style={{ width: getWidthCollumn('chat') }}>
            {!isChatCollapsed && (
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  overflow: 'hidden',
                  height: '100%',
                  width: '100%',
                  backgroundColor: '#F2F2F2',
                }}
              >
                <ContactProvider contactId={attendance.contact.id}>
                  <Chat
                    isCompact
                    isBlocked={
                      attendance.status.status_type === 'C' ||
                      attendance.status.status_type === 'X'
                    }
                  />
                </ContactProvider>
              </div>
            )}
          </Column>
          <Button
            onClick={() => {
              setIsLogCollapsed(!isLogCollapsed);
            }}
            size="small"
            style={{
              height: '100%',
              padding: '0',
            }}
          >
            {isLogCollapsed ? <LeftOutlined /> : <RightOutlined />}
          </Button>
          <Column style={{ width: getWidthCollumn('others'), gap: 0 }}>
            {!isLogCollapsed && (
              <>
                {attendance.order_summary && (
                  <div>
                    <Typography.Title level={4}>
                      {intl.get('attendance.order_summary')}
                    </Typography.Title>
                    <Paragraph>{attendance.order_summary}</Paragraph>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                  }}
                >
                  <Typography.Title level={4}>
                    {intl.get('modal_attendance.attendance_log')}
                  </Typography.Title>
                  <NewAttendanceLog />
                  <div style={{ overflow: 'auto', marginTop: '2px' }}>
                    <AttendanceLog />
                  </div>
                </div>
              </>
            )}
          </Column>
        </Content>
      </Container>
    </Modal>
  );
};

export default memo(ModalAttendance, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
