import {
  DownOutlined,
  ExportOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Result, Modal, Typography, Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useReport } from '~/hooks/reports/report';

interface ReportModalProps {
  type?: 'default' | 'text';
}

const ReportModal: React.FC<ReportModalProps> = ({ type = 'default' }) => {
  const { generateReport, reportUrl, isLoading } = useReport();

  const [isVisible, setIsVisible] = useState(false);

  const handleCloseReportModal = () => {
    setIsVisible(false);
  };

  const handleGenerateReport = (format: 'csv' | 'excel') => {
    setIsVisible(true);
    generateReport(format);
  };

  return (
    <div>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={() => handleGenerateReport('csv')}>
              {intl.get('reports.format_csv')}
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleGenerateReport('excel')}>
              {intl.get('reports.format_excel')}
            </Menu.Item>
          </Menu>
        }
      >
        {type === 'default' ? (
          <Button icon={<ExportOutlined />} size="large" type="primary">
            {intl.get('reports.export_report')} <DownOutlined />
          </Button>
        ) : (
          <Button type="link" icon={<ExportOutlined />}>
            {intl.get('reports.export_report')} <DownOutlined />
          </Button>
        )}
      </Dropdown>

      <Modal
        open={isVisible}
        onOk={handleCloseReportModal}
        onCancel={handleCloseReportModal}
        centered
        footer={null}
      >
        <>
          {isLoading || !reportUrl ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '250px',
              }}
            >
              <Typography.Title level={2}>Gerando relatório</Typography.Title>
              <p>
                <LoadingOutlined style={{ fontSize: '3rem' }} spin />
              </p>
            </div>
          ) : (
            <Result
              status="success"
              title={intl.get('reports.alert_generate.title')}
              subTitle={intl.get('reports.alert_generate.description')}
              extra={[
                <Link
                  key="download"
                  to={{
                    pathname: reportUrl,
                  }}
                  target="_blank"
                >
                  <Button type="primary">
                    {intl.get('reports.download_report')}
                  </Button>
                </Link>,
              ]}
            />
          )}
        </>
      </Modal>
    </div>
  );
};

export default ReportModal;
