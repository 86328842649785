import React from 'react';
import { Skeleton, Tabs, Tag, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import { useQuery } from 'react-query';
import { Container } from './styles';
import { useStatus } from '~/hooks/status/status';
import { useCallCenter } from '~/hooks/callCenter/callCenter';
import { useEnvironment } from '~/hooks/environments/environment';
import api from '~/services/api';

interface IStatus {
  status: string;
  code?: string;
  count?: number;
}
const SelectStatus: React.FC = () => {
  const { environment } = useEnvironment();
  const { statusList } = useStatus();
  const { filters, setNewFilters } = useCallCenter();

  const { data: status, isLoading } = useQuery(
    ['totalAttendanceByStatus', environment, filters, statusList],
    async (): Promise<IStatus[] | undefined> => {
      if (!environment || !statusList) {
        return undefined;
      }

      const newStatusList: IStatus[] = [...statusList];
      // if()
      newStatusList.splice(0, 0, { status: 'Todos', code: undefined });
      const formattedStatusList = await Promise.all(
        newStatusList.map(async item => {
          const response = await api.get(`/zc/${environment.id}/order/`, {
            params: {
              offset: 0,
              limit: 1,
              ...(item.code && { status: item.code }),
              ...(filters.attendanceId && { id: filters.attendanceId }),
              ...(filters.contact && {
                client: filters.contact,
              }),
              ...(filters.responsible && { location: filters.responsible }),
              ...(filters.attendance && {
                order: filters.attendance.slice(0, 100),
              }),
              ...(filters.expired === true && {
                expired: true,
              }),
              ...(filters.timeCreated && {
                time_created__gt: filters.timeCreated[0],
                time_created__lte: filters.timeCreated[1],
              }),
              ...(filters.timeStatus && {
                time_status__gt: filters.timeStatus[0],
                time_status__lte: filters.timeStatus[1],
              }),
              ...(filters.timeLastUpdated && {
                time_last_updated__gt: filters.timeLastUpdated[0],
                time_last_updated__lte: filters.timeLastUpdated[1],
              }),
              ...(filters.attendanceIdStart && {
                id__gt: filters.attendanceIdStart,
              }),
              ...(filters.attendanceIdEnd && {
                id__lte: filters.attendanceIdEnd,
              }),
            },
          });

          const { data } = response;

          const formattedStatus: IStatus = {
            ...item,
            count: data.count,
          };
          return formattedStatus;
        }),
      );

      return formattedStatusList;
    },
  );

  if (!status || isLoading) {
    return (
      <>
        <Skeleton.Button active block />
      </>
    );
  }

  return (
    <Container>
      <Tabs
        defaultActiveKey={filters.statusCode ? filters.statusCode : undefined}
        onChange={key => {
          setNewFilters({ ...filters, statusCode: key });
        }}
      >
        {status.map(item => (
          <Tabs.TabPane
            tab={
              <>
                <Tooltip
                  title={intl.get('attendance.counter', {
                    total: item.count,
                  })}
                >
                  {item.code ? `${item.code} - ` : null}
                  {item.status} <Tag>{item.count}</Tag>
                </Tooltip>
              </>
            }
            key={item.code}
          />
        ))}
      </Tabs>
    </Container>
  );
};

export default SelectStatus;
