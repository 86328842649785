import styled from 'styled-components';

export const Container = styled.div`
  .site-collapse-custom-collapse {
    .ant-collapse-item.disabled .ant-collapse-header span {
      text-decoration: line-through;
    }
    .btn-switch-list {
      font-size: 1.5em;
    }
    .btn-switch-list.disable {
      color: red;
      &:hover {
        color: green;
      }
    }
    .btn-switch-list.active {
      color: green;
      &:hover {
        color: red;
      }
    }
  }
`;
