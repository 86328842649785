import { UnorderedListOutlined } from '@ant-design/icons';
import { Button, Tooltip, Drawer } from 'antd';
import intl from 'react-intl-universal';
import React, { useState } from 'react';

import FormAttendanceView from '~/components/global/molecules/FormAttendanceView';
import { useAttendance } from '~/hooks/attendances/attendance';

const DrawerAttendanceForm: React.FC = () => {
  const { attendance, isLoading } = useAttendance();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };
  const onClose = () => {
    setIsDrawerVisible(false);
  };

  if (isLoading || !attendance) {
    return <Button type="text" size="large" loading />;
  }
  return (
    <div>
      <Tooltip title={intl.get('chat.title_bar.answered_form')}>
        <Button
          type="text"
          size="large"
          icon={<UnorderedListOutlined />}
          onClick={() => showDrawer()}
        />
      </Tooltip>
      <Drawer
        title={intl.get('chat.title_bar.answered_form')}
        placement="left"
        onClose={onClose}
        open={isDrawerVisible}
        width="350px"
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <FormAttendanceView
          questions={attendance.order}
          isBlocked={
            attendance.status.status_type === 'C' ||
            attendance.status.status_type === 'X'
          }
        />
      </Drawer>
    </div>
  );
};

export default DrawerAttendanceForm;
