import styled from 'styled-components';

interface ContentProps {
  isDraggingOver?: boolean;
}

export const ContainerCollapsed = styled.div`
  flex: 0 0 55px;
  max-width: 55px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

interface HeaderProps {
  statusType?: 'O' | 'C' | 'X';
}

export const HeaderCollapsed = styled.div<HeaderProps>`
  flex: 1 1 auto;
  padding: 1rem 0.5rem;
  margin: 0 4px 1rem 4px;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: ${props => {
    switch (props.statusType) {
      case 'C':
        return 'rgba(0, 143, 99, 0.2)';
      case 'X':
        return 'rgba(255, 0, 0, 0.2)';
      default:
        return 'rgba(0, 0, 255, 0.15)';
    }
  }};
  cursor: pointer;
  border-top: 2px solid
    ${props => {
      switch (props.statusType) {
        case 'C':
          return 'rgba(0, 143, 99, 1)';
        case 'X':
          return 'rgba(255, 0, 0, 1)';
        default:
          return 'rgba(0, 0, 255, 1)';
      }
    }};
  border-radius: var(--radius);
  font-weight: 500;
  text-transform: uppercase;
  overflow: hidden;
  writing-mode: vertical-rl;
  text-orientation: mixed;

  .title {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .ant-tag {
    margin-right: 0;
    padding: 7px 0;
  }
`;

export const Container = styled.div`
  flex: 0 0 270px;
  min-width: 270px;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  height: 100%;
`;

export const Header = styled.div<HeaderProps>`
  min-height: 50px;
  flex: 0 0 auto;
  padding: 0.25rem 0.5rem;
  margin: 0 16px 0 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => {
    switch (props.statusType) {
      case 'C':
        return 'rgba(0, 143, 99, 0.2)';
      case 'X':
        return 'rgba(255, 0, 0, 0.2)';
      default:
        return 'rgba(0, 0, 255, 0.15)';
    }
  }};
  border-top: 2px solid
    ${props => {
      switch (props.statusType) {
        case 'C':
          return 'rgba(0, 143, 99, 1)';
        case 'X':
          return 'rgba(255, 0, 0, 1)';
        default:
          return 'rgba(0, 0, 255, 1)';
      }
    }};
  border-radius: 2px 2px var(--radius) var(--radius);
  font-weight: 500;
  text-transform: uppercase;
  overflow: hidden;

  .title {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    overflow: hidden;
  }
  .options {
    flex: 0 0 auto;
  }
`;

export const Content = styled.div<ContentProps>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 4px;
  height: 100%;

  .list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 4px 4px 30px 4px;
    background: ${props => (props.isDraggingOver ? '#04bf7b26' : '')};
  }

  :hover {
    ::-webkit-scrollbar-thumb {
      display: block;
    }
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }
`;
