/* eslint-disable @typescript-eslint/no-explicit-any */
import intl from 'react-intl-universal';
import {
  CloseOutlined,
  CommentOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  SendOutlined,
  SmileOutlined,
  SmileTwoTone,
} from '@ant-design/icons';
import { Button, Image, Input, Upload } from 'antd';
import Picker, { IEmojiData } from 'emoji-picker-react';
import React, { useState } from 'react';

import Paragraph from 'antd/es/typography/Paragraph';
import ReactPlayer from 'react-player';
import { Link, useParams } from 'react-router-dom';
import { useBot } from '~/hooks/bot/bot';
import { useMessages } from '~/hooks/messages/messages';
import RecoderVoiceMessage from '~/components/global/molecules/RecoderVoiceMessage';

import { Container, ContainerInfo, QuotedMsg, Wrapper } from './styles';
import { useDevice } from '~/hooks/device';
import { useContact } from '~/hooks/contacts/contact';
import Address from '../../atoms/Address';
import Document from '../../atoms/Document';
import { formatterMessageText } from '~/util/formatMessageText';
import VCards from '../../atoms/Message/VCards';
import ShareContact from './ShareContact';
import ModalNewAttendance from '../ModalNewAttendance';
import { ParamsProps } from '~/@types/params';
import { useAttendance } from '~/hooks/attendances/attendance';
import SendMessageTemplate from '../SendMessageTemplate';

interface TextComposerProps {
  isBlocked?: boolean;
}
const TextComposer: React.FC<TextComposerProps> = ({ isBlocked }) => {
  const { environmentId } = useParams<ParamsProps>();

  const {
    sendMessage,
    sendMessageFile,
    isLoadingUploadMedia,
    quotedMsgId,
    clearQuotedMsgId,
    messagesList,
  } = useMessages();

  const { contact } = useContact();
  const { isBotEnabled, updateBotStatus } = useBot();
  const { sendNote } = useAttendance();
  const { device, isLoading } = useDevice();

  const [isPickEmojiVisible, setIsPickEmojiVisible] = useState(false);

  const inputRef = React.useRef<any>(null);
  const [messageText, setMessageText] = useState('');

  const onEmojiClick = (event: React.MouseEvent, emojiObject: IEmojiData) => {
    setMessageText(messageTextOld => `${messageTextOld}${emojiObject.emoji}`);
    setIsPickEmojiVisible(false);
  };

  const handleSendMessage = () => {
    sendMessage(messageText);
    setMessageText('');
  };

  if (isLoading || !device || !contact) {
    return (
      <Wrapper>
        <Container style={{ justifyContent: 'center' }}>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </Container>
      </Wrapper>
    );
  }

  if (device.status === 'QRCODE') {
    return (
      <Wrapper>
        <ContainerInfo>{intl.get('device.alerts.qrcode.title')}</ContainerInfo>
      </Wrapper>
    );
  }

  if (device.status === 'OPENING') {
    return (
      <Wrapper>
        <ContainerInfo>{intl.get('device.alerts.opening.title')}</ContainerInfo>
      </Wrapper>
    );
  }

  if (device.status === 'PAIRING') {
    return (
      <Wrapper>
        <ContainerInfo>Emparelhando, aguarde...</ContainerInfo>
      </Wrapper>
    );
  }

  if (isBlocked) {
    return (
      <Wrapper>
        <ContainerInfo>
          <div>{intl.getHTML('chat.blocked')}</div>
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <ModalNewAttendance
              type="contactSmall"
              contact={{
                name: contact.name || contact.number_formatted,
                label: contact.name || contact.number_formatted,
                value: contact.id,
              }}
            />
            <Link
              to={{
                pathname: `/c/${environmentId}/contacts/${contact.id}`,
              }}
              target="_blank"
            >
              <Button ghost icon={<CommentOutlined />}>
                {intl.get('modal_attendance.open_conversation')}
              </Button>
            </Link>
          </div>
        </ContainerInfo>
      </Wrapper>
    );
  }

  const getQuotedMessage = () => {
    const message = messagesList.find(msg => msg.id === quotedMsgId);
    if (!message) return <>Vazio</>;

    const isVCards =
      !message.deleted &&
      message.msg_type === 'ch' &&
      message.msg_data.text.includes('#VCARD@;');
    return (
      <QuotedMsg dir={message.msg_dir}>
        <div className="title">
          <div className="name">
            {message.msg_dir === 'i' ? contact?.name : intl.get('message.you')}
          </div>
          <div>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => clearQuotedMsgId()}
            />
          </div>
        </div>

        <div className="content">
          {
            {
              ch: isVCards ? (
                <VCards data={message.msg_data.text} />
              ) : (
                <Paragraph>
                  {formatterMessageText(message.msg_data.text)}
                </Paragraph>
              ),
              im: (
                <>
                  <Image
                    src={message.msg_data.url}
                    alt={intl.get('chat.message_list.message.image')}
                  />

                  {message.msg_data.description && (
                    <Paragraph>
                      {formatterMessageText(message.msg_data.description)}
                    </Paragraph>
                  )}
                </>
              ),
              au: (
                <ReactPlayer
                  controls
                  width="100%"
                  height="60px"
                  url={message.msg_data.url}
                />
              ),
              pt: (
                <ReactPlayer
                  controls
                  width="100%"
                  height="60px"
                  url={message.msg_data.url}
                />
              ),
              do: <Document url={message.msg_data.url} />,
              lo: (
                <Address
                  lat={message.msg_data.lat}
                  lng={message.msg_data.lng}
                />
              ),
              vi: (
                <ReactPlayer controls width="100%" url={message.msg_data.url} />
              ),
            }[message.msg_type]
          }
        </div>
      </QuotedMsg>
    );
  };

  return (
    <Wrapper>
      {isPickEmojiVisible ? (
        <div style={{ position: 'absolute', marginTop: '-325px' }}>
          <Picker
            onEmojiClick={onEmojiClick}
            preload
            groupNames={{
              smileys_people: intl.get('text_composer.emojis.smileys_people'),
              animals_nature: intl.get('text_composer.emojis.animals_nature'),
              food_drink: intl.get('text_composer.emojis.food_drink'),
              travel_places: intl.get('text_composer.emojis.travel_places'),
              activities: intl.get('text_composer.emojis.activities'),
              objects: intl.get('text_composer.emojis.objects'),
              symbols: intl.get('text_composer.emojis.symbols'),
              flags: intl.get('text_composer.emojis.flags'),
              recently_used: intl.get('text_composer.emojis.recently_used'),
            }}
            groupVisibility={{
              recently_used: false,
            }}
          />
        </div>
      ) : (
        ''
      )}
      {quotedMsgId ? getQuotedMessage() : null}
      <Container>
        {isLoadingUploadMedia ? (
          <>
            <div
              style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
            >
              <Button
                size="large"
                type="text"
                icon={<LoadingOutlined style={{ fontSize: 25 }} spin />}
              />
              <span>{intl.get('text_composer.sending')}</span>
            </div>
          </>
        ) : (
          <>
            {isPickEmojiVisible && (
              <Button
                size="large"
                type="text"
                icon={<CloseOutlined />}
                onClick={() => {
                  setIsPickEmojiVisible(false);
                }}
              />
            )}

            <ShareContact />

            <Button
              size="large"
              type="text"
              shape="circle"
              icon={isPickEmojiVisible ? <SmileTwoTone /> : <SmileOutlined />}
              value={messageText}
              onClick={() => {
                setIsPickEmojiVisible(!isPickEmojiVisible);
              }}
            />

            <Upload
              name="msg_type"
              showUploadList={false}
              onChange={info => {
                if (info.event?.percent === 100) {
                  sendMessageFile(info.file.originFileObj);
                }
              }}
            >
              <Button
                shape="circle"
                size="large"
                type="text"
                icon={<PaperClipOutlined />}
              />
            </Upload>

            {device.isWhatsAppOfficial && <SendMessageTemplate />}

            <Input.TextArea
              size="large"
              className="inputText"
              placeholder={intl.get('text_composer.type_a_message')}
              autoSize={{ minRows: 1, maxRows: 5 }}
              ref={inputRef}
              value={messageText}
              onChange={e => {
                setMessageText(e.target.value);
              }}
              onKeyDown={event => {
                if (isBotEnabled) {
                  if (sendNote) {
                    sendNote(intl.get('bot.user_disabled'));
                  }
                  updateBotStatus(false);
                }

                if (event.keyCode === 13 && !event.shiftKey) {
                  event.preventDefault();

                  handleSendMessage();
                }
              }}
            />

            {messageText && (
              <Button
                shape="circle"
                size="large"
                type="text"
                icon={<SendOutlined />}
                onClick={() => {
                  handleSendMessage();
                }}
              />
            )}
            <RecoderVoiceMessage />
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default TextComposer;
