import React, { useCallback, useEffect, useState } from 'react';

import clearSpecialCharacters from '~/util/clearSpecialCharacters';
import { Container } from './styles';

import VCard from './VCard';

interface IVCard {
  name: string;
  phone?: string | null;
  formattedPhone?: string | null;
}
interface VCardsProps {
  data: string;
}

const VCards: React.FC<VCardsProps> = ({ data }) => {
  const [vCards, setVCards] = useState<IVCard[] | null>(null);

  const formatVCard = (dataRaw: string): IVCard => {
    const [, name, phoneNumberRaw] = dataRaw.split('@;');
    const formattedPhoneNumber = clearSpecialCharacters(phoneNumberRaw);
    return {
      name,
      phone: formattedPhoneNumber !== '' ? phoneNumberRaw : null,
      formattedPhone: phoneNumberRaw !== '' ? phoneNumberRaw : null,
    };
  };

  const getVCards = useCallback(() => {
    const formattedVCards = [];
    if (data.includes('#;#VCARD')) {
      const vCardsRaw = data.split('#;');
      vCardsRaw.forEach(vCardRaw => {
        formattedVCards.push(formatVCard(vCardRaw));
      });
    } else {
      formattedVCards.push(formatVCard(data));
    }
    setVCards(formattedVCards);
  }, [data]);

  useEffect(() => {
    getVCards();
  }, [getVCards]);

  return (
    <Container>{vCards && vCards.map(item => <VCard data={item} />)}</Container>
  );
};

export default VCards;
