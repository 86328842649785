import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0.5rem 0.5rem 0 0.5rem;
`;

export const Container = styled.div`
  background: #fff;
  border-bottom: var(--border-bottom);
  border-radius: var(--radius);
  padding: 0.5rem 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;

export const Title = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
`;

export const Actions = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
