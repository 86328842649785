import { LoadingOutlined } from '@ant-design/icons';
import { Input, Modal, Skeleton, Typography } from 'antd';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import intl from 'react-intl-universal';
import { useQuery } from 'react-query';
import { Avatar } from '~/components/global/atoms/Avatar';
import { ContactResponseProps, ContactProps } from '~/hooks/contacts/contact';
import { useEnvironment } from '~/hooks/environments/environment';
import { useMessages } from '~/hooks/messages/messages';
import api from '~/services/api';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import formatPhoneNumber from '~/util/formatPhoneNumber';
import { stringToHslColor } from '~/util/stringToHslColor';
import { Item, List } from './styles';

interface ModalShareContactProp {
  onCloseModal: () => void;
}
const ModalShareContact: React.FC<ModalShareContactProp> = ({
  onCloseModal,
}) => {
  const { sendMessage } = useMessages();
  const { environment } = useEnvironment();
  const [hasMore, setHasMore] = useState(false);
  const [quickSearch, setQuickSearch] = useState<string | null>(null);
  const [contacts, setContacts] = useState<ContactProps[]>([]);
  const limitDefault = 10;
  const [limit, setLimit] = useState(limitDefault);
  const { isLoading } = useQuery(
    [`contactsModalShareContact`, limit, environment?.id, quickSearch],
    async () => {
      if (!environment) return;
      const { data } = await api.get(`/zc/${environment.id}/client/`, {
        params: {
          offset: 0,
          limit,
          ...(quickSearch
            ? quickSearch.match(/\d/) != null
              ? { number__icontains: quickSearch }
              : { name__icontains: quickSearch }
            : {}),
        },
      });
      setHasMore(!!data.next);
      const formattedContacts = await formatterContacts(data.results);
      setContacts(formattedContacts);
    },
  );

  const moreContacts = () => setLimit(limitOld => limitOld + limitDefault);

  const handleSendContactMessage = (contact: ContactProps) => {
    sendMessage(`*${contact.name}*\n${contact.number_formatted}`);
    onCloseModal();
  };

  if (isLoading && contacts.length === 0) {
    return (
      <Modal title="Compartilhar contato" open centered footer={[]}>
        {contacts.length}
        <Skeleton />
      </Modal>
    );
  }

  return (
    <>
      <Modal
        title="Compartilhar contato"
        onCancel={() => onCloseModal()}
        footer={[]}
        open
        centered
      >
        <Input.Search
          allowClear
          onSearch={setQuickSearch}
          onChange={e => {
            setQuickSearch(e.target.value);
          }}
          placeholder={intl.get('contact.filters.search_contact_placeholder')}
        />
        <List id="scrollableDivList">
          <InfiniteScroll
            dataLength={contacts.length}
            next={moreContacts}
            hasMore={hasMore}
            loader={
              <div
                style={{
                  margin: '1rem auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <LoadingOutlined style={{ fontSize: 24 }} spin />
              </div>
            }
            scrollableTarget="scrollableDivList"
            style={{ overflow: 'none' }}
          >
            {contacts.map(contactItem => (
              <Item
                key={contactItem.id}
                onClick={() => handleSendContactMessage(contactItem)}
              >
                <div className="avatar">
                  {contactItem.address === '' ? (
                    <Avatar
                      size={48}
                      style={{
                        backgroundColor: stringToHslColor(contactItem.name),
                      }}
                    >
                      {createLetterAvatar(contactItem.name)}
                    </Avatar>
                  ) : (
                    <Avatar size={48} src={contactItem.address} />
                  )}
                </div>
                <div className="content">
                  <Typography.Text>
                    {contactItem.name || contactItem.number_formatted}
                  </Typography.Text>
                  <div>
                    <Typography.Text type="secondary">
                      {contactItem.number_formatted}
                    </Typography.Text>
                  </div>
                </div>
              </Item>
            ))}
          </InfiniteScroll>
        </List>
      </Modal>
    </>
  );
};

export default ModalShareContact;

const formatterContacts = async (
  contactsRaw: ContactResponseProps[],
): Promise<ContactProps[]> => {
  const contacts = await Promise.all(
    contactsRaw.map(async contactRaw => {
      return {
        ...contactRaw,
        number_formatted: formatPhoneNumber(contactRaw.number),
        id_name: `#${contactRaw.id} - ${contactRaw.name}`,
      } as ContactProps;
    }),
  );
  return contacts;
};
