/* eslint-disable import/no-duplicates */
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { useLocales } from '../locales';

export interface FormsResponseProps {
  count: number;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      name: string;
      is_active: boolean;
      start_msg: string;
      start_cmd: string;
      end_msg: string;
      end_cmd: string;
      response_timeout: string;
      msg_timeout: string;
      last_updated: Date;
      allow_web: boolean;
      language: string;
    },
  ];
}

export interface FormsProps {
  id: number;
  name: string;
  isActive: boolean;
  startMsg: string;
  startCmd: string;
  endMsg: string;
  endCmd: string;
  responseTimeout: string;
  msgTimeout: string;
  lastUpdated: Date;
  lastUpdatedFormatted: string;
  allowWeb: boolean;
  language: string;
}

interface BotFormsContextData {
  pageSize: number;
  handleChangePageSize: (value: number) => void;
  formsList?: FormsProps[];
  totalForms: number;
  moreFormsPerPage: (page: number) => void;
  isLoading: boolean;
  refetch: () => void;
  onIsLoading: () => void;
}
const ListFormsBotContext = createContext<BotFormsContextData>(
  {} as BotFormsContextData,
);

const BotFormsProvider: React.FC = ({ children }) => {
  const { currentLocale } = useLocales();
  const { environment } = useEnvironment();

  const [totalForms, setTotalForms] = useState(0);

  const [pageSize, setPageSize] = useState(20);
  const [limit, setLimit] = useState(pageSize);

  const [isLoading, setIsLoading] = useState(true);

  const formatterDate = (value: Date) => {
    return format(value, 'dd/MM/yyyy HH:mm:ss aa', {
      locale: currentLocale === 'pt-BR' ? ptBR : es,
    });
  };

  const { data: formsList, refetch } = useQuery(
    [`botForms`, limit, environment?.id],
    async () => {
      if (!environment) {
        return undefined;
      }
      const response = await api.get<FormsResponseProps>(`/zf/form/`, {
        params: {
          offset: 0,
          limit,
        },
      });

      setTotalForms(response.data.count);

      const formsListResponse = response.data.results.map(form => {
        const formResponse: FormsProps = {
          ...form,
          isActive: form.is_active,
          startMsg: form.start_msg,
          startCmd: form.start_cmd,
          endMsg: form.end_msg,
          endCmd: form.end_cmd,
          responseTimeout: form.response_timeout,
          msgTimeout: form.msg_timeout,
          lastUpdated: form.last_updated,
          allowWeb: form.allow_web,
          lastUpdatedFormatted: formatterDate(new Date(form.last_updated)),
        };

        return formResponse;
      });

      setIsLoading(false);
      return formsListResponse;
    },
  );

  const moreFormsPerPage = useCallback(
    (page: number) => {
      setLimit(page * pageSize);
    },
    [pageSize],
  );

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const onIsLoading = () => {
    setIsLoading(true);
  };

  return (
    <ListFormsBotContext.Provider
      value={{
        pageSize,
        handleChangePageSize,
        formsList,
        totalForms,
        moreFormsPerPage,
        isLoading,
        refetch,
        onIsLoading,
      }}
    >
      {children}
    </ListFormsBotContext.Provider>
  );
};
function useBotForms(): BotFormsContextData {
  const context = useContext(ListFormsBotContext);

  if (!context) {
    throw new Error('useBotForms must be used within an FormsChatBotsProvider');
  }

  return context;
}

export { BotFormsProvider, useBotForms };
