import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactRouterProps,
  Redirect,
} from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useAuth } from '../hooks/auth';

import DefaultTemplate from '../templates/Default';
import AuthTemplate from '../templates/Auth';

interface RouteProps extends ReactRouterProps {
  isPrivate?: boolean;
  isPrint?: boolean;
  computedMatch: {
    params: {
      environmentId: string;
      attendanceId?: string;
      contactId?: string;
      phoneNumberToBill?: string;
    };
  };
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isPrint = false,
  component: Component,
  computedMatch,
  ...rest
}) => {
  const { token } = useAuth();
  const isSigned = !!token;

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        const { phoneNumberToBill } = computedMatch.params;
        if (isMobile && !phoneNumberToBill) {
          const {
            environmentId,
            attendanceId,
            contactId,
          } = computedMatch.params;

          if (attendanceId) {
            window.location.href = `${process.env.REACT_APP_MOBILE_URL}/c/${environmentId}/workflow/${attendanceId}`;
          } else if (contactId) {
            window.location.href = `${process.env.REACT_APP_MOBILE_URL}/c/${environmentId}/whatsappweb/${contactId}`;
          } else {
            window.location.href = `${process.env.REACT_APP_MOBILE_URL}/c/${environmentId}/workflow`;
          }

          return <></>;
        }

        if (isPrivate && !isSigned) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }

        const environmentId = localStorage.getItem('@ZapForm:environmentId');
        if (!isPrivate && isSigned && environmentId) {
          return (
            <Redirect
              to={{
                pathname: `/c/${environmentId}/workflow`,
                state: { from: location },
              }}
            />
          );
        }

        return (
          <>
            {isPrivate ? (
              <DefaultTemplate isPrint={isPrint}>
                <Component />
              </DefaultTemplate>
            ) : (
              <AuthTemplate>
                <Component />
              </AuthTemplate>
            )}
          </>
        );
      }}
    />
  );
};

export default Route;
