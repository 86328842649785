import React, { useState } from 'react';

import { Input } from 'antd';
import intl from 'react-intl-universal';
import ChatFilters from '../../molecules/ChatFilters';
import ChatSettings from '../../molecules/ChatSettings';
import NotificationPermissionAlert from '~/components/global/atoms/NotificationPermissionAlert';

import { Container } from './styles';
import SelectStatus from '../../molecules/SelectStatus';
import { FiltersProps } from '~/@types/attendances';
import { useCallCenter } from '~/hooks/callCenter/callCenter';
import clearSpecialCharacters from '~/util/clearSpecialCharacters';
import apiWhatsApp from '~/services/apiWhatsApp';
import { useEnvironment } from '~/hooks/environments/environment';

const { Search } = Input;

const ChatPanel: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setNewFilters } = useCallCenter();
  const { instanceId } = useEnvironment();
  const validatePhoneNumber = async (
    phoneNumber: string,
  ): Promise<string | undefined> => {
    const formattedPhoneNumber = clearSpecialCharacters(phoneNumber);
    try {
      const response = await apiWhatsApp.post(
        `/clients/${instanceId}/validatePhoneNumber`,
        {
          phoneNumber: formattedPhoneNumber,
        },
      );
      return response.data.user;
    } catch (error) {
      return undefined;
    }
  };
  const onSearch = async (value: string) => {
    if (/\d{8,}/.test(value)) {
      const phoneNumber = value.replace(/[^0-9]/g, '');
      setIsLoading(true);
      const validatedPhoneNumber = await validatePhoneNumber(phoneNumber);
      setIsLoading(false);
      if (validatedPhoneNumber) {
        setNewFilters({ contact: validatedPhoneNumber });
        return;
      }
    }
    if (/\d{4,}/.test(value)) {
      const phoneNumber = value.replace(/[^0-9]/g, '');
      setNewFilters({ contact: phoneNumber });
      return;
    }
    const newFilter: FiltersProps = { contact: value };
    setNewFilters(newFilter);
  };

  return (
    <>
      <Container>
        <Search
          placeholder={intl.get(
            'attendance.filters.contact_information_placeholder',
          )}
          allowClear
          onSearch={onSearch}
          loading={isLoading}
        />

        <ChatFilters />
        <ChatSettings />
      </Container>
      <NotificationPermissionAlert />

      <SelectStatus />
    </>
  );
};

export default ChatPanel;
