import { Button, Tag } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { useQuery } from 'react-query';
import { useEnvironment } from '~/hooks/environments/environment';
import BaseField from '../../atoms/BaseField';
import { useStatus } from '~/hooks/status/status';
import api from '~/services/api';

interface TotalAttendancesContactProps {
  contactPhoneNumber: string;
}

interface ITotalByStatusType {
  totalOpen: number;
  totalCanceled: number;
  totalConcluded: number;
}

const TotalAttendancesContact: React.FC<TotalAttendancesContactProps> = ({
  contactPhoneNumber,
}) => {
  const { environment } = useEnvironment();
  const { statusList } = useStatus();

  const getTotal = async (statusCode: string): Promise<number> => {
    if (!environment) return 0;
    try {
      const response = await api.get(`zc/${environment.id}/order/`, {
        params: {
          offset: 0,
          limit: 1,
          status: statusCode,
          client: contactPhoneNumber,
        },
      });
      return response.data.count;
    } catch (error) {
      return 0;
    }
  };
  const { data, isLoading } = useQuery(
    [
      `totalAttendancesContact${contactPhoneNumber}`,
      environment,
      contactPhoneNumber,
    ],
    async (): Promise<ITotalByStatusType | null> => {
      if (!environment || !contactPhoneNumber || !statusList) return null;
      const open = statusList.filter(item => item.status_type === 'O');
      const concluded = statusList.filter(item => item.status_type === 'C');
      const canceled = statusList.filter(item => item.status_type === 'X');
      let totalOpen = 0;
      let totalConcluded = 0;
      let totalCanceled = 0;
      await Promise.all(
        open.map(async item => {
          const count = await getTotal(item.code);
          totalOpen += count;
        }),
      );
      await Promise.all(
        concluded.map(async item => {
          const count = await getTotal(item.code);
          totalConcluded += count;
        }),
      );
      await Promise.all(
        canceled.map(async item => {
          const count = await getTotal(item.code);
          totalCanceled += count;
        }),
      );
      return {
        totalOpen,
        totalConcluded,
        totalCanceled,
      };
    },
  );

  if (isLoading || !data) {
    return (
      <>
        <Button
          block
          size="large"
          loading
          type="text"
          style={{ minWidth: '150px' }}
        />
      </>
    );
  }
  return (
    <BaseField
      label={intl.get('contact.contact_calls')}
      style={{
        display: 'flex',
      }}
    >
      <Tag color="blue">
        {intl.getHTML('workflow.status.open', { total: data.totalOpen })}
      </Tag>
      <Tag color="green">
        {intl.getHTML('workflow.status.concluded', {
          total: data.totalConcluded,
        })}
      </Tag>
      <Tag color="red">
        {intl.getHTML('workflow.status.canceled', {
          total: data.totalCanceled,
        })}
      </Tag>
    </BaseField>
  );
};

export default TotalAttendancesContact;
