import React from 'react';
import intl from 'react-intl-universal';
import MessageTemplatesComponent from '~/components/MessageTemplates';
import Navbar from '~/components/global/organisms/Navbar';
import { MessageTemplateProvider } from '~/hooks/device/messageTemplates';

const MessageTemplates: React.FC = () => {
  return (
    <>
      <Navbar title={intl.get('message_templates.title')} />

      <MessageTemplateProvider>
        <MessageTemplatesComponent />
      </MessageTemplateProvider>
    </>
  );
};

export default MessageTemplates;
