import React, { useState } from 'react';

import { Button, Tooltip, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import intl from 'react-intl-universal';
import { BiSupport } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const ModalSupportZapForm: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title={intl.get('support_zapform.title')}>
        <Button
          type="default"
          icon={<BiSupport size="1.4rem" />}
          style={{ lineHeight: 0 }}
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        title={intl.get('support_zapform.title')}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Typography.Paragraph>
          {intl.getHTML('support_zapform.describe_whatsapp')}
          <Tooltip
            title={intl.get('support_zapform.tooltip_whatsapp')}
            placement="right"
          >
            <Link
              to={{
                pathname: `https://wa.me/5511944466386`,
              }}
              target="_blank"
            >
              +55 (11) 94446-6386
            </Link>
          </Tooltip>
          <br />
          {intl.get('support_zapform.describe_email')}
          <Tooltip
            title={intl.get('support_zapform.tooltip_email')}
            placement="right"
          >
            <Link
              to={{
                pathname: `mailto:suporte@zapform.com.br`,
              }}
              target="_blank"
            >
              suporte@zapform.com.br
            </Link>
          </Tooltip>
        </Typography.Paragraph>
      </Modal>
    </>
  );
};

export default ModalSupportZapForm;
