import { SettingOutlined } from '@ant-design/icons';
import { Button, Drawer, Tooltip, Select, Form } from 'antd';
import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { ListSettingsProps, useList } from '~/hooks/workflow/list';

const { Option, OptGroup } = Select;

const ListSettings: React.FC = () => {
  const { settings, setNewSettings, resetSettings } = useList();

  const [isVisible, setIsVisible] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(settings);
  }, [settings, form]);

  const handleRequestOpen = () => {
    setIsVisible(true);
  };

  const handleRequestClose = () => {
    setIsVisible(false);
  };

  const handleSettingsChange = (allValues: ListSettingsProps) => {
    setNewSettings(allValues);
  };

  return (
    <div>
      <Tooltip title={intl.get('workflow.list.settings.title')}>
        <Button
          type="text"
          size="large"
          icon={<SettingOutlined />}
          onClick={handleRequestOpen}
        />
      </Tooltip>
      <Drawer
        title={intl.get('workflow.list.settings.title')}
        width="40%"
        placement="right"
        closable
        onClose={handleRequestClose}
        open={isVisible}
        forceRender
        footer={
          <Button
            type="link"
            onClick={() => {
              resetSettings();
            }}
            block
          >
            {intl.get('buttons.reset_settings')}
          </Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onValuesChange={(changedValues, allValues) =>
            handleSettingsChange(allValues)
          }
          initialValues={settings}
        >
          <Form.Item
            label={intl.get('workflow.list.settings.title_column')}
            name="columns"
          >
            <Select
              mode="multiple"
              maxTagCount="responsive"
              style={{ width: '100%' }}
              showArrow
            >
              <OptGroup label={intl.get('attendance.title')}>
                <Option value="attendance.order_summary">
                  {intl.get('attendance.order_summary')}
                </Option>
                <Option value="attendance.time_created">
                  {intl.get('attendance.creation_date')}
                </Option>
                <Option value="attendance.time_createdThere">
                  {intl.get('attendance.creation_date_there')}
                </Option>
                <Option value="attendance.time_status">
                  {intl.get('attendance.date_of_status_change')}
                </Option>
                <Option value="attendance.time_deadline">
                  {intl.get('attendance.deadline')}
                </Option>
                <Option value="attendance.time_timeout">
                  {intl.get('attendance.deadline_expiration')}
                </Option>
              </OptGroup>

              <OptGroup label={intl.get('attendance.contact.title')}>
                <Option value="contact.name">
                  {intl.get('attendance.contact.name')}
                </Option>
                <Option value="contact.number">
                  {intl.get('attendance.contact.number')}
                </Option>
              </OptGroup>

              <OptGroup label={intl.get('attendance.responsible.title')}>
                <Option value="responsible.name">
                  {intl.get('attendance.responsible.name')}
                </Option>
                <Option value="responsible.address">
                  {intl.get('attendance.responsible.address')}
                </Option>
              </OptGroup>
            </Select>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default ListSettings;
