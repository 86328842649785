import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
// import { useHistory } from 'react-router-dom';
import ChatMessage from '~/components/global/organisms/ChatMessage';
import { BotProvider } from '~/hooks/bot/bot';
import { useContact } from '~/hooks/contacts/contact';
import { MessagesProvider } from '~/hooks/messages/messages';
import ChatTitleBar from '../ChatTitleBar';

import { Container } from './styles';

interface ChatProps {
  isBlocked?: boolean;
  isCompact?: boolean;
}

const Chat: React.FC<ChatProps> = ({ isBlocked, isCompact }) => {
  // const history = useHistory();
  const { getRootProps } = useDropzone();
  const { isLoading, contact } = useContact();

  useEffect(() => {
    if (contact) {
      localStorage.setItem('@ZapForm:lastOpenContact', String(contact.id));
    }
  }, [contact]);

  if (isLoading || !contact) {
    return (
      <Container>
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 48, margin: '4rem' }} spin />
          }
        />
      </Container>
    );
  }

  return (
    <Container {...getRootProps({ className: 'dropzone' })}>
      <BotProvider contactId={contact.id}>
        <MessagesProvider>
          <ChatTitleBar isCompact={isCompact} />
          <ChatMessage isBlocked={isBlocked} />
        </MessagesProvider>
      </BotProvider>
    </Container>
  );
};

export default Chat;
