import { Avatar, Button, Skeleton, Typography } from 'antd';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { ContactResponseProps } from '~/hooks/contacts/contacts';

import { useEnvironment } from '~/hooks/environments/environment';
import api from '~/services/api';

import { createLetterAvatar } from '~/util/createLetterAvatar';
import { stringToHslColor } from '~/util/stringToHslColor';

import { Container, Actions, Wrapper } from './styles';

interface IContact {
  id: number;
  name: string;
  number: string;
  address: string;
}

interface VCardProps {
  data: {
    name: string;
    phone?: string | null;
    formattedPhone?: string | null;
  };
}

const VCard: React.FC<VCardProps> = ({ data }) => {
  const { environment } = useEnvironment();

  const { name, phone, formattedPhone } = data;

  const { data: contact, isLoading: isLoadingProfile, refetch } = useQuery(
    [`vCard${name}${phone}`],
    async (): Promise<IContact | undefined> => {
      if (!environment) return undefined;
      try {
        const response = await api.get<ContactResponseProps>(
          `/zc/${environment.id}/client/`,
          {
            params: {
              offset: 0,
              limit: 1,
              number__icontains: phone,
            },
          },
        );
        if (response.data.results[0]) {
          return response.data.results[0];
        }
        return undefined;
      } catch (error) {
        return undefined;
      }
    },
  );

  const {
    mutateAsync: createNewContact,
    isLoading: isLoadingNewContact,
  } = useMutation(async () => {
    if (!environment || !phone || !name) return;
    try {
      await api.post(`/zc/${environment.id}/client/`, {
        name,
        number: phone,
      });

      toast.success(
        intl.get('contact.create.successfully_created', {
          name,
        }),
      );
    } catch (error) {
      toast.error(intl.get('contact.create.error_creating'));
    }
    refetch();
  });

  if (isLoadingProfile) {
    return (
      <Wrapper>
        <Container>
          <Skeleton avatar paragraph={{ rows: 2 }} />
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container>
        <div>
          {!contact || contact.address === '' ? (
            <Avatar
              size={48}
              style={{
                backgroundColor: stringToHslColor(name),
              }}
            >
              {createLetterAvatar(name)}
            </Avatar>
          ) : (
            <Avatar size={48} src={contact.address} />
          )}
        </div>
        <div>
          <Typography.Text strong>{name}</Typography.Text>
          <br />
          <Typography.Text>
            {phone ? formattedPhone : 'Telefone não informado'}
          </Typography.Text>
        </div>
      </Container>
      {phone ? (
        <Actions>
          {contact ? (
            <Link
              to={{
                pathname: `/c/${environment?.id}/contacts/${contact.id}`,
              }}
              target="_blank"
            >
              <Button block type="link" size="middle">
                Conversar
              </Button>
            </Link>
          ) : (
            <Button
              block
              type="link"
              size="middle"
              onClick={() => createNewContact()}
              loading={isLoadingNewContact}
            >
              Cadastrar contato
            </Button>
          )}
        </Actions>
      ) : null}
    </Wrapper>
  );
};

export default VCard;
