import React from 'react';

import { WorkflowProvider } from '~/hooks/workflow/workflow';
import WorkflowComponent from '~/components/Workflow';

import { Container } from './styles';

const Workflow: React.FC = () => {
  return (
    <Container>
      <WorkflowProvider>
        <WorkflowComponent />
      </WorkflowProvider>
    </Container>
  );
};

export default Workflow;
