import React from 'react';
import { Card, Image, Result, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { useReport } from '~/hooks/reports/report';
import GrowthAnalyticsImg from '~/assets/growth_analytics.svg';
import ReportModal from '~/components/global/molecules/ReportModal';
import { Container } from './styles';
import { ListProvider } from '~/hooks/workflow/list';
import AttendancesTable from '~/components/global/molecules/AttendancesTable';
import { AttendancesProvider } from '~/hooks/attendances/attendances';

const Results: React.FC = () => {
  const { isLoading, totalAttendances, filters } = useReport();

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '1rem',
        }}
      >
        <div>
          <Typography.Title level={3}>
            {intl.get('reports.new_report')}
          </Typography.Title>
          <Typography.Text>{intl.get('reports.report_info')}</Typography.Text>
        </div>
        <div>
          {totalAttendances && totalAttendances > 0 ? <ReportModal /> : ''}
        </div>
      </div>

      {totalAttendances === null && !isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <Image width="350px" preview={false} src={GrowthAnalyticsImg} />
        </div>
      ) : (
        <>
          {isLoading ? (
            <Card style={{ textAlign: 'center' }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            </Card>
          ) : (
            <Card style={{ width: '100%' }}>
              {totalAttendances === 0 ? (
                <Result title={intl.get('reports.empty')} />
              ) : (
                <ListProvider>
                  <AttendancesProvider filters={filters} disabledSort>
                    <AttendancesTable />
                  </AttendancesProvider>
                </ListProvider>
              )}
            </Card>
          )}
        </>
      )}
    </Container>
  );
};

export default Results;
