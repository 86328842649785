import React, { createContext, useCallback, useContext, useState } from 'react';
import apiWhatsApp from '~/services/apiWhatsApp';
import clearSpecialCharacters from '~/util/clearSpecialCharacters';
import { useEnvironment } from '../environments/environment';

export interface SettingsProps {
  title?: Array<'contact.avatar' | 'contact.id_name' | 'contact.number'>;
  subTitles?: [];
  sortBy?: {
    sort: 'asc' | 'desc';
    by: 'last_msg' | 'name' | 'number_unread_msgs';
  };
}

interface WhatsAppWebContextData {
  settings: SettingsProps;
  setNewSettings: (newSettingsInput: SettingsProps) => void;
  resetSettings: () => void;
  quickSearch: string | null;
  setNewQuickSearch: (newQuickSearch: string | null) => Promise<void>;
}
const WhatsAppWebContext = createContext<WhatsAppWebContextData>(
  {} as WhatsAppWebContextData,
);

const WhatsAppWebProvider: React.FC = ({ children }) => {
  const defaultSettings: SettingsProps = {
    title: ['contact.avatar', 'contact.number'],
    subTitles: [],
    sortBy: { sort: 'desc', by: 'last_msg' },
  };

  const [quickSearch, setQuickSearch] = useState<string | null>(null);
  const [settings, setSettings] = useState<SettingsProps>(() => {
    const settingsLocalStorage = localStorage.getItem(
      '@ZapForm:whatsAppWebSettings',
    );

    if (settingsLocalStorage) {
      const formattedSettings: SettingsProps = JSON.parse(settingsLocalStorage);

      if (
        formattedSettings.title &&
        formattedSettings.subTitles &&
        formattedSettings.sortBy
      ) {
        return formattedSettings;
      }
    }

    localStorage.setItem(
      '@ZapForm:whatsAppWebSettings',
      JSON.stringify(defaultSettings),
    );

    return defaultSettings;
  });
  const { instanceId } = useEnvironment();
  const validatePhoneNumber = useCallback(
    async (phoneNumber: string): Promise<string | undefined> => {
      const formattedPhoneNumber = clearSpecialCharacters(phoneNumber);
      try {
        const response = await apiWhatsApp.post(
          `/clients/${instanceId}/validatePhoneNumber`,
          {
            phoneNumber: formattedPhoneNumber,
          },
        );
        return response.data.user;
      } catch (error) {
        return undefined;
      }
    },
    [instanceId],
  );

  const setNewQuickSearch = async (newQuickSearch: string | null) => {
    if (!newQuickSearch) {
      setQuickSearch(() => null);
      return;
    }
    if (/\d{8,}/.test(newQuickSearch)) {
      const phoneNumber = newQuickSearch.replace(/[^0-9]/g, '');
      const validatedPhoneNumber = await validatePhoneNumber(phoneNumber);
      if (validatedPhoneNumber) {
        setQuickSearch(() => validatedPhoneNumber);
        return;
      }
    }
    if (/\d{4,}/.test(newQuickSearch)) {
      const phoneNumber = newQuickSearch.replace(/[^0-9]/g, '');
      setQuickSearch(() => phoneNumber);
      return;
    }
    setQuickSearch(() => newQuickSearch);
  };
  const setNewSettings = (newSettingsInput: SettingsProps) => {
    localStorage.setItem(
      '@ZapForm:whatsAppWebSettings',
      JSON.stringify(newSettingsInput),
    );
    setSettings(newSettingsInput);
  };

  const resetSettings = () => {
    localStorage.setItem(
      '@ZapForm:whatsAppWebSettings',
      JSON.stringify(defaultSettings),
    );
    setSettings(defaultSettings);
  };

  return (
    <WhatsAppWebContext.Provider
      value={{
        settings,
        setNewSettings,
        resetSettings,
        quickSearch,
        setNewQuickSearch,
      }}
    >
      {children}
    </WhatsAppWebContext.Provider>
  );
};
function useWhatsAppWeb(): WhatsAppWebContextData {
  const context = useContext(WhatsAppWebContext);

  if (!context) {
    throw new Error(
      'useWhatsAppWeb must be used within an WhatsAppWebProvider',
    );
  }

  return context;
}

export { WhatsAppWebProvider, useWhatsAppWeb };
