/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-duplicates */
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { v1 as uuid } from 'uuid';
import api from '~/services/api';
import { ParamsProps } from '~/@types/params';
import { useAttendance } from '~/hooks/attendances/attendance';
import { useUser } from '~/hooks/user';
import { useUnreadMessages } from './unreadMessages';
import { useLocales } from '../locales';

export interface MessageProps {
  id: number;
  msg_data: {
    text: string;
    url: string;
    description?: string;
    lat: number;
    lng: number;
  };
  msg_dir: 'i' | 'o'; //  i (inbound), significa que a pessoa mandou a mensagem para o servidor. o (outbound), significa que o servidor enviou a mensagem para a pessoa.
  msg_status: 'r' | 'w' | 's' | 'a' | 'd'; // r: recebida pelo servidor (para mensagens inbound)  w: servidor enviando para o usuário  s: servidor enviou  a: pessoa recebeu a mensagem  d: pessoa leu a mensagem
  msg_time: Date;
  msg_timeFormatted: string;
  msg_type: 'ch' | 'im' | 'vi' | 'au' | 'pt' | 'do' | 'lo'; // ch: mensagem de texto (chat)  im: imagem  vi: video  au: audio  do: documento  lo: localização
  sent_by: string;
  sent_by_type: 'f' | 'u'; // como a mensagem foi enviada (para mensagems outbound). f quando foi enviada por um formulárop, u quando for enviada pelo usuário
  quoted_msg_id?: number;
  forwarded: boolean;
  deleted: boolean;
}

interface MessagesContextData {
  messagesList: MessageProps[];
  countMessages: number;
  quotedMsgId: number | null;
  clearQuotedMsgId: () => void;
  setQuotedMsgId: (msgId: number) => void;
  isLoading: boolean;
  hasMore: boolean;
  moreMessages: () => void;
  sendMessage: (message: string) => void;
  isLoadingUploadMedia: boolean;
  sendMessageAudio: (audioURL: any) => void;
  sendMessageFile: (file: any) => void;
  refetch: () => void;
  readMessages: () => void;
  deleteMessage: (messageId: number) => void;
}

export const MessagesContext = createContext<MessagesContextData>(
  {} as MessagesContextData,
);

const MessagesProvider: React.FC = ({ children }) => {
  const { currentLocale } = useLocales();
  const {
    contactId: contactIdExternal,
    attendanceId,
  } = useParams<ParamsProps>();

  const { environmentId } = useParams<ParamsProps>();
  const { attendance } = useAttendance();
  const { user } = useUser();
  const { refetch: refechUnreadMessages } = useUnreadMessages();

  const [messagesList, setMessagesList] = useState<MessageProps[]>([]);
  const [quotedMsgId, setQuotedMsgId] = useState<number | null>(null);
  const [limit, setLimit] = useState(25);
  const [countMessages, setTotalMessssa] = useState(0);

  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUploadMedia, setIsLoadingUploadMedia] = useState(false);

  useEffect(() => {
    setMessagesList([]);
    setTotalMessssa(0);
    setLimit(25);
    setHasMore(false);
    setIsLoading(true);
  }, [attendanceId]);

  const contactId = contactIdExternal || attendance?.contact.id;

  const { refetch } = useQuery(
    [
      `messages${contactId}`,
      contactId,
      attendance,
      environmentId,
      limit,
      messagesList,
      isLoadingUploadMedia,
    ],
    async () => {
      if (!environmentId) {
        return;
      }
      if (!contactId && !attendance?.contact?.id) {
        return;
      }
      const formattedContactId = contactId;
      const response = await api.get(
        `zc/${environmentId}/client/${formattedContactId}/message/`,
        {
          params: {
            offset: 0,
            limit,
          },
        },
      );

      const { data } = response;

      const formattedMessagesList = data.results.map(
        (message: MessageProps) => {
          return {
            ...message,
            msg_timeFormatted: formatterDate(new Date(message.msg_time)),
          };
        },
      );

      setMessagesList(formattedMessagesList);

      setTotalMessssa(data.count);
      setHasMore(!!data.next);
      setIsLoading(false);
    },
    {
      refetchInterval: 5000,
    },
  );

  const clearQuotedMsgId = () => {
    setQuotedMsgId(null);
  };

  useEffect(() => {
    clearQuotedMsgId();
  }, [contactId, attendance]);

  const formatterDate = (value: Date) => {
    return format(value, 'dd/MM/yyyy HH:mm:ss aa', {
      locale: currentLocale === 'pt-BR' ? ptBR : es,
    });
  };

  const sendMessage = async (message: string) => {
    if (!message) {
      return;
    }

    try {
      const response = await api.post(
        `/zc/${environmentId}/client/${contactId}/message/`,
        {
          msg_type: 'ch',
          msg_data: {
            text: attendance?.id
              ? `*${user?.name} (Atendimento ${attendance.id}):*
            \n${message}`
              : `*${user?.name}:*
              \n${message}`,
          },
          sent_by: user?.name,
          ...(quotedMsgId && { quoted_msg_id: quotedMsgId }),
        },
      );
      const { data } = response;
      clearQuotedMsgId();
      setMessagesList(messagesListOld => [...messagesListOld, data]);
    } catch (err) {
      toast.error(intl.get('message.error_sending_message'));
    }
  };

  const sendMessageAudio = async (audio: any) => {
    setIsLoadingUploadMedia(true);

    const formData = new FormData();
    formData.append('msg_media', audio, renameFile(`${contactId}-audio.mp3`));
    formData.append('msg_type', 'au');
    if (quotedMsgId) formData.append('quoted_msg_id', String(quotedMsgId));

    try {
      await api.post(
        `/zc/${environmentId}/client/${contactId}/media_message/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    } catch (error) {
      toast.error(intl.get('message.error_sending_audio'));
    }
    setIsLoadingUploadMedia(false);
  };

  async function checkTypeFile(file: any) {
    if (file.type.match(/image/g) !== null) {
      return 'im';
    }
    if (file.type.match(/audio/g) !== null) {
      return 'au';
    }
    if (file.type.match(/video/g) !== null) {
      return 'vi';
    }
    return 'do';
  }

  const renameFile = (fileName: string): string => {
    return `${new Date().getTime()}-${uuid()}-${fileName}`;
  };

  const sendMessageFile = async (file: File) => {
    if (file) {
      setIsLoadingUploadMedia(true);
      const formData = new FormData();

      formData.append('msg_media', file, renameFile(file.name));

      const typeFile = await checkTypeFile(file);
      formData.append('msg_type', typeFile);

      if (quotedMsgId) formData.append('quoted_msg_id', String(quotedMsgId));

      try {
        await api.post(
          `/zc/${environmentId}/client/${contactId}/media_message/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        setIsLoadingUploadMedia(false);
      } catch (error) {
        setIsLoadingUploadMedia(false);
        toast.error(intl.get('message.error_sending_file'));
      }
    }
  };

  const moreMessages = useCallback(() => {
    setLimit(limitOld => limitOld + 20);
  }, []);

  const readMessages = async () => {
    await api.put(`/zc/${environmentId}/client/${contactId}/read_message/`);
    refechUnreadMessages();
  };

  const deleteMessage = async (messageId: number) => {
    await api.delete(
      `/zc/${environmentId}/client/${contactId}/message/${messageId}`,
    );
    refetch();
  };

  return (
    <MessagesContext.Provider
      value={{
        messagesList,
        countMessages,
        quotedMsgId,
        clearQuotedMsgId,
        setQuotedMsgId,
        isLoading,
        hasMore,
        moreMessages,
        sendMessage,
        sendMessageAudio,
        sendMessageFile,
        isLoadingUploadMedia,
        readMessages,
        deleteMessage,
        refetch,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};
function useMessages(): MessagesContextData {
  const context = useContext(MessagesContext);

  if (!context) {
    throw new Error('useMessages must be used within an MessagesProvider');
  }

  return context;
}

export { MessagesProvider, useMessages };
