import styled from 'styled-components';

export const Wreaper = styled.div`
  padding: 1rem;
  background: #fff;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;
`;
export const Container = styled.div`
  margin: 2rem 0;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: 'center';
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
`;
