import { SettingOutlined } from '@ant-design/icons';
import { Button, Drawer, Tooltip, Select, Form } from 'antd';
import React, { useState, useEffect, memo } from 'react';
import intl from 'react-intl-universal';
import { useStatus } from '~/hooks/status/status';
import { KanbanSettingsProps, useKanban } from '~/hooks/workflow/kanban';

const { Option, OptGroup } = Select;

const Settings: React.FC = () => {
  const { settings, setNewSettings, resetSettings } = useKanban();
  const { resetSortedKanbanList } = useStatus();

  const [isVisible, setIsVisible] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(settings);
  }, [settings, form]);

  const handleRequestOpen = () => {
    setIsVisible(true);
  };

  const handleRequestClose = () => {
    setIsVisible(false);
  };

  const handleSettingsChange = (allValues: KanbanSettingsProps) => {
    setNewSettings(allValues);
  };

  return (
    <div>
      <Tooltip title={intl.get('workflow.kanban.settings.title')}>
        <Button
          type="text"
          size="large"
          icon={<SettingOutlined />}
          onClick={handleRequestOpen}
        />
      </Tooltip>
      <Drawer
        title={intl.get('workflow.kanban.settings.title')}
        width="40%"
        placement="right"
        closable
        onClose={handleRequestClose}
        open={isVisible}
        forceRender
        footer={
          <Button
            type="link"
            onClick={() => {
              resetSettings();
            }}
            block
          >
            {intl.get('buttons.reset_settings')}
          </Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onValuesChange={(changedValues, allValues) =>
            handleSettingsChange(allValues)
          }
          initialValues={settings}
        >
          <Form.Item
            label={intl.get('workflow.kanban.settings.title_card')}
            name="title"
          >
            <Select
              mode="multiple"
              maxTagCount="responsive"
              style={{ width: '100%' }}
              showArrow
            >
              <OptGroup label={intl.get('attendance.contact.title')}>
                <Option value="contact.id_name">
                  {intl.get('attendance.contact.id_and_name')}
                </Option>
                <Option value="contact.number">
                  {intl.get('attendance.contact.number')}
                </Option>
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.get('workflow.kanban.settings.sub_titles_card')}
            name="subTitles"
          >
            <Select
              mode="multiple"
              maxTagCount="responsive"
              style={{ width: '100%' }}
              showArrow
            >
              <OptGroup label={intl.get('attendance.title')}>
                <Option value="attendance.order_summary">
                  {intl.get('attendance.order_summary')}
                </Option>
                <Option value="attendance.time_created">
                  {intl.get('attendance.creation_date')}
                </Option>
                <Option value="attendance.time_createdThere">
                  {intl.get('attendance.creation_date_there')}
                </Option>
                <Option value="attendance.time_status">
                  {intl.get('attendance.date_of_status_change')}
                </Option>
                <Option value="attendance.time_deadline">
                  {intl.get('attendance.deadline')}
                </Option>
                <Option value="attendance.time_timeout">
                  {intl.get('attendance.deadline_expiration')}
                </Option>
              </OptGroup>

              <OptGroup label={intl.get('attendance.responsible.title')}>
                <Option value="responsible.name">
                  {intl.get('attendance.responsible.name')}
                </Option>
                <Option value="responsible.address">
                  {intl.get('attendance.responsible.address')}
                </Option>
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.get('workflow.kanban.settings.column_ordering')}
          >
            <Button onClick={() => resetSortedKanbanList()} block>
              {intl.get('workflow.kanban.settings.reset_column_ordering')}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default memo(Settings, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
