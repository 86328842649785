import { Input } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import ChatSettings from '../../molecules/ChatSettings';
import { useWhatsAppWeb } from '~/hooks/contacts/whatsAppWeb';
import NewContactModal from '~/components/global/molecules/NewContactModal';

import { Container } from './styles';
import NotificationPermissionAlert from '~/components/global/atoms/NotificationPermissionAlert';

const ChatPanel: React.FC = () => {
  const { setNewQuickSearch } = useWhatsAppWeb();
  const [isLoading, setIsLoading] = useState(false);

  const handleQuickSearch = async (value: string) => {
    if (!value) return;
    setIsLoading(true);
    await setNewQuickSearch(value);
    setIsLoading(false);
  };

  const handleClearSeacrh = (value: string) => {
    if (!value) {
      setNewQuickSearch(null);
    }
  };

  return (
    <>
      <Container>
        <Input.Search
          allowClear
          onSearch={handleQuickSearch}
          onChange={e => {
            handleClearSeacrh(e.target.value);
          }}
          placeholder={intl.get('contact.filters.search_contact_placeholder')}
          loading={isLoading}
        />

        <NewContactModal />

        <ChatSettings />
      </Container>
      <NotificationPermissionAlert />
    </>
  );
};

export default ChatPanel;
