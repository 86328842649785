import React from 'react';

import { Button, Card, Skeleton } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { Container } from './styles';

import ListMessageTemplates from './molecules/ListMessageTemplates';
import { useMessageTemplates } from '~/hooks/device/messageTemplates';
import { useDevice } from '~/hooks/device';
import { ParamsProps } from '~/@types/params';

const MessageTemplatesComponent: React.FC = () => {
  const { environmentId } = useParams<ParamsProps>();
  const { isLoading: isLoadingDrive, device } = useDevice();
  const { listMessageTemplate, isLoading } = useMessageTemplates();

  const history = useHistory();

  if (isLoading || isLoadingDrive || !listMessageTemplate || !device) {
    return (
      <Container>
        <Card>
          <Skeleton paragraph={{ rows: 1 }} />
        </Card>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            size="large"
            icon={<PlusOutlined />}
            onClick={() => {
              history.push(`/c/${environmentId}/messagetemplates/create`);
            }}
          >
            Criar modelo de mensagem
          </Button>
        </div>
        <ListMessageTemplates data={listMessageTemplate} />
      </Container>
    </>
  );
};

export default MessageTemplatesComponent;
