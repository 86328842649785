import React from 'react';

import { Button } from 'antd';
import SortByComponent from './SortByComponent';
import { useKanban } from '~/hooks/workflow/kanban';
import { ISortByDTOS } from '~/hooks/attendances/dtos/ISortByDTOS';

const SortByKanban: React.FC = () => {
  const { settings, setNewSettings, resetSettings } = useKanban();

  if (!settings.sortBy) {
    return <Button type="text" loading />;
  }

  const handleSetNewSettings = (sortBy: ISortByDTOS) => {
    const newSettings = settings;
    newSettings.sortBy = sortBy;
    setNewSettings(newSettings);
  };

  return (
    <SortByComponent
      sortBy={settings.sortBy}
      onSetNewSettings={handleSetNewSettings}
      onResetSettings={resetSettings}
    />
  );
};

export default SortByKanban;
