import { Tag } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import ResponsiblesComponent from '~/components/Responsibles';
import Navbar from '~/components/global/organisms/Navbar';
import { useEnvironment } from '~/hooks/environments/environment';
import { ResponsiblesProvider } from '~/hooks/responsibles/responsibles';
import { Container } from './styles';

const Responsibles: React.FC = () => {
  const { totalResponsible } = useEnvironment();
  return (
    <>
      <Navbar title="Responsáveis">
        <Tag>
          {intl.get('responsible.counter', { total: totalResponsible })}
        </Tag>
      </Navbar>
      <Container>
        <ResponsiblesProvider>
          <ResponsiblesComponent />
        </ResponsiblesProvider>
      </Container>
    </>
  );
};

export default Responsibles;
