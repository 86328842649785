import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 0 0 55px;
`;

export const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  margin: 2px;
  background: #fff;

  .inputText {
    border-radius: var(--radius);
  }
`;
