import intl from 'react-intl-universal';

import React from 'react';

import { StatusChangeProps } from '~/@types/attendance';

interface ItemLogProps {
  data:
    | StatusChangeProps
    | string
    | number
    | boolean
    | null
    | Array<{ [key: string]: string }>;
}
const ItemLog: React.FC<ItemLogProps> = ({ data }) => {
  if (!data) {
    return <>{intl.get('ui.blank')}</>;
  }

  if (typeof data === 'string') {
    return <>{data}</>;
  }

  if (typeof data === 'number') {
    return <>{data}</>;
  }

  if (typeof data === 'boolean') {
    return <>{String(data)}</>;
  }

  if (Array.isArray(data)) {
    return (
      <ul>
        {data.map(item => {
          return Object.keys(item).map(key => {
            return <li key={String(key)}>{`${key}: ${item[key]}`}</li>;
          });
        })}
      </ul>
    );
  }

  if (typeof data === 'object') {
    // Midias
    if (data.url) {
      return <>{data.url}</>;
    }

    if (data.lat && data.lng) {
      return (
        <>
          lat: {data.lat} - long: {data.lng}
        </>
      );
    }
  }

  return <>{String(data)}</>;
};

export default ItemLog;
