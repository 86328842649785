import { UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import ModalShareContact from './ModalShareContact';

const ShareContact: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        shape="circle"
        size="large"
        type="text"
        icon={<UserOutlined />}
        onClick={() => showModal()}
      />

      {isModalOpen ? (
        <ModalShareContact onCloseModal={() => closeModal()} />
      ) : null}
    </>
  );
};

export default ShareContact;
