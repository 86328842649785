import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: var(--border-bottom);
`;

export const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  overflow: auto;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 0rem;
  padding: 0 0.5em;
`;
