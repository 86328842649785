import React, { createContext, useContext, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { useWhatsAppWeb } from './whatsAppWeb';
import formatPhoneNumber from '~/util/formatPhoneNumber';

export interface ContactResponseProps {
  count: number;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      name: string;
      number: string;
      address: string;
    },
  ];
}

export interface ContactProps {
  id: number;
  name: string;
  id_name: string;
  number: string;
  number_formatted: string;
  address: string;
}

interface ContactsContextData {
  contactsList: ContactProps[];
  totalContacts: number | null;
  hasMore: boolean;
  moreContacts: () => void;
  isLoading: boolean;
  refetch: () => void;
}
const ContactsContext = createContext<ContactsContextData>(
  {} as ContactsContextData,
);

const ContactsProvider: React.FC = ({ children }) => {
  const [contactsList, setContactsList] = useState<ContactProps[]>([]);
  const [totalContacts, setTotalContacts] = useState<number | null>(null);
  const defaultLimit = 10;
  const [limit, setLimit] = useState(defaultLimit);
  const [hasMore, setHasMore] = useState(false);

  const { environment } = useEnvironment();

  const { quickSearch, settings } = useWhatsAppWeb();

  const formatSorting = (): string => {
    if (!settings.sortBy) {
      return '';
    }
    let result = '';
    result += settings.sortBy.sort === 'asc' ? '' : '-';
    result += settings.sortBy.by;

    return result;
  };

  const { isLoading, refetch } = useQuery(
    [`chatContactsList`, limit, environment?.id, quickSearch, settings],
    async () => {
      if (!environment) return;
      const response = await api.get(`/zc/${environment.id}/client/`, {
        params: {
          offset: 0,
          limit,
          ...(quickSearch
            ? quickSearch.match(/\d/) !== null
              ? { number__icontains: quickSearch }
              : { name__icontains: quickSearch }
            : {}),
          ...(settings.sortBy && { order_by: formatSorting() }),
        },
      });

      const { data } = response;
      await formatterListContact(data);
    },
  );

  const formatterListContact = async (data: ContactResponseProps) => {
    setHasMore(!!data.next);
    setTotalContacts(data.count);

    const formattedContacts = await Promise.all(
      data.results.map(async contact => {
        const contactResponse: ContactProps = {
          ...contact,
          number_formatted: formatPhoneNumber(contact.number),
          id_name: `#${contact.id} - ${contact.name}`,
        };

        return contactResponse;
      }),
    );
    setContactsList(formattedContacts);
  };

  const moreContacts = useCallback(() => {
    setLimit(limitOld => limitOld + defaultLimit);
  }, []);

  return (
    <ContactsContext.Provider
      value={{
        contactsList,
        totalContacts,
        hasMore,
        moreContacts,
        isLoading,
        refetch,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};
function useContacts(): ContactsContextData {
  const context = useContext(ContactsContext);

  if (!context) {
    throw new Error('useContacts must be used within an ContactsProvider');
  }

  return context;
}

export { ContactsProvider, useContacts };
