import axios from 'axios';

const source = axios.CancelToken.source();

const api = axios.create({
  cancelToken: source.token,
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.data.detail === 'Token inválido.') {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default api;
