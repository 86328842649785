import ReactHtmlParser from 'react-html-parser';
import React, { ReactElement } from 'react';
import intl from 'react-intl-universal';
import Linkify from '~/components/global/atoms/Linkify';

export const formatterMessageText = (
  text: string,
  textOnly?: boolean,
): ReactElement => {
  let textResult = '';

  if (textOnly) {
    try {
      textResult = text.replace(
        /(\n|^|\s)(?:\*)(?:(?!\s))((?:(?!\n|\*).)+)(?:\*)/g,
        match => {
          const textMatch = match.replace(/\*/g, '');
          return `${textMatch}`;
        },
      );
      textResult = textResult.replace(
        /(\n|^|\s)(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,
        match => {
          const textMatch = match.replace(/_/g, '');
          return `${textMatch}`;
        },
      );
      return <>{textResult}</>;
    } catch (err) {
      return (
        <i>{intl.get('chat.message_list.message.error_displaying_message')}</i>
      );
    }
  }

  try {
    textResult = text.replace(
      /(\n|^|\s)(?:\*)(?:(?!\s))((?:(?!\n|\*).)+)(?:\*)/g,
      match => {
        const textMatch = match.replace(/\*/g, '');
        return `<b>${textMatch}</b>`;
      },
    );

    textResult = textResult.replace(
      /(\n|^|\s)(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,
      match => {
        const textMatch = match.replace(/_/g, '');
        return `<i>${textMatch}</i>`;
      },
    );

    textResult = textResult.replace(/(\r\n|\n|\r)/gm, '<br />');

    return <Linkify>{ReactHtmlParser(textResult)}</Linkify>;
  } catch (error) {
    return (
      <i>{intl.get('chat.message_list.message.error_displaying_message')}</i>
    );
  }
};
