import { Card, Skeleton } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import DeviceComponent from '~/components/Device';
import Navbar from '~/components/global/organisms/Navbar';

import { useEnvironment } from '~/hooks/environments/environment';

const Device: React.FC = () => {
  const { environment, instanceId } = useEnvironment();

  return (
    <>
      <Navbar title={intl.get('device.title')} />
      {environment && environment.id && instanceId ? (
        <DeviceComponent />
      ) : (
        <Card>
          <Skeleton paragraph={{ rows: 1 }} />
        </Card>
      )}
    </>
  );
};

export default Device;
