import styled from 'styled-components';

export const Wrapper = styled.div`
  line-height: 1rem;
  padding: 0.5rem 1rem;
  width: fit-content;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #54656f;
  text-align: center;

  text-shadow: 0 1px 0 rgb(255 255 255 / 40%);
  background-color: #fffffff2;
  border-radius: var(--radius);
  box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
`;

export const Container = styled.div``;

export const Metadata = styled.div`
  display: flex;
  margin-top: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.75rem;
`;
