import React from 'react';

import { Card, Space, Tag, Typography } from 'antd';
import { IMessageTemplateComponentsDTO } from '~/hooks/device/messageTemplates';

const { Text } = Typography;

export interface IPreviewMessageTemplate {
  data: IMessageTemplateComponentsDTO;
  language: string;
}

const PreviewMessageTemplate: React.FC<IPreviewMessageTemplate> = ({
  data,
  language,
}) => {
  return (
    <>
      <Card style={{ maxWidth: '400px' }}>
        <Space direction="vertical">
          {data.map(item => {
            if (item.type === 'HEADER') {
              return <Text strong>{item.text && item.text}</Text>;
            }
            if (item.type === 'BODY') {
              return <Text>{item.text && item.text}</Text>;
            }
            if (item.type === 'FOOTER') {
              return <Text type="secondary">{item.text && item.text}</Text>;
            }

            return <></>;
          })}
          <Tag bordered={false}>{language}</Tag>
        </Space>
      </Card>
    </>
  );
};

export default PreviewMessageTemplate;
