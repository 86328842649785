import { Divider, Skeleton } from 'antd';
import React from 'react';

import { useContact } from '~/hooks/contacts/contact';

import { Container, Title, Actions, Wrapper } from './styles';
import ChangeStatusChatBot from '~/components/global/atoms/ChangeStatusChatBot';
import QRCodeModal from '~/components/global/molecules/QRCodeModal';
// import DrawerContact from '~/components/global/organisms/DrawerContact';
import DrawerContactAttendances from '~/components/global/organisms/DrawerContactAttendances';
import ContactInfo from '~/components/global/molecules/ContactInfo';
import ModalNewAttendance from '~/components/global/molecules/ModalNewAttendance';

interface ChatTitleBarProps {
  isCompact?: boolean;
}
const ChatTitleBar: React.FC<ChatTitleBarProps> = ({ isCompact }) => {
  const { contact, isLoading } = useContact();

  if (isLoading || !contact) {
    return (
      <Wrapper>
        <Container>
          <Skeleton.Input active style={{ width: 200 }} />
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Title>
          <ContactInfo />
        </Title>

        <Actions>
          {!isCompact && contact ? (
            <ModalNewAttendance
              type="contact"
              contact={{
                name: contact.name || contact.number_formatted,
                label: contact.name || contact.number_formatted,
                value: contact.id,
              }}
            />
          ) : null}
          {!isCompact && <DrawerContactAttendances />}
          <Divider type="vertical" />
          <ChangeStatusChatBot />
          <QRCodeModal data={{ type: 'contact', value: contact.id }} />
        </Actions>
      </Container>
    </Wrapper>
  );
};

export default ChatTitleBar;
