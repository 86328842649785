import { EnvironmentOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

interface AddressProps {
  address?: string;
  lat: number;
  lng: number;
}
const Address: React.FC<AddressProps> = ({ address, lat, lng }) => {
  return (
    <div>
      <MapContainer
        center={{ lat, lng }}
        zoom={16}
        style={{ width: '100%', height: '250px', marginBottom: '0.5rem' }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={{ lat, lng }} />
      </MapContainer>

      {address ? (
        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'center',

            justifyContent: 'space-between',
          }}
        >
          <div>{address}</div>
          <Link
            to={{
              pathname: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
            }}
            target="_blank"
          >
            <Tooltip title={intl.get('chat.message_list.message.view_on_map')}>
              <Button
                type="primary"
                size="large"
                ghost
                icon={<EnvironmentOutlined />}
              />
            </Tooltip>
          </Link>
        </div>
      ) : (
        <Link
          to={{
            pathname: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
          }}
          target="_blank"
        >
          <Button
            type="primary"
            size="large"
            ghost
            block
            icon={<EnvironmentOutlined />}
          >
            {intl.get('chat.message_list.message.view_on_map')}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default Address;
