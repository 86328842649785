import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .site-collapse-custom-collapse {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 0;
  }
  .site-collapse-custom-panel {
    background: #ffffff;
    border-radius: var(--radius);
    border: 1px solid #f0f0f0;
  }

  .ant-collapse-header {
    padding: 1rem 1rem !important;
    /* padding-left: 2.4rem !important; */

    .ant-collapse-header-text {
      display: inline-flex;
      align-items: center;
      gap: 1.5rem;
    }
  }

  .ant-collapse-content {
    border-top: 1px solid #f0f0f0;
  }
`;
