import React, { useEffect, useState } from 'react';

import { Button, Divider, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';

import { Container } from './styles';
import { ISortByDTOS } from '~/hooks/attendances/dtos/ISortByDTOS';

interface SortByComponentProps {
  sortBy: ISortByDTOS;
  onSetNewSettings: (sortBy: ISortByDTOS) => void;
  onResetSettings: () => void;
}
const SortByComponent: React.FC<SortByComponentProps> = ({
  sortBy,
  onSetNewSettings,
  onResetSettings,
}) => {
  const [textSort, setTextSort] = useState<string>('');
  const [textSortBy, setTextSortBy] = useState<string>('');

  function formattedSort(value: string) {
    if (value === 'asc') {
      setTextSort(intl.get('sort_by.asc'));
      return;
    }
    setTextSort(intl.get('sort_by.desc'));
  }

  function formattedSortBy(value: string) {
    if (value === 'last_msg') {
      setTextSortBy(intl.get('sort_by.last_msg'));
      return;
    }

    if (value === 'name') {
      setTextSortBy(intl.get('sort_by.name'));
      return;
    }

    if (value === 'id') {
      setTextSortBy(intl.get('sort_by.id'));
      return;
    }

    setTextSortBy(intl.get('sort_by.number_unread_msgs'));
  }

  useEffect(() => {
    formattedSort(sortBy.sort);
    formattedSortBy(sortBy.by);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSort = (newValue: 'asc' | 'desc') => {
    const newSortBy = sortBy;

    if (!newSortBy || !newSortBy.sort) {
      return;
    }

    newSortBy.sort = newValue;

    formattedSort(newValue);

    onSetNewSettings(newSortBy);
  };

  const handleChangeSortBy = (
    newValue: 'name' | 'last_msg' | 'number_unread_msgs' | 'id',
  ) => {
    const newSortBy = sortBy;

    if (!newSortBy || !newSortBy.by) {
      return;
    }

    newSortBy.by = newValue;

    formattedSortBy(newValue);

    onSetNewSettings(newSortBy);
  };
  return (
    <Container>
      <b>{intl.get('sort_by.title')} </b>
      <Dropdown
        overlay={
          <Menu>
            <Menu.ItemGroup>
              <Menu.Item
                onClick={() => {
                  handleChangeSort('asc');
                }}
              >
                {intl.get('sort_by.asc')}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  handleChangeSort('desc');
                }}
              >
                {intl.get('sort_by.desc')}
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        }
      >
        <Button type="text">{textSort}</Button>
      </Dropdown>
      <Dropdown
        overlay={
          <Menu>
            <Menu.ItemGroup title={intl.get('sort_by.title')}>
              <Menu.Item
                onClick={() => {
                  handleChangeSortBy('last_msg');
                }}
              >
                {intl.get('sort_by.last_msg')}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  handleChangeSortBy('name');
                }}
              >
                {intl.get('sort_by.name')}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  handleChangeSortBy('number_unread_msgs');
                }}
              >
                {intl.get('sort_by.number_unread_msgs')}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  handleChangeSortBy('id');
                }}
              >
                {intl.get('sort_by.id')}
              </Menu.Item>
              <Divider style={{ margin: '0.5rem 0' }} />
              <Menu.Item
                onClick={() => {
                  onResetSettings();
                }}
                danger
              >
                {intl.get('buttons.reset')}
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        }
      >
        <Button type="text">
          {textSortBy}
          <DownOutlined />
        </Button>
      </Dropdown>
    </Container>
  );
};

export default SortByComponent;
