import React from 'react';

import {
  Button,
  Dropdown,
  Card,
  Empty,
  Menu,
  // Badge,
  // Image,
  // Modal,
  // Tag,
  // Typography,
} from 'antd';
// import intl from 'react-intl-universal';
import { BellOutlined } from '@ant-design/icons';

// import Image1 from './assets/image1.png';
// import Image2 from './assets/image2.png';

// const { Paragraph, Text, Link } = Typography;

// const notificationId = 'da54sd';
const Notifications: React.FC = () => {
  // const [wasViewed, setWasViewed] = useState<boolean>(() => {
  //   const wasViewrdLocalStorage = localStorage.getItem(
  //     `@ZapForm:notificationsViewed${notificationId}`,
  //   );

  //   if (wasViewrdLocalStorage) {
  //     return true;
  //   }

  //   return false;
  // });

  // const confirmWasViewed = () => {
  //   localStorage.setItem(
  //     `@ZapForm:notificationsViewed${notificationId}`,
  //     JSON.stringify(true),
  //   );
  //   setWasViewed(true);
  // };

  // const notifications = (
  //   <Menu style={{ padding: 0 }}>
  //     <Menu.Item
  //       key="1"
  //       style={{ padding: 0 }}
  //       onClick={() => {
  //         confirmWasViewed();
  //         Modal.info({
  //           width: '40vw',
  //           bodyStyle: { display: 'flex' },
  //           icon: null,
  //           centered: true,
  //           content: (
  //             <>
  //               <Paragraph>
  //                 Recentemente o WhatsApp começou a bloquear automaticamente e
  //                 sem aviso prévio os contatos do seu dispositivo
  //               </Paragraph>
  //               <Paragraph>
  //                 Para que possamos validar que seu dispositivo esteja
  //                 funcionando corretamente é necessário que faça o desbloqueio
  //                 do nosso número de validação{' '}
  //                 <Link
  //                   href="https://wa.me/5511990091011"
  //                   target="_blank"
  //                   rel="noreferrer"
  //                   copyable={{ text: '5511990091011' }}
  //                 >
  //                   55 11 99009 1011
  //                 </Link>
  //               </Paragraph>
  //               <Paragraph style={{ textAlign: 'center' }}>
  //                 Caso apareça a seguinte mensagem em nosso número:
  //                 <Image
  //                   src={Image1}
  //                   alt="Mensagem de número bloqueado"
  //                   height={60}
  //                 />
  //               </Paragraph>
  //               <br />
  //               <Paragraph style={{ textAlign: 'center' }}>
  //                 Clique na mensagem para desbloquear, até aparecer a seguinte
  //                 mensagem:
  //                 <Image
  //                   src={Image2}
  //                   alt="Mensagem de número desbloqueado"
  //                   height={40}
  //                 />
  //               </Paragraph>
  //               <br />
  //               <Paragraph>
  //                 Qualquer dúvida entrem em contato com o nosso suporte.
  //               </Paragraph>

  //               <Text>Atenciosamente,</Text>
  //               <br />
  //               <Text> Equipe ZapForm</Text>
  //             </>
  //           ),
  //         });
  //       }}
  //     >
  //       <Card size="small">
  //         <div style={{ marginBottom: '0.5rem' }}>
  //           <Tag color="orange">Instabilidade</Tag>
  //         </div>
  //         <div>
  //           <b>Seu dispositivo está instável?</b>
  //           <br />
  //           clique aqui para mais informações
  //         </div>
  //       </Card>
  //     </Menu.Item>
  //   </Menu>
  // );

  const noNotifications = (
    <Menu style={{ padding: 0 }}>
      <Menu.Item key="1" style={{ padding: 0 }}>
        <Card size="small">
          <Empty
            imageStyle={{
              height: 60,
            }}
            description={<span>Sem novas notificações</span>}
          />
        </Card>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      dropdownRender={() => noNotifications}
      placement="bottomCenter"
      arrow
    >
      <Button
        icon={
          // <Badge count={wasViewed ? 0 : 1}>
          <BellOutlined />
          // </Badge>
        }
        type="text"
      />
    </Dropdown>
  );
};

export default Notifications;
