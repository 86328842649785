/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EditOutlined,
  FilterOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Upload,
  Button,
  Form,
  Image,
  Input,
  Switch,
  Tooltip,
  Typography,
  Select,
  message,
} from 'antd';
import React, { ReactElement, useState } from 'react';
import { CSSProperties } from 'styled-components';
import intl from 'react-intl-universal';
import { toast } from 'react-toastify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Linkify from '../Linkify';
import { Container, Label, Value } from './styles';
import { FieldProps } from '~/@types/fields';
import apiAttachments from '~/services/apiAttachments';
import Document from '../Document';
import Audio from '../Audio';
import Video from '../Video';
import PhoneInput from '../../molecules/PhoneInput';
import clearSpecialCharacters from '~/util/clearSpecialCharacters';
import ModalChangeLabel from './ModalChangeLabel';
import { useWorkflow } from '~/hooks/workflow/workflow';
import { ParamsProps } from '~/@types/params';
import { useCallCenter } from '~/hooks/callCenter/callCenter';

interface BaseFieldProps {
  label: string;
  editable?: boolean;
  ellipsis?: boolean;
  type?: 'text' | 'phone' | 'image' | 'audio' | 'video' | 'document' | 'select';
  small?: boolean;
  name?: string;
  value?: string | number | null | any;
  valueOptions?: string[];
  url?: string | null;
  onChange?: (field: any) => void;
  style?: CSSProperties;
  copyable?: boolean;
}

const BaseField: React.FC<BaseFieldProps> = ({
  children,
  small = false,
  type = 'text',
  editable = false,
  valueOptions = [],
  label,
  name,
  value,
  url,
  onChange,
  style,
  copyable = false,
  ellipsis = true,
}) => {
  const { setNewFilters: setNewFiltersWorkFlow } = useWorkflow();
  const { setNewFilters: setNewFiltersCallCenter } = useCallCenter();
  const { environmentId } = useParams<ParamsProps>();
  const [messageApi, contextHolder] = message.useMessage();

  const history = useHistory();
  const location = useLocation();

  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  const onFinish = (field: FieldProps) => {
    if (type === 'phone' && name) {
      // eslint-disable-next-line no-param-reassign
      field[name] = clearSpecialCharacters(String(field[name]));
    }
    if (onChange) {
      onChange(field);
    }
    handleEditing();
  };

  const getTextValue = (): ReactElement => {
    if (isEditing && name) {
      return (
        <Form initialValues={{ [name]: value }} onFinish={onFinish}>
          <Form.Item name={name}>
            <Input.TextArea autoSize value={value || ''} />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Button htmlType="button" onClick={() => handleEditing()}>
                {intl.get('buttons.cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {intl.get('buttons.save')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      );
    }

    return (
      <Linkify>
        <Typography.Paragraph
          ellipsis={ellipsis ? { rows: 3, expandable: true } : false}
          copyable={copyable}
        >
          {value === null || value === '' || value === undefined
            ? intl.get('ui.blank')
            : value}
        </Typography.Paragraph>
      </Linkify>
    );
  };

  const getPhoneValue = (): ReactElement => {
    if (isEditing && name) {
      return (
        <Form
          form={form}
          initialValues={{ [name]: String(value) }}
          onFinish={onFinish}
        >
          <PhoneInput form={form} name={name} />
          <Form.Item>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Button htmlType="button" onClick={() => handleEditing()}>
                {intl.get('buttons.cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {intl.get('buttons.save')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      );
    }

    return (
      <Typography.Paragraph
        ellipsis={ellipsis ? { rows: 3, expandable: true } : false}
        copyable={copyable}
      >
        {value === null || value === '' || value === undefined
          ? intl.get('ui.blank')
          : value}
      </Typography.Paragraph>
    );
  };

  const getFileValue = (): ReactElement => {
    async function checkTypeFile(file: any) {
      if (file.type.match(/image/g) !== null) {
        return 'image';
      }
      if (file.type.match(/audio/g) !== null) {
        return 'audio';
      }
      if (file.type.match(/video/g) !== null) {
        return 'video';
      }
      return 'document';
    }

    const sendFile = async (file: any) => {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        const typeFile = await checkTypeFile(file);

        try {
          const response = await apiAttachments.post(`/attachments`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          const { data } = response;

          const newField = {
            [`${name}`]: {
              url: String(data.url),
              _type: typeFile,
            },
          };
          if (onChange) {
            onChange(newField);
          }
        } catch (error) {
          toast.error(intl.get('message.error_sending_file'));
        }
      }
    };

    if (isEditing && name) {
      return (
        <>
          <Upload
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            customRequest={async (options: any) => {
              if (options.file) {
                await sendFile(options.file);
                options.onSuccess('Ok');
                handleEditing();
              }
            }}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>
              {intl.get('buttons.click_to_upload')}
            </Button>
          </Upload>
          <br />
        </>
      );
    }

    if (url === null || url === '' || url === undefined) {
      return <>{intl.get('ui.blank')}</>;
    }

    switch (type) {
      case 'image':
        return (
          <Image
            src={url}
            alt={url}
            style={{ maxWidth: '400px', maxHeight: '400px' }}
          />
        );
      case 'audio':
        return <Audio url={url} />;
      case 'video':
        return <Video url={url} />;
      default:
        return <Document url={url} />;
    }
  };

  const getBooleanValue = (): ReactElement => {
    if (isEditing && name) {
      return (
        <Form initialValues={{ [name]: value }} onFinish={onFinish}>
          <Form.Item name={name} valuePropName="checked">
            <Switch checkedChildren="Sim" unCheckedChildren="Não" />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Button htmlType="button" onClick={() => handleEditing()}>
                {intl.get('buttons.cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {intl.get('buttons.save')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      );
    }

    return (
      <Typography.Paragraph
        ellipsis={ellipsis ? { rows: 3, expandable: true } : false}
        copyable={copyable}
      >
        {value || value === 'True' ? 'Sim' : 'Não'}
      </Typography.Paragraph>
    );
  };

  const getSelectValue = (): ReactElement => {
    if (!name) return <></>;

    const formatToDisplay = (rawValue: string[]) => {
      const formattedValueOptions = rawValue.map((itemLabel, index) => ({
        value: (index + 1).toString(),
        label: itemLabel,
      }));
      formattedValueOptions.unshift({
        value: '0',
        label: intl.get('ui.blank'),
      });
      return formattedValueOptions;
    };
    const formatToSave = (
      valueSelected: string,
      formattedValues: {
        value: string;
        label: string;
      }[],
    ) => {
      let formattedValue = `OptionList;${valueSelected}`;
      formattedValue += formattedValues.map((item, index) =>
        index > 0 ? `;${item.label}` : '',
      );
      const data = formattedValue.replace(/,/g, '');
      return data;
    };
    const formattedValues = formatToDisplay(valueOptions);
    if (isEditing) {
      return (
        <>
          <Form.Item name={name}>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={formattedValues}
              defaultValue={value}
              onChange={valueSelected => {
                if (onChange) {
                  const newValue = {
                    [name]: formatToSave(valueSelected, formattedValues),
                  };
                  onChange(newValue);
                  handleEditing();
                }
              }}
            />
          </Form.Item>
        </>
      );
    }
    return (
      <Typography.Paragraph
        ellipsis={ellipsis ? { rows: 3, expandable: true } : false}
        copyable={copyable}
      >
        {value === null || value === '' || value === undefined
          ? intl.get('ui.blank')
          : formattedValues.find(item => item.value === value)?.label}
      </Typography.Paragraph>
    );
  };

  const getTypeValue = (): ReactElement => {
    if (children) {
      return <>{children}</>;
    }

    if (
      typeof value === 'boolean' ||
      (typeof value === 'string' &&
        (value.toLowerCase() === 'true' || value.toLowerCase() === 'false'))
    ) {
      return getBooleanValue();
    }

    if (type === 'phone') {
      return getPhoneValue();
    }

    if (
      type === 'image' ||
      type === 'document' ||
      type === 'audio' ||
      type === 'video'
    ) {
      return getFileValue();
    }

    if (type === 'select') {
      return getSelectValue();
    }

    return getTextValue();
  };

  return (
    <Container small={small} className="form-question">
      <Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip
            title={
              label.includes('🔅')
                ? intl.get('attendance.new_question.information')
                : null
            }
          >
            <Typography.Title
              ellipsis={ellipsis}
              level={5}
              style={{ marginBottom: '0' }}
            >
              {label}
            </Typography.Title>
          </Tooltip>

          {editable && (
            <div className="options">
              <Tooltip title="Editar">
                <Button
                  type="link"
                  size="large"
                  icon={<EditOutlined />}
                  onClick={() => handleEditing()}
                />
              </Tooltip>
            </div>
          )}

          {type === 'select' && (
            <Tooltip title="Filtrar">
              <Button
                type="link"
                size="large"
                icon={<FilterOutlined />}
                onClick={async () => {
                  if (setNewFiltersWorkFlow || setNewFiltersCallCenter) {
                    let formattedValue = `OptionList;${value}`;
                    formattedValue += valueOptions.map(item => `;${item}`);
                    const data = formattedValue.replace(/,/g, '');

                    if (setNewFiltersWorkFlow) {
                      setNewFiltersWorkFlow({
                        attendance: data,
                      });
                    }
                    if (setNewFiltersCallCenter) {
                      setNewFiltersCallCenter({
                        attendance: data,
                      });
                    }
                    if (
                      location.pathname.includes('/workflow/') &&
                      environmentId
                    ) {
                      messageApi.loading('Aplicando filtro, aguarde...');
                      await new Promise<void>(resolve => {
                        setTimeout(() => {
                          resolve();
                        }, 3000);
                      });

                      history.push(`/c/${environmentId}/workflow`);
                    }
                  }
                }}
              />
            </Tooltip>
          )}
        </div>
        {label.includes('🔅') && <ModalChangeLabel value={label} />}
      </Label>
      <Value style={style}>{getTypeValue()}</Value>
      {contextHolder}
    </Container>
  );
};

export default BaseField;
