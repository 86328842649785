import { Button, Switch, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import React, { useEffect, useState } from 'react';
import { FaRobot } from 'react-icons/fa';

import { useBot } from '~/hooks/bot/bot';
import { useAttendance } from '~/hooks/attendances/attendance';

const ChangeStatusChatBot: React.FC = () => {
  const { sendNote, attendance } = useAttendance();
  const {
    isBotEnabled,
    updateBotStatus,
    isBotStatusPersistEnabled,
    handleSetBotStatusPersistEnabled,
  } = useBot();

  const handleBotActive = async () => {
    const value = !isBotEnabled;
    if (sendNote) {
      if (value) {
        await sendNote(intl.get('bot.user_actived'));
      } else {
        await sendNote(intl.get('bot.user_disabled'));
      }
    }
    updateBotStatus(value);
  };

  const [showPersistEnabled, setShowPersistEnabled] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    if (attendance) {
      if (attendance.status.status_type === 'O') {
        setShowPersistEnabled(true);
      } else {
        setShowPersistEnabled(false);
      }
    } else {
      setShowPersistEnabled(true);
    }
  }, [attendance]);

  if (isBotEnabled === null || isBotStatusPersistEnabled === null) {
    return <Button type="text" size="large" loading />;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip
        title={
          isBotEnabled ? intl.get('bot.actived') : intl.get('bot.disabled')
        }
      >
        <Button
          type="text"
          size="large"
          icon={
            <FaRobot
              color={isBotEnabled ? '#009b60' : '#ff4d4f'}
              size="1.4rem"
            />
          }
          onClick={() => {
            handleBotActive();
          }}
        />
      </Tooltip>
      {typeof showPersistEnabled === 'boolean' ? (
        <Tooltip title={intl.get('bot.persist_description')}>
          <Switch
            checked={isBotStatusPersistEnabled}
            checkedChildren={intl.get('bot.persist_actived')}
            unCheckedChildren={intl.get('bot.persist_disabled')}
            onClick={value => {
              handleSetBotStatusPersistEnabled(value);
            }}
          />
        </Tooltip>
      ) : null}
    </div>
  );
};

export default ChangeStatusChatBot;
