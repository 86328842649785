import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Select,
  Tooltip,
  Switch,
} from 'antd';
import intl from 'react-intl-universal';
import React, { useEffect, useState } from 'react';
import { CloseOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { useCallCenter } from '~/hooks/callCenter/callCenter';
import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { useStatus } from '~/hooks/status/status';
import isEmpty from '~/util/isEmpty';
import { FiltersProps } from '~/@types/attendances';
import { SelectOptions } from '~/@types/fields';

const ChatFilters: React.FC = () => {
  const { statusList, isLoading: isLoadingStatusList } = useStatus();

  const {
    responsiblesList,
    isLoading: isLoadingResponsiblesList,
  } = useResponsibles();

  const { filters, setNewFilters, cleanFilters } = useCallCenter();

  const [isVisible, setIsVisible] = useState(false);
  const [debounceFilters, setDebounceFilters] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEmpty(filters)) {
      form.resetFields();
    }

    form.setFieldsValue({
      ...filters,
      ...(filters.timeCreated && {
        timeCreated: [
          dayjs(filters.timeCreated[0], 'YYYY-MM-DD'),
          dayjs(filters.timeCreated[1], 'YYYY-MM-DD'),
        ],
      }),
      ...(filters.timeLastUpdated && {
        timeLastUpdated: [
          dayjs(filters.timeLastUpdated[0], 'YYYY-MM-DD'),
          dayjs(filters.timeLastUpdated[1], 'YYYY-MM-DD'),
        ],
      }),
      ...(filters.timeStatus && {
        timeStatus: [
          dayjs(filters.timeStatus[0], 'YYYY-MM-DD'),
          dayjs(filters.timeStatus[1], 'YYYY-MM-DD'),
        ],
      }),
    });
  }, [filters, form]);

  const showDrawer = () => {
    setIsVisible(true);
  };
  const onClose = () => {
    setIsVisible(false);
  };

  const onFieldsChange = async (allValues: FiltersProps) => {
    const newValues = {
      ...allValues,
      ...(allValues.timeCreated && {
        timeCreated: [
          dayjs.utc(allValues.timeCreated[0]).startOf('day').toISOString(),
          dayjs.utc(allValues.timeCreated[1]).endOf('day').toISOString(),
        ],
      }),
      ...(allValues.timeLastUpdated && {
        timeLastUpdated: [
          dayjs.utc(allValues.timeLastUpdated[0]).startOf('day').toISOString(),
          dayjs.utc(allValues.timeLastUpdated[1]).endOf('day').toISOString(),
        ],
      }),
      ...(allValues.timeStatus && {
        timeStatus: [
          dayjs.utc(allValues.timeStatus[0]).startOf('day').toISOString(),
          dayjs.utc(allValues.timeStatus[1]).endOf('day').toISOString(),
        ],
      }),
    };

    setNewFilters(newValues);
  };
  if (
    isLoadingStatusList ||
    !statusList ||
    isLoadingResponsiblesList ||
    !responsiblesList
  ) {
    return <Button type="text" size="large" loading />;
  }

  const formattedResponsiblesList: SelectOptions[] = responsiblesList.map(
    responsible => ({
      name: responsible.name,
      label: responsible.name,
      value: responsible.id,
    }),
  );

  const formattedFilterAttendance = () => {
    if (
      filters.attendance &&
      typeof filters.attendance === 'string' &&
      filters.attendance.includes('OptionList')
    ) {
      const [, value, ...options] = filters.attendance.split(';');
      return (
        <Form.Item
          label={intl.get('attendance.filters.search_in_the_attendance')}
        >
          <Input
            placeholder={intl.get(
              'attendance.filters.search_in_the_attendance_placeholder',
            )}
            value={options[Number(value)]}
            disabled
          />
        </Form.Item>
      );
    }
    return (
      <Form.Item
        label={intl.get('attendance.filters.search_in_the_attendance')}
        name="attendance"
      >
        <Input
          placeholder={intl.get(
            'attendance.filters.search_in_the_attendance_placeholder',
          )}
        />
      </Form.Item>
    );
  };

  const checkFiltersEmpty = { ...filters };
  delete checkFiltersEmpty.statusCode;
  delete checkFiltersEmpty.contact;
  return (
    <>
      {isEmpty(checkFiltersEmpty) ? (
        <Tooltip title={intl.get('chat.filters.title')}>
          <Button
            type="text"
            size="large"
            icon={<FilterOutlined />}
            onClick={showDrawer}
          />
        </Tooltip>
      ) : (
        <>
          <Tooltip title={intl.get('chat.filters.actived')}>
            <Button
              type="primary"
              size="middle"
              danger
              icon={<FilterFilled />}
              onClick={showDrawer}
            />
          </Tooltip>
          <Tooltip title={intl.get('buttons.clean_filters')}>
            <Button
              type="link"
              size="small"
              danger
              icon={<CloseOutlined />}
              onClick={() => cleanFilters()}
              style={{ marginLeft: '2px' }}
            />
          </Tooltip>
        </>
      )}
      <Drawer
        title={intl.get('chat.filters.title')}
        placement="top"
        height="100%"
        closable
        onClose={onClose}
        open={isVisible}
        getContainer={false}
        style={{ position: 'absolute' }}
        footer={
          <Button
            type="primary"
            size="large"
            onClick={() => {
              onFieldsChange(debounceFilters);
              onClose();
            }}
            block
          >
            {intl.get('buttons.save_filters')}
          </Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onValuesChange={async (changedValues, allValues) => {
            setDebounceFilters(allValues);
          }}
          onKeyDown={event => {
            if (event.keyCode === 13 && !event.shiftKey) {
              event.preventDefault();

              onFieldsChange(debounceFilters);
            }
          }}
        >
          <Form.Item
            label={intl.get('attendance.filters.attendance_id')}
            name="attendanceId"
          >
            <Input
              type="number"
              placeholder={intl.get(
                'attendance.filters.attendance_id_placeholder',
              )}
            />
          </Form.Item>

          {formattedFilterAttendance()}
          <Form.Item
            label={intl.get('attendance.filters.responsible_for_the_service')}
            name="responsible"
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={intl.get(
                'attendance.filters.responsible_for_the_service_placeholder',
              )}
              filterSort={(optionA, optionB) =>
                optionA?.name
                  .toLowerCase()
                  .localeCompare(optionB.name.toLowerCase())
              }
              options={formattedResponsiblesList}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.expired')}
            name="expired"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.created_between')}
            name="timeCreated"
          >
            <DatePicker.RangePicker
              format={value => `${value.format('DD/MM/YYYY')}`}
              allowClear={false}
              presets={[
                {
                  label: intl.get('date.today'),
                  value: [dayjs().startOf('day'), dayjs().endOf('day')],
                },
                {
                  label: intl.get('date.this_week'),
                  value: [dayjs().startOf('week'), dayjs().endOf('week')],
                },
                {
                  label: intl.get('date.this_month'),
                  value: [dayjs().startOf('month'), dayjs().endOf('month')],
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.latest_changes_between')}
            name="timeLastUpdated"
          >
            <DatePicker.RangePicker
              format={value => `${value.format('DD/MM/YYYY')}`}
              allowClear={false}
              presets={[
                {
                  label: intl.get('date.today'),
                  value: [dayjs().startOf('day'), dayjs().endOf('day')],
                },
                {
                  label: intl.get('date.this_week'),
                  value: [dayjs().startOf('week'), dayjs().endOf('week')],
                },
                {
                  label: intl.get('date.this_month'),
                  value: [dayjs().startOf('month'), dayjs().endOf('month')],
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.status_changes_between')}
            name="timeStatus"
          >
            <DatePicker.RangePicker
              format={value => `${value.format('DD/MM/YYYY')}`}
              allowClear={false}
              presets={[
                {
                  label: intl.get('date.today'),
                  value: [dayjs().startOf('day'), dayjs().endOf('day')],
                },
                {
                  label: intl.get('date.this_week'),
                  value: [dayjs().startOf('week'), dayjs().endOf('week')],
                },
                {
                  label: intl.get('date.this_month'),
                  value: [dayjs().startOf('month'), dayjs().endOf('month')],
                },
              ]}
            />
          </Form.Item>
        </Form>

        <Button
          type="link"
          onClick={() => {
            cleanFilters();
          }}
          block
        >
          {intl.get('buttons.clean_filters')}
        </Button>
      </Drawer>
    </>
  );
};

export default ChatFilters;
