/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  Button,
  Form,
  Input,
  Select,
  Divider,
  Dropdown,
  Menu,
  Space,
} from 'antd';
import intl from 'react-intl-universal';
import React, { useState } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { useStatus } from '~/hooks/status/status';
import { useReport } from '~/hooks/reports/report';
import isEmpty from '~/util/isEmpty';
import { Container, Field } from './styles';
import { FiltersProps } from '~/@types/attendances';
import { SelectOptions } from '~/@types/fields';
import { RangePickerWithLimit } from './components/RangePickerWithLimit';

type FieldsOptions = Array<
  | 'status'
  | 'contact'
  | 'responsible'
  | 'attendance.status'
  | 'attendance.attendanceId'
  | 'attendance.attendanceIdTo'
  | 'attendance.attendance'
  | 'attendance.timeCreated'
  | 'attendance.timeLastUpdated'
  | 'attendance.timeStatus'
>;

const Filters: React.FC = () => {
  const {
    responsiblesList,
    isLoading: isLoadingResponsiblesList,
  } = useResponsibles();
  const { statusList, isLoading: isLoadingListStatus } = useStatus();
  const { generateReport, isLoading, setNewFilters, cleanReport } = useReport();

  const [form] = Form.useForm();

  const [fieldsVisible, setFieldsVisible] = useState<FieldsOptions>([]);

  const handleCleanFilters = () => {
    form.resetFields();
  };

  const handleCleanReport = () => {
    cleanReport();
    setFieldsVisible([]);
    handleCleanFilters();
  };

  const handleAddFieldVisible = (e: any) => {
    const field = e.key;
    setFieldsVisible(fieldsVisibleOld => {
      return [...fieldsVisibleOld, field];
    });
  };

  const handleRemoveFieldVisible = (fieldVisibleRemoved: string) => {
    const newFieldsVisible = fieldsVisible.filter(
      field => field !== fieldVisibleRemoved,
    );

    setFieldsVisible(newFieldsVisible);
  };

  const handleSubmitFilters = async (filters: FiltersProps) => {
    const filtersFormatted = {
      ...filters,
      ...(filters.timeCreated && {
        timeCreated: [
          dayjs.utc(filters.timeCreated[0]).startOf('day').toISOString(),
          dayjs.utc(filters.timeCreated[1]).endOf('day').toISOString(),
        ],
      }),
      ...(filters.timeLastUpdated && {
        timeLastUpdated: [
          dayjs.utc(filters.timeLastUpdated[0]).startOf('day').toISOString(),
          dayjs.utc(filters.timeLastUpdated[1]).endOf('day').toISOString(),
        ],
      }),
      ...(filters.timeStatus && {
        timeStatus: [
          dayjs.utc(filters.timeStatus[0]).startOf('day').toISOString(),
          dayjs.utc(filters.timeStatus[1]).endOf('day').toISOString(),
        ],
      }),
    };

    setNewFilters(filtersFormatted);
    generateReport();
  };

  if (
    isLoadingListStatus ||
    !statusList ||
    isLoadingResponsiblesList ||
    !responsiblesList
  ) {
    return (
      <Container>
        <Card loading />
      </Container>
    );
  }

  const formattedResponsiblesList: SelectOptions[] = responsiblesList.map(
    responsible => ({
      name: responsible.name,
      label: responsible.name,
      value: responsible.id,
    }),
  );

  return (
    <Container>
      <Card>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu onClick={e => handleAddFieldVisible(e)}>
              <Menu.ItemGroup title={intl.get('contact.title')}>
                <Menu.Item
                  key="contact"
                  disabled={!!fieldsVisible.includes('contact')}
                >
                  {intl.get('reports.filters.contact_information')}
                </Menu.Item>
              </Menu.ItemGroup>

              <Menu.ItemGroup title={intl.get('responsible.title')}>
                <Menu.Item
                  key="responsible"
                  disabled={!!fieldsVisible.includes('responsible')}
                >
                  {intl.get('reports.filters.responsible_for_the_service')}
                </Menu.Item>
              </Menu.ItemGroup>

              <Menu.ItemGroup title={intl.get('attendance.title')}>
                <Menu.Item
                  key="attendance.status"
                  disabled={!!fieldsVisible.includes('attendance.status')}
                >
                  {intl.get('reports.filters.status')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.attendanceId"
                  disabled={!!fieldsVisible.includes('attendance.attendanceId')}
                >
                  {intl.get('reports.filters.attendance_id')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.attendanceIdTo"
                  disabled={
                    !!fieldsVisible.includes('attendance.attendanceIdTo')
                  }
                >
                  {intl.get('reports.filters.id_of_calls_between_2_values')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.attendance"
                  disabled={!!fieldsVisible.includes('attendance.attendance')}
                >
                  {intl.get('reports.filters.search_in_the_attendance')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.timeCreated"
                  disabled={!!fieldsVisible.includes('attendance.timeCreated')}
                >
                  {intl.get('reports.filters.created_between')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.timeLastUpdated"
                  disabled={
                    !!fieldsVisible.includes('attendance.timeLastUpdated')
                  }
                >
                  {intl.get('reports.filters.latest_changes_between')}
                </Menu.Item>
                <Menu.Item
                  key="attendance.timeStatus"
                  disabled={!!fieldsVisible.includes('attendance.timeStatus')}
                >
                  {intl.get('reports.filters.status_changes_between')}
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>
          }
        >
          <Button icon={<PlusOutlined />} block size="large">
            {intl.get('buttons.add_filters')}
          </Button>
        </Dropdown>
      </Card>

      {!isEmpty(fieldsVisible) && (
        <Card title={intl.get('reports.filter.filter_by')}>
          <Form
            form={form}
            layout="vertical"
            onFinish={values => handleSubmitFilters(values)}
          >
            {fieldsVisible.includes('contact') && (
              <Field>
                <Form.Item
                  label={intl.get('reports.filters.contact_information')}
                  name="contact"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <Input
                    placeholder={intl.get(
                      'reports.filters.contact_information_placeholder',
                    )}
                  />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() => handleRemoveFieldVisible('contact')}
                />
              </Field>
            )}

            {fieldsVisible.includes('responsible') && (
              <Field>
                <Form.Item
                  label={intl.get(
                    'reports.filters.responsible_for_the_service',
                  )}
                  name="responsible"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder={intl.get(
                      'attendance.filters.responsible_for_the_service_placeholder',
                    )}
                    filterSort={(optionA, optionB) =>
                      optionA?.name
                        .toLowerCase()
                        .localeCompare(optionB.name.toLowerCase())
                    }
                    options={formattedResponsiblesList}
                  />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() => handleRemoveFieldVisible('responsible')}
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.status') && (
              <Field>
                <Form.Item
                  label={intl.get('reports.filters.status')}
                  name="statusCode"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <Select
                    placeholder={intl.get('reports.filters.status_placeholder')}
                  >
                    {statusList.map(status => (
                      <Select.Option key={status.code} value={status.code}>
                        {status.code} - {status.status}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() => handleRemoveFieldVisible('attendance.status')}
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.attendanceId') && (
              <Field>
                <Form.Item
                  label={intl.get('reports.filters.attendance_id')}
                  name="attendanceId"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <Input placeholder="Ex. 145" />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleRemoveFieldVisible('attendance.attendanceId')
                  }
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.attendanceIdTo') && (
              <Field>
                <Form.Item
                  label={intl.get('reports.filters.id_of_calls_from')}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="attendanceIdStart"
                    style={{
                      display: 'inline-block',
                      width: 'calc(40% - 8px)',
                    }}
                  >
                    <Input type="number" placeholder="Ex. 145" />
                  </Form.Item>
                  <Form.Item
                    style={{
                      display: 'inline-block',
                      textAlign: 'center',
                      width: '12%',
                    }}
                  >
                    {intl.get('reports.filters.until')}
                  </Form.Item>
                  <Form.Item
                    name="attendanceIdEnd"
                    style={{
                      display: 'inline-block',
                      width: 'calc(40% - 8px)',
                      margin: '0 8px',
                    }}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            !value ||
                            getFieldValue('attendanceIdStart') < value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              intl.get(
                                'reports.filters.enter_value_greater_than_initial',
                              ),
                            ),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input type="number" placeholder="Ex. 186" />
                  </Form.Item>
                  <Button
                    type="default"
                    shape="circle"
                    size="small"
                    icon={<MinusOutlined />}
                    onClick={() =>
                      handleRemoveFieldVisible('attendance.attendanceIdTo')
                    }
                  />
                </Form.Item>
              </Field>
            )}

            {fieldsVisible.includes('attendance.attendance') && (
              <Field>
                <Form.Item
                  label={intl.get('reports.filters.search_in_the_attendance')}
                  name="attendance"
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <Input
                    placeholder={intl.get(
                      'reports.filters.search_in_the_attendance_placeholder',
                    )}
                  />
                </Form.Item>
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleRemoveFieldVisible('attendance.attendance')
                  }
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.timeCreated') && (
              <Field>
                <RangePickerWithLimit
                  label={intl.get('reports.filters.created_between')}
                  name="timeCreated"
                />

                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleRemoveFieldVisible('attendance.timeCreated')
                  }
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.timeLastUpdated') && (
              <Field>
                <RangePickerWithLimit
                  label={intl.get('reports.filters.latest_changes_between')}
                  name="timeLastUpdated"
                />
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleRemoveFieldVisible('attendance.timeLastUpdated')
                  }
                />
              </Field>
            )}

            {fieldsVisible.includes('attendance.timeStatus') && (
              <Field>
                <RangePickerWithLimit
                  label={intl.get('reports.filters.status_changes_between')}
                  name="timeStatus"
                />

                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={() =>
                    handleRemoveFieldVisible('attendance.timeStatus')
                  }
                />
              </Field>
            )}

            <Divider />
            <Space
              direction="vertical"
              style={{ width: '100%', textAlign: 'center' }}
            >
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                loading={isLoading}
              >
                {isLoading
                  ? intl.get('reports.generating_report')
                  : intl.get('reports.generate_report')}
              </Button>

              <Button
                type="text"
                onClick={() => {
                  handleCleanFilters();
                }}
              >
                {intl.get('buttons.clean_filters')}
              </Button>
            </Space>
          </Form>
        </Card>
      )}
      {!isEmpty(fieldsVisible) && (
        <Button
          type="link"
          onClick={() => {
            handleCleanReport();
          }}
          block
          size="large"
        >
          {intl.get('reports.new_report')}
        </Button>
      )}
    </Container>
  );
};

export default Filters;
