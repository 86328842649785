/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState, useCallback } from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import api from '~/services/api';
import isEmpty from '~/util/isEmpty';
import { useEnvironment } from '~/hooks/environments/environment';
import { FiltersProps } from '~/@types/attendances';
import { ParamsProps } from '~/@types/params';

interface CampaignContextData {
  filters: FiltersProps;
  isLoading: boolean;
  isValidateFilters: boolean;
  isSent: boolean | null;
  message: string | null;
  setNewFilters: (filters: FiltersProps) => void;
  onIsValidateFilters: (validade: boolean) => void;
  setNewMessage: (message: string) => void;
  sendCampaign: () => Promise<void>;
  totalContacts: number;
  isLoadingTotalContacts: boolean;
}

export const CampaignContext = createContext<CampaignContextData>(
  {} as CampaignContextData,
);

const CampaignProvider: React.FC = ({ children }) => {
  const { environment } = useEnvironment();
  const { environmentId } = useParams<ParamsProps>();

  const [filters, setFilters] = useState<FiltersProps>({} as FiltersProps);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isValidateFilters, setIsValidateFilters] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean | null>(null);

  const [totalContacts, setTotalContacts] = useState(0);

  const setNewFilters = (newFilters: FiltersProps) => {
    setFilters(newFilters);
  };

  const setNewMessage = (newMessage: string) => {
    setMessage(newMessage);
  };

  const onIsValidateFilters = useCallback((isValidate: boolean) => {
    setIsValidateFilters(isValidate);
  }, []);

  const sendCampaign = async () => {
    setIsLoading(true);

    if (!message && message === '') {
      setIsLoading(false);
      return;
    }

    try {
      await api.post(
        `/zc/${environmentId}/order/send_message/`,
        { message },
        {
          params: {
            ...(filters.contact && { client: filters.contact }),
            ...(filters.attendance && {
              order: filters.attendance.slice(0, 100),
            }),
            ...(filters.statusCode && {
              status: filters.statusCode,
            }),
            ...(filters.responsible && {
              location: filters.responsible,
            }),
            ...(filters.attendanceId && {
              order_list: filters.attendanceId,
            }),
            ...(filters.timeCreated && {
              time_created__gt: filters.timeCreated[0],
              time_created__lte: filters.timeCreated[1],
            }),
            ...(filters.timeStatus && {
              time_status__gt: filters.timeStatus[0],
              time_status__lte: filters.timeStatus[1],
            }),
            ...(filters.timeLastUpdated && {
              time_last_updated__gt: filters.timeLastUpdated[0],
              time_last_updated__lte: filters.timeLastUpdated[1],
            }),
            ...(filters.attendanceIdStart && {
              id__gt: filters.attendanceIdStart,
            }),
            ...(filters.attendanceIdEnd && {
              id__lte: filters.attendanceIdEnd,
            }),
          },
        },
      );

      setIsSent(true);
    } catch (err) {
      setIsSent(false);
    }
    setIsLoading(false);
  };

  const { isLoading: isLoadingTotalContacts } = useQuery(
    [`campaignTotalContacts`, filters],
    async () => {
      if (isEmpty(filters)) {
        setTotalContacts(0);
        return;
      }

      await getTotalAttendances();
    },
  );

  const getTotalAttendances = async () => {
    if (!environment) {
      return;
    }
    const response = await api.get(`/zc/${environment.id}/order/`, {
      params: {
        offset: 0,
        limit: 1,
        ...(filters.contact && { client: filters.contact }),
        ...(filters.attendance && { order: filters.attendance.slice(0, 100) }),
        ...(filters.statusCode && {
          status: filters.statusCode,
        }),
        ...(filters.responsible && {
          location: filters.responsible,
        }),
        ...(filters.attendanceId && {
          order_list: filters.attendanceId,
        }),
        ...(filters.expired === true && {
          expired: true,
        }),
        ...(filters.timeCreated && {
          time_created__gt: filters.timeCreated[0],
          time_created__lte: filters.timeCreated[1],
        }),
        ...(filters.timeStatus && {
          time_status__gt: filters.timeStatus[0],
          time_status__lte: filters.timeStatus[1],
        }),
        ...(filters.timeLastUpdated && {
          time_last_updated__gt: filters.timeLastUpdated[0],
          time_last_updated__lte: filters.timeLastUpdated[1],
        }),
        ...(filters.attendanceIdStart && {
          id__gt: filters.attendanceIdStart,
        }),
        ...(filters.attendanceIdEnd && {
          id__lte: filters.attendanceIdEnd,
        }),
      },
    });

    const { data } = response;
    setTotalContacts(data.count);
  };

  return (
    <CampaignContext.Provider
      value={{
        filters,
        isLoading,
        isSent,
        isValidateFilters,
        onIsValidateFilters,
        totalContacts,
        setNewFilters,
        setNewMessage,
        sendCampaign,
        isLoadingTotalContacts,
        message,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
function useCampaign(): CampaignContextData {
  const context = useContext(CampaignContext);

  if (!context) {
    throw new Error('useCampaign must be used within an CampaignProvider');
  }

  return context;
}

export { CampaignProvider, useCampaign };
