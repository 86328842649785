import { CameraOutlined } from '@ant-design/icons';
import { Button, Image, notification } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import apiWhatsApp from '~/services/apiWhatsApp';

interface ModalDeviceScreenShotProps {
  clientId: string;
}

export interface IDeviceScreenShotResponse {
  url: string;
}

const ModalDeviceScreenShot: React.FC<ModalDeviceScreenShotProps> = ({
  clientId,
}) => {
  const [screenShotUrl, setScreenShotUrl] = useState<string | undefined>(
    undefined,
  );

  const getScreenShot = async () => {
    const url = await apiWhatsApp
      .get<IDeviceScreenShotResponse>(`/clients/${clientId}/screenshot`)
      .then(response => response.data.url)
      .catch(() => {
        notification.error({
          message: intl.get('device.screenshot_device.error'),
          description: intl.get('device.screenshot_device.error_desc'),
        });

        return undefined;
      });

    setScreenShotUrl(`${url}?${Date.now()}`);
  };

  const handleOpenModal = () => {
    getScreenShot();
  };

  const handleCloseModal = () => {
    setScreenShotUrl(undefined);
  };

  return (
    <>
      <Button
        key="1"
        onClick={() => handleOpenModal()}
        icon={<CameraOutlined />}
      >
        {intl.get('device.screenshot_device.title')}
      </Button>
      {screenShotUrl && (
        <Image
          src={screenShotUrl}
          style={{ display: 'none' }}
          preview={{
            visible: !!screenShotUrl,
            src: screenShotUrl,
            onVisibleChange: value => {
              if (!value) {
                handleCloseModal();
              }
            },
          }}
        />
      )}
    </>
  );
};

export default ModalDeviceScreenShot;
