import { Tooltip } from 'antd';
import React from 'react';
import { MdDone, MdDoneAll, MdOutlineClose } from 'react-icons/md';
import intl from 'react-intl-universal';
import { Container } from './styles';

interface MessageStatusProps {
  status: 'r' | 'w' | 's' | 'a' | 'd';
}
export const MessageStatus: React.FC<MessageStatusProps> = ({ status }) => {
  // r: recebida pelo servidor (para mensagens inbound)
  // s: servidor enviou
  // w: servidor enviando para o usuário
  // d: pessoa recebeu a mensagem
  // a: pessoa leu a mensagem
  return (
    <Container status={status}>
      {
        {
          r: <></>,
          s: (
            <Tooltip title={intl.get('message.status.s')} placement="left">
              <MdDone />
            </Tooltip>
          ),
          w: (
            <Tooltip title={intl.get('message.status.w')} placement="left">
              <MdDone />
            </Tooltip>
          ),
          d: (
            <Tooltip title={intl.get('message.status.d')} placement="left">
              <MdDoneAll />
            </Tooltip>
          ),
          a: (
            <Tooltip title={intl.get('message.status.a')} placement="left">
              <MdDoneAll />
            </Tooltip>
          ),
          default: <MdOutlineClose />,
        }[status]
      }
    </Container>
  );
};
