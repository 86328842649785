import React from 'react';
import intl from 'react-intl-universal';
import { LoadingOutlined } from '@ant-design/icons';
import { Skeleton, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Text from 'antd/lib/typography/Text';
import { useChatAttendances } from '~/hooks/callCenter/chatAttendances';
import { useCallCenter } from '~/hooks/callCenter/callCenter';
import { Container } from './styles';
import ChatItem from '../../molecules/ChatItem';
import isEmpty from '~/util/isEmpty';
import { LastMessageProvider } from '~/hooks/messages/lastMessage';

const ChatList: React.FC = () => {
  const {
    attendancesList,
    hasMore,
    moreAttendances,
    totalAttendances,
    isFetching,
    isLoading,
  } = useChatAttendances();

  const { filters } = useCallCenter();

  if (isLoading && totalAttendances === 0) {
    return (
      <>
        <div style={{ padding: '0.25rem' }}>
          <Skeleton active avatar paragraph={{ rows: 1 }} />
        </div>
        <div style={{ padding: '0.25rem' }}>
          <Skeleton active avatar paragraph={{ rows: 1 }} />
        </div>
        <div style={{ padding: '0.25rem' }}>
          <Skeleton active avatar paragraph={{ rows: 1 }} />
        </div>
      </>
    );
  }

  return (
    <Container id="scrollableDivListAttendances">
      <>
        {isFetching ? (
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 24, marginTop: '0.75rem' }}
                spin
              />
            }
          />
        ) : (
          <>
            {!isEmpty(filters) && !isLoading && (
              <div style={{ margin: '0.5rem auto' }}>
                <Text>
                  {intl.get('chat.list.counter', {
                    total: totalAttendances,
                  })}
                </Text>
              </div>
            )}
          </>
        )}

        <InfiniteScroll
          dataLength={attendancesList.length}
          next={moreAttendances}
          hasMore={hasMore}
          loader={
            <div
              style={{
                margin: '1rem auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            </div>
          }
          scrollableTarget="scrollableDivListAttendances"
          style={{ overflow: 'none' }}
        >
          {attendancesList.map(attendance => (
            <LastMessageProvider contactId={attendance.contact.id}>
              <ChatItem key={attendance.id} data={attendance} />
            </LastMessageProvider>
          ))}
        </InfiniteScroll>
      </>
    </Container>
  );
};

export default ChatList;
