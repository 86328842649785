import React, { createContext, useContext } from 'react';
import intl from 'react-intl-universal';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { ParamsProps } from '~/@types/params';
import isEmpty from '~/util/isEmpty';
import { FieldProps } from '~/@types/fields';

export interface ResponsibleResponseProps {
  id: number;
  name: string;
  address: string;
}

export interface ResponsibleProps {
  id: number;
  name: string;
  id_name: string;
  address: string;
}

interface ResponsibleContextData {
  responsible?: ResponsibleProps;
  isLoading: boolean;
  isError: boolean;
  updateResponsible: (data: ResponsibleResponseProps) => void;
  updateResponsibleField: (field: FieldProps) => void;
  deleteResponsible: () => void;
  refetch: () => void;
}

export const ResponsibleContext = createContext<ResponsibleContextData>(
  {} as ResponsibleContextData,
);

const ResponsibleProvider: React.FC = ({ children }) => {
  const { environment } = useEnvironment();
  const { responsibleId, environmentId } = useParams<ParamsProps>();
  const history = useHistory();

  const { data: responsible, isLoading, isError, refetch } = useQuery(
    [`responsible${responsibleId}`, responsibleId, environment?.id],
    async () => {
      if (!environment) {
        return undefined;
      }
      const response = await api.get(
        `zc/${environment.id}/location/${responsibleId}/`,
      );

      const { data } = response;
      return formatterResponsible(data);
    },
  );

  const formatterResponsible = (
    data: ResponsibleResponseProps,
  ): ResponsibleProps => {
    const responsibleResponse: ResponsibleProps = {
      ...data,
      id_name: `#${data.id} - ${data.name}`,
    };
    return responsibleResponse;
  };

  const updateResponsible = async (data: ResponsibleResponseProps) => {
    if (isEmpty(data)) {
      return;
    }
    try {
      await api.put(`/zc/${environmentId}/location/${responsibleId}/`, data);

      refetch();
    } catch (error) {
      toast.error(intl.get('responsible.update.error_update'));
    }
  };

  const updateResponsibleField = async (field: FieldProps) => {
    if (!responsible) {
      return;
    }

    try {
      await api.patch(`/zc/${environmentId}/location/${responsibleId}/`, {
        ...responsible,
        ...field,
      });

      refetch();
    } catch (error) {
      toast.error(intl.get('responsible.update.error_update_field'));
    }
  };

  const deleteResponsible = async () => {
    try {
      await api.delete(`/zc/${environmentId}/location/${responsibleId}/`);
      history.push(`/c/${environmentId}/responsibles`);
    } catch (error) {
      toast.error(intl.get('responsible.delete.error_deleted'));
    }
  };

  return (
    <ResponsibleContext.Provider
      value={{
        responsible,
        isLoading,
        isError,
        updateResponsible,
        updateResponsibleField,
        deleteResponsible,
        refetch,
      }}
    >
      {children}
    </ResponsibleContext.Provider>
  );
};
function useResponsible(): ResponsibleContextData {
  const context = useContext(ResponsibleContext);

  if (!context) {
    throw new Error(
      'useResponsible must be used within an ResponsibleProvider',
    );
  }

  return context;
}

export { ResponsibleProvider, useResponsible };
