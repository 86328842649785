import intl from 'react-intl-universal';
import { ArrowUpOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Space } from 'antd';
import React, { useEffect } from 'react';
import { useUnreadMessages } from '~/hooks/messages/unreadMessages';

const NotificationPermissionAlert: React.FC = () => {
  const openNotification = () => {
    notification.open({
      message: intl.get('allow_notifications.title'),
      description: intl.get('allow_notifications.description'),
      placement: 'top',
      type: 'info',
      btn: (
        <Space>
          <Button
            type="link"
            size="large"
            onClick={() => notification.destroy()}
          >
            {intl.get('allow_notifications.cancel')}
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              Modal.info(config);
              requestPermissionNotification();
              notification.destroy();
            }}
          >
            {intl.get('allow_notifications.confirm')}
          </Button>
        </Space>
      ),
    });
  };

  const config = {
    title: intl.get('alert_allow_notifications.title'),
    icon: <ArrowUpOutlined rotate={-45} />,
    content: (
      <div>
        <p>{intl.getHTML('alert_allow_notifications.description')}</p>
      </div>
    ),
    okText: intl.get('alert_allow_notifications.ok'),
  };

  const {
    requestPermissionNotification,
    notificationsIsGuaranteed,
  } = useUnreadMessages();

  useEffect(() => {
    if (!notificationsIsGuaranteed) {
      const random = Math.floor(Math.random() * 20);

      if (random < 1) {
        openNotification();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default NotificationPermissionAlert;
