import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import intl from 'react-intl-universal';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';

import { ParamsProps } from '~/@types/params';

import MessageList from '~/components/global/molecules/MessageList';
import TextComposer from '~/components/global/molecules/TextComposer';
import { useAttendance } from '~/hooks/attendances/attendance';
import { useContact } from '~/hooks/contacts/contact';
import { useMessages } from '~/hooks/messages/messages';
import { Container, BoxUpload } from './styles';
import { MessageTemplateProvider } from '~/hooks/device/messageTemplates';

interface ChatMessageProps {
  isBlocked?: boolean;
}
const ChatMessage: React.FC<ChatMessageProps> = ({ isBlocked }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { attendance, isLoading: isLoadingAttendance } = useAttendance();
  const { contact, isLoading: isLoadingContact } = useContact();
  const { isLoading: isLoadingMessages, sendMessageFile } = useMessages();

  const onDrop = useCallback(
    acceptedFiles => {
      sendMessageFile(acceptedFiles[0]);
    },
    [sendMessageFile],
  );

  const { getRootProps, isDragAccept, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const { attendanceId, contactId } = useParams<ParamsProps>();

  useEffect(() => {
    if ((!isLoadingAttendance || !isLoadingContact) && !isLoadingMessages) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [
    isLoadingAttendance,
    isLoadingMessages,
    attendance,
    attendanceId,
    contact,
    contactId,
    isLoadingContact,
  ]);

  useEffect(() => {
    setIsLoading(true);
  }, [attendanceId, contactId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <LoadingOutlined style={{ fontSize: 42 }} spin />
      </div>
    );
  }

  return (
    <Container {...getRootProps()}>
      {isDragAccept ? (
        <BoxUpload>
          <input {...getInputProps()} />
          <Result
            icon={<InboxOutlined />}
            title={intl.get('chat.message_list.drag_and_drop_the_file_here')}
          />
        </BoxUpload>
      ) : (
        <MessageList />
      )}
      <MessageTemplateProvider>
        <TextComposer isBlocked={isBlocked} />
      </MessageTemplateProvider>
    </Container>
  );
};

export default ChatMessage;
