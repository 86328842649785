import { LoadingOutlined } from '@ant-design/icons';

import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Message from '~/components/global/atoms/Message';

import { useMessages } from '~/hooks/messages/messages';

import MessageLog from '../../atoms/MessageLog';
import { Container } from './styles';

const MessageList: React.FC = () => {
  const { messagesList, hasMore, moreMessages, readMessages } = useMessages();

  useEffect(() => {
    readMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container id="scrollableDivMessageList">
      <InfiniteScroll
        dataLength={messagesList.length}
        next={moreMessages}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
        inverse
        hasMore={hasMore}
        loader={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '38px',
              height: '38px',
              borderRadius: '100%',
              margin: '0.5rem auto',
            }}
          >
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>
        }
        scrollableTarget="scrollableDivMessageList"
      >
        {messagesList.map(message =>
          message.msg_data.text === '#[LOG]new-conversation' ? (
            <MessageLog key={message.id} data={message} />
          ) : (
            <Message key={message.id} data={message} />
          ),
        )}
      </InfiniteScroll>
    </Container>
  );
};

export default MessageList;
