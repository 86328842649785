import React from 'react';
import {
  CloseOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Radio } from 'antd';
import intl from 'react-intl-universal';
import { Container } from './styles';
import BroadcastMessageModal from '~/components/global/molecules/BroadcastMessageModal';
import { BroadcastMessagesProvider } from '~/hooks/messages/broadcastMessages';
import { ReportProvider } from '~/hooks/reports/report';
import ReportModal from '~/components/global/molecules/ReportModal';
import { useEnvironment } from '~/hooks/environments/environment';
import { useWorkflow } from '~/hooks/workflow/workflow';
import { StatusProps } from '~/hooks/status/status';

const { confirm } = Modal;

const SelectedAttendancesPanel: React.FC = () => {
  const { environment, isLoading } = useEnvironment();
  const { selectedCards, removeAllSelectedCards, moveCard } = useWorkflow();

  const handleMoveCardsSelected = (status: StatusProps) => {
    confirm({
      title: intl.get('attendance.update.confirm_status_change', {
        total: selectedCards.length,
      }),
      icon: <ExclamationCircleOutlined />,
      okText: intl.get('buttons.yes'),
      okType: 'danger',
      cancelText: intl.get('buttons.no'),
      onOk: async () => {
        await Promise.all(
          selectedCards.map(selectedCard =>
            moveCard(String(selectedCard), status),
          ),
        );
        removeAllSelectedCards();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel() {},
    });
  };

  if (isLoading || !environment) {
    return <></>;
  }
  if (selectedCards.length <= 0) {
    return <></>;
  }
  return (
    <Container>
      <div>
        <Radio defaultChecked disabled />
        {intl.get('workflow.selected_card.counter', {
          total: selectedCards.length,
        })}
      </div>

      <div>
        <BroadcastMessagesProvider>
          <BroadcastMessageModal />
        </BroadcastMessagesProvider>

        <ReportProvider>
          <ReportModal type="text" />
        </ReportProvider>

        <Dropdown
          overlay={
            <Menu>
              {environment.status.map(status => (
                <Menu.Item
                  key={status.code}
                  onClick={() => handleMoveCardsSelected(status)}
                >
                  {status.code} - {status.status}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button
            type="link"
            icon={<VerticalAlignBottomOutlined rotate={-90} />}
          >
            {intl.get('workflow.selected_card.change_status')}
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>

      <div>
        <Button
          type="default"
          ghost
          icon={<CloseOutlined />}
          onClick={() => removeAllSelectedCards()}
        >
          {intl.get('workflow.selected_card.deselect_all')}
        </Button>
      </div>
    </Container>
  );
};

export default SelectedAttendancesPanel;
