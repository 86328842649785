import { Button, Divider, Skeleton, Tooltip } from 'antd';
import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Wrapper, Container, Actions, Title } from './styles';
import { useAttendance } from '~/hooks/attendances/attendance';
import ChangeStatusChatBot from '~/components/global/atoms/ChangeStatusChatBot';
import DrawerAttendance from '../../molecules/DrawerAttendance';
import QRCodeModal from '~/components/global/molecules/QRCodeModal';
import DrawerAttendanceForm from '../../molecules/DrawerAttendanceForm';
import DrawerAttendanceLog from '../../molecules/DrawerAttendanceLog';
import ContactInfo from '~/components/global/molecules/ContactInfo';
import DrawerContactAttendances from '~/components/global/organisms/DrawerContactAttendances';
import { ParamsProps } from '~/@types/params';

const ChatTitleBar: React.FC = () => {
  const { environmentId } = useParams<ParamsProps>();

  const { attendance, isLoading } = useAttendance();

  if (isLoading || !attendance) {
    return (
      <Wrapper>
        <Container>
          <Skeleton.Input active style={{ width: 150 }} />
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Title>
          <b>{attendance.idFormatted}</b>
          <Divider type="vertical" />
          <ContactInfo />
        </Title>

        <Actions>
          {/* <Tooltip title={intl.get('modal_attendance.view_attendance')}>
            <Link
              to={{
                pathname: `/c/${environmentId}/workflow/${attendance.id}`,
              }}
              target="_blank"
            >
              <Button type="text" size="large" icon={<ProfileOutlined />} />
            </Link>
          </Tooltip> */}
          <Tooltip title={intl.get('modal_attendance.view_contact')}>
            <Link
              to={{
                pathname: `/c/${environmentId}/contacts/${attendance.contact.id}`,
              }}
              target="_blank"
            >
              <Button type="text" size="large" icon={<UserOutlined />} />
            </Link>
          </Tooltip>
          <DrawerAttendance />
          <DrawerAttendanceForm />
          <DrawerAttendanceLog />

          <Divider type="vertical" />
          <DrawerContactAttendances typeIcon="icon" />
          <Divider type="vertical" />
          <ChangeStatusChatBot />

          <QRCodeModal data={{ type: 'attendance', value: attendance.id }} />
        </Actions>
      </Container>
    </Wrapper>
  );
};

export default ChatTitleBar;
