import React from 'react';
import intl from 'react-intl-universal';
import { Dropdown, Checkbox, Typography, Menu, Button, Tooltip } from 'antd';
import {
  DisconnectOutlined,
  EllipsisOutlined,
  MessageOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { Link, useParams } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Text from 'antd/lib/typography/Text';
import { toast } from 'react-toastify';
import { Draggable } from 'react-beautiful-dnd';
import { useKanban } from '~/hooks/workflow/kanban';
import { AttendancesItemProps } from '~/@types/attendances';

import { Wrapper, Container, Options, Header } from './styles';
import UnreadMessages from '~/components/global/atoms/UnreadMessages';
import BaseField from '~/components/global/atoms/BaseField';
import { useWorkflow } from '~/hooks/workflow/workflow';
import { ParamsProps } from '~/@types/params';

interface CardItemProps {
  index: number;
  data: AttendancesItemProps;
}
const CardItem: React.FC<CardItemProps> = ({ index, data }) => {
  const { environmentId } = useParams<ParamsProps>();
  const {
    selectedCards,
    addNewSelectedCard,
    removeSelectedCard,
  } = useWorkflow();
  const { settings } = useKanban();

  const handleCopyLink = () => {
    copy(
      `https://${window.location.host}/c/${environmentId}/workflow/${data.id}`,
    );
    toast.success(intl.get('workflow.kanban.card.link_copied_successfully'));
  };

  const copyIdAttendance = () => {
    copy(String(data.id));
    toast.success(intl.get('workflow.kanban.card.ID_copied_successfully'));
  };

  const handleAddSelectedCard = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      addNewSelectedCard(data.id);
    } else {
      removeSelectedCard(data.id);
    }
  };

  return (
    <Draggable draggableId={data.id.toString()} index={index}>
      {providedCard => (
        <Wrapper
          ref={providedCard.innerRef}
          {...providedCard.draggableProps}
          {...providedCard.dragHandleProps}
          style={{
            ...providedCard.draggableProps.style,
          }}
          isSelected={!!selectedCards.includes(data.id)}
        >
          <Container>
            <Link to={`/c/${environmentId}/workflow/${data.id}`}>
              <Header>
                <div style={{ overflow: 'hidden' }}>
                  <Text>
                    <b>#{data.id}</b>
                  </Text>
                  <Typography.Title ellipsis level={4} style={{ margin: '0' }}>
                    {settings.title?.includes('contact.id_name')
                      ? data.contact.id_name
                      : data.contact.name}
                  </Typography.Title>
                  <Text>
                    {settings.title?.includes('contact.number') &&
                      data.contact.number_formatted}
                  </Text>
                </div>

                <div style={{ width: '40px' }}>
                  <UnreadMessages contactId={data.contact.id} />
                </div>
              </Header>

              {/* CONTACT */}

              {/* RESPONSIBLE */}
              {settings.subTitles?.includes('responsible.name') &&
                data.responsible.name && (
                  <BaseField
                    small
                    label={intl.get('attendance.responsible.name')}
                    value={data.responsible.name}
                  />
                )}

              {settings.subTitles?.includes('responsible.address') &&
                data.responsible.address && (
                  <BaseField
                    small
                    label={intl.get('attendance.responsible.address')}
                    value={data.responsible.address}
                  />
                )}

              {/* ATTENDANCE */}
              {settings.subTitles?.includes('attendance.order_summary') &&
                data.order_summary && (
                  <BaseField
                    small
                    label={intl.get('attendance.order_summary')}
                    value={data.order_summary}
                  />
                )}
              {settings.subTitles?.includes('attendance.time_created') &&
                data.time_createdFormatted && (
                  <BaseField
                    small
                    label={intl.get('attendance.creation_date')}
                    value={data.time_createdFormatted}
                  />
                )}
              {settings.subTitles?.includes('attendance.time_createdThere') &&
                data.time_createdThere && (
                  <BaseField
                    small
                    label={intl.get('attendance.creation_date_there')}
                    value={data.time_createdThere}
                  />
                )}
              {settings.subTitles?.includes('attendance.time_status') &&
                data.time_statusFormatted && (
                  <BaseField
                    small
                    label={intl.get('attendance.date_of_status_change')}
                    value={data.time_statusFormatted}
                  />
                )}
              {settings.subTitles?.includes('attendance.time_deadline') &&
                data.time_deadlineFormatted && (
                  <BaseField
                    small
                    label={intl.get('attendance.deadline')}
                    value={data.time_deadlineFormatted}
                  />
                )}

              {settings.subTitles?.includes('attendance.time_timeout') &&
                data.time_howIsTimeout && (
                  <BaseField
                    small
                    label={intl.get('attendance.deadline_expiration')}
                  >
                    <div
                      style={{
                        color:
                          data.time_howIsTimeout === 'timeout'
                            ? 'red'
                            : data.time_howIsTimeout === 'insideTime'
                            ? '#faad14'
                            : 'none',
                      }}
                    >
                      {data.time_timeoutFormatted}
                    </div>
                  </BaseField>
                )}
            </Link>
          </Container>
          <Options className="options">
            <Tooltip title={intl.get('workflow.kanban.card.select_attendance')}>
              <Checkbox
                checked={!!selectedCards.includes(data.id)}
                onChange={e => handleAddSelectedCard(e)}
              />
            </Tooltip>

            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="1" icon={<MessageOutlined />}>
                    <Link
                      to={{
                        pathname: `/c/${environmentId}/workflow/${data.id}`,
                      }}
                      target="_blank"
                    >
                      {intl.get('workflow.kanban.card.open_in_new_tab')}
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    icon={<DisconnectOutlined />}
                    onClick={() => handleCopyLink()}
                  >
                    {intl.get('workflow.kanban.card.copy_attendance_link')}
                  </Menu.Item>
                  <Menu.Item
                    key="3"
                    icon={<NumberOutlined />}
                    onClick={() => copyIdAttendance()}
                  >
                    {intl.get('workflow.kanban.card.copy_attendance_ID')}
                  </Menu.Item>
                </Menu>
              }
            >
              <Tooltip title={intl.get('workflow.kanban.card.more_options')}>
                <Button
                  type="text"
                  size="small"
                  icon={<EllipsisOutlined style={{ fontSize: '1.25rem' }} />}
                />
              </Tooltip>
            </Dropdown>
          </Options>
        </Wrapper>
      )}
    </Draggable>
  );
};

export default CardItem;
