import React, { memo, useEffect, useState } from 'react';
import { Divider, Tag, Typography } from 'antd';
import intl from 'react-intl-universal';
import { useHistory, useParams } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import { stringToHslColor } from '~/util/stringToHslColor';

import { useCallCenter } from '~/hooks/callCenter/callCenter';
import { Container } from './styles';
import UnreadMessages from '~/components/global/atoms/UnreadMessages';
import { ParamsProps } from '~/@types/params';
import BaseField from '~/components/global/atoms/BaseField';
import { IAttendancesItem } from '~/hooks/callCenter/chatAttendances';
import { Avatar } from '~/components/global/atoms/Avatar';
import { useLastMessage } from '~/hooks/messages/lastMessage';

interface ChatItemProps {
  data: IAttendancesItem;
  autoScroll?: boolean;
}
const { Paragraph } = Typography;
const ChatItem: React.FC<ChatItemProps> = ({ data, autoScroll = true }) => {
  const history = useHistory();

  const { attendanceId, environmentId } = useParams<ParamsProps>();
  const { lastMessage } = useLastMessage();
  const { settings } = useCallCenter();

  const ref = React.createRef<HTMLDivElement>();

  const [isFound, setIsFound] = useState(false);
  useEffect(() => {
    if (
      !isFound &&
      autoScroll &&
      attendanceId &&
      attendanceId === String(data.id) &&
      ref &&
      ref.current
    ) {
      setIsFound(true);
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendanceId]);

  return (
    <Container
      isActived={!!(data.id === Number(attendanceId))}
      ref={ref}
      onClick={() => history.push(`/c/${environmentId}/workflow/${data.id}`)}
    >
      <div className="title">
        {settings.title?.includes('contact.avatar') && (
          <>
            {data.contact.address === '' ? (
              <Avatar
                size={48}
                style={{
                  backgroundColor: stringToHslColor(data.contact.name),
                }}
              >
                {createLetterAvatar(data.contact.name)}
              </Avatar>
            ) : (
              <Avatar size={48} src={data.contact.address} />
            )}
          </>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            minWidth: '130px',
          }}
        >
          <Text style={{ fontSize: '0.75rem' }}>
            <b>#{data.id}</b>
          </Text>

          <Text ellipsis>{data.contact.name} </Text>

          {settings.title?.includes('contact.number') ? (
            <Text>{data.contact.number_formatted}</Text>
          ) : null}
          <div>
            {settings.title?.includes('attendance.status') ? (
              <Tag className="tag">{data.status.code_status}</Tag>
            ) : null}
          </div>
          {lastMessage ? (
            <Paragraph ellipsis>{lastMessage.formatted}</Paragraph>
          ) : null}
        </div>

        <div
          style={{
            flex: '0 0 40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <UnreadMessages contactId={data.contact.id} />
          {lastMessage ? lastMessage.formattedDate : null}
        </div>
      </div>

      <div className="sub-titles">
        {/* CONTACT */}

        {/* RESPONSIBLE */}
        {settings.subTitles?.includes('responsible.name') &&
          data.responsible.name && (
            <BaseField
              small
              label={intl.get('attendance.responsible.name')}
              value={data.responsible.name}
            />
          )}

        {settings.subTitles?.includes('responsible.address') &&
          data.responsible.address && (
            <BaseField
              small
              label={intl.get('attendance.responsible.address')}
              value={data.responsible.address}
            />
          )}

        {/* ATTENDANCE */}
        {settings.subTitles?.includes('attendance.order_summary') &&
          data.order_summary && (
            <BaseField
              small
              label={intl.get('attendance.order_summary')}
              value={data.order_summary}
            />
          )}
        {settings.subTitles?.includes('attendance.time_created') &&
          data.time_createdFormatted && (
            <BaseField
              small
              label={intl.get('attendance.creation_date')}
              value={data.time_createdFormatted}
            />
          )}
        {settings.subTitles?.includes('attendance.time_createdThere') &&
          data.time_createdThere && (
            <BaseField
              small
              label={intl.get('attendance.creation_date_there')}
              value={data.time_createdThere}
            />
          )}
        {settings.subTitles?.includes('attendance.time_status') &&
          data.time_statusFormatted && (
            <BaseField
              small
              label={intl.get('attendance.date_of_status_change')}
              value={data.time_statusFormatted}
            />
          )}
        {settings.subTitles?.includes('attendance.time_deadline') &&
          data.time_deadlineFormatted && (
            <BaseField
              small
              label={intl.get('attendance.deadline')}
              value={data.time_deadlineFormatted}
            />
          )}

        {settings.subTitles?.includes('attendance.time_timeout') &&
          data.time_howIsTimeout && (
            <BaseField small label={intl.get('attendance.deadline_expiration')}>
              <div
                style={{
                  color:
                    data.time_howIsTimeout === 'timeout'
                      ? 'red'
                      : data.time_howIsTimeout === 'insideTime'
                      ? '#faad14'
                      : 'none',
                }}
              >
                {data.time_timeoutFormatted}
              </div>
            </BaseField>
          )}
      </div>
      <Divider style={{ margin: '0.5rem 0 0 0' }} />
    </Container>
  );
};

export default memo(ChatItem, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
