/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import api from '~/services/api';

import { useAuth } from '../auth';
import apiWhatsApp from '~/services/apiWhatsApp';
import { ParamsProps } from '~/@types/params';

interface Environment {
  id: number;
  name: string;
  status: [
    {
      code: string;
      status: string;
      status_type: 'O' | 'C' | 'X';
    },
  ];
}

interface EnvironmentContextData {
  environment?: Environment;
  setEnvironmentCurrent: (id: number) => void;
  totalAttendances: number;
  totalResponsible: number;
  totalContacts: number;
  instanceId: string | null;
  isFetching: boolean;
  refetch: () => void;
  isLoading: boolean;
}
const EnvironmentContext = createContext<EnvironmentContextData>(
  {} as EnvironmentContextData,
);

const EnvironmentProvider: React.FC = ({ children }) => {
  const location = useLocation();

  const { environmentId } = useParams<ParamsProps>();

  const { token } = useAuth();

  const history = useHistory();

  const [instanceId, setInstanceId] = useState<string | null>(null);

  const [totalAttendances, setTotalAttendances] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);
  const [totalResponsible, setTotalResponsible] = useState(0);

  const { data: environment, refetch, isLoading, isFetching } = useQuery(
    [`environment${environmentId}`, environmentId],
    async () => {
      if (!environmentId) {
        return undefined;
      }

      const response = await api.get<Environment>(
        `/zc/config/${environmentId}/`,
      );

      apiWhatsApp.defaults.headers.Authorization = `Bearer Token ${token} ${environmentId}`;

      return response.data;

      return undefined;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  useQuery(
    [`environmentStatus${environmentId}`, environmentId],
    async () => {
      if (!environmentId) {
        return;
      }
      async function getTotalAttendances() {
        const response = await api.get(`/zc/${environmentId}/order/`, {
          params: {
            limit: 1,
          },
        });
        const { data } = response;
        setTotalAttendances(data.count);
      }

      async function getTotalContacts() {
        const response = await api.get(`/zc/${environmentId}/client/`, {
          params: {
            limit: 1,
          },
        });
        const { data } = response;
        setTotalContacts(data.count);
      }

      async function getTotalResponsible() {
        const response = await api.get(`/zc/${environmentId}/location/`, {
          params: {
            limit: 1,
          },
        });
        const { data } = response;
        setTotalResponsible(data.count);
      }

      async function getInstanceId() {
        const response = await api.get(`/zc/config/${environmentId}/resp/`);
        const { data } = response;
        setInstanceId(data.api_instance);
      }

      await getTotalAttendances();
      await getTotalContacts();
      await getTotalResponsible();

      await getInstanceId();
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const setEnvironmentCurrent = useCallback(
    async (id: number) => {
      localStorage.setItem('@ZapForm:environmentId', String(id));

      let newPathname = location.pathname;
      newPathname = newPathname.replace(String(environmentId), String(id));

      history.push(newPathname);
    },
    [location],
  );

  useEffect(() => {
    if (environmentId) {
      localStorage.setItem('@ZapForm:environmentId', environmentId);
    }
  }, [environmentId]);

  return (
    <EnvironmentContext.Provider
      value={{
        environment,
        setEnvironmentCurrent,
        totalAttendances,
        totalContacts,
        totalResponsible,
        instanceId,
        refetch,
        isLoading,
        isFetching,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

function useEnvironment(): EnvironmentContextData {
  const context = useContext(EnvironmentContext);

  if (!context) {
    throw new Error(
      'useEnvironment must be used within an EnvironmentProvider',
    );
  }

  return context;
}

export { EnvironmentProvider, useEnvironment };
