/* eslint-disable import/no-duplicates */
import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import intl from 'react-intl-universal';
import { format, isSameDay } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import { StopOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { MessageProps } from './messages';
import { formatterMessageText } from '~/util/formatMessageText';
import { useLocales } from '../locales';

interface LastMessageProps extends MessageProps {
  formatted: string | JSX.Element;
  formattedDate: string;
}

interface LastMessageContextData {
  lastMessage?: LastMessageProps | null;
  isLoading: boolean;
  refetch: () => void;
  isFetching: boolean;
}

export const LastMessageContext = createContext<LastMessageContextData>(
  {} as LastMessageContextData,
);

interface LastMessageProviderProps {
  contactId: number;
}

const LastMessageProvider: React.FC<LastMessageProviderProps> = ({
  children,
  contactId,
}) => {
  const { currentLocale } = useLocales();
  const { environment } = useEnvironment();

  const { data: lastMessage, isLoading, isFetching, refetch } = useQuery(
    [`lastMessage${contactId}`, contactId, environment?.id],
    async () => {
      if (!environment || !contactId) {
        return null;
      }
      const { data } = await api.get(
        `zc/${environment.id}/client/${contactId}/message/`,
        {
          params: {
            offset: 0,
            limit: 1,
          },
        },
      );
      const lastMessageRaw: LastMessageProps | undefined = data.results[0];
      if (!lastMessageRaw) return null;
      const formattedLastMessage: LastMessageProps = {
        ...lastMessageRaw,
        formatted: formatterLastMessage(lastMessageRaw),
        formattedDate: formatterDate(new Date(lastMessageRaw.msg_time)),
      };
      return formattedLastMessage;
    },
    { refetchInterval: 10 * 1000 },
  );

  const formatterDate = (value: Date) => {
    if (!isSameDay(value, new Date())) {
      return format(value, 'dd/MM/yyyy', {
        locale: currentLocale === 'pt-BR' ? ptBR : es,
      });
    }
    return format(value, 'HH:mm', {
      locale: currentLocale === 'pt-BR' ? ptBR : es,
    });
  };

  return (
    <LastMessageContext.Provider
      value={{
        lastMessage,
        isLoading,
        isFetching,
        refetch,
      }}
    >
      {children}
    </LastMessageContext.Provider>
  );
};
function useLastMessage(): LastMessageContextData {
  const context = useContext(LastMessageContext);

  if (!context) {
    throw new Error(
      'useLastMessage must be used within an LastMessageProvider',
    );
  }

  return context;
}

const formatterLastMessage = (lastMessage: MessageProps) => {
  const { msg_dir, msg_type, msg_data, deleted } = lastMessage;
  const textMe = msg_dir === 'o' ? `${intl.get('message.you')}: ` : '';

  if (deleted) {
    return (
      <>
        {textMe}{' '}
        <i style={{ color: 'var(--gray-9)' }}>
          <StopOutlined /> {intl.get('chat.message_list.message.deleted')}
        </i>
      </>
    );
  }
  const getContent = () => {
    switch (msg_type) {
      case 'ch':
        if (msg_data.text.includes('#VCARD')) {
          return intl.get('message.types.vcard');
        }
        return formatterMessageText(msg_data.text, true);
      case 'au':
        return intl.get('message.types.audio');
      case 'do':
        return intl.get('message.types.document');
      case 'im':
        return intl.get('message.types.image');
      case 'lo':
        return intl.get('message.types.location');
      case 'pt':
        return intl.get('message.types.audio');
      case 'vi':
        return intl.get('message.types.video');
      default:
        return <> </>;
    }
  };
  return (
    <Typography.Text type="secondary">
      {textMe} {getContent()}
    </Typography.Text>
  );
};

export { LastMessageProvider, useLastMessage };
