import React from 'react';
import { Button, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import { useLocales } from '~/hooks/locales';
import iconBr from '~/assets/images/locale/br.svg';
import iconEs from '~/assets/images/locale/es.svg';

const ChangeLocale: React.FC = () => {
  const { currentLocale, changeCurrentLocale } = useLocales();
  return (
    <Tooltip title={intl.get('language')}>
      <Button
        onClick={() =>
          currentLocale === 'pt-BR'
            ? changeCurrentLocale('es')
            : changeCurrentLocale('pt-BR')
        }
        style={{ padding: '0 0.25rem' }}
      >
        <img
          src={currentLocale === 'pt-BR' ? iconBr : iconEs}
          alt={intl.get('language')}
          style={{ height: '25px', borderRadius: '4px' }}
        />
      </Button>
    </Tooltip>
  );
};

export default ChangeLocale;
