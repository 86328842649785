import React from 'react';

import Panel from './organisms/Panel';

import { Container, Wrapper } from './styles';

import { AttendancesProvider } from '~/hooks/attendances/attendances';
import AttendancesTable from '~/components/global/molecules/AttendancesTable';

const List: React.FC = () => {
  return (
    <Wrapper>
      <Panel />

      <Container>
        <AttendancesProvider>
          <AttendancesTable isWorkflowList />
        </AttendancesProvider>
      </Container>
    </Wrapper>
  );
};

export default List;
