import React, { memo } from 'react';

import { useContacts } from '~/hooks/contacts/contacts';

import ChatItem from '../ChatItem';
import { ContactProvider, useContact } from '~/hooks/contacts/contact';
import { LastMessageProvider } from '~/hooks/messages/lastMessage';
import { useWhatsAppWeb } from '~/hooks/contacts/whatsAppWeb';

const LastChatItemOpened: React.FC = () => {
  const { contactsList, isLoading: isLoadingContactList } = useContacts();

  const { quickSearch } = useWhatsAppWeb();

  if (quickSearch) {
    return <></>;
  }

  if (isLoadingContactList || contactsList.length === 0) {
    return <></>;
  }

  const lastOpenContactId = localStorage.getItem('@ZapForm:lastOpenContact');

  const contactExist = contactsList.find(
    item => String(item.id) === lastOpenContactId,
  );

  if (contactExist) {
    return <></>;
  }

  return (
    <ContactProvider>
      <LastChatItemOpenedRender />
    </ContactProvider>
  );
};

const LastChatItemOpenedRender: React.FC = () => {
  const { contact, isLoading } = useContact();

  if (isLoading || !contact) {
    return <></>;
  }

  return (
    <LastMessageProvider contactId={contact.id}>
      <ChatItem key={contact.id} data={contact} autoScroll={false} />
    </LastMessageProvider>
  );
};

export default memo(LastChatItemOpened, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
