import React, { createContext, useContext } from 'react';
import intl from 'react-intl-universal';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { ParamsProps } from '~/@types/params';
import isEmpty from '~/util/isEmpty';
import { FieldProps } from '~/@types/fields';
import formatPhoneNumber from '~/util/formatPhoneNumber';
import apiWhatsApp from '~/services/apiWhatsApp';
import clearSpecialCharacters from '~/util/clearSpecialCharacters';
import queryClient from '~/services/queryClient';

interface UpdaateContactProps {
  name: string;
  number: string;
  address?: string;
}
export interface ContactResponseProps {
  id: number;
  name: string;
  number: string;
  address: string;
}

export interface IContactProfile {
  id: {
    server: string;
    user: string;
    _serialized: string;
  };
  number: string;
  pushname: string;
  avatar?: string;
}

export interface ContactProps {
  id: number;
  name: string;
  id_name: string;
  number: string;
  number_formatted: string;
  address: string;
}

interface ContactContextData {
  contact?: ContactProps;
  isLoading: boolean;
  isError: boolean;
  updateContact: (data: ContactResponseProps) => void;
  updateContactField: (field: FieldProps) => void;
  deleteContact: () => Promise<void>;
  refetch: () => void;
  isFetching: boolean;
}

export const ContactContext = createContext<ContactContextData>(
  {} as ContactContextData,
);

interface ContactProviderProps {
  contactId?: number;
  attendanceId?: number;
}

const ContactProvider: React.FC<ContactProviderProps> = ({
  children,
  contactId: externalContactId,
  attendanceId,
}) => {
  const { environment, instanceId } = useEnvironment();
  const { contactId, environmentId } = useParams<ParamsProps>();
  const history = useHistory();

  const { data: contact, isLoading, isError, isFetching, refetch } = useQuery(
    [
      `contact${contactId || externalContactId}`,
      contactId,
      externalContactId,
      environment?.id,
    ],
    async () => {
      if (!environment || (!contactId && !externalContactId)) {
        return undefined;
      }
      const response = await api.get(
        `zc/${environment.id}/client/${contactId || externalContactId}/`,
      );

      const { data } = response;
      const formattedContact = await formatterContact(data);
      return formattedContact;
    },
  );

  const formatterContact = async (
    data: ContactResponseProps,
  ): Promise<ContactProps> => {
    const contactResponse: ContactProps = {
      ...data,
      number_formatted: formatPhoneNumber(data.number),
      id_name: `#${data.id} - ${data.name}`,
    };
    return contactResponse;
  };

  const getAvatarContact = async (value?: string): Promise<string> => {
    if (!instanceId) return '';
    try {
      const response = await apiWhatsApp.post<IContactProfile>(
        `/clients/${instanceId}/contacts/profile`,
        {
          phoneNumber: value || contact?.number,
        },
      );

      const { data } = response;

      if (data.avatar) {
        return data.avatar;
      }
      return '';
    } catch (error) {
      return '';
    }
  };

  useQuery(
    [`updateContact${contact?.id}`, contact?.id, contact?.number, instanceId],
    async () => {
      if (!contact || !instanceId) return;
      let validatedContact: IContactProfile = {} as IContactProfile;
      try {
        const response = await apiWhatsApp.post<IContactProfile>(
          `/clients/${instanceId}/contacts/profile`,
          {
            phoneNumber: contact?.number,
          },
        );
        if (response.data) validatedContact = response.data;
      } catch (err) {
        return;
      }
      const formattedContact = {
        name: contact.name,
        number:
          validatedContact.number !== contact.number
            ? validatedContact.number
            : contact.number,
        ...(validatedContact.avatar &&
          validatedContact.avatar !== '' && {
            address: validatedContact.avatar,
          }),
      };
      await updateContact(formattedContact);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const updateContact = async (data: UpdaateContactProps) => {
    if (isEmpty(data)) {
      return;
    }

    const formattedNumber = clearSpecialCharacters(data.number);

    let avatar = '';
    if (formattedNumber !== contact?.number) {
      avatar = await getAvatarContact(formattedNumber);
    }

    try {
      await api.patch(
        `/zc/${environmentId}/client/${contactId || externalContactId}/`,
        {
          ...data,
          ...(avatar !== '' && { address: avatar }),
          ...(data.number && { number: formattedNumber }),
        },
      );

      refetch();
      queryClient.refetchQueries(['chatContactsList']);
      queryClient.refetchQueries(['chatAttendances']);
      if (attendanceId) {
        queryClient.refetchQueries([`attendance${attendanceId}`]);
      }
    } catch (error) {
      // toast.error(intl.get('contact.update.error_update'));
    }
  };

  const updateContactField = async (field: FieldProps) => {
    if (!contact) {
      return;
    }
    const contactRaw = {
      name: contact.name,
      address: contact.address,
      number: contact.number,
    };

    let formattedContact = {
      ...contactRaw,
      ...field,
    };

    if (formattedContact.number !== contact?.number) {
      const avatar = await getAvatarContact(formattedContact.number);

      formattedContact = { ...formattedContact, address: avatar };
    }

    try {
      await api.put(
        `/zc/${environmentId}/client/${contactId || externalContactId}/`,
        formattedContact,
      );

      refetch();
    } catch (error) {
      toast.error(intl.get('contact.update.error_update_field'));
    }
  };

  const deleteContact = async () => {
    try {
      await api.delete(
        `/zc/${environmentId}/client/${contactId || externalContactId}/`,
      );
      toast.success(
        intl.get('contact.delete.successfully_deleted', {
          name: contact?.name,
        }),
      );
      history.go(0);
    } catch (error) {
      toast.error(intl.get('contact.delete.error_deleted'));
    }
  };

  return (
    <ContactContext.Provider
      value={{
        contact,
        isLoading,
        isFetching,
        isError,
        updateContact,
        updateContactField,
        deleteContact,
        refetch,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};
function useContact(): ContactContextData {
  const context = useContext(ContactContext);

  if (!context) {
    throw new Error('useContact must be used within an ContactProvider');
  }

  return context;
}

export { ContactProvider, useContact };
