/* eslint-disable no-underscore-dangle */
import React, { createContext, useContext } from 'react';
import { UseMutationResult, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import apiWhatsApp from '~/services/apiWhatsApp';

import { useAuth } from '../auth';
import { useEnvironment } from '../environments/environment';

export type IMessageTemplateComponentsDTO = Array<{
  type: 'HEADER' | 'BODY' | 'FOOTER';
  format?: 'TEXT';
  text: string;
}>;

export interface IMessageTemplateDTO {
  id: string;
  name: string;
  category: 'UTILITY' | 'MARKETING' | 'AUTHENTICATION';
  components: IMessageTemplateComponentsDTO;
  language: string;
  status:
    | 'APPROVED'
    | 'IN_APPEAL'
    | 'PENDING'
    | 'REJECTED'
    | 'PENDING_DELETION'
    | 'DELETED'
    | 'DISABLED'
    | 'PAUSED'
    | 'LIMIT_EXCEEDED';
  rejected_reason:
    | 'ABUSIVE_CONTENT'
    | 'INVALID_FORMAT'
    | 'NONE'
    | 'PROMOTIONAL'
    | 'TAG_CONTENT_MISMATCH'
    | 'SCAM';
  quality_score: {
    score: string;
    date: number;
  };
}

interface MessageTemplateContextData {
  listMessageTemplate?: IMessageTemplateDTO[];
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
  deleteMessageTemplate: UseMutationResult<void, unknown, string, unknown>;
}
const MessageTemplateContext = createContext<MessageTemplateContextData>(
  {} as MessageTemplateContextData,
);

const MessageTemplateProvider: React.FC = ({ children }) => {
  const { token } = useAuth();
  const { environment, instanceId } = useEnvironment();

  const {
    data: listMessageTemplate,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    [`listModelTemplates`, environment, environment?.id, instanceId],
    async () => {
      if (!token || !environment || !instanceId) {
        return undefined;
      }
      const response = await apiWhatsApp.get<IMessageTemplateDTO[]>(
        `clients/${instanceId}/templates`,
      );

      const { data } = response;

      return data;
    },
  );

  const deleteMessageTemplate = useMutation(
    async (name: string) => {
      if (!instanceId) {
        return;
      }
      await apiWhatsApp
        .delete(`/clients/${instanceId}/templates/${name}`)
        .then(() => {
          toast.success('Modelo de mensagem deletada com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao deletar modelo de mensagem');
        });
    },
    {
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        refetch();
      },
    },
  );

  return (
    <MessageTemplateContext.Provider
      value={{
        listMessageTemplate,
        isLoading,
        isFetching,
        refetch,
        deleteMessageTemplate,
      }}
    >
      {children}
    </MessageTemplateContext.Provider>
  );
};

function useMessageTemplates(): MessageTemplateContextData {
  const context = useContext(MessageTemplateContext);

  if (!context) {
    throw new Error(
      'useMessageTemplates must be used within an MessageTemplateProvider',
    );
  }

  return context;
}

export { MessageTemplateProvider, useMessageTemplates };
