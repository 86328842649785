import React from 'react';
import FormsList from './FormsList';

// import { Container } from './styles';

const BotForms: React.FC = () => {
  return (
    <div>
      <FormsList />
    </div>
  );
};

export default BotForms;
