import React from 'react';
import { Avatar as AvatarAntd, AvatarProps } from 'antd';
import { Container } from './styles';

export const Avatar: React.FC<AvatarProps> = props => {
  return (
    <Container>
      <AvatarAntd {...props} />
    </Container>
  );
};
