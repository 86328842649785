import { Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import intl from 'react-intl-universal';
import React, { memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useBotForms, FormsProps } from '~/hooks/bot/botForms';
import { ParamsProps } from '~/@types/params';

const FormsList: React.FC = () => {
  const history = useHistory();
  const { environmentId } = useParams<ParamsProps>();
  const {
    formsList,
    moreFormsPerPage,
    isLoading,
    onIsLoading,
    pageSize,
    handleChangePageSize,
    totalForms,
  } = useBotForms();

  const columns: ColumnsType<FormsProps> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
    },
    {
      title: intl.get('chatbot.list.name'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: intl.get('chatbot.list.status'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: isActive =>
        isActive ? (
          <Tooltip placement="topLeft" title={intl.get('ui.actived')}>
            <Tag color="green">{intl.get('ui.actived')}</Tag>
          </Tooltip>
        ) : (
          <Tooltip placement="topLeft" title={intl.get('ui.disabled')}>
            <Tag color="red">{intl.get('ui.disabled')}</Tag>
          </Tooltip>
        ),
    },
    {
      title: intl.get('chatbot.list.startup_command'),
      dataIndex: 'startCmd',
      key: 'startCmd',
    },
    {
      title: intl.get('chatbot.list.termination_command'),
      dataIndex: 'endCmd',
      key: 'endCmd',
    },
    {
      title: intl.get('chatbot.list.web_form'),
      dataIndex: 'allowWeb',
      key: 'allowWeb',
      render: allowWeb =>
        allowWeb ? (
          <Tooltip placement="topLeft" title={intl.get('ui.actived')}>
            <Tag color="green">{intl.get('ui.actived')}</Tag>
          </Tooltip>
        ) : (
          <Tooltip placement="topLeft" title={intl.get('ui.disabled')}>
            <Tag color="red">{intl.get('ui.disabled')}</Tag>
          </Tooltip>
        ),
    },
    {
      title: intl.get('chatbot.list.language'),
      dataIndex: 'language',
      key: 'language',
    },
    {
      title: intl.get('chatbot.list.last_change'),
      dataIndex: 'lastUpdatedFormatted',
      key: 'lastUpdatedFormatted',
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        scroll={{
          y: 'calc(100vh - 260px)',
        }}
        dataSource={formsList}
        pagination={{
          onChange: (page: number) => {
            onIsLoading();
            moreFormsPerPage(page);
          },
          pageSize,
          showSizeChanger: true,
          onShowSizeChange: (current: number, size: number) =>
            handleChangePageSize(size),
          total: totalForms,
        }}
        loading={isLoading}
        bordered
        rowKey="id"
        onRow={row => {
          return {
            onClick: () => {
              history.push(`/c/${environmentId}/chatbot/forms/${row.id}`);
            },
          };
        }}
      />
    </div>
  );
};

export default memo(FormsList, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
