import {
  CommentOutlined,
  DownOutlined,
  ProjectOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import { Tag, Menu } from 'antd';

import React from 'react';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { ParamsProps } from '~/@types/params';
import Navbar from '~/components/global/organisms/Navbar';
import { AttendanceProvider } from '~/hooks/attendances/attendance';
import { useEnvironment } from '~/hooks/environments/environment';
import { KanbanProvider } from '~/hooks/workflow/kanban';
import { ListProvider } from '~/hooks/workflow/list';
import { useWorkflow } from '~/hooks/workflow/workflow';
import Kanban from './organisms/Kanban';
import List from './organisms/List';
import SelectedAttendancesPanel from './molecules/SelectedAttendancesPanel';

import { Container } from './styles';
import ShowAttendance from './organisms/ShowAttendance';
import CallCenterAttendances from './organisms/CallCenter';

const Workflow: React.FC = () => {
  const { totalAttendances } = useEnvironment();
  const { layoutBoard, changeLayoutBoard } = useWorkflow();

  const { attendanceId } = useParams<ParamsProps>();

  const viewAttendance = () => {
    if (!attendanceId) return <></>;
    return (
      <AttendanceProvider>
        <ShowAttendance />
      </AttendanceProvider>
    );
  };

  return (
    <>
      <Navbar title={intl.get('workflow.title')}>
        <>
          <div>
            <Tag>
              {intl.get('attendance.counter', { total: totalAttendances })}
            </Tag>
          </div>

          <Menu
            style={{ height: '55px', lineHeight: '53px' }}
            mode="horizontal"
            defaultSelectedKeys={[`board:${layoutBoard}`]}
          >
            <Menu.SubMenu
              key="board"
              icon={
                layoutBoard === 'kanban' ? (
                  <ProjectOutlined />
                ) : (
                  <UnorderedListOutlined />
                )
              }
              title={
                <>
                  {
                    {
                      kanban: intl.get('workflow.kanban.title'),
                      list: intl.get('workflow.list.title'),
                      chat: intl.get('chat.title'),
                    }[layoutBoard]
                  }
                  <DownOutlined
                    style={{ fontSize: '0.5rem', marginLeft: '6px' }}
                  />
                </>
              }
            >
              <Menu.Item
                key="board:kanban"
                icon={<ProjectOutlined />}
                onClick={() => changeLayoutBoard('kanban')}
              >
                {intl.get('workflow.kanban.title')}
              </Menu.Item>
              <Menu.Item
                key="board:list"
                icon={<UnorderedListOutlined />}
                onClick={() => changeLayoutBoard('list')}
              >
                {intl.get('workflow.list.title')}
              </Menu.Item>
              <Menu.Item
                key="board:chat"
                icon={<CommentOutlined />}
                onClick={() => changeLayoutBoard('chat')}
              >
                {intl.get('chat.title')}
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </>
      </Navbar>
      <Container>
        <SelectedAttendancesPanel />

        {
          {
            kanban: (
              <>
                <KanbanProvider>
                  <Kanban />
                </KanbanProvider>
                {viewAttendance()}
              </>
            ),
            list: (
              <>
                <ListProvider>
                  <List />
                </ListProvider>
                {viewAttendance()}
              </>
            ),
            chat: <CallCenterAttendances />,
          }[layoutBoard]
        }
      </Container>
    </>
  );
};

export default Workflow;
