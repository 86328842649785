import React from 'react';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import intl from 'react-intl-universal';

interface DeviceStatusTagProps {
  value: string | undefined;
}
const DeviceStatusTag: React.FC<DeviceStatusTagProps> = ({ value }) => {
  if (!value) {
    return (
      <Tag icon={<CheckCircleOutlined />} color="gold">
        {intl.get('device.status.undefined')}
      </Tag>
    );
  }
  if (value === 'CONNECTED') {
    return (
      <Tag icon={<CheckCircleOutlined />} color="success">
        {intl.get('device.status.connected')}
      </Tag>
    );
  }
  if (value === 'OPENING') {
    return (
      <Tag icon={<SyncOutlined spin />} color="processing">
        {intl.get('device.status.opening')}
      </Tag>
    );
  }
  if (value === 'PAIRING') {
    return (
      <Tag icon={<SyncOutlined spin />} color="blue">
        {intl.get('device.status.pairing')}
      </Tag>
    );
  }
  if (value === 'QRCODE') {
    return (
      <Tag icon={<ClockCircleOutlined />} color="gold">
        {intl.get('device.status.qrcode')}
      </Tag>
    );
  }
  if (value === 'DISCONNECTED') {
    return <Tag color="error">{intl.get('device.status.disconnected')}</Tag>;
  }

  return <Tag color="warning">{value}</Tag>;
};

export default DeviceStatusTag;
