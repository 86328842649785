import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Modal,
  notification,
  Select,
  Spin,
  Tooltip,
  Divider,
  Empty,
  Alert,
} from 'antd';
import intl from 'react-intl-universal';
import { useMutation } from 'react-query';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { AttendanceResponseProps } from '~/@types/attendance';
import { useEnvironment } from '~/hooks/environments/environment';
import api from '~/services/api';
import queryClient from '~/services/queryClient';
import { SelectOptions } from '~/@types/fields';
import { useResponsibles } from '~/hooks/responsibles/responsibles';
import { ContactResponseProps } from '~/hooks/contacts/contacts';
import NewContactModal from '../NewContactModal';
import ModalNewResponsible from '~/components/Responsibles/ModalNewResponsible';
import { useStatus } from '~/hooks/status/status';
import { ParamsProps } from '~/@types/params';

type IAttendanceResponse = AttendanceResponseProps;

export interface ICreateAttendance {
  responsibleId: string;
  contactId: string;
  statusCode: string;
  order?: { [key: string]: string };
}

type IButtonTypes = 'contact' | 'contactSmall' | 'responsible' | 'status';
interface ModalNewAttendanceProps {
  type?: IButtonTypes;
  contact?: SelectOptions;
  responsibleId?: number;
  statusCode?: string;
}

const ModalNewAttendance: React.FC<ModalNewAttendanceProps> = ({
  type,
  contact: contactExternal,
  responsibleId: responsibleIdExternal,
  statusCode: statusCodeExternal,
}) => {
  const { environment } = useEnvironment();
  const { environmentId } = useParams<ParamsProps>();
  const { isLoading: isLoadingStatus, statusList } = useStatus();

  const [form] = Form.useForm();

  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const createAttendance = useMutation(
    async ({
      contactId,
      responsibleId,
      statusCode,
    }: ICreateAttendance): Promise<IAttendanceResponse | undefined> => {
      if (!environment) {
        return undefined;
      }

      setIsLoading(true);
      const newAttendance = await api
        .post<IAttendanceResponse>(`/zc/${environment.id}/order/`, {
          client: contactId,
          location: responsibleId,
          order: { Origem: 'front' },
        })
        .then(response => {
          const { data } = response;
          notification.success({
            message: intl.get('modal_new_attendance.create.message'),
          });

          return data;
        })
        .catch(() => {
          notification.error({
            message: intl.get('modal_new_attendance.error.message'),
            description: intl.get('modal_new_attendance.error.description'),
          });

          return undefined;
        });

      if (newAttendance && newAttendance.id) {
        try {
          await api.post(
            `/zc/${environmentId}/order/${newAttendance.id}/change_status/`,
            {
              next_status_code: statusCode,
            },
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }

        history.push(`/c/${environmentId}/workflow/${newAttendance.id}`);

        closeModal();
      }

      setIsLoading(false);
      return newAttendance;
    },
    {
      onSuccess: () => {
        if (!environment) {
          return;
        }
        queryClient.invalidateQueries(`/zc/${environment.id}/order/`);
      },
      onError: () => {
        if (!environment) {
          return;
        }
        queryClient.invalidateQueries(`/zc/${environment.id}/order/`);
      },
    },
  );

  const [isExistsOpenTasksContact, setIsExistsOpenTasksContact] = useState(
    false,
  );
  const [totalOpenTasksContact, setTotalOpenTasksContact] = useState(0);

  const getTotal = async (
    statusCode: string,
    contactPhoneNumber: string,
  ): Promise<number> => {
    if (!environment) return 0;
    try {
      const response = await api.get(`zc/${environment.id}/order/`, {
        params: {
          offset: 0,
          limit: 1,
          status: statusCode,
          client: contactPhoneNumber,
        },
      });
      return response.data.count;
    } catch (error) {
      return 0;
    }
  };

  const checkContactOpenTasks = useMutation(
    async (contactId: string): Promise<void> => {
      if (!environment || !statusList) return;
      const contact = await api
        .get(`/zc/${environment.id}/client/${contactId}`)
        .then(response => response.data);
      const open = statusList.filter(item => item.status_type === 'O');
      let totalOpen = 0;
      await Promise.all(
        open.map(async item => {
          const count = await getTotal(item.code, contact.number);
          totalOpen += count;
        }),
      );
      if (totalOpen >= 1) {
        setTotalOpenTasksContact(totalOpen);
        setIsExistsOpenTasksContact(true);
      } else {
        setTotalOpenTasksContact(0);
        setIsExistsOpenTasksContact(false);
      }
    },
  );

  useEffect(() => {
    if (contactExternal && contactExternal.value) {
      checkContactOpenTasks.mutateAsync(contactExternal.value.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoadingContactsList, setIsLoadingContactsList] = useState(false);
  const [contactsList, setContactsList] = useState<SelectOptions[]>(
    contactExternal ? [contactExternal] : [],
  );

  const handleSetContactsList = (data: SelectOptions[]) => {
    const selectedContact = data[0];
    setContactsList(() => data);
    form.setFieldsValue({ contactId: selectedContact.value });
  };

  const getContactList = useMutation(
    async (quickSearch?: string): Promise<void> => {
      if (!environment) {
        return;
      }

      setIsLoadingContactsList(true);

      let filter = null;
      if (quickSearch) {
        if (quickSearch.match(/\d/) != null) {
          filter = { number__icontains: quickSearch };
        } else {
          filter = { name__icontains: quickSearch };
        }
      }
      await api
        .get<ContactResponseProps>(`/zc/${environment.id}/client/`, {
          params: {
            offset: 0,
            limit: 100,
            ...(filter && { ...filter }),
          },
        })
        .then(response => {
          const { data } = response;
          const formattedContactsList: SelectOptions[] = data.results.map(
            contact => ({
              name: contact.name || contact.number,
              label: contact.name || contact.number,
              value: contact.id,
            }),
          );

          setContactsList(() => [...formattedContactsList]);
        });

      setIsLoadingContactsList(false);
    },
    {
      onSuccess: () => {
        if (!environment) {
          return;
        }
        queryClient.invalidateQueries(`/zc/${environment.id}/order/`);
      },
      onError: () => {
        if (!environment) {
          return;
        }
        queryClient.invalidateQueries(`/zc/${environment.id}/order/`);
      },
    },
  );

  useEffect(() => {
    getContactList.mutateAsync(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    isLoading: isLoadingResponsiblesList,
    responsiblesList,
  } = useResponsibles();

  const handleSetResponsibleSelected = (responsibleId: number) => {
    form.setFieldsValue({ responsibleId });
  };

  if (
    !environment ||
    isLoadingResponsiblesList ||
    !responsiblesList ||
    isLoadingStatus ||
    !statusList
  ) {
    return <Button type="default" icon={<PlusOutlined />} loading />;
  }

  if (statusList.length === 0) {
    return <></>;
  }

  const formattedResponsiblesList = responsiblesList.map(responsible => ({
    name: responsible.name,
    label: responsible.name,
    value: responsible.id,
  }));

  const onFinish = (newAttendance: ICreateAttendance) => {
    createAttendance.mutateAsync(newAttendance);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    form.resetFields();

    setIsModalVisible(false);
  };

  const getButtonType = (value?: IButtonTypes) => {
    if (value === 'contact') {
      return (
        <Tooltip title={intl.get('modal_new_attendance.title_for_contact')}>
          <Button
            type="text"
            size="large"
            icon={<PlusOutlined />}
            onClick={showModal}
          />
        </Tooltip>
      );
    }
    if (value === 'contactSmall') {
      return (
        <Button ghost size="middle" icon={<PlusOutlined />} onClick={showModal}>
          {intl.get('modal_new_attendance.title_for_contact')}
        </Button>
      );
    }
    if (value === 'responsible') {
      return (
        <Button
          type="default"
          size="large"
          block
          icon={<PlusOutlined />}
          onClick={showModal}
        >
          {intl.get('modal_new_attendance.title_for_responsible')}
        </Button>
      );
    }

    if (value === 'status') {
      return (
        <Button
          type="text"
          ghost
          block
          icon={<PlusOutlined />}
          onClick={showModal}
        >
          {intl.get('modal_new_attendance.title')}
        </Button>
      );
    }

    return (
      <Button type="default" icon={<PlusOutlined />} onClick={showModal}>
        {intl.get('modal_new_attendance.title')}
      </Button>
    );
  };

  return (
    <>
      {getButtonType(type)}

      <Modal
        title={intl.get('modal_new_attendance.title')}
        onCancel={closeModal}
        open={isModalVisible}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            ...(responsibleIdExternal && {
              responsibleId: responsibleIdExternal,
            }),
            ...(contactExternal && { contactId: contactExternal.value }),
            statusCode: statusCodeExternal || statusList[0].code,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label={intl.get('attendance.filters.responsible_for_the_service')}
            name="responsibleId"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={intl.get(
                'attendance.filters.responsible_for_the_service_placeholder',
              )}
              filterSort={(optionA, optionB) =>
                optionA?.name
                  .toLowerCase()
                  .localeCompare(optionB.name.toLowerCase())
              }
              options={formattedResponsiblesList}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <ModalNewResponsible
                    type="text"
                    onSetResponsibleSelected={handleSetResponsibleSelected}
                    isOpenAfterCreated={false}
                  />
                </>
              )}
            />
          </Form.Item>

          <Form.Item
            label={intl.get('attendance.filters.contact_for_the_attendance')}
            name="contactId"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              filterOption={false}
              notFoundContent={
                isLoadingContactsList ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  <Empty />
                )
              }
              dropdownRender={menu => (
                <>
                  {menu}

                  <Divider style={{ margin: '8px 0' }} />
                  <NewContactModal
                    type="text"
                    isOpenAfterCreated={false}
                    onSetContactsList={handleSetContactsList}
                  />
                </>
              )}
              onSearch={value => {
                getContactList.mutateAsync(value);
              }}
              onSelect={value => checkContactOpenTasks.mutateAsync(value)}
              placeholder={intl.get(
                'attendance.filters.contact_for_the_attendance_placeholder',
              )}
              options={contactsList}
            />
          </Form.Item>
          {isExistsOpenTasksContact && !checkContactOpenTasks.isLoading ? (
            <Alert
              message={intl.getHTML('modal_new_attendance.open', {
                total: totalOpenTasksContact,
              })}
              type="info"
              showIcon
              style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
            />
          ) : null}

          <Form.Item
            label={intl.get('attendance.filters.status')}
            name="statusCode"
          >
            <Select
              placeholder={intl.get('attendance.filters.status_placeholder')}
            >
              {statusList.map(state => (
                <Select.Option key={state.code} value={`${state.code}`}>
                  {state.code} - {state.status}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              size="middle"
              block
              type="primary"
              htmlType="submit"
              loading={isLoading || checkContactOpenTasks.isLoading}
            >
              {intl.get('modal_new_attendance.create_attendance')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalNewAttendance;
