/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import intl from 'react-intl-universal';

import { Timeline, Tag, Typography, Card } from 'antd';

import React from 'react';

import BaseField from '~/components/global/atoms/BaseField';
import { useAttendance } from '~/hooks/attendances/attendance';

import ItemLog from './Item';

const AttendanceLog: React.FC = () => {
  const { attendance, isLoading } = useAttendance();

  if (isLoading || !attendance?.status_history) {
    return (
      <div>
        <Card loading bordered={false} />
      </div>
    );
  }

  return (
    <div>
      <Timeline style={{ marginTop: '1rem' }}>
        {attendance.status_history.map((log, logIndex) => {
          if (log.event_data.change) {
            return (
              <Timeline.Item key={logIndex} color="red">
                <Tag>{log.status.status}</Tag>
                <br />
                <small>{log.time_createdFormatted}</small>

                <BaseField
                  small
                  label={intl.get('log.modified_by')}
                  value={log.event_data.user}
                />

                {log.event_data.message && (
                  <BaseField
                    small
                    label={intl.get('log.note')}
                    value={log.event_data.message}
                  />
                )}

                <BaseField small label={intl.get('log.modifications')}>
                  <ul style={{ paddingLeft: '1rem' }}>
                    {log.event_data.change.order &&
                      Object.entries(log.event_data.change.order).map(
                        (question, index) => {
                          const title = question[0];
                          const valueOld = question[1].old;
                          const valueNew = question[1].new;

                          if (
                            typeof valueNew === 'string' &&
                            typeof valueOld === 'string' &&
                            valueNew.includes('OptionList')
                          ) {
                            const [
                              ,
                              valueSelectedOld,
                              ...optionsValueOld
                            ] = valueOld.split(';');
                            optionsValueOld.unshift(intl.get('ui.blank'));
                            const [
                              ,
                              valueSelectedNew,
                              ...optionsValueNew
                            ] = valueNew.split(';');
                            optionsValueNew.unshift(intl.get('ui.blank'));
                            return (
                              <li key={index}>
                                <b>{title}:</b>{' '}
                                <Typography.Text delete>
                                  <ItemLog
                                    data={
                                      optionsValueOld[Number(valueSelectedOld)]
                                    }
                                  />
                                </Typography.Text>
                                {' > '}
                                <Typography.Text>
                                  <ItemLog
                                    data={
                                      optionsValueNew[Number(valueSelectedNew)]
                                    }
                                  />
                                </Typography.Text>
                              </li>
                            );
                          }
                          return (
                            <li key={index}>
                              <b>{title}:</b>{' '}
                              <Typography.Text delete>
                                <ItemLog data={valueOld} />
                              </Typography.Text>
                              {' > '}
                              <Typography.Text>
                                <ItemLog data={valueNew} />
                              </Typography.Text>
                            </li>
                          );
                        },
                      )}
                  </ul>
                </BaseField>
              </Timeline.Item>
            );
          }

          if (log.event_data.source === 'update_expired_orders') {
            return (
              <Timeline.Item key={logIndex} color="red">
                <Tag>{log.status.status}</Tag>
                <Tag color="red">{intl.get('log.expired')}</Tag>
                <br />

                <>{log.time_createdFormatted}</>
              </Timeline.Item>
            );
          }

          if (log.event_data.source === 'creation') {
            return (
              <Timeline.Item key={logIndex} color="green">
                <Tag>{log.status.status}</Tag>
                <Tag color="green">{intl.get('log.creation')}</Tag>
                <br />

                {log.event_data.message && (
                  <BaseField
                    small
                    label={intl.get('log.note')}
                    value={log.event_data.message}
                  />
                )}
                <>{log.time_createdFormatted}</>
              </Timeline.Item>
            );
          }
          return (
            <Timeline.Item
              key={logIndex}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Tag>{log.status.status}</Tag>

              <br />
              <>{log.time_createdFormatted}</>

              <BaseField
                small
                label={intl.get('log.modified_by')}
                value={log.event_data.user}
              />

              {log.event_data.message && (
                <BaseField
                  small
                  label={intl.get('log.note')}
                  value={log.event_data.message}
                />
              )}
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export default AttendanceLog;
