import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';

import { useParams } from 'react-router-dom';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { ISortByDTOS } from '../attendances/dtos/ISortByDTOS';
import { ParamsProps } from '~/@types/params';

export interface ListSettingsProps {
  columns?: Array<
    | 'contact.name'
    | 'contact.number'
    | 'responsible.name'
    | 'responsible.address'
    | 'attendance.order_summary'
    | 'attendance.time_created'
    | 'attendance.time_createdThere'
    | 'attendance.time_status'
    | 'attendance.time_deadline'
    | 'attendance.time_timeout'
  >;
  sortBy?: ISortByDTOS;
}

interface ListContextData {
  settings: ListSettingsProps;
  quickSearch?: string;
  setNewSettings: (newSettingsInput: ListSettingsProps) => void;
  resetSettings: () => void;
  listCollapsed: Array<string>;
  addListCollapsed: (newListCollapsed: string) => void;
  removeListCollapsed: (ListCollapsedRemoved: string) => void;
  setNewQuickSearch: (newQuickSearch: string) => void;
  selectedCards: Array<number>;
  addNewSelectedCard: (newSelectedCard: number) => void;
  removeSelectedCard: (selectedCardRemoved: number) => void;
  setNewListSelectedCards: (newListSelectedCards: Array<number>) => void;
  removeAllSelectedCards: () => void;
  moveCard: (cardId: string, statusCode: string) => void;
}

const ListContext = createContext<ListContextData>({} as ListContextData);

const ListProvider: React.FC = ({ children }) => {
  const { environmentId } = useParams<ParamsProps>();

  const defaultSettings: ListSettingsProps = {
    columns: [
      'attendance.order_summary',
      'contact.name',
      'contact.number',
      'responsible.name',
      'attendance.time_created',
      'attendance.time_createdThere',
      'attendance.time_timeout',
    ],
    sortBy: { sort: 'desc', by: 'id' },
  };

  const { refetch: refetchEnvironment } = useEnvironment();

  const [quickSearch, setQuickSearch] = useState<string>('');

  const [settings, setSettings] = useState<ListSettingsProps>(() => {
    const settingsLocalStorage = localStorage.getItem(
      '@ZapForm:workflowListSettings',
    );

    if (settingsLocalStorage) {
      const formattedSettings: ListSettingsProps = JSON.parse(
        settingsLocalStorage,
      );

      if (formattedSettings.sortBy) {
        return formattedSettings;
      }
    }

    localStorage.setItem(
      '@ZapForm:workflowListSettings',
      JSON.stringify(defaultSettings),
    );

    return defaultSettings;
  });

  const [listCollapsed, setListCollapsed] = useState<Array<string>>(() => {
    const listCollapsedLocalStorage = localStorage.getItem(
      '@ZapForm:workflowListCollapsedList',
    );

    if (listCollapsedLocalStorage) {
      return JSON.parse(listCollapsedLocalStorage);
    }

    localStorage.setItem(
      '@ZapForm:workflowListCollapsedList',
      JSON.stringify([]),
    );

    return [];
  });

  const [selectedCards, setSelectedCards] = useState<Array<number>>([]);

  const setNewQuickSearch = (newQuickSearch: string) => {
    setQuickSearch(newQuickSearch);
  };

  const setNewSettings = (newSettingsInput: ListSettingsProps) => {
    localStorage.setItem(
      '@ZapForm:workflowListSettings',
      JSON.stringify(newSettingsInput),
    );
    setSettings(() => ({ ...newSettingsInput }));
  };

  const resetSettings = () => {
    localStorage.setItem(
      '@ZapForm:workflowListSettings',
      JSON.stringify(defaultSettings),
    );
    setSettings(defaultSettings);
  };

  const addListCollapsed = (newListCollapsed: string) => {
    localStorage.setItem(
      '@ZapForm:workflowListCollapsedList',
      JSON.stringify([...listCollapsed, newListCollapsed]),
    );

    setListCollapsed(listCollapsedOld => [
      ...listCollapsedOld,
      newListCollapsed,
    ]);
  };

  const removeListCollapsed = (listCollapsedRemoved: string) => {
    const newListCollapsed = listCollapsed.filter(
      list => list !== listCollapsedRemoved,
    );

    localStorage.setItem(
      '@ZapForm:workflowListCollapsedList',
      JSON.stringify(newListCollapsed),
    );

    setListCollapsed(newListCollapsed);
  };

  const addNewSelectedCard = (newSelectedCard: number) => {
    if (selectedCards.includes(newSelectedCard)) {
      return;
    }

    setSelectedCards([...selectedCards, newSelectedCard]);
  };

  const setNewListSelectedCards = (newListSelectedCards: Array<number>) => {
    const newListSelectedCardsFiltered = newListSelectedCards.filter(
      card => !selectedCards.includes(card),
    );

    setSelectedCards([...selectedCards, ...newListSelectedCardsFiltered]);
  };

  const removeSelectedCard = (selectedCardRemoved: number) => {
    const newSelectedCards = selectedCards.filter(
      card => card !== selectedCardRemoved,
    );

    setSelectedCards(newSelectedCards);
  };

  const removeAllSelectedCards = () => {
    setSelectedCards([]);
  };

  const moveCard = async (cardId: string, statusCode: string) => {
    try {
      await api.post(`/zc/${environmentId}/order/${cardId}/change_status/`, {
        next_status_code: statusCode,
      });
      refetchEnvironment();
    } catch (error) {
      toast.error(intl.get('attendance.update.error_change_status'));
    }
  };

  return (
    <ListContext.Provider
      value={{
        settings,
        setNewSettings,
        resetSettings,
        addListCollapsed,
        removeListCollapsed,
        listCollapsed,
        quickSearch,
        setNewQuickSearch,
        selectedCards,
        setNewListSelectedCards,
        addNewSelectedCard,
        removeSelectedCard,
        removeAllSelectedCards,
        moveCard,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};
function useList(): ListContextData {
  const context = useContext(ListContext);

  if (!context) {
    throw new Error('useList must be used within an ListProvider');
  }

  return context;
}

export { ListProvider, useList };
