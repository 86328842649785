import React, { createContext, useContext, useState } from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ParamsProps } from '~/@types/params';
import api from '~/services/api';

export interface BroadcastMessagesProps {
  enabled: boolean;
}

interface BroadcastMessagesContextData {
  isLoading: boolean;

  sendBroadcastMessages: (
    listAttendanceIds: Array<number>,
    message: string,
  ) => void;
}

export const BroadcastMessagesContext = createContext<BroadcastMessagesContextData>(
  {} as BroadcastMessagesContextData,
);

const BroadcastMessagesProvider: React.FC = ({ children }) => {
  const { environmentId } = useParams<ParamsProps>();

  const [isLoading, setIsLoading] = useState(false);

  const sendBroadcastMessages = async (
    listAttendanceIds: Array<number>,
    message: string,
  ) => {
    setIsLoading(true);

    if (message === '') {
      setIsLoading(false);
      return;
    }

    const listAttendanceIdsFormatted = listAttendanceIds.toString();

    try {
      await api.post(
        `/zc/${environmentId}/order/send_message/`,
        { message },
        {
          params: {
            order_list: listAttendanceIdsFormatted,
          },
        },
      );

      toast.success(intl.get('broadcast_messages.messages_sent_successfully'));
    } catch (err) {
      toast.error(
        intl.get('broadcast_messages.error_messages_sent_successfully'),
      );
    }
    setIsLoading(false);
  };

  return (
    <BroadcastMessagesContext.Provider
      value={{
        sendBroadcastMessages,
        isLoading,
      }}
    >
      {children}
    </BroadcastMessagesContext.Provider>
  );
};
function useBroadcastMessages(): BroadcastMessagesContextData {
  const context = useContext(BroadcastMessagesContext);

  if (!context) {
    throw new Error(
      'useBroadcastMessages must be used within an BroadcastMessagesProvider',
    );
  }

  return context;
}

export { BroadcastMessagesProvider, useBroadcastMessages };
