import { ScanOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import intl from 'react-intl-universal';
import apiWhatsApp from '~/services/apiWhatsApp';
import queryClient from '~/services/queryClient';

interface RecreateDeviceButtonProps {
  clientId: string;
  disabled?: boolean;
}

const RecreateDeviceButton: React.FC<RecreateDeviceButtonProps> = ({
  clientId,
  disabled = false,
}) => {
  const [visible, setVisible] = useState(false);

  const [isTimeout, setIsTimeout] = useState(false);

  const RecreateDevice = useMutation(
    async () => {
      const deviceRecreated = await apiWhatsApp
        .put(`/clients/${clientId}/recreate`, {})
        .then(response => {
          const { data } = response;
          notification.success({
            message: intl.get('device.recreate_device.successfully'),
          });

          return data;
        })
        .catch(() => {
          notification.error({
            message: intl.get('device.recreate_device.error'),
            description: intl.get('device.recreate_device.error_desc'),
          });
        });

      return deviceRecreated;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`device`);
      },
      onError: () => {
        queryClient.invalidateQueries(`device`);
      },
    },
  );

  const handleSubmit = () => {
    setIsTimeout(true);
    RecreateDevice.mutateAsync();
    setTimeout(() => {
      setIsTimeout(false);
    }, 1000 * 20);
  };

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    handleSubmit();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {disabled ? (
        <Tooltip title="Aguarde...">
          <Button danger icon={<ScanOutlined />} disabled>
            {intl.get('device.recreate_device.title')}
          </Button>
        </Tooltip>
      ) : (
        <Popconfirm
          title={intl.get('device.recreate_device.popconfirm.title')}
          open={visible}
          onConfirm={handleOk}
          onCancel={handleCancel}
          okText={intl.get('device.recreate_device.popconfirm.ok')}
          cancelText={intl.get('device.recreate_device.popconfirm.cancel')}
        >
          <Button
            danger
            icon={<ScanOutlined />}
            onClick={showPopconfirm}
            loading={RecreateDevice.isLoading || isTimeout}
          >
            {intl.get('device.recreate_device.title')}
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export default RecreateDeviceButton;
