import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

interface ContainerProps {
  isActived: boolean;
}
interface cardItemVariationsProps {
  [propName: string]: FlattenSimpleInterpolation;
}

const cardItemVariations: cardItemVariationsProps = {
  default: css``,
  isActived: css`
    background: var(--gray-color);
    border: 1px solid var(--primary-color);
    border-left: 3px solid var(--primary-color);
    border-radius: var(--radius);
  `,
};

export const Container = styled.div<ContainerProps>`
  padding: 0.5rem 0.5rem 0 0.5rem;
  cursor: pointer;

  ${props => cardItemVariations[props.isActived ? 'isActived' : 'default']}

  &:hover {
    background: var(--gray-color);
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  .tag {
    margin: 0.5rem;
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub-titles {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;

    .ant-timeline-item {
      padding-bottom: 0;
    }
  }
`;
