import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 0 0 55px;
  border-radius: var(--radius);
  background: #fff;
  margin: 0 0.5rem 0.5rem 0.5rem;
`;

interface QuotedMsgProps {
  dir: 'i' | 'o';
}

export const QuotedMsg = styled.div<QuotedMsgProps>`
  border-radius: 2px;
  background: #f0f2f5;
  padding: 0.5rem;

  border-left: 3px solid ${props => (props.dir === 'i' ? '#00a761' : '#49b9ee')};
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .name {
      color: ${props => (props.dir === 'i' ? '#00a761' : '#49b9ee')};
      font-weight: bold;
      padding-bottom: 0.5rem;
    }
  }

  .content {
    overflow: auto;
    max-height: 150px;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5rem;

  height: 100%;

  .inputText {
    border-radius: var(--radius);
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);

  text-align: center;
  align-items: center;
  border-radius: var(--radius);
  height: 100%;
`;
