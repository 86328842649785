import { FileZipOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface DocumentProps {
  url: string;
}
const Document: React.FC<DocumentProps> = ({ url }) => {
  return (
    <div>
      <Link
        to={{
          pathname: url,
        }}
        target="_blank"
      >
        <Button
          type="primary"
          size="large"
          ghost
          block
          icon={<FileZipOutlined />}
        >
          {intl.get('chat.message_list.message.view_the_document')}
        </Button>
      </Link>
    </div>
  );
};

export default Document;
