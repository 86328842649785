/* eslint-disable no-underscore-dangle */
import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import apiWhatsApp from '~/services/apiWhatsApp';
import formatPhoneNumber from '~/util/formatPhoneNumber';
import { useAuth } from './auth';
import { useEnvironment } from './environments/environment';
import clearSpecialCharacters from '~/util/clearSpecialCharacters';

type IWAState =
  | 'CONFLICT'
  | 'CONNECTED'
  | 'DEPRECATED_VERSION'
  | 'DISCONNECTED'
  | 'OPENING'
  | 'PAIRING'
  | 'PROXYBLOCK'
  | 'QRCODE'
  | 'SMB_TOS_BLOCK'
  | 'TIMEOUT'
  | 'TOS_BLOCK'
  | 'UNLAUNCHED'
  | 'UNPAIRED';

export interface IResponsiblePhone {
  phone: string;
  email: string;
  name: string;
}

export interface IDeviceResponse {
  _id: string;
  name: string;
  isBot: boolean;
  webhook_url?: string;
  session?: string;
  qrcode?: string;
  status?: IWAState;
  device?: {
    wid: {
      server: string;
      user: string;
      _serialized: string;
    };
    platform: string;
    pushname: string;
  };
  wweb_version?: string;
  responsiblePhones?: IResponsiblePhone[];
  retries?: number;
  ping?: {
    sendAt?: Date | null;
    receivedAt?: Date | null;
  };
  disabledOptions?: boolean;
  startedAt?: Date;
  updatedAt: Date;
}

export interface IDevice {
  id: string;
  name: string;
  isBot: boolean;
  isWhatsAppOfficial?: boolean;
  accountWhatsApp?: {
    id: string;
    businessId: string;
  };
  webhook_url?: string;
  session?: string;
  qrcode?: string;
  status?: IWAState;
  info?: {
    wid: {
      server: string;
      user: string;
      _serialized: string;
    };
    platform: string;
    pushname: 'smba' | 'android' | string;
  };
  wwebVersion?: string;
  responsiblePhones?: IResponsiblePhone[];
  retries?: number;
  ping?: {
    sendAt?: Date | null;
    receivedAt?: Date | null;
  };
  disabledOptions?: boolean;
  startedAt?: Date;
  updatedAt: Date;
}

export interface IDeviceUpdate {
  name?: string;
  isBot?: boolean;
  webhook_url?: string;
  responsiblePhones?: Array<{ phone: string; name: string }>;
}

interface DeviceContextData {
  device?: IDevice;
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
  updateResponsibles: (responsiblePhones: IResponsiblePhone[]) => void;
}
const DeviceContext = createContext<DeviceContextData>({} as DeviceContextData);

const DeviceProvider: React.FC = ({ children }) => {
  const { token } = useAuth();
  const { environment, instanceId } = useEnvironment();

  const { data: device, isLoading, refetch, isFetching } = useQuery(
    [`device`, environment, environment?.id, instanceId],
    async () => {
      if (!token || !environment || !instanceId) {
        return undefined;
      }
      const response = await apiWhatsApp.get<IDeviceResponse>(
        `clients/${instanceId}`,
      );

      const { data } = response;

      const formattedDevice: IDevice = {
        ...data,
        id: data._id,
        ...(data.responsiblePhones && {
          responsiblePhones: formatResponsiblePhone(data.responsiblePhones),
        }),
        ...(data.wweb_version && { wwebVersion: data.wweb_version }),
        ...(data.device && {
          info: {
            ...data.device,
            wid: {
              ...data.device.wid,
              user: formatPhoneNumber(data.device.wid.user),
            },
            platform: formatPlatformDevice(data.device.platform),
          },
        }),
      };

      return formattedDevice;
    },
    {
      refetchInterval: 5 * 1000,
    },
  );

  const formatResponsiblePhone = (
    responsiblePhones: IResponsiblePhone[],
  ): IResponsiblePhone[] => {
    return responsiblePhones.map((responsible: IResponsiblePhone) => ({
      ...responsible,
      phone: `+${responsible.phone}`,
    }));
  };

  const formatPlatformDevice = (value: string): string => {
    if (value === 'smba') {
      return 'WhatsApp Business';
    }
    if (value === 'android') {
      return 'WhatsApp';
    }
    return value;
  };

  const updateResponsibles = async (responsiblePhones: IResponsiblePhone[]) => {
    const formattedResponsiblePhones = responsiblePhones.map(responsible => {
      return {
        ...responsible,
        phone: clearSpecialCharacters(responsible.phone),
      };
    });

    try {
      await apiWhatsApp.put(`clients/${instanceId}`, {
        responsiblePhones: formattedResponsiblePhones,
      });
      toast.success(
        intl.get(
          'device.instability_alert.contacts_to_be_notified.successfully_updated',
        ),
      );
      refetch();
    } catch (error) {
      toast.error(
        intl.get(
          'device.instability_alert.contacts_to_be_notified.could_not_update',
        ),
      );
    }
  };

  return (
    <DeviceContext.Provider
      value={{ device, isLoading, isFetching, refetch, updateResponsibles }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

function useDevice(): DeviceContextData {
  const context = useContext(DeviceContext);

  if (!context) {
    throw new Error('useDevice must be used within an DeviceProvider');
  }

  return context;
}

export { DeviceProvider, useDevice };
