/* eslint-disable react/no-array-index-key */
import intl from 'react-intl-universal';
import { CommentOutlined } from '@ant-design/icons';
import { Button, Input, Form } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';

import { useAttendance } from '~/hooks/attendances/attendance';

const NewAttendanceLog: React.FC = () => {
  const { attendance, isLoading, sendNote } = useAttendance();
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);

  const handleOpenNoteModal = () => {
    setIsNoteModalVisible(true);
  };

  const handleCloseNoteModal = () => {
    setIsNoteModalVisible(false);
  };

  const handleSendNote = async (note: string) => {
    try {
      await sendNote(note);
      handleCloseNoteModal();
    } catch (error) {
      setIsNoteModalVisible(true);
    }
  };

  if (isLoading || !attendance?.status_history) {
    return (
      <div>
        <Button size="large" block icon={<CommentOutlined />} />
      </div>
    );
  }
  return (
    <div>
      <Modal
        open={isNoteModalVisible}
        centered
        title={intl.get('log.new_note')}
        onOk={() => handleOpenNoteModal()}
        onCancel={() => handleCloseNoteModal()}
        footer={null}
      >
        <Form onFinish={values => handleSendNote(values.note)}>
          <Form.Item name="note">
            <Input.TextArea
              placeholder={intl.get('log.describe_your_observation_here')}
              size="large"
            />
          </Form.Item>
          <Button htmlType="submit" size="large" type="primary" block>
            {intl.get('log.send_note')}
          </Button>
        </Form>
      </Modal>
      <Button
        size="large"
        block
        icon={<CommentOutlined />}
        onClick={() => handleOpenNoteModal()}
      >
        {intl.get('log.new_note')}
      </Button>
    </div>
  );
};

export default NewAttendanceLog;
