import { SnippetsOutlined } from '@ant-design/icons';
import { Button, Drawer, Tooltip } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { FiltersProps } from '~/@types/attendances';
import { AttendancesProvider } from '~/hooks/attendances/attendances';
import { useContact } from '~/hooks/contacts/contact';
import { ListProvider } from '~/hooks/workflow/list';
import AttendancesTable from '../../molecules/AttendancesTable';
import TotalAttendancesContact from '../../molecules/TotalAttendancesContact';
import Filters from './components/Filters';
// import { Container } from './styles';
interface IDrawerContactAttendances {
  typeIcon?: 'text' | 'icon' | 'graph';
}

const DrawerContactAttendances: React.FC<IDrawerContactAttendances> = ({
  typeIcon = 'text',
}) => {
  const { isLoading, contact } = useContact();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };
  const onClose = () => {
    setIsDrawerVisible(false);
  };

  const [filters, setFilters] = useState<FiltersProps>({});

  const handleSetNewFilters = (newFiltersInput: FiltersProps) => {
    const formattedNewFiltersInput = {
      ...newFiltersInput,
      ...(newFiltersInput.expired === true
        ? { expired: true }
        : { expired: undefined }),
    };
    localStorage.setItem(
      '@ZapForm:workflowFilters',
      JSON.stringify(formattedNewFiltersInput),
    );
    setFilters(formattedNewFiltersInput);
  };

  const handleCleanFilters = () => {
    localStorage.removeItem('@ZapForm:workflowFilters');
    setFilters({} as FiltersProps);
  };

  if (isLoading || !contact) {
    return <Button type="text" size="large" loading />;
  }
  return (
    <div>
      {
        {
          text: (
            <Button icon={<SnippetsOutlined />} onClick={() => showDrawer()}>
              {intl.get('contact.contact_calls')}
            </Button>
          ),
          icon: (
            <Tooltip title={intl.get('contact.contact_calls')}>
              <Button
                type="text"
                size="large"
                icon={<SnippetsOutlined />}
                onClick={() => showDrawer()}
              />
            </Tooltip>
          ),
          graph: (
            <Button
              type="text"
              onClick={() => showDrawer()}
              style={{ height: 'auto' }}
            >
              <TotalAttendancesContact contactPhoneNumber={contact.number} />
            </Button>
          ),
        }[typeIcon]
      }

      <Drawer
        title={intl.get('contact.contact_calls')}
        onClose={onClose}
        open={isDrawerVisible}
        width="95vw"
        extra={[
          <Filters
            cleanFilters={handleCleanFilters}
            filters={filters}
            setNewFilters={handleSetNewFilters}
          />,
        ]}
      >
        <TotalAttendancesContact contactPhoneNumber={contact.number} />
        <br />
        <ListProvider>
          <AttendancesProvider
            contactNumber={contact.number}
            filters={filters}
            disabledSort
          >
            <AttendancesTable />
          </AttendancesProvider>
        </ListProvider>
      </Drawer>
    </div>
  );
};

export default DrawerContactAttendances;
