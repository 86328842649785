import { DeleteOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { Button, Popconfirm, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useContact } from '~/hooks/contacts/contact';

// import { Container } from './styles';

const DeleteContactModal: React.FC = () => {
  const { deleteContact } = useContact();
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteContact = async () => {
    setIsLoading(true);
    await deleteContact();
    setIsLoading(false);
  };

  return (
    <div>
      <Tooltip title={intl.get('contact.delete.title')}>
        <Popconfirm
          title={intl.get('contact.delete.confirm_message')}
          onConfirm={handleDeleteContact}
          okText={intl.get('contact.delete.yes')}
          cancelText={intl.get('contact.delete.no')}
        >
          <Button
            block
            size="large"
            danger
            icon={<DeleteOutlined />}
            loading={isLoading}
          >
            {intl.get('contact.delete.title')}
          </Button>
        </Popconfirm>
      </Tooltip>
    </div>
  );
};

export default DeleteContactModal;
