import React from 'react';
import intl from 'react-intl-universal';
import { MessageProps as IMessageProps } from '~/hooks/messages/messages';
import * as styles from './styles';

interface MessageProps {
  data: IMessageProps;
}

const MessageLog: React.FC<MessageProps> = ({ data }) => {
  const message = data;

  return (
    <div>
      <styles.Wrapper>
        <styles.Container>
          <b>{intl.get('chat.message_list.new_conversation')}</b>
        </styles.Container>
        <styles.Metadata>
          <span className="time">{message.msg_timeFormatted}</span>
        </styles.Metadata>
      </styles.Wrapper>
    </div>
  );
};

export default MessageLog;
