/* eslint-disable import/no-duplicates */
import { format, formatDistance, differenceInHours, isBefore } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useQuery } from 'react-query';

import api from '~/services/api';

import { useEnvironment } from '~/hooks/environments/environment';
import { useWorkflow } from '~/hooks/workflow/workflow';
import {
  AttendancesItemProps,
  AttendancesResponseProps,
} from '~/@types/attendances';
import { useLocales } from '../locales';
import { useKanban } from '../workflow/kanban';
import formatPhoneNumber from '~/util/formatPhoneNumber';

interface AttendancesByStatusContextData {
  attendancesList: AttendancesItemProps[];
  totalAttendances: number;
  hasMore: boolean;
  moreAttendances: () => void;
  isLoading: boolean;
}
const AttendancesByStatusContext = createContext<AttendancesByStatusContextData>(
  {} as AttendancesByStatusContextData,
);

interface AttendancesByStatusProviderProps {
  status: string;
}

const AttendancesByStatusProvider: React.FC<AttendancesByStatusProviderProps> = ({
  status,
  children,
}) => {
  const limitDefault = 10;

  const { currentLocale } = useLocales();
  const { environment, isFetching } = useEnvironment();
  const { filters, quickSearch } = useWorkflow();

  const { settings } = useKanban();

  const [attendancesList, setAttendancesByStatus] = useState<
    AttendancesItemProps[]
  >([]);
  const [totalAttendances, setTotalAttendances] = useState(0);
  const [limit, setLimit] = useState(limitDefault);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const formatterDate = (value: Date) => {
    return format(value, 'dd/MM/yyyy HH:mm:ss aa', {
      locale: currentLocale === 'pt-BR' ? ptBR : es,
    });
  };

  const formatSorting = (): string => {
    if (!settings.sortBy) {
      return '';
    }
    let result = '';
    result += settings.sortBy.sort === 'asc' ? '' : '-';
    result += settings.sortBy.by;

    return result;
  };

  useQuery(
    [
      `attendancesByStatus${status}`,
      limit,
      settings,
      filters,
      quickSearch,
      environment?.id,
      isFetching,
    ],
    async () => {
      if (!environment) {
        return;
      }

      const response = await api.get(`/zc/${environment.id}/order/`, {
        params: {
          offset: 0,
          limit,
          status,
          ...(settings.sortBy && { order_by: formatSorting() }),
          ...(filters.attendanceId && { id: filters.attendanceId }),
          ...((filters.contact || (quickSearch && quickSearch !== '')) && {
            client: quickSearch || filters.contact,
          }),
          ...(filters.responsible && { location: filters.responsible }),
          ...(filters.attendance && {
            order: filters.attendance.slice(0, 100).slice(0, 100),
          }),
          ...(filters.expired === true && {
            expired: true,
          }),
          ...(filters.timeCreated && {
            time_created__gt: filters.timeCreated[0],
            time_created__lte: filters.timeCreated[1],
          }),
          ...(filters.timeStatus && {
            time_status__gt: filters.timeStatus[0],
            time_status__lte: filters.timeStatus[1],
          }),
          ...(filters.timeLastUpdated && {
            time_last_updated__gt: filters.timeLastUpdated[0],
            time_last_updated__lte: filters.timeLastUpdated[1],
          }),
          ...(filters.attendanceIdStart && {
            id__gt: filters.attendanceIdStart,
          }),
          ...(filters.attendanceIdEnd && {
            id__lte: filters.attendanceIdEnd,
          }),
        },
      });

      const { data } = response;
      setHasMore(!!data.next);
      setTotalAttendances(data.count);
      formatterListAttendance(data);
      setIsLoading(false);
    },
    {
      refetchInterval: 10000,
    },
  );

  useEffect(() => {
    setIsLoading(true);
    setLimit(limitDefault);
  }, [filters, quickSearch, settings]);

  const formatterListAttendance = (data: AttendancesResponseProps) => {
    const formattedAttendancesList = data.results.map(attendance => {
      const howIsTimeout = ():
        | 'timeout'
        | 'insideTime'
        | 'distantTime'
        | undefined => {
        if (!attendance.time_timeout) {
          return undefined;
        }

        if (isBefore(new Date(attendance.time_timeout), new Date())) {
          return 'timeout';
        }

        if (
          differenceInHours(new Date(attendance.time_timeout), new Date()) <= 24
        ) {
          return 'insideTime';
        }

        return 'distantTime';
        // return isBefore(new Date(attendance.time_timeout), new Date())
        //   ? 'timeout'
        //   : differenceInHours(new Date(attendance.time_timeout), new Date()) <=
        //     24
        //     ? 'insideTime'
        //     : 'distantTime';
      };

      const formattedAttendance: AttendancesItemProps = {
        id: attendance.id,
        order_summary: attendance.order_summary,
        time_created: attendance.time_created,
        time_createdFormatted: formatterDate(new Date(attendance.time_created)),
        time_createdThere: formatDistance(
          new Date(attendance.time_created),
          new Date(),
          {
            includeSeconds: true,
            addSuffix: true,
            locale: currentLocale === 'pt-BR' ? ptBR : es,
          },
        ),
        time_status: attendance.time_status,
        time_statusFormatted: formatterDate(new Date(attendance.time_status)),
        time_deadline: attendance.time_timeout,
        time_deadlineFormatted:
          attendance.time_timeout &&
          formatterDate(new Date(attendance.time_timeout)),
        time_howIsTimeout: howIsTimeout(),
        time_timeoutFormatted:
          attendance.time_timeout &&
          formatDistance(new Date(attendance.time_timeout), new Date(), {
            includeSeconds: true,
            addSuffix: true,
            locale: currentLocale === 'pt-BR' ? ptBR : es,
          }),
        responsible: {
          ...attendance.location,
          id_name: `#${attendance.location.id} - ${attendance.location.name}`,
        },
        contact: {
          ...attendance.client,
          number_formatted: formatPhoneNumber(attendance.client.number),
          id_name: `#${attendance.client.id} - ${attendance.client.name}`,
        },
        status: {
          ...attendance.status,
          code_status: `${attendance.status.code} - ${attendance.status.status}`,
        },
      };

      return formattedAttendance;
    });
    setAttendancesByStatus(formattedAttendancesList);
  };

  const moreAttendances = useCallback(() => {
    setLimit(limitOld => limitOld + limitDefault);
  }, []);

  return (
    <AttendancesByStatusContext.Provider
      value={{
        attendancesList,
        totalAttendances,
        hasMore,
        moreAttendances,
        isLoading,
      }}
    >
      {children}
    </AttendancesByStatusContext.Provider>
  );
};
function useAttendancesByStatus(): AttendancesByStatusContextData {
  const context = useContext(AttendancesByStatusContext);

  if (!context) {
    throw new Error(
      'useAttendancesByStatus must be used within an AttendancesProvider',
    );
  }

  return context;
}

export { AttendancesByStatusProvider, useAttendancesByStatus };
