import { Typography } from 'antd';
import React, { useState } from 'react';

import intl from 'react-intl-universal';

import ModalForward from './ModalForward';

interface ForwardProps {
  messageId: number;
}

const Forward: React.FC<ForwardProps> = ({ messageId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Typography onClick={showModal}>{intl.get('message.forward')}</Typography>

      {isModalOpen ? (
        <ModalForward handleClose={() => handleClose()} messageId={messageId} />
      ) : null}
    </>
  );
};

export default Forward;
