import { Button, Dropdown, Form, Input, Modal, Popconfirm } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { EllipsisOutlined } from '@ant-design/icons';
import { useAttendance } from '~/hooks/attendances/attendance';

interface ModalChangeLabelProps {
  value: string;
}

const ModalChangeLabel: React.FC<ModalChangeLabelProps> = ({ value: key }) => {
  const { attendance, updateAttendanceFormOrder } = useAttendance();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmitChangeTitle = async ({
    newTitle,
  }: {
    newTitle: string;
  }) => {
    if (!attendance) return;
    setIsLoading(true);
    let formattedOrder = {};
    Object.entries(attendance.order).forEach(([title, value]) => {
      if (title === key) {
        formattedOrder = {
          ...formattedOrder,
          [key]: '*DELETE*',
          [`${newTitle} 🔅`]: value,
        };
        return;
      }
      formattedOrder = { ...formattedOrder, [title]: value };
    });
    await updateAttendanceFormOrder(formattedOrder);
    setIsLoading(false);
    closeModal();
  };

  const handleSubmitDeleteTitle = async () => {
    if (!attendance) return;
    setIsLoading(true);
    let formattedOrder = {};
    Object.entries(attendance.order).forEach(([title, value]) => {
      if (title === key) {
        formattedOrder = { ...formattedOrder, [title]: '*DELETE*' };
        return;
      }
      formattedOrder = { ...formattedOrder, [title]: value };
    });
    await updateAttendanceFormOrder(formattedOrder);
    setIsLoading(false);
    closeModal();
  };

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              key: '1',
              label: intl.get('attendance.new_question.change_title.title'),
              onClick: () => showModal(),
            },
            {
              key: 2,
              label: (
                <Popconfirm
                  title={intl.get('attendance.delete_question.confirm')}
                  onConfirm={handleSubmitDeleteTitle}
                  okText={intl.get('buttons.yes')}
                  cancelText={intl.get('buttons.no')}
                >
                  {intl.get('attendance.delete_question.title')}
                </Popconfirm>
              ),
            },
          ],
        }}
      >
        <Button shape="round" icon={<EllipsisOutlined />} className="edit" />
      </Dropdown>
      <Modal
        title={intl.get('attendance.new_question.change_title.title_modal')}
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
      >
        <Form
          onFinish={handleSubmitChangeTitle}
          initialValues={{ newTitle: key.replace(' 🔅', '') }}
          autoComplete="off"
        >
          <Form.Item
            name="newTitle"
            rules={[
              {
                required: true,
                message: intl.get('inputs.mandatory'),
              },
            ]}
          >
            <Input
              placeholder={intl.get(
                'attendance.new_question.change_title.placeholder',
              )}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              loading={isLoading}
            >
              {intl.get('buttons.save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalChangeLabel;
