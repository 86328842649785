import { Button, Dropdown, Form, Input, MenuProps, Modal } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { FormFieldProps } from '~/@types/attendance';
import { useAttendance } from '~/hooks/attendances/attendance';
import { Container } from './styles';

const NewQuestion: React.FC = () => {
  const { newFormField } = useAttendance();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [type, setType] = useState<FormFieldProps>('text');

  const showModal = (typeValue: FormFieldProps) => {
    setType(typeValue);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleSubmit = async ({ title }: { title: string }) => {
    setIsLoading(true);
    try {
      await newFormField(title, type);
      closeModal();
      // eslint-disable-next-line no-empty
    } catch (error) {}
    setIsLoading(false);
  };

  const items: MenuProps['items'] = [
    {
      key: 'text',
      label: intl.get('chatbot.question.types.text'),
      onClick: () => showModal('text'),
    },
    {
      key: 'number',
      label: intl.get('chatbot.question.types.numeric'),
      onClick: () => showModal('number'),
    },
    {
      key: 'boolean',
      label: intl.get('chatbot.question.types.true_and_false'),
      onClick: () => showModal('boolean'),
    },
    {
      key: 'image',
      label: intl.get('chatbot.question.types.image'),
      onClick: () => showModal('image'),
    },
    {
      key: 'audio',
      label: intl.get('chatbot.question.types.audio'),
      onClick: () => showModal('audio'),
    },
    {
      key: 'video',
      label: intl.get('chatbot.question.types.video'),
      onClick: () => showModal('video'),
    },
  ];
  return (
    <Container>
      <Dropdown menu={{ items }} placement="top">
        <Button size="large" type="primary" block>
          {intl.get('attendance.new_question.title')}
        </Button>
      </Dropdown>
      <Modal
        title={intl.get('attendance.new_question.title_modal')}
        open={open}
        footer={null}
        onCancel={closeModal}
      >
        <Form name="newQuestion" onFinish={handleSubmit} autoComplete="off">
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: intl.get('inputs.mandatory'),
              },
            ]}
          >
            <Input
              placeholder={intl.get('attendance.new_question.placeholder')}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              loading={isLoading}
            >
              {intl.get('buttons.create')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  );
};

export default NewQuestion;
