import React from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { Image } from 'antd';

import ImgWorkChat from '~/assets/work_chat.svg';

import { AttendanceProvider } from '~/hooks/attendances/attendance';
import ChatPanel from '~/components/CallCenter/organisms/ChatPanel';
import { ParamsProps } from '~/@types/params';
import { ChatAttendancesProvider } from '~/hooks/callCenter/chatAttendances';

import { CallCenterProvider } from '~/hooks/callCenter/callCenter';
import ChatList from '~/components/CallCenter/organisms/ChatList';
import Chat from '~/components/CallCenter/organisms/Chat';

import { Wrapper, ContainerLeft, Alert, RowChatList } from './styles';

const CallCenterAttendances: React.FC = () => {
  const { attendanceId, environmentId } = useParams<ParamsProps>();

  const lastOpenAttendance = localStorage.getItem(
    '@ZapForm:lastOpenAttendance',
  );

  const history = useHistory();

  if (!attendanceId && lastOpenAttendance) {
    history.push(`/c/${environmentId}/workflow/${lastOpenAttendance}`);
  }

  return (
    <>
      <CallCenterProvider>
        <Wrapper>
          <ChatAttendancesProvider>
            <ContainerLeft>
              <ChatPanel />
              <RowChatList>
                <ChatList />
              </RowChatList>
            </ContainerLeft>

            {attendanceId ? (
              <AttendanceProvider>
                <Chat />
              </AttendanceProvider>
            ) : (
              <Alert>
                <Image src={ImgWorkChat} width="40%" preview={false} />
              </Alert>
            )}
          </ChatAttendancesProvider>
        </Wrapper>
      </CallCenterProvider>
    </>
  );
};

export default CallCenterAttendances;
