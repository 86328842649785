import React, { createContext, useContext, useState } from 'react';
import intl from 'react-intl-universal';

import { useHistory, useLocation } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import esAntd from 'antd/locale/es_ES';
import ptBRAntd from 'antd/locale/pt_BR';

import 'dayjs/locale/pt-br';
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import es from '~/locales/es.json';
import ptBR from '~/locales/pt-BR.json';

dayjs.extend(utc);

type acronymLocales = 'pt-BR' | 'es';

type LocalesProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [id: string]: any;
};

interface LocalesContextData {
  isLoading: boolean;
  currentLocale: 'pt-BR' | 'es';
  changeCurrentLocale: (newCurrentLocale: acronymLocales) => void;
}

const LocalesContext = createContext<LocalesContextData>(
  {} as LocalesContextData,
);

const LocalesProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [currentLocale, setCurrentLocale] = useState<acronymLocales>(() => {
    const currentLocaleStorage = localStorage.getItem(
      '@ZapForm:currenteLocale',
    );

    if (currentLocaleStorage === 'es') {
      return 'es';
    }

    return 'pt-BR';
  });

  const locales: LocalesProps = {
    'pt-BR': ptBR,
    es,
  };

  intl
    .init({
      currentLocale: currentLocale || 'pt-BR',
      locales,
    })
    .then(() => {
      setIsLoading(false);
    });

  const changeCurrentLocale = (newCurrentLocale: acronymLocales) => {
    localStorage.setItem('@ZapForm:currenteLocale', newCurrentLocale);
    setCurrentLocale(newCurrentLocale);
    history.push(pathname);
  };

  return (
    <LocalesContext.Provider
      value={{ isLoading, currentLocale, changeCurrentLocale }}
    >
      {isLoading ? (
        <></>
      ) : (
        <>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#009a63',
              },
            }}
            locale={currentLocale === 'pt-BR' ? ptBRAntd : esAntd}
          >
            {children}
          </ConfigProvider>
        </>
      )}
    </LocalesContext.Provider>
  );
};

function useLocales(): LocalesContextData {
  const context = useContext(LocalesContext);

  if (!context) {
    throw new Error('useLocales must be used within an LocalesProvider');
  }

  return context;
}

export { LocalesProvider, useLocales };
