import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/Auth/SignIn';
// import Dashboard from '../pages/Dashboard';
import Workflow from '../pages/Workflow';
import WorkflowAttendancePrint from '../pages/Workflow/WorkflowAttendancePrint';
import Page404 from '../pages/404';
import ListResponsible from '~/pages/Responsibles';
import Responsible from '~/pages/Responsibles/Responsible';
import BotForms from '~/pages/BotForms';
import BotForm from '~/pages/BotForms/BotForm';
import MyAccount from '~/pages/MyAccount';
import AccountRecovery from '~/pages/Auth/AccountRecovery';
import Reports from '~/pages/Reports';
import Campaigns from '~/pages/Campaigns';
import Device from '~/pages/Device';
import Contacts from '~/pages/Contacts';
import PageRedirect from '~/pages/Redirect';
import AttendancePrintCustom from '~/pages/Workflow/AttendancePrintCustom';
import ValidationBillApp from '~/pages/ValidationBillApp';
import MessageTemplates from '~/pages/MessageTemplates';
import NewMessageTemplate from '~/pages/MessageTemplates/NewMessageTemplate';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route
      path="/ativar-bill/:phoneNumberToBill"
      exact
      component={ValidationBillApp}
    />

    <Route path="/accounts/recovery" exact component={AccountRecovery} />
    <Route
      path="/c/:environmentId/account"
      exact
      component={MyAccount}
      isPrivate
    />

    <Route path="/redirect" exact component={PageRedirect} isPrivate />
    <Route
      path="/c/:environmentId/workflow"
      exact
      component={Workflow}
      isPrivate
    />
    <Route
      path="/c/:environmentId/workflow/:attendanceId"
      exact
      component={Workflow}
      isPrivate
    />

    <Route
      path="/c/:environmentId/workflow/:attendanceId/print"
      exact
      component={WorkflowAttendancePrint}
      isPrint
      isPrivate
    />

    <Route
      path="/c/:environmentId/workflow/:attendanceId/print/custom"
      exact
      component={AttendancePrintCustom}
      isPrint
      isPrivate
    />

    <Route
      path="/c/:environmentId/contacts/"
      exact
      component={Contacts}
      isPrivate
    />
    <Route
      path="/c/:environmentId/contacts/:contactId"
      exact
      component={Contacts}
      isPrivate
    />

    <Route
      path="/c/:environmentId/chatbot/forms"
      exact
      component={BotForms}
      isPrivate
    />

    <Route
      path="/c/:environmentId/chatbot/forms/:botFormId"
      exact
      component={BotForm}
      isPrivate
    />

    <Route
      path="/c/:environmentId/chatbot/forms/:botFormId/:step"
      component={BotForm}
      isPrivate
    />

    <Route
      path="/c/:environmentId/responsibles"
      exact
      component={ListResponsible}
      isPrivate
    />
    <Route
      path="/c/:environmentId/responsibles/:responsibleId"
      exact
      component={Responsible}
      isPrivate
    />

    <Route
      path="/c/:environmentId/reports"
      exact
      component={Reports}
      isPrivate
    />

    <Route
      path="/c/:environmentId/campaigns"
      exact
      component={Campaigns}
      isPrivate
    />

    <Route path="/c/:environmentId/device" exact component={Device} isPrivate />
    <Route
      path="/c/:environmentId/messagetemplates"
      exact
      component={MessageTemplates}
      isPrivate
    />

    <Route
      path="/c/:environmentId/messagetemplates/create"
      exact
      component={NewMessageTemplate}
      isPrivate
    />

    <Route exact component={Page404} />
  </Switch>
);

export default Routes;
