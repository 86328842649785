import React from 'react';
import ReportFilters from './Filters';
import ReportResults from './Results';
import { ReportProvider } from '~/hooks/reports/report';

import { Container } from './styles';

const Report: React.FC = () => {
  return (
    <Container>
      <ReportProvider>
        <div style={{ flex: '0 0 350px' }}>
          <ReportFilters />
        </div>
        <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
          <ReportResults />
        </div>
      </ReportProvider>
    </Container>
  );
};

export default Report;
