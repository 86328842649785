import React from 'react';
import intl from 'react-intl-universal';
import CampaignsComponent from '~/components/Campaigns';
import Navbar from '~/components/global/organisms/Navbar';
import { CampaignProvider } from '~/hooks/campaigns/campaign';
import { Container } from './styles';

const Campaigns: React.FC = () => {
  return (
    <>
      <CampaignProvider>
        <Navbar title={intl.get('campaign.title_plural')} />
        <Container>
          <CampaignsComponent />
        </Container>
      </CampaignProvider>
    </>
  );
};

export default Campaigns;
