import React from 'react';

import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import ComponentToPrint589 from './ComponentToPrint/589';
import { AttendanceProvider } from '~/hooks/attendances/attendance';
import { Container, Header, Wrapper } from './styles';
import { ParamsProps } from '~/@types/params';

const AttendancePrintCustom: React.FC = () => {
  const { environmentId } = useParams<ParamsProps>();
  document.title = 'ZapForm - Relatório customizado do atendimento';

  const handlePrint = () => {
    window.print();
  };
  return (
    <Wrapper>
      <div className="no-print">
        <Header>
          <Button onClick={() => handlePrint()}>Imprimir</Button>
        </Header>
      </div>
      <Container>
        <AttendanceProvider>
          {environmentId === '589' && <ComponentToPrint589 />}
        </AttendanceProvider>
      </Container>
    </Wrapper>
  );
};

export default AttendancePrintCustom;
