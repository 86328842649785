import { UserAddOutlined, UserOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import {
  Button,
  Tooltip,
  Modal,
  Input,
  Form,
  Avatar,
  Skeleton,
  Alert,
} from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import api from '~/services/api';
import { useContacts } from '~/hooks/contacts/contacts';
import { SelectOptions } from '~/@types/fields';
import PhoneInput from '../PhoneInput';
import clearSpecialCharacters from '~/util/clearSpecialCharacters';
import apiWhatsApp from '~/services/apiWhatsApp';
import { ParamsProps } from '~/@types/params';
import { useEnvironment } from '~/hooks/environments/environment';
import { useDevice } from '~/hooks/device';

interface NewContactProps {
  id: number;
  name: string;
  number: string;
}

interface NewContactModalProps {
  type?: 'text' | 'icon';
  isOpenAfterCreated?: boolean;
  onSetContactsList?: (selectOptions: SelectOptions[]) => void;
}

interface IContactProfile {
  id: {
    server: string;
    user: string;
    _serialized: string;
  };
  pushname?: string;
  avatar?: string;
}

const NewContactModal: React.FC<NewContactModalProps> = ({
  type = 'icon',
  isOpenAfterCreated = true,
  onSetContactsList,
}) => {
  const { environmentId } = useParams<ParamsProps>();
  const { instanceId } = useEnvironment();
  const { device } = useDevice();
  const { refetch: refetchChatContacts } = useContacts();
  const { refetch: refetchContacts } = useContacts();
  const [isVisible, setIsVisible] = useState(false);

  const [avatar, setAvatar] = useState<string | undefined>(undefined);

  const [form] = Form.useForm();

  const history = useHistory();

  const handleOpenModal = () => {
    setIsVisible(true);
  };

  const handleCloseModal = () => {
    setIsVisible(false);
    setAvatar(() => undefined);

    form.resetFields();
  };

  const { mutateAsync: createNewContact, isLoading } = useMutation(
    async (contact: NewContactProps) => {
      const formattedContact: NewContactProps = {
        ...contact,
        ...(avatar && { address: avatar }),
        number: clearSpecialCharacters(contact.number),
      };

      try {
        const response = await api.post(
          `/zc/${environmentId}/client/`,
          formattedContact,
        );
        const newContact: NewContactProps = response.data;

        if (refetchChatContacts) {
          refetchChatContacts();
        }
        if (refetchContacts) {
          refetchContacts();
        }

        toast.success(
          intl.get('contact.create.successfully_created', {
            name: newContact.name,
          }),
        );
        if (isOpenAfterCreated) {
          history.push(`/c/${environmentId}/contacts/${newContact.id}`);
        }

        if (onSetContactsList) {
          const contactsList: SelectOptions[] = [
            {
              name: newContact.name || newContact.number,
              label: newContact.name || newContact.number,
              value: newContact.id,
            },
          ];
          onSetContactsList(contactsList);
        }
        handleCloseModal();
      } catch (error) {
        toast.error(intl.get('contact.create.error_creating'));
      }
    },
  );

  const { mutateAsync: getProfile, isLoading: isLoadingProfile } = useMutation(
    async (phoneNumber: string): Promise<IContactProfile | undefined> => {
      if (!device) return undefined;
      const formattedPhoneNumber = clearSpecialCharacters(phoneNumber);

      try {
        const response = await apiWhatsApp.post<IContactProfile>(
          `/clients/${instanceId}/contacts/profile`,
          {
            phoneNumber: formattedPhoneNumber,
          },
        );

        const { data } = response;

        return data;
      } catch (error) {
        return undefined;
      }
    },
  );

  if (!device) {
    return (
      <Button
        type="text"
        size="large"
        block
        icon={<UserAddOutlined />}
        loading
      />
    );
  }

  return (
    <>
      {type === 'icon' ? (
        <Tooltip title={intl.get('contact.create.title')}>
          <Button
            type="text"
            size="large"
            block
            icon={<UserAddOutlined />}
            onClick={handleOpenModal}
          />
        </Tooltip>
      ) : (
        <Button
          size="large"
          block
          icon={<UserAddOutlined />}
          onClick={handleOpenModal}
        >
          {intl.get('contact.create.title')}
        </Button>
      )}

      <Modal
        title={intl.get('contact.create.title')}
        open={isVisible}
        centered
        footer={null}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        zIndex={9999}
      >
        {device.status !== 'CONNECTED' ? (
          <Alert
            description={intl.get('device.alerts.disconnected_operation.title')}
            type="warning"
            showIcon
          />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={values => createNewContact(values)}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {isLoadingProfile ? (
                <Skeleton.Avatar size={124} />
              ) : avatar ? (
                <Avatar size={124} src={avatar} />
              ) : (
                <Avatar size={124} icon={<UserOutlined />} />
              )}
            </div>

            <Form.Item
              label={intl.get('contact.name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: intl.get('contact.create.name_validation'),
                },
              ]}
            >
              <Input
                placeholder={intl.get('contact.create.name_placeholder')}
              />
            </Form.Item>

            <PhoneInput
              form={form}
              name="number"
              onValidated={async value => {
                if (value) {
                  const profile = await getProfile(value);

                  if (profile) {
                    if (!form.getFieldValue('name')) {
                      form.setFieldValue('name', profile.pushname);
                    }
                    setAvatar(() => profile.avatar);
                  }
                } else {
                  setAvatar(() => undefined);
                }
              }}
            />

            <Button
              type="primary"
              htmlType="submit"
              block
              loading={isLoading}
              size="large"
            >
              {intl.get('contact.create.create_contact')}
            </Button>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default NewContactModal;
