/* eslint-disable import/no-duplicates */
import { format, formatDistance, differenceInHours, isBefore } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';

import { ResponsibleProps } from '~/hooks/responsibles/responsible';
import {
  FiltersProps,
  AttendancesItemProps,
  AttendancesResponseProps,
} from '~/@types/attendances';
import { useWorkflow } from '~/hooks/workflow/workflow';
import { useLocales } from '../locales';
import { useList } from '../workflow/list';
import formatPhoneNumber from '~/util/formatPhoneNumber';

interface AttendancesContextData {
  pageSize: number;
  handleChangePageSize: (value: number) => void;
  attendancesList?: AttendancesItemProps[];
  totalAttendances: number;
  hasMore: boolean;
  moreAttendances: (page?: number, pageSize?: number) => void;
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
}
const AttendancesContext = createContext<AttendancesContextData>(
  {} as AttendancesContextData,
);

interface AttendancesProviderProps {
  contactNumber?: string;
  responsible?: ResponsibleProps;
  filters?: FiltersProps;
  disabledSort?: boolean;
}

const AttendancesProvider: React.FC<AttendancesProviderProps> = ({
  contactNumber,
  responsible,
  filters: filtersExternal,
  disabledSort = false,
  children,
}) => {
  const { currentLocale } = useLocales();
  const { environment, isFetching: isFetchingEnvironment } = useEnvironment();
  const { filters: filtersWorkflow } = useWorkflow();

  const { settings } = useList();

  const filters = filtersExternal || filtersWorkflow || ({} as FiltersProps);

  const [totalAttendances, setTotalAttendances] = useState(0);

  const [pageSize, setPageSize] = useState(20);
  const [limit, setLimit] = useState(pageSize);
  const [hasMore, setHasMore] = useState(false);

  const formatterDate = (value: Date) => {
    return format(value, 'dd/MM/yyyy HH:mm:ss aa', {
      locale: currentLocale === 'pt-BR' ? ptBR : es,
    });
  };

  useEffect(() => {
    setLimit(pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, settings]);

  const formatSorting = (): string => {
    if (!settings.sortBy) {
      return '';
    }
    let result = '';
    result += settings.sortBy.sort === 'asc' ? '' : '-';
    result += settings.sortBy.by;

    return result;
  };

  const { data: attendancesList, isLoading, isFetching, refetch } = useQuery(
    [
      `attendancesList`,
      pageSize,
      limit,
      environment?.id,
      contactNumber,
      responsible,
      settings,
      filters,
      isFetchingEnvironment,
    ],
    async () => {
      if (!environment) {
        return undefined;
      }
      const response = await api.get(`/zc/${environment.id}/order/`, {
        params: {
          offset: limit - pageSize,
          limit: pageSize,
          ...(!disabledSort &&
            settings.sortBy && { order_by: formatSorting() }),
          ...(filters.status && { status: filters.status }),
          ...(filters.attendanceId && { id: filters.attendanceId }),
          ...((filters.contact || contactNumber) && {
            client: filters.contact || contactNumber,
          }),
          ...(filters.responsible && {
            location: filters.responsible,
          }),
          ...(responsible?.name && {
            location_name: responsible?.name,
          }),
          ...(filters.attendance && {
            order: filters.attendance.slice(0, 100),
          }),
          ...(filters.expired === true && {
            expired: true,
          }),
          ...(filters.timeCreated && {
            time_created__gt: filters.timeCreated[0],
            time_created__lte: filters.timeCreated[1],
          }),
          ...(filters.timeStatus && {
            time_status__gt: filters.timeStatus[0],
            time_status__lte: filters.timeStatus[1],
          }),
          ...(filters.timeLastUpdated && {
            time_last_updated__gt: filters.timeLastUpdated[0],
            time_last_updated__lte: filters.timeLastUpdated[1],
          }),
          ...(filters.attendanceIdStart && {
            id__gt: filters.attendanceIdStart,
          }),
          ...(filters.attendanceIdEnd && {
            id__lte: filters.attendanceIdEnd,
          }),
        },
      });

      const { data } = response;

      return formatterListAttendance(data);
    },
  );

  const formatterListAttendance = (
    data: AttendancesResponseProps,
  ): AttendancesItemProps[] => {
    setHasMore(!!data.next);
    setTotalAttendances(data.count);

    const attendancesListResponse = data.results.map(attendance => {
      const howIsTimeout = ():
        | 'timeout'
        | 'insideTime'
        | 'distantTime'
        | undefined => {
        if (!attendance.time_timeout) {
          return undefined;
        }

        if (isBefore(new Date(attendance.time_timeout), new Date())) {
          return 'timeout';
        }

        if (
          differenceInHours(new Date(attendance.time_timeout), new Date()) <= 24
        ) {
          return 'insideTime';
        }

        return 'distantTime';
        // return isBefore(new Date(attendance.time_timeout), new Date())
        //   ? 'timeout'
        //   : differenceInHours(new Date(attendance.time_timeout), new Date()) <=
        //     24
        //     ? 'insideTime'
        //     : 'distantTime';
      };

      const attendanceResponse: AttendancesItemProps = {
        id: attendance.id,
        order_summary: attendance.order_summary,
        time_created: attendance.time_created,
        time_createdFormatted: formatterDate(new Date(attendance.time_created)),
        time_createdThere: formatDistance(
          new Date(attendance.time_created),
          new Date(),
          {
            includeSeconds: true,
            addSuffix: true,
            locale: currentLocale === 'pt-BR' ? ptBR : es,
          },
        ),
        time_status: attendance.time_status,
        time_statusFormatted: formatterDate(new Date(attendance.time_status)),
        time_deadline: attendance.time_timeout,
        time_deadlineFormatted:
          attendance.time_timeout &&
          formatterDate(new Date(attendance.time_timeout)),
        time_howIsTimeout: howIsTimeout(),
        time_timeoutFormatted:
          attendance.time_timeout &&
          formatDistance(new Date(attendance.time_timeout), new Date(), {
            includeSeconds: true,
            addSuffix: true,
            locale: currentLocale === 'pt-BR' ? ptBR : es,
          }),
        responsible: {
          ...attendance.location,
          id_name: `#${attendance.location.id} - ${attendance.location.name}`,
        },
        contact: {
          ...attendance.client,
          number_formatted: formatPhoneNumber(attendance.client.number),
          id_name: `#${attendance.client.id} - ${attendance.client.name}`,
        },
        status: {
          ...attendance.status,
          code_status: `${attendance.status.code} - ${attendance.status.status}`,
        },
      };

      return attendanceResponse;
    });
    return attendancesListResponse;
  };

  const moreAttendances = (page?: number, size?: number) => {
    if (page) {
      setLimit(page * (size || pageSize));
      setPageSize(size || pageSize);
    } else {
      setLimit(limitOld => limitOld + (size || pageSize));
    }
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  return (
    <AttendancesContext.Provider
      value={{
        attendancesList,
        totalAttendances,
        hasMore,
        moreAttendances,
        isLoading,
        pageSize,
        handleChangePageSize,

        isFetching,
        refetch,
      }}
    >
      {children}
    </AttendancesContext.Provider>
  );
};
function useAttendances(): AttendancesContextData {
  const context = useContext(AttendancesContext);

  if (!context) {
    throw new Error(
      'useAttendances must be used within an AttendancesProvider',
    );
  }

  return context;
}

export { AttendancesProvider, useAttendances };
