import React, { createContext, useContext, useState } from 'react';
import { ISortByDTOS } from '../attendances/dtos/ISortByDTOS';

export interface KanbanSettingsProps {
  title?: Array<'contact.id_name' | 'contact.number'>;
  subTitles?: Array<
    | 'responsible.name'
    | 'responsible.address'
    | 'attendance.order_summary'
    | 'attendance.time_created'
    | 'attendance.time_createdThere'
    | 'attendance.time_status'
    | 'attendance.time_deadline'
    | 'attendance.time_timeout'
  >;
  sortBy?: ISortByDTOS;
}

interface KanbanContextData {
  settings: KanbanSettingsProps;
  quickSearch?: string;
  setNewSettings: (newSettingsInput: KanbanSettingsProps) => void;
  resetSettings: () => void;
  columnCollapsed: Array<string>;
  addColumnCollapsed: (newColumnCollapsed: string) => void;
  removeColumnCollapsed: (columnCollapsedRemoved: string) => void;
  setNewQuickSearch: (newQuickSearch: string) => void;
}
const KanbanContext = createContext<KanbanContextData>({} as KanbanContextData);

const KanbanProvider: React.FC = ({ children }) => {
  const defaultSettings: KanbanSettingsProps = {
    title: [],
    subTitles: [
      'responsible.name',
      'attendance.time_createdThere',
      'attendance.time_timeout',
      'attendance.order_summary',
    ],
    sortBy: { sort: 'desc', by: 'id' },
  };

  const [quickSearch, setQuickSearch] = useState<string>('');

  const [settings, setSettings] = useState<KanbanSettingsProps>(() => {
    const settingsLocalStorage = localStorage.getItem(
      '@ZapForm:workflowKanbanSettings',
    );

    if (settingsLocalStorage) {
      const formattedSettings: KanbanSettingsProps = JSON.parse(
        settingsLocalStorage,
      );

      if (formattedSettings.sortBy) {
        return formattedSettings;
      }
    }

    localStorage.setItem(
      '@ZapForm:workflowKanbanSettings',
      JSON.stringify(defaultSettings),
    );

    return defaultSettings;
  });

  const [columnCollapsed, setColumnCollapsed] = useState<Array<string>>(() => {
    const columnCollapsedLocalStorage = localStorage.getItem(
      '@ZapForm:workflowKanbanCollapsedColumn',
    );

    if (columnCollapsedLocalStorage) {
      return JSON.parse(columnCollapsedLocalStorage);
    }

    localStorage.setItem(
      '@ZapForm:workflowKanbanCollapsedColumn',
      JSON.stringify([]),
    );

    return [];
  });

  const setNewQuickSearch = (newQuickSearch: string) => {
    setQuickSearch(newQuickSearch);
  };

  const setNewSettings = (newSettingsInput: KanbanSettingsProps) => {
    localStorage.setItem(
      '@ZapForm:workflowKanbanSettings',
      JSON.stringify(newSettingsInput),
    );
    setSettings(() => ({ ...newSettingsInput }));
  };

  const resetSettings = () => {
    localStorage.setItem(
      '@ZapForm:workflowKanbanSettings',
      JSON.stringify(defaultSettings),
    );
    setSettings(defaultSettings);
  };

  const addColumnCollapsed = (newColumnCollapsed: string) => {
    localStorage.setItem(
      '@ZapForm:workflowKanbanCollapsedColumn',
      JSON.stringify([...columnCollapsed, newColumnCollapsed]),
    );

    setColumnCollapsed(columnCollapsedOld => [
      ...columnCollapsedOld,
      newColumnCollapsed,
    ]);
  };

  const removeColumnCollapsed = (columnCollapsedRemoved: string) => {
    const newColumnCollapsed = columnCollapsed.filter(
      list => list !== columnCollapsedRemoved,
    );

    localStorage.setItem(
      '@ZapForm:workflowKanbanCollapsedColumn',
      JSON.stringify(newColumnCollapsed),
    );

    setColumnCollapsed(newColumnCollapsed);
  };

  return (
    <KanbanContext.Provider
      value={{
        settings,
        setNewSettings,
        resetSettings,
        addColumnCollapsed,
        removeColumnCollapsed,
        columnCollapsed,
        quickSearch,
        setNewQuickSearch,
      }}
    >
      {children}
    </KanbanContext.Provider>
  );
};
function useKanban(): KanbanContextData {
  const context = useContext(KanbanContext);

  if (!context) {
    throw new Error('useKanban must be used within an KanbanProvider');
  }

  return context;
}

export { KanbanProvider, useKanban };
