import { Button, Steps } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

import { useHistory, useParams } from 'react-router-dom';

import apiWhatsApp from '~/services/apiWhatsApp';

import { useEnvironment } from '~/hooks/environments/environment';
import { ParamsProps } from '~/@types/params';
import Navbar from '~/components/global/organisms/Navbar';
import { Container, Wrapper } from './styles';
import One from './steps/one';
import Two from './steps/two';

export type ICategory = 'AUTHENTICATION' | 'MARKETING' | 'UTILITY';
export type ILanguage = 'pt_BR' | 'es' | 'en';

interface INewMessageTemplateToSend {
  name: string;
  category: ICategory;
  language: ILanguage;
  components: Array<{
    type: 'HEADER' | 'BODY' | 'FOOTER';
    text: string;
    format?: 'TEXT';
  }>;
}

export interface INewMessageTemplate {
  name: string;
  category: ICategory;
  language: ILanguage;
  components: {
    header?: string;
    body: string;
    footer?: string;
  };
}

const NewMessageTemplate: React.FC = () => {
  return (
    <>
      <Navbar title={intl.get('message_templates.title')} />

      <NewMessageTemplateRender />
    </>
  );
};

const steps = [
  {
    title: 'Definição de informações',
  },
  {
    title: 'Mensagem',
  },
];

const NewMessageTemplateRender: React.FC = () => {
  const { environmentId } = useParams<ParamsProps>();
  const { instanceId } = useEnvironment();

  const history = useHistory();

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map(item => ({ key: item.title, title: item.title }));

  const createNewMessageTemplate = useMutation(
    async (messageTemplate: INewMessageTemplateToSend): Promise<void> => {
      try {
        await apiWhatsApp.post(
          `/clients/${instanceId}/templates`,
          messageTemplate,
        );

        history.push(`/c/${environmentId}/messagetemplates`);
      } catch (error) {
        toast.error('Erro ao criar');
      }
    },
  );

  const [
    newMessageTemplate,
    setNewMessageTemplate,
  ] = useState<INewMessageTemplate>({
    category: 'UTILITY',
    language: 'pt_BR',
  } as INewMessageTemplate);

  const stepRender = (value: number) => {
    switch (value) {
      case 0:
        return (
          <One
            data={newMessageTemplate}
            onChangeCategory={categoryValue =>
              setNewMessageTemplate(oldValue => {
                return { ...oldValue, category: categoryValue };
              })
            }
            onChangeName={nameValue =>
              setNewMessageTemplate(oldValue => {
                return { ...oldValue, name: nameValue };
              })
            }
            onChangeLanguage={languageValue =>
              setNewMessageTemplate(oldValue => {
                return { ...oldValue, language: languageValue };
              })
            }
          />
        );
      default:
        return (
          <Two
            data={newMessageTemplate}
            onChangeHeader={headerValue =>
              setNewMessageTemplate(oldValue => {
                return {
                  ...oldValue,
                  components: { ...oldValue.components, header: headerValue },
                };
              })
            }
            onChangeBody={bodyValue =>
              setNewMessageTemplate(oldValue => {
                return {
                  ...oldValue,
                  components: { ...oldValue.components, body: bodyValue },
                };
              })
            }
            onChangeFooter={footerValue =>
              setNewMessageTemplate(oldValue => {
                return {
                  ...oldValue,
                  components: { ...oldValue.components, footer: footerValue },
                };
              })
            }
          />
        );
    }
  };

  const onFinish = () => {
    const formattedNewMessageTemplate: INewMessageTemplateToSend = {
      ...newMessageTemplate,
      components: [],
    };

    if (newMessageTemplate.components.header) {
      formattedNewMessageTemplate.components.push({
        type: 'HEADER',
        format: 'TEXT',
        text: newMessageTemplate.components.header,
      });
    }

    if (newMessageTemplate.components.footer) {
      formattedNewMessageTemplate.components.push({
        type: 'FOOTER',
        text: newMessageTemplate.components.footer,
      });
    }

    formattedNewMessageTemplate.components.push({
      type: 'BODY',
      text: newMessageTemplate.components.body,
    });

    createNewMessageTemplate.mutateAsync(formattedNewMessageTemplate);
  };

  return (
    <Wrapper>
      <Steps current={current} items={items} />

      <Container>
        {stepRender(current)}
        <div style={{ marginTop: 24 }}>
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Voltar
            </Button>
          )}
          {current === 0 &&
            newMessageTemplate.name &&
            newMessageTemplate.language && (
              <Button type="primary" size="large" onClick={() => next()}>
                Avançar
              </Button>
            )}
          {current === 1 && newMessageTemplate.components.body && (
            <Button
              type="primary"
              size="large"
              onClick={() => onFinish()}
              loading={createNewMessageTemplate.isLoading}
            >
              Concluir
            </Button>
          )}
        </div>
      </Container>
    </Wrapper>
  );
};

export default NewMessageTemplate;
