import { Button, Modal, Result, Tooltip } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import QRCode from 'qrcode.react';
import { MobileOutlined } from '@ant-design/icons';

interface QRCodeModalProps {
  data: {
    type: 'attendance' | 'contact';
    value: number;
  };
}
const QRCodeModal: React.FC<QRCodeModalProps> = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleShowModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title={intl.get('chat.view_on_cell_phone.title')}>
        <Button
          type="text"
          size="large"
          onClick={() => {
            handleShowModal();
          }}
          icon={<MobileOutlined />}
        />
        <Modal
          title={intl.get('chat.view_on_cell_phone.title')}
          open={isModalVisible}
          onOk={handleCloseModal}
          onCancel={handleCloseModal}
          footer={null}
        >
          <Result
            icon={
              <div style={{ textAlign: 'center' }}>
                <QRCode
                  value={JSON.stringify(data)}
                  renderAs="svg"
                  size={250}
                />
              </div>
            }
            subTitle={
              <>{intl.getHTML('chat.view_on_cell_phone.description')}</>
            }
          />
        </Modal>
      </Tooltip>
    </>
  );
};

export default QRCodeModal;
