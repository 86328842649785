import { LoadingOutlined } from '@ant-design/icons';
import { Input, Modal, Skeleton, Typography } from 'antd';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import intl from 'react-intl-universal';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  ContactProps,
  ContactResponseProps,
  useContact,
} from '~/hooks/contacts/contact';
import { useEnvironment } from '~/hooks/environments/environment';
import api from '~/services/api';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import formatPhoneNumber from '~/util/formatPhoneNumber';
import { stringToHslColor } from '~/util/stringToHslColor';
import { Avatar } from '../../Avatar';
import { Item, List } from './styles';

interface ModalForwardProps {
  handleClose: () => void;

  messageId: number;
}

const ModalForward: React.FC<ModalForwardProps> = ({
  handleClose,
  messageId,
}) => {
  const { environment } = useEnvironment();
  const { contact } = useContact();
  const limitDefault = 10;
  const [limit, setLimit] = useState(limitDefault);
  const [hasMore, setHasMore] = useState(false);
  const [quickSearch, setQuickSearch] = useState<string | null>(null);
  const [contacts, setContacts] = useState<ContactProps[]>([]);
  const { isLoading } = useQuery(
    [`contactsModalForward`, limit, environment?.id, quickSearch],
    async () => {
      if (!environment) return;
      const { data } = await api.get(`/zc/${environment.id}/client/`, {
        params: {
          offset: 0,
          limit,
          ...(quickSearch
            ? quickSearch.match(/\d/) != null
              ? { number__icontains: quickSearch }
              : { name__icontains: quickSearch }
            : {}),
        },
      });
      setHasMore(!!data.next);
      const formattedContacts = await formatterContacts(data.results);
      setContacts(formattedContacts);
    },
  );

  const moreContacts = () => setLimit(limitOld => limitOld + limitDefault);

  const forwardMessage = async (destinationContactId: number) => {
    if (!environment || !contact) return;
    handleClose();
    try {
      await api.post(
        `/zc/${environment.id}/client/${contact.id}/message/${messageId}/forward/`,
        {
          destination_client_id: destinationContactId,
        },
      );
      toast.success(intl.get('broadcast_messages.messages_sent_successfully'));
    } catch (error) {
      toast.success(
        intl.get('broadcast_messages.error_messages_sent_successfully'),
      );
    }
  };

  if (isLoading && contacts.length === 0 && !contact) {
    return (
      <Modal
        title={intl.get('message.title_forward')}
        open
        centered
        footer={[]}
      >
        <Skeleton />
      </Modal>
    );
  }

  return (
    <Modal
      title={intl.get('message.title_forward')}
      open
      onOk={handleClose}
      onCancel={handleClose}
      footer={[]}
      centered
    >
      <Input.Search
        allowClear
        onSearch={setQuickSearch}
        onChange={e => {
          setQuickSearch(e.target.value);
        }}
        placeholder={intl.get('contact.filters.search_contact_placeholder')}
      />

      <List id="scrollableDivList">
        <InfiniteScroll
          dataLength={contacts.length}
          next={moreContacts}
          hasMore={hasMore}
          loader={
            <div
              style={{
                margin: '1rem auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            </div>
          }
          scrollableTarget="scrollableDivList"
          style={{ overflow: 'none' }}
        >
          {contacts.map(contactItem => (
            <Item
              key={contactItem.id}
              onClick={() => forwardMessage(contactItem.id)}
            >
              <div className="avatar">
                {contactItem.address === '' ? (
                  <Avatar
                    size={48}
                    style={{
                      backgroundColor: stringToHslColor(contactItem.name),
                    }}
                  >
                    {createLetterAvatar(contactItem.name)}
                  </Avatar>
                ) : (
                  <Avatar size={48} src={contactItem.address} />
                )}
              </div>
              <div className="content">
                <Typography.Text>
                  {contactItem.name || contactItem.number_formatted}
                </Typography.Text>
                <div>
                  <Typography.Text type="secondary">
                    {contactItem.number_formatted}
                  </Typography.Text>
                </div>
              </div>
            </Item>
          ))}
        </InfiniteScroll>
      </List>
    </Modal>
  );
};

export default ModalForward;

const formatterContacts = async (
  contactsRaw: ContactResponseProps[],
): Promise<ContactProps[]> => {
  const contacts = await Promise.all(
    contactsRaw.map(async contactRaw => {
      return {
        ...contactRaw,
        number_formatted: formatPhoneNumber(contactRaw.number),
        id_name: `#${contactRaw.id} - ${contactRaw.name}`,
      } as ContactProps;
    }),
  );
  return contacts;
};
