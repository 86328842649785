import React from 'react';
import BotFormComponent from '~/components/BotForm';

import { BotFormProvider } from '~/hooks/bot/botForm';

const BotForm: React.FC = () => {
  return (
    <BotFormProvider>
      <BotFormComponent />
    </BotFormProvider>
  );
};

export default BotForm;
