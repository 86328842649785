import React from 'react';

import Settings from '../../molecules/Settings';
import Filters from '../../molecules/Filters';
import { Container, LeftBar, RightBar } from './styles';
import SortByKanban from '~/components/Workflow/molecules/SortBy/kanban';

import { CounterTotalFiltered } from '~/components/Workflow/molecules/CounterTotalFiltered';

const Panel: React.FC = () => {
  return (
    <Container>
      <LeftBar>
        <CounterTotalFiltered />
      </LeftBar>
      <RightBar>
        <SortByKanban />
        <Filters />
        <Settings />
      </RightBar>
    </Container>
  );
};

export default Panel;
