import { Card, Input, Tag, Typography } from 'antd';
import React from 'react';
import { INewMessageTemplate } from '..';

const { Paragraph } = Typography;

interface ITwoProps {
  data: INewMessageTemplate;
  onChangeHeader: (value: string) => void;
  onChangeBody: (value: string) => void;
  onChangeFooter: (value: string) => void;
}

const Two: React.FC<ITwoProps> = ({
  data,
  onChangeHeader,
  onChangeBody,
  onChangeFooter,
}) => {
  return (
    <>
      <Card
        title={
          <>
            Cabeçalho <Tag>Opcional</Tag>
          </>
        }
      >
        <Paragraph type="secondary">
          Insira o texto que você usará para esse cabeçalho.
        </Paragraph>
        <Input
          maxLength={60}
          showCount
          placeholder="Insira o texto"
          value={data?.components?.header ? data.components.header : ''}
          onChange={e => onChangeHeader(e.target.value)}
        />
      </Card>
      <Card title="Corpo">
        <Paragraph type="secondary">
          Insira o texto do seu modelo de mensagem.
        </Paragraph>
        <Input.TextArea
          maxLength={1024}
          placeholder="Insira o texto"
          showCount
          rows={6}
          value={data?.components?.body ? data.components.body : undefined}
          onChange={e => onChangeBody(e.target.value)}
        />
      </Card>
      <Card
        title={
          <>
            Rodapé <Tag>Opcional</Tag>
          </>
        }
      >
        <Paragraph type="secondary">
          Adicione uma pequena linha de texto na parte inferior do seu modelo de
          mensagem.
        </Paragraph>
        <Input
          maxLength={60}
          showCount
          placeholder="Insira o texto"
          value={data?.components?.footer ? data.components.footer : undefined}
          onChange={e => onChangeFooter(e.target.value)}
        />
      </Card>
    </>
  );
};

export default Two;
