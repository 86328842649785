import React from 'react';

import { Badge, Button, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  MobileOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useEnvironment } from '~/hooks/environments/environment';
import { useDevice } from '~/hooks/device';
import { useUser } from '~/hooks/user';
import { ParamsProps } from '~/@types/params';

const DeviceStatus: React.FC = () => {
  const { environmentId } = useParams<ParamsProps>();
  const { isLoading: isLoadingEnvironment, environment } = useEnvironment();
  const { device, isLoading } = useDevice();
  const { user } = useUser();

  const history = useHistory();

  if (isLoadingEnvironment || !environment || isLoading || !device || !user) {
    return <Button loading />;
  }

  const goDevicePage = () => {
    if (!user || !user.apps.includes('zapchamado.generate_qr')) {
      return;
    }

    history.push(`/c/${environmentId}/device`);
  };

  const canManage = user.apps.includes('zapchamado.generate_qr');

  if (device.status === 'QRCODE') {
    return (
      <Tooltip
        title={
          canManage
            ? intl.get('device.alerts.qrcode.can_manage.title')
            : intl.get('device.alerts.qrcode.title')
        }
        defaultVisible
      >
        <Button
          icon={
            <Badge
              count={
                <WarningTwoTone
                  twoToneColor="#F56526"
                  style={{ margin: '3px 2px' }}
                />
              }
            >
              <MobileOutlined />
            </Badge>
          }
          style={{ lineHeight: 0 }}
          onClick={() => goDevicePage()}
        />
      </Tooltip>
    );
  }

  if (device.status === 'CONNECTED') {
    return (
      <Tooltip title={intl.get('device.alerts.connected.title')}>
        <Button
          icon={
            <Badge
              count={
                <CheckCircleTwoTone
                  twoToneColor="#63BC2E"
                  style={{ margin: '3px 2px' }}
                />
              }
            >
              <MobileOutlined />
            </Badge>
          }
          ghost
          style={{ lineHeight: 0 }}
          onClick={() => goDevicePage()}
        />
      </Tooltip>
    );
  }

  if (device.status === 'OPENING') {
    return (
      <Tooltip
        title={intl.get('device.alerts.opening.title')}
        style={{ margin: '3px 2px' }}
      >
        <Button
          icon={
            <Badge count={<ClockCircleTwoTone twoToneColor="blue" />}>
              <MobileOutlined />
            </Badge>
          }
          color="blue"
          style={{ lineHeight: 0 }}
          onClick={() => goDevicePage()}
        />
      </Tooltip>
    );
  }

  if (device.status === 'PAIRING') {
    return (
      <Tooltip title="Emparelhando, aguarde..." style={{ margin: '3px 2px' }}>
        <Button
          icon={
            <Badge count={<ClockCircleTwoTone twoToneColor="blue" />}>
              <MobileOutlined />
            </Badge>
          }
          color="blue"
          style={{ lineHeight: 0 }}
          onClick={() => goDevicePage()}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={
        canManage
          ? intl.get('device.alerts.opening.can_manage.title')
          : intl.get('device.alerts.opening.title')
      }
    >
      <Button
        icon={
          <Badge status="error">
            <MobileOutlined />
          </Badge>
        }
        style={{ lineHeight: 0 }}
        onClick={() => goDevicePage()}
      />
    </Tooltip>
  );
};

export default DeviceStatus;
