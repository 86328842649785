import { Table, Tooltip, Tag, Pagination } from 'antd';
import intl from 'react-intl-universal';
import React, { memo, useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useAttendances } from '~/hooks/attendances/attendances';

import ModalAttendance from '~/components/global/organisms/ModalAttendance';
import { AttendanceProvider } from '~/hooks/attendances/attendance';
import { useList } from '~/hooks/workflow/list';

import { Container } from './styles';

import { useWorkflow } from '~/hooks/workflow/workflow';
import { AttendancesItemProps } from '~/@types/attendances';
import { ParamsProps } from '~/@types/params';
import { StatusProps } from '~/hooks/status/status';

const { Column, ColumnGroup } = Table;
interface AttendancesTableProps {
  isWorkflowList?: boolean;
}

const AttendancesTable: React.FC<AttendancesTableProps> = ({
  isWorkflowList = false,
}) => {
  const { environmentId } = useParams<ParamsProps>();

  const { filters, selectedCards, setListSelectedCards } = useWorkflow();
  const { settings } = useList();

  const {
    attendancesList,
    moreAttendances,
    isLoading,
    totalAttendances,
    pageSize,
  } = useAttendances();

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [attendanceId, setAttendanceId] = useState<number | null>(null);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const handleSetAttendance = (newAttendanceId: number) => {
    if (isWorkflowList) {
      history.push(`/c/${environmentId}/workflow/${newAttendanceId}`);
      return;
    }
    setAttendanceId(newAttendanceId);
  };

  const handleCloseModalAttendance = () => {
    setAttendanceId(null);
  };

  return (
    <Container>
      <Table
        size="middle"
        scroll={{
          ...(settings.columns && { x: settings.columns.length * 300 }),
          y: 'calc(100vh - 320px)',
        }}
        pagination={false}
        rowSelection={
          isWorkflowList
            ? {
                selectedRowKeys: selectedCards,
                onChange: selectedRowKeys => {
                  const selecteds: Array<number> = Object.values(
                    selectedRowKeys,
                  ).map(id => Number(id));
                  setListSelectedCards(selecteds);
                },
              }
            : undefined
        }
        bordered
        dataSource={attendancesList}
        rowKey="id"
        loading={isLoading}
        onRow={row => {
          return {
            onClick: () => {
              handleSetAttendance(row.id);
            },
          };
        }}
      >
        <Column title="ID" dataIndex="id" key="id" fixed width={70} ellipsis />
        <Column
          title={intl.get('attendance.status.title')}
          dataIndex={['status']}
          key="status"
          width={200}
          ellipsis={{ showTitle: false }}
          render={(status: StatusProps) => (
            <Tooltip placement="topLeft" title={status.status}>
              <Tag
                color={{ O: 'blue', C: 'green', X: 'red' }[status.status_type]}
              >
                {status.status}
              </Tag>
            </Tooltip>
          )}
        />
        {settings.columns?.includes('attendance.order_summary') && (
          <Column
            title={intl.get('attendance.order_summary')}
            dataIndex="order_summary"
            key="orderSummary"
            width={250}
            ellipsis={{ showTitle: false }}
            render={orderSummary => (
              <Tooltip placement="topLeft" title={orderSummary}>
                {orderSummary}
              </Tooltip>
            )}
          />
        )}
        {(settings.columns?.includes('contact.name') ||
          settings.columns?.includes('contact.number')) && (
          <ColumnGroup title={intl.get('contact.title')}>
            <>
              {settings.columns?.includes('contact.name') && (
                <Column
                  title={intl.get('contact.name')}
                  dataIndex={['contact', 'name']}
                  key="name"
                  width={200}
                  render={name => (
                    <Tooltip placement="topLeft" title={name}>
                      {name}
                    </Tooltip>
                  )}
                />
              )}

              {settings.columns?.includes('contact.number') && (
                <Column
                  title={intl.get('contact.number')}
                  dataIndex={['contact', 'number']}
                  key="number"
                  width={200}
                  render={number => (
                    <Tooltip placement="topLeft" title={number}>
                      {number}
                    </Tooltip>
                  )}
                />
              )}
            </>
          </ColumnGroup>
        )}
        {(settings.columns?.includes('responsible.name') ||
          settings.columns?.includes('responsible.address')) && (
          <ColumnGroup title={intl.get('responsible.title')}>
            <>
              {settings.columns?.includes('responsible.name') && (
                <Column
                  title={intl.get('responsible.name')}
                  dataIndex={['responsible', 'name']}
                  key="name"
                  width={200}
                  render={name => (
                    <Tooltip placement="topLeft" title={name}>
                      {name}
                    </Tooltip>
                  )}
                />
              )}

              {settings.columns?.includes('responsible.address') && (
                <Column
                  title={intl.get('responsible.address')}
                  dataIndex={['responsible', 'address']}
                  key="address"
                  width={200}
                  render={address => (
                    <Tooltip placement="topLeft" title={address}>
                      {address}
                    </Tooltip>
                  )}
                />
              )}
            </>
          </ColumnGroup>
        )}

        {settings.columns?.includes('attendance.time_created') && (
          <Column
            title={intl.get('attendance.creation_date')}
            dataIndex="time_createdFormatted"
            key="timeCreated"
            width={200}
            render={timeCreated => (
              <Tooltip placement="topLeft" title={timeCreated}>
                {timeCreated}
              </Tooltip>
            )}
          />
        )}

        {settings.columns?.includes('attendance.time_createdThere') && (
          <Column
            title={intl.get('attendance.creation_date_there')}
            dataIndex="time_createdThere"
            key="timeCreatedThere"
            width={200}
            render={timeCreatedThere => (
              <Tooltip placement="topLeft" title={timeCreatedThere}>
                {timeCreatedThere}
              </Tooltip>
            )}
          />
        )}

        {settings.columns?.includes('attendance.time_status') && (
          <Column
            title={intl.get('attendance.date_of_status_change')}
            dataIndex="time_statusFormatted"
            key="timeStatus"
            width={200}
            render={timeStatus => (
              <Tooltip placement="topLeft" title={timeStatus}>
                {timeStatus}
              </Tooltip>
            )}
          />
        )}

        {settings.columns?.includes('attendance.time_deadline') && (
          <Column
            title={intl.get('attendance.deadline')}
            dataIndex="time_deadlineFormatted"
            key="timeDeadLine"
            width={200}
            render={timeDeadLine => (
              <Tooltip placement="topLeft" title={timeDeadLine}>
                {timeDeadLine || intl.get('attendance.no_deadline')}
              </Tooltip>
            )}
          />
        )}

        {settings.columns?.includes('attendance.time_timeout') && (
          <Column
            title={intl.get('attendance.deadline_expiration')}
            dataIndex="time_timeoutFormatted"
            key="timeTimeout"
            width={110}
            fixed="right"
            render={(timeTimeout, attendance: AttendancesItemProps) => {
              return (
                <Tooltip placement="topLeft" title={timeTimeout}>
                  <div
                    style={{
                      color:
                        attendance.time_howIsTimeout === 'timeout'
                          ? 'red'
                          : attendance.time_howIsTimeout === 'insideTime'
                          ? '#faad14'
                          : 'none',
                    }}
                  >
                    {timeTimeout || intl.get('attendance.no_deadline')}
                  </div>
                </Tooltip>
              );
            }}
          />
        )}
      </Table>

      <Pagination
        current={currentPage}
        onChange={(page: number, size?: number) => {
          setCurrentPage(page);
          moreAttendances(page, size || pageSize);
        }}
        pageSize={pageSize}
        showSizeChanger
        total={totalAttendances}
      />

      {!isWorkflowList && attendanceId && (
        <AttendanceProvider attendanceId={attendanceId}>
          <ModalAttendance
            isChildren
            onCloseModalAttendance={handleCloseModalAttendance}
          />
        </AttendanceProvider>
      )}
    </Container>
  );
};

export default memo(AttendancesTable, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
