import React, { memo, useEffect, useState } from 'react';
import { Divider, Skeleton, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { createLetterAvatar } from '~/util/createLetterAvatar';
import { stringToHslColor } from '~/util/stringToHslColor';

import { ContactProps } from '~/hooks/contacts/contacts';
import { Container } from './styles';
import UnreadMessages from '~/components/global/atoms/UnreadMessages';
import { useWhatsAppWeb } from '~/hooks/contacts/whatsAppWeb';

import { ParamsProps } from '~/@types/params';
import { Avatar } from '~/components/global/atoms/Avatar';
import { useLastMessage } from '~/hooks/messages/lastMessage';

interface ChatItemProps {
  data: ContactProps;
  autoScroll?: boolean;
}

const { Text, Paragraph } = Typography;
const ChatItem: React.FC<ChatItemProps> = ({ data, autoScroll = true }) => {
  const { environmentId } = useParams<ParamsProps>();
  const history = useHistory();

  const { contactId } = useParams<ParamsProps>();
  const { lastMessage, isLoading: isLoadingLastMessage } = useLastMessage();
  const { settings } = useWhatsAppWeb();

  const ref = React.createRef<HTMLDivElement>();

  const [isFound, setIsFound] = useState(false);
  useEffect(() => {
    if (
      !isFound &&
      autoScroll &&
      contactId &&
      contactId === String(data.id) &&
      ref &&
      ref.current
    ) {
      setIsFound(true);
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  return (
    <Container
      isActived={!!(data.id === Number(contactId))}
      ref={ref}
      onClick={() => history.push(`/c/${environmentId}/contacts/${data.id}`)}
    >
      <div className="title">
        {settings.title?.includes('contact.avatar') && (
          <>
            {data.address === '' ? (
              <Avatar
                size={48}
                style={{
                  backgroundColor: stringToHslColor(data.name),
                }}
              >
                {createLetterAvatar(data.name)}
              </Avatar>
            ) : (
              <Avatar size={48} src={data.address} />
            )}
          </>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            minWidth: '130px',
          }}
        >
          <div>
            <Text>
              {settings.title?.includes('contact.id_name')
                ? data.id_name
                : data.name}
            </Text>
          </div>
          <div>
            <Text>
              {settings.title?.includes('contact.number') &&
                data.number_formatted}
            </Text>
          </div>
          {isLoadingLastMessage ? (
            <Skeleton.Input size="small" />
          ) : (
            <>
              {lastMessage ? (
                <Paragraph ellipsis>{lastMessage.formatted}</Paragraph>
              ) : null}
            </>
          )}
        </div>

        <div
          style={{
            flex: '0 0 40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <UnreadMessages contactId={data.id} />

          {lastMessage ? lastMessage.formattedDate : null}
        </div>
      </div>

      <div className="sub-titles">{/* CONTACT */}</div>
      <Divider style={{ margin: '0.5rem 0 0 0' }} />
    </Container>
  );
};

export default memo(ChatItem, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
