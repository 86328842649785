import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Select,
  Switch,
  Tooltip,
} from 'antd';
import intl from 'react-intl-universal';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { CloseOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useResponsibles } from '~/hooks/responsibles/responsibles';

import isEmpty from '~/util/isEmpty';
import { useWorkflow } from '~/hooks/workflow/workflow';
import { FiltersProps } from '~/@types/attendances';
import { useStatus } from '~/hooks/status/status';
import { SelectOptions } from '~/@types/fields';
import clearSpecialCharacters from '~/util/clearSpecialCharacters';
import apiWhatsApp from '~/services/apiWhatsApp';
import { useEnvironment } from '~/hooks/environments/environment';

const Filters: React.FC = () => {
  const [form] = Form.useForm();
  const { instanceId } = useEnvironment();
  const {
    isLoading: isLoadingResponsiblesList,
    responsiblesList,
  } = useResponsibles();

  const { statusList } = useStatus();

  const { filters, setNewFilters, cleanFilters } = useWorkflow();

  const [isVisible, setIsVisible] = useState(false);

  const [debounceFilters, setDebounceFilters] = useState({});

  const getFiltersValues = useCallback(() => {
    form.setFieldsValue({
      ...filters,
      ...(filters.timeCreated && {
        timeCreated: [
          dayjs(filters.timeCreated[0], 'YYYY-MM-DD'),
          dayjs(filters.timeCreated[1], 'YYYY-MM-DD'),
        ],
      }),
      ...(filters.timeLastUpdated && {
        timeLastUpdated: [
          dayjs(filters.timeLastUpdated[0], 'YYYY-MM-DD'),
          dayjs(filters.timeLastUpdated[1], 'YYYY-MM-DD'),
        ],
      }),
      ...(filters.timeStatus && {
        timeStatus: [
          dayjs(filters.timeStatus[0], 'YYYY-MM-DD'),
          dayjs(filters.timeStatus[1], 'YYYY-MM-DD'),
        ],
      }),
    });
  }, [filters, form]);

  useEffect(() => {
    getFiltersValues();
  }, [filters, form, getFiltersValues, isVisible]);

  const handleOpenDrawer = () => {
    setIsVisible(true);
  };

  const handleCloseDrawer = () => {
    form.resetFields();
    setIsVisible(false);
  };

  const handleCleanFilters = () => {
    form.resetFields();
    cleanFilters();
  };

  const validatePhoneNumber = async (
    phoneNumber: string,
  ): Promise<string | undefined> => {
    const formattedPhoneNumber = clearSpecialCharacters(phoneNumber);
    try {
      const response = await apiWhatsApp.post(
        `/clients/${instanceId}/validatePhoneNumber`,
        {
          phoneNumber: formattedPhoneNumber,
        },
      );
      return response.data.user;
    } catch (error) {
      return undefined;
    }
  };

  const onFieldsChange = async (values: FiltersProps) => {
    const newFilters = values;
    const { contact } = newFilters;
    if (contact) {
      if (/\d{8,}/.test(contact)) {
        const phoneNumber = contact.replace(/[^0-9]/g, '');
        const validatedPhoneNumber = await validatePhoneNumber(phoneNumber);
        if (validatedPhoneNumber) {
          newFilters.contact = validatedPhoneNumber;
        }
      }
    }
    const formattedNewFilters = {
      ...newFilters,
      ...(newFilters.timeCreated && {
        timeCreated: [
          dayjs.utc(newFilters.timeCreated[0]).startOf('day').toISOString(),
          dayjs.utc(newFilters.timeCreated[1]).endOf('day').toISOString(),
        ],
      }),
      ...(newFilters.timeLastUpdated && {
        timeLastUpdated: [
          dayjs.utc(newFilters.timeLastUpdated[0]).startOf('day').toISOString(),
          dayjs.utc(newFilters.timeLastUpdated[1]).endOf('day').toISOString(),
        ],
      }),
      ...(newFilters.timeStatus && {
        timeStatus: [
          dayjs.utc(newFilters.timeStatus[0]).startOf('day').toISOString(),
          dayjs.utc(newFilters.timeStatus[1]).endOf('day').toISOString(),
        ],
      }),
    };

    setNewFilters(formattedNewFilters);
  };

  if (!statusList || isLoadingResponsiblesList || !responsiblesList) {
    return (
      <div>
        <Button type="text" size="large" loading />
      </div>
    );
  }

  const formattedResponsiblesList: SelectOptions[] = responsiblesList.map(
    responsible => ({
      name: responsible.name,
      label: responsible.name,
      value: responsible.id,
    }),
  );

  const formattedFilterAttendance = () => {
    if (
      filters.attendance &&
      typeof filters.attendance === 'string' &&
      filters.attendance.includes('OptionList')
    ) {
      const [, value, ...options] = filters.attendance.split(';');
      return (
        <Form.Item
          label={intl.get('attendance.filters.search_in_the_attendance')}
        >
          <Input
            placeholder={intl.get(
              'attendance.filters.search_in_the_attendance_placeholder',
            )}
            value={options[Number(value)]}
            disabled
          />
        </Form.Item>
      );
    }
    return (
      <Form.Item
        label={intl.get('attendance.filters.search_in_the_attendance')}
        name="attendance"
      >
        <Input
          placeholder={intl.get(
            'attendance.filters.search_in_the_attendance_placeholder',
          )}
        />
      </Form.Item>
    );
  };

  return (
    <div>
      {isEmpty(filters) ? (
        <Tooltip title={intl.get('workflow.list.filters.title')}>
          <Button
            type="text"
            size="large"
            icon={<FilterOutlined />}
            onClick={handleOpenDrawer}
          />
        </Tooltip>
      ) : (
        <>
          <Tooltip title={intl.get('workflow.kanban.filters.actived')}>
            <Button
              type="primary"
              size="middle"
              danger
              icon={<FilterFilled />}
              onClick={handleOpenDrawer}
            />
          </Tooltip>
          <Tooltip title={intl.get('buttons.clean_filters')}>
            <Button
              type="link"
              size="small"
              danger
              icon={<CloseOutlined />}
              onClick={() => handleCleanFilters()}
              style={{ marginLeft: '2px' }}
            />
          </Tooltip>
        </>
      )}
      <Drawer
        title={intl.get('workflow.list.filters.title')}
        width="40%"
        placement="right"
        closable
        onClose={handleCloseDrawer}
        open={isVisible}
        forceRender
        footer={
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            block
            onClick={() => {
              onFieldsChange(debounceFilters);
              handleCloseDrawer();
            }}
          >
            {intl.get('buttons.save_filters')}
          </Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onValuesChange={async (changedValues, allValues) => {
            setDebounceFilters(allValues);
          }}
          onKeyDown={event => {
            if (event.keyCode === 13 && !event.shiftKey) {
              event.preventDefault();

              onFieldsChange(debounceFilters);
            }
          }}
        >
          <Form.Item
            label={intl.get('attendance.filters.status')}
            name="status"
          >
            <Select
              placeholder={intl.get('attendance.filters.status_placeholder')}
            >
              {statusList.map(status => (
                <Select.Option key={status.code} value={status.code}>
                  {status.code} - {status.status}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.attendance_id')}
            name="attendanceId"
          >
            <Input
              type="number"
              placeholder={intl.get(
                'attendance.filters.attendance_id_placeholder',
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.id_of_calls_from')}
            style={{ marginBottom: 0 }}
          >
            <Form.Item
              name="attendanceIdStart"
              style={{
                display: 'inline-block',
                width: 'calc(40% - 8px)',
              }}
            >
              <Input type="number" placeholder="Ex. 145" />
            </Form.Item>
            <Form.Item
              style={{
                display: 'inline-block',
                textAlign: 'center',
                width: '20%',
              }}
            >
              {intl.get('attendance.filters.until')}
            </Form.Item>
            <Form.Item
              name="attendanceIdEnd"
              style={{
                display: 'inline-block',
                width: 'calc(40% - 8px)',
                margin: '0 8px',
              }}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('attendanceIdStart') < value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        intl.get(
                          'attendance.filters.enter_value_greater_than_initial',
                        ),
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input type="number" placeholder="Ex. 186" />
            </Form.Item>
          </Form.Item>
          {formattedFilterAttendance()}
          <Form.Item
            label={intl.get('attendance.filters.contact_information')}
            name="contact"
          >
            <Input
              placeholder={intl.get(
                'attendance.filters.contact_information_placeholder',
              )}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.responsible_for_the_service')}
            name="responsible"
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={intl.get(
                'attendance.filters.responsible_for_the_service_placeholder',
              )}
              filterSort={(optionA, optionB) =>
                optionA?.name
                  .toLowerCase()
                  .localeCompare(optionB.name.toLowerCase())
              }
              options={formattedResponsiblesList}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.expired')}
            name="expired"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.created_between')}
            name="timeCreated"
          >
            <DatePicker.RangePicker
              format={value => `${value.format('DD/MM/YYYY')}`}
              allowClear={false}
              presets={[
                {
                  label: intl.get('date.today'),
                  value: [dayjs().startOf('day'), dayjs().endOf('day')],
                },
                {
                  label: intl.get('date.this_week'),
                  value: [dayjs().startOf('week'), dayjs().endOf('week')],
                },
                {
                  label: intl.get('date.this_month'),
                  value: [dayjs().startOf('month'), dayjs().endOf('month')],
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.latest_changes_between')}
            name="timeLastUpdated"
          >
            <DatePicker.RangePicker
              format={value => `${value.format('DD/MM/YYYY')}`}
              allowClear={false}
              presets={[
                {
                  label: intl.get('date.today'),
                  value: [dayjs().startOf('day'), dayjs().endOf('day')],
                },
                {
                  label: intl.get('date.this_week'),
                  value: [dayjs().startOf('week'), dayjs().endOf('week')],
                },
                {
                  label: intl.get('date.this_month'),
                  value: [dayjs().startOf('month'), dayjs().endOf('month')],
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={intl.get('attendance.filters.status_changes_between')}
            name="timeStatus"
          >
            <DatePicker.RangePicker
              format={value => `${value.format('DD/MM/YYYY')}`}
              allowClear={false}
              presets={[
                {
                  label: intl.get('date.today'),
                  value: [dayjs().startOf('day'), dayjs().endOf('day')],
                },
                {
                  label: intl.get('date.this_week'),
                  value: [dayjs().startOf('week'), dayjs().endOf('week')],
                },
                {
                  label: intl.get('date.this_month'),
                  value: [dayjs().startOf('month'), dayjs().endOf('month')],
                },
              ]}
            />
          </Form.Item>
          <Button
            type="link"
            onClick={() => {
              handleCleanFilters();
            }}
            block
          >
            {intl.get('buttons.clean_filters')}
          </Button>
        </Form>
      </Drawer>
    </div>
  );
};

export default memo(Filters, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
