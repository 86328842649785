import React from 'react';
import { useLocales } from '~/hooks/locales';
import { useUser } from '~/hooks/user';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const ConpassTour: React.FC = () => {
  const { user } = useUser();
  const { currentLocale } = useLocales();

  if (!user || !currentLocale) {
    return <></>;
  }
  let count = 0;
  const ConpassInterval = window.setInterval(() => {
    if (window.Conpass) {
      window.Conpass.init({
        name: user.name,
        email: user.email,
        custom_fields: {
          lang: currentLocale === 'pt-BR' ? 'pt_BR' : 'es',
        },
      });
      if (window.Conpass || count >= 100) clearInterval(ConpassInterval);
    }
    count += 1;
  }, 100);

  return <></>;
};

export default ConpassTour;
