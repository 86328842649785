/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, memo } from 'react';
import intl from 'react-intl-universal';
import { Card, Menu, Dropdown, Button, Tooltip, Tag } from 'antd';
import {
  CheckSquareOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Text from 'antd/lib/typography/Text';
import CardItem from '../../molecules/CardItem';
import { useAttendancesByStatus } from '~/hooks/attendances/attendancesByStatus';
import {
  ContainerCollapsed,
  HeaderCollapsed,
  Container,
  Header,
  Content,
} from './styles';
import { useKanban } from '~/hooks/workflow/kanban';
import { useEnvironment } from '~/hooks/environments/environment';
import { useWorkflow } from '~/hooks/workflow/workflow';
import ModalNewAttendance from '~/components/global/molecules/ModalNewAttendance';
import { StatusProps } from '~/hooks/status/status';

interface ColumnProps {
  index: number;
  status: StatusProps;
}
const Column: React.FC<ColumnProps> = ({ index, status }) => {
  const { environment } = useEnvironment();

  const { setNewListSelectedCards } = useWorkflow();

  const {
    columnCollapsed,
    addColumnCollapsed,
    removeColumnCollapsed,
  } = useKanban();

  const title = `${status.code} - ${status.status}`;
  const titleFormmatedToListCollapsed = environment
    ? `${environment.id} - ${title}`
    : title;

  const {
    attendancesList,
    moreAttendances,
    totalAttendances,
    hasMore,
    isLoading,
  } = useAttendancesByStatus();

  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (columnCollapsed.includes(titleFormmatedToListCollapsed)) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [columnCollapsed, title, titleFormmatedToListCollapsed]);

  const handleNewListCollapsed = () => {
    addColumnCollapsed(titleFormmatedToListCollapsed);
  };

  const handleRemoveListCollapsed = () => {
    removeColumnCollapsed(titleFormmatedToListCollapsed);
  };

  const handleSetNewListSelectedCards = () => {
    if (!attendancesList) {
      return;
    }
    const newListSelectedCards = attendancesList.map(
      attendance => attendance.id,
    );
    setNewListSelectedCards(newListSelectedCards);
  };

  if (isLoading || !attendancesList) {
    return (
      <Container>
        <Header statusType={status.status_type}>
          <div className="title">
            <Tooltip title={title}>
              <Text ellipsis style={{ maxWidth: '139px' }}>
                {title}
              </Text>
            </Tooltip>
            <Tag>{totalAttendances}</Tag>
          </div>
        </Header>
        <Content id={`scrollableColumn${title}`}>
          <Card loading style={{ width: '100%' }} />
        </Content>
      </Container>
    );
  }

  if (isCollapsed) {
    return (
      <ContainerCollapsed onClick={() => handleRemoveListCollapsed()}>
        <HeaderCollapsed statusType={status.status_type}>
          <div className="title">
            <Tooltip title={title}>
              <Text ellipsis style={{ maxWidth: '165px' }}>
                {title}
              </Text>
            </Tooltip>
            <Tag>{totalAttendances}</Tag>
          </div>
          <div className="options">
            <Button
              type="text"
              size="small"
              shape="circle"
              icon={<RightOutlined />}
            />
          </div>
        </HeaderCollapsed>
      </ContainerCollapsed>
    );
  }

  return (
    <Draggable draggableId={status.code} index={index}>
      {providedDraggableColumn => (
        <Container
          ref={providedDraggableColumn.innerRef}
          {...providedDraggableColumn.draggableProps}
        >
          <Header
            statusType={status.status_type}
            {...providedDraggableColumn.dragHandleProps}
          >
            <div className="title">
              <Tooltip title={title}>
                <Text ellipsis>{title}</Text>
              </Tooltip>
              <Tag>{totalAttendances}</Tag>
            </div>
            <div className="options">
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item
                      key="1"
                      icon={<DownloadOutlined rotate={90} />}
                      onClick={() => handleNewListCollapsed()}
                    >
                      {intl.get('workflow.kanban.column.collapse_this_column')}
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      icon={<CheckSquareOutlined />}
                      onClick={() => handleSetNewListSelectedCards()}
                    >
                      {intl.get('workflow.kanban.column.select_all')}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Tooltip
                  title={intl.get('workflow.kanban.column.more_options')}
                >
                  <Button
                    type="text"
                    size="large"
                    icon={<EllipsisOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Tooltip>
              </Dropdown>
            </div>
          </Header>
          <Droppable key={status.code} droppableId={status.code} type="card">
            {(providedDroppableColumn, snapshotColumn) => (
              <Content
                id={`scrollableColumn${title}`}
                key={status.code}
                {...providedDroppableColumn.droppableProps}
                ref={providedDroppableColumn.innerRef}
                isDraggingOver={snapshotColumn.isDraggingOver}
              >
                {totalAttendances === 0 ? (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <Text>
                      {intl.get('workflow.kanban.column.no_attendance')}
                    </Text>
                  </div>
                ) : (
                  <InfiniteScroll
                    dataLength={attendancesList.length}
                    next={moreAttendances}
                    hasMore={hasMore}
                    className="list"
                    scrollableTarget={`scrollableColumn${title}`}
                    loader={
                      <div
                        style={{
                          margin: '1rem auto',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      </div>
                    }
                  >
                    {attendancesList.map((attendance, indexCard) => (
                      <CardItem
                        key={String(indexCard)}
                        index={indexCard}
                        data={attendance}
                      />
                    ))}
                  </InfiniteScroll>
                )}
              </Content>
            )}
          </Droppable>
          <div style={{ marginRight: '16px' }}>
            <ModalNewAttendance statusCode={status.code} type="status" />
          </div>
        </Container>
      )}
    </Draggable>
  );
};

export default memo(Column, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
