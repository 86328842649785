import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 55px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background: #fff;
  border-bottom: var(--border-bottom);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const Settings = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const User = styled.div`
  display: flex;
  flex-direction: row;

  .avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Children = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
