import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import { useHistory, useParams } from 'react-router-dom';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import { ParamsProps } from '~/@types/params';
import { useAttendance } from '../attendances/attendance';

export interface BotProps {
  enabled: boolean;
}

interface BotContextData {
  isBotEnabled: boolean | null;
  isBotStatusPersistEnabled: boolean | null;
  updateBotStatus: (value: boolean) => void;
  handleSetBotStatusPersistEnabled: (value: boolean) => void;
}

export const BotContext = createContext<BotContextData>({} as BotContextData);

interface BotProviderProps {
  contactId: number;
}

const BotProvider: React.FC<BotProviderProps> = ({ children, contactId }) => {
  const { environmentId } = useParams<ParamsProps>();

  const { environment } = useEnvironment();

  const { attendance } = useAttendance();

  const [isBotEnabled, setIsBotEnabled] = useState<boolean | null>(null);

  const [isBotStatusPersistEnabled, setIsBotStatusPersistEnabled] = useState<
    boolean | null
  >(null);

  const handleSetBotStatusPersistEnabled = (value: boolean) => {
    setIsBotStatusPersistEnabled(value);
  };

  const updateBotStatus = useCallback(
    async (value: boolean) => {
      try {
        await api.put(`/zc/${environmentId}/client/${contactId}/bot/`, {
          enabled: value,
        });

        setIsBotEnabled(value);
      } catch (err) {
        toast.error(intl.get('bot.error_update'));
      }
    },
    [environmentId, contactId],
  );

  const handlePersistBot = useCallback(() => {
    if (attendance && attendance.status.status_type !== 'O') return;
    if (isBotStatusPersistEnabled === null || isBotEnabled === null) return;
    if (isBotStatusPersistEnabled) {
      if (isBotEnabled === true) return;
      updateBotStatus(true);
    } else {
      if (isBotEnabled === false) return;
      updateBotStatus(false);
    }
  }, [isBotStatusPersistEnabled, attendance, isBotEnabled, updateBotStatus]);

  window.onbeforeunload = () => {
    handlePersistBot();
  };

  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      // Executa a função antes da mudança de URL

      handlePersistBot();
    });

    return () => {
      unlisten(); // Remove o ouvinte quando o componente é desmontado
    };
  }, [history, handlePersistBot]);

  useEffect(() => {
    async function getInicialStatus() {
      if (!environment || !contactId) {
        return;
      }
      const response = await api.get(
        `/zc/${environment.id}/client/${contactId}/bot/`,
      );
      const { data } = response;

      setIsBotStatusPersistEnabled(data.enabled);
    }

    getInicialStatus();

    return () => {
      setIsBotEnabled(null);
      setIsBotStatusPersistEnabled(null);
    };
  }, [environment, contactId]);

  useQuery(
    [`bot${contactId}`, environment?.id, contactId],
    async () => {
      if (!environment || !contactId) {
        return;
      }

      try {
        const response = await api.get(
          `/zc/${environment.id}/client/${contactId}/bot/`,
        );
        const { data } = response;

        setIsBotEnabled(data.enabled);
      } catch (error) {
        setIsBotEnabled(null);
      }
    },
    {
      cacheTime: 0,
      refetchInterval: 10000,
      refetchOnMount: true,
    },
  );

  return (
    <BotContext.Provider
      value={{
        isBotEnabled,
        updateBotStatus,
        isBotStatusPersistEnabled,
        handleSetBotStatusPersistEnabled,
      }}
    >
      {children}
    </BotContext.Provider>
  );
};
function useBot(): BotContextData {
  const context = useContext(BotContext);

  if (!context) {
    throw new Error('useBot must be used within an BotProvider');
  }

  return context;
}

export { BotProvider, useBot };
