import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

interface MessageDirectVariationsProps {
  [propName: string]: FlattenSimpleInterpolation;
}

interface MessageTypeVariationsProps {
  [propName: string]: FlattenSimpleInterpolation;
}

interface WrapperProps {
  dir: string;
}

interface ContainerProps {
  type: string;
}

interface MetadataTypeProps {
  status: 'r' | 'w' | 's' | 'a' | 'd';
}

const messageDirectVariations: MessageDirectVariationsProps = {
  sent: css`
    float: right;
    background: #e1ffc7;
    margin-right: 1rem;
    border-radius: var(--radius) 0px var(--radius) var(--radius);
    &:after {
      border-width: 0px 0 10px 10px;
      border-color: transparent transparent transparent #e1ffc7;
      right: -10px;
    }
  `,

  received: css`
    float: left;
    background: #fff;
    margin-left: 1rem;
    border-radius: 0px var(--radius) var(--radius) var(--radius);
    &:after {
      border-width: 0px 10px 10px 0;
      border-color: transparent #fff transparent transparent;
      left: -10px;
    }
  `,

  alert: css`
    max-width: 100%;
    color: #fff;
    background: rgb(117 117 117 / 60%);
    border-radius: var(--radius);
    text-align: center;
    &:after {
      border-width: 0px 10px 10px 0;
      border-color: transparent transparent transparent transparent;
      left: -10px;
    }
  `,
};

const messageTypeVariations: MessageTypeVariationsProps = {
  default: css``,
  im: css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;

    img {
      width: 100%;
      max-height: 350px;
      border-radius: var(--radius);
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  clear: both;
  line-height: 1rem;
  padding: 0.5rem;
  position: relative;
  margin: 0.5rem 0;
  min-width: 40%;
  max-width: 80%;
  word-wrap: break-word;

  box-shadow: 0 1px 2px 1px rgb(0 0 0 / 6%);

  ${props => messageDirectVariations[props.dir || 'sent']}

  .more {
    cursor: pointer;
    position: absolute;
    right: 15px;
    display: none;
    width: 50px;
    height: 20px;
    z-index: 999;
    justify-content: flex-end;
  }
  &:hover {
    .more {
      display: flex;
    }
  }
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    top: 0;
  }

  .reply {
    background: rgb(0, 0, 0, 0.05);
    padding: 8px 6px;
    padding-right: 40px;
    margin: 4px;
    border-radius: var(--radius);
    border-left: 4px solid var(--primary-color);
    max-height: 65px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Container = styled.div<ContainerProps>`
  ${props => messageTypeVariations[props.type || 'default']}
`;

export const Metadata = styled.div<MetadataTypeProps>`
  display: flex;
  flex-direction: column;
  /* margin-top: 0.5rem; */
  gap: 0.5rem;
  justify-content: flex-end;
  font-size: 0.75rem;
  color: var(--gray-7);

  .by {
    text-align: left;
  }

  div {
    display: flex;
    justify-content: end;
    gap: 0.5rem;
  }
`;
