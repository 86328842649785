import {
  LikeOutlined,
  LoadingOutlined,
  SmileOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import intl from 'react-intl-universal';
import { Card, Input, Steps, Form, Switch, Button, TimePicker } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { ParamsProps } from '~/@types/params';
import QuestionsList from './QuestionsList';

import { UpdateBotFormProps, useBotForm } from '~/hooks/bot/botForm';
import { BotFormQuestionsProvider } from '~/hooks/bot/botFormQuestions';
import Navbar from '~/components/global/organisms/Navbar';

import { Wrapper, Container } from './styles';

const BotForm: React.FC = () => {
  const history = useHistory();
  const { form, isLoading, updateBotForm, isError } = useBotForm();
  const { environmentId } = useParams<ParamsProps>();

  const { botFormId, step: stepParams } = useParams<ParamsProps>();

  const [step, setStep] = useState(
    !stepParams ? 0 : stepParams === 'questions' ? 1 : 2,
  );

  const handleChangeStep = (newStep: number) => {
    if (newStep === 1) {
      history.replace({
        pathname: `/c/${environmentId}/chatbot/forms/${botFormId}/questions`,
      });
    } else if (newStep === 2) {
      history.replace({
        pathname: `/c/${environmentId}/chatbot/forms/${botFormId}/finalization`,
      });
    } else {
      history.replace({
        pathname: `/c/${environmentId}/chatbot/forms/${botFormId}`,
      });
    }
    setStep(newStep);
  };

  const handleSubmit = (data: UpdateBotFormProps) => {
    const dataFormatted = {
      ...data,
      ...(data.response_timeout && {
        response_timeout: dayjs(data.response_timeout).format('HH:mm:ss'),
      }),
    };

    updateBotForm(dataFormatted);
  };

  if (isError) {
    toast.error(intl.get('chatbot.not_found.title'));
    history.push(`/c/${environmentId}/chatbot/forms`);
    return <></>;
  }

  if (isLoading || !form) {
    return (
      <Wrapper>
        <Navbar />
        <Container>
          <Card loading />
          <Card>
            <Steps type="navigation">
              <Steps.Step status="process" icon={<LoadingOutlined />} />
              <Steps.Step status="process" icon={<LoadingOutlined />} />
              <Steps.Step status="process" icon={<LoadingOutlined />} />
            </Steps>
          </Card>
          <Card loading />
        </Container>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Navbar title={form.name} />
      <Container>
        <Card
          title={intl.get('chatbot.general_settings.title')}
          loading={isLoading}
        >
          <Form
            layout="horizontal"
            initialValues={form}
            onFinish={values => {
              handleSubmit(values);
            }}
          >
            <Form.Item
              label={intl.get('chatbot.general_settings.name')}
              name="name"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={intl.get('chatbot.general_settings.status')}
              name="is_active"
              valuePropName="checked"
            >
              <Switch
                checkedChildren={intl.get('ui.actived')}
                unCheckedChildren={intl.get('ui.disabled')}
              />
            </Form.Item>

            <Form.Item
              label={intl.get('chatbot.general_settings.web_form')}
              name="allow_web"
              valuePropName="checked"
            >
              <Switch
                checkedChildren={intl.get('ui.actived')}
                unCheckedChildren={intl.get('ui.disabled')}
              />
            </Form.Item>

            <Form.Item
              label={intl.get('chatbot.general_settings.maximum_response_time')}
              name="response_timeout"
              extra={intl.get(
                'chatbot.general_settings.maximum_response_time_info',
              )}
            >
              <TimePicker showNow={false} />
            </Form.Item>

            <Form.Item
              label={intl.get('chatbot.general_settings.time_expired_message')}
              name="msg_timeout"
            >
              <Input
                placeholder={intl.get(
                  'chatbot.general_settings.time_expired_message_placeholder',
                )}
              />
            </Form.Item>

            <Button type="primary" htmlType="submit" size="large">
              {intl.get('buttons.save')}
            </Button>
          </Form>
        </Card>
        <Card>
          <Steps
            current={step}
            type="navigation"
            onChange={current => handleChangeStep(current)}
          >
            <Steps.Step
              title={intl.get('chatbot.welcome.title')}
              icon={<SmileOutlined />}
            />
            <Steps.Step
              title={intl.get('chatbot.question.title_plural')}
              icon={<SolutionOutlined />}
            />
            <Steps.Step
              title={intl.get('chatbot.question.completion.title')}
              icon={<LikeOutlined />}
            />
          </Steps>
        </Card>

        {step === 0 && (
          <Card title={intl.get('chatbot.welcome.title')} loading={isLoading}>
            <Form
              layout="vertical"
              initialValues={form}
              onFinish={values => {
                handleSubmit(values);
              }}
            >
              <Form.Item
                label={intl.get('chatbot.welcome.startup_command')}
                name="start_cmd"
                extra={intl.get('chatbot.welcome.startup_command_info')}
              >
                <Input
                  placeholder={intl.get(
                    'chatbot.welcome.startup_command_placeholder',
                  )}
                />
              </Form.Item>
              <Form.Item
                label={intl.get('chatbot.welcome.welcome_message')}
                name="start_msg"
              >
                <Input.TextArea
                  placeholder={intl.get(
                    'chatbot.welcome.welcome_message_placeholder',
                  )}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit" size="large">
                {intl.get('buttons.save')}
              </Button>
            </Form>
          </Card>
        )}
        {step === 1 && (
          <BotFormQuestionsProvider>
            <QuestionsList />
          </BotFormQuestionsProvider>
        )}
        {step === 2 && (
          <Card
            title={intl.get('chatbot.question.completion.title')}
            loading={isLoading}
          >
            <Form
              layout="vertical"
              initialValues={form}
              onFinish={values => {
                handleSubmit(values);
              }}
            >
              <Form.Item
                label={intl.get(
                  'chatbot.question.completion.termination_command',
                )}
                name="end_cmd"
                extra={intl.get(
                  'chatbot.question.completion.termination_command_info',
                )}
              >
                <Input
                  placeholder={intl.get(
                    'chatbot.question.completion.termination_command_placeholder',
                  )}
                />
              </Form.Item>
              <Form.Item
                label={intl.get(
                  'chatbot.question.completion.completion_message',
                )}
                name="end_msg"
              >
                <Input.TextArea
                  placeholder={intl.get(
                    'chatbot.question.completion.completion_message_placeholder',
                  )}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit" size="large">
                {intl.get('buttons.save')}
              </Button>
            </Form>
          </Card>
        )}
      </Container>
    </Wrapper>
  );
};

export default BotForm;
