import { Dropdown, Image } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import ReactPlayer from 'react-player';

import Paragraph from 'antd/lib/typography/Paragraph';

import { DownOutlined, StopOutlined } from '@ant-design/icons';
import { TiArrowForward } from 'react-icons/ti';
import Document from '~/components/global/atoms/Document';
import {
  MessageProps as IMessageProps,
  useMessages,
} from '~/hooks/messages/messages';
import * as styles from './styles';
import Address from '~/components/global/atoms/Address';
import { formatterMessageText } from '~/util/formatMessageText';
import { MessageStatus } from '../MessageStatus';
import QuotedMessage from './QuotedMessage';
import { useUser } from '~/hooks/user';
import Forward from './Forward';
import VCards from './VCards';
import { useAttendance } from '~/hooks/attendances/attendance';

interface MessageProps {
  data: IMessageProps;
}

const Message: React.FC<MessageProps> = ({ data: message }) => {
  const { user } = useUser();
  const { setQuotedMsgId, deleteMessage } = useMessages();
  const { sendNote } = useAttendance();

  const handleSetQuotedMsgId = () => {
    setQuotedMsgId(message.id);
  };

  const handleDeleteMessage = (messageId: number) => {
    deleteMessage(messageId);
    if (sendNote) {
      sendNote(intl.get('log.message_deleted'));
    }
  };

  const isVCards =
    !message.deleted &&
    message.msg_type === 'ch' &&
    message.msg_data.text.includes('#VCARD@;');

  return (
    <div>
      <styles.Wrapper dir={message.msg_dir === 'i' ? 'received' : 'sent'}>
        {!message.deleted && user && (
          <div className="more">
            <Dropdown
              menu={{
                items: [
                  user.apps.includes('zapchamado.chat')
                    ? {
                        label: intl.get('message.answer'),
                        key: '0',
                        onClick: () => handleSetQuotedMsgId(),
                      }
                    : null,
                  {
                    label: <Forward messageId={message.id} />,
                    key: '1',
                  },
                  message.msg_dir === 'o'
                    ? {
                        label: intl.get('message.delete'),
                        key: '2',
                        onClick: () => handleDeleteMessage(message.id),
                      }
                    : null,
                ],
              }}
              trigger={['click']}
            >
              <DownOutlined />
            </Dropdown>
          </div>
        )}
        {message.forwarded && (
          <div style={{ marginBottom: '0.5rem' }}>
            <i style={{ color: 'var(--gray-9)' }}>
              <TiArrowForward /> {intl.get('chat.message_list.message.forward')}
            </i>
          </div>
        )}

        {message.quoted_msg_id && !message.deleted ? (
          <QuotedMessage messageId={message.quoted_msg_id} />
        ) : null}

        <styles.Container type={message.msg_type}>
          {message.deleted ? (
            <i style={{ color: 'var(--gray-9)' }}>
              <StopOutlined /> {intl.get('chat.message_list.message.deleted')}
            </i>
          ) : (
            <>
              {
                {
                  ch: isVCards ? (
                    <VCards data={message.msg_data.text} />
                  ) : (
                    <Paragraph>
                      {formatterMessageText(message.msg_data.text)}
                    </Paragraph>
                  ),
                  im: (
                    <>
                      <Image
                        src={message.msg_data.url}
                        alt={intl.get('chat.message_list.message.image')}
                      />

                      {message.msg_data.description && (
                        <Paragraph>
                          {formatterMessageText(message.msg_data.description)}
                        </Paragraph>
                      )}
                    </>
                  ),
                  au: (
                    <ReactPlayer
                      controls
                      width="100%"
                      height="60px"
                      url={message.msg_data.url}
                    />
                  ),
                  pt: (
                    <ReactPlayer
                      controls
                      width="100%"
                      height="60px"
                      url={message.msg_data.url}
                    />
                  ),
                  do: <Document url={message.msg_data.url} />,
                  lo: (
                    <Address
                      lat={message.msg_data.lat}
                      lng={message.msg_data.lng}
                    />
                  ),
                  vi: (
                    <ReactPlayer
                      controls
                      width="100%"
                      url={message.msg_data.url}
                    />
                  ),
                }[message.msg_type]
              }
            </>
          )}
        </styles.Container>

        <styles.Metadata status={message.msg_status}>
          <span className="by">{message.sent_by}</span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="time">{message.msg_timeFormatted}</span>
            {message.msg_dir === 'o' && (
              <MessageStatus status={message.msg_status} />
            )}
          </div>
        </styles.Metadata>
      </styles.Wrapper>
    </div>
  );
};

export default Message;
