import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--global-header-height));
`;

export const Container = styled.div`
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  flex-direction: row;
  margin: 0 0 0 1rem;
  padding-right: 1rem;
  gap: 0.5rem;
  overflow: hidden;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 28px;
  }
  ::-webkit-scrollbar-thumb {
    border: 8px solid var(--background);
    border-radius: var(--radius);
  }

  ::-webkit-scrollbar-track {
    margin-right: 1rem;
    height: 50px;
  }
`;
