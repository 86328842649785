import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';

import { useHistory, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import { FiltersProps } from '~/@types/attendances';
import api from '~/services/api';
import { useEnvironment } from '~/hooks/environments/environment';
import isEmpty from '~/util/isEmpty';
import { ParamsProps } from '~/@types/params';
import { StatusProps } from '../status/status';
import ModalFinishAttendance from '~/components/global/organisms/ModalFinishAttendance';

type LayoutBoardProps = 'kanban' | 'list' | 'chat';
interface WorkflowContextData {
  layoutBoard: LayoutBoardProps;
  changeLayoutBoard: (layoutBoard: LayoutBoardProps) => void;
  quickSearch?: string;
  filters: FiltersProps;
  totalFiltered?: number;
  setNewQuickSearch: (newQuickSearch: string) => void;
  setNewFilters: (newFiltersInput: FiltersProps) => void;
  cleanFilters: () => void;
  selectedCards: Array<number>;
  addNewSelectedCard: (newSelectedCard: number) => void;
  removeSelectedCard: (selectedCardRemoved: number) => void;
  setNewListSelectedCards: (newListSelectedCards: Array<number>) => void;
  setListSelectedCards: (newListSelectedCards: Array<number>) => void;
  removeAllSelectedCards: () => void;
  moveCard: (cardId: string, status: StatusProps) => void;
}

const WorkflowContext = createContext<WorkflowContextData>(
  {} as WorkflowContextData,
);

const WorkflowProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [modal, contextHolder] = Modal.useModal();

  const { environmentId } = useParams<ParamsProps>();

  const { environment, refetch } = useEnvironment();

  const [quickSearch, setQuickSearch] = useState<string>('');
  const [layoutBoard, setLayoutBoard] = useState<LayoutBoardProps>(() => {
    const layoutLocalStorage = localStorage.getItem(
      '@ZapForm:workflowLayoutBoard',
    );

    if (layoutLocalStorage) {
      return JSON.parse(layoutLocalStorage);
    }

    localStorage.setItem(
      '@ZapForm:workflowLayoutBoard',
      JSON.stringify('kanban'),
    );
    return 'kanban';
  });

  const [filters, setFilters] = useState<FiltersProps>(() => {
    const filtersLocalStorage = localStorage.getItem(
      '@ZapForm:workflowFilters',
    );

    if (filtersLocalStorage) {
      return JSON.parse(filtersLocalStorage);
    }

    return {} as FiltersProps;
  });

  const [selectedCards, setSelectedCards] = useState<Array<number>>([]);

  const changeLayoutBoard = (value: LayoutBoardProps) => {
    localStorage.setItem('@ZapForm:workflowLayoutBoard', JSON.stringify(value));
    setLayoutBoard(value);
    history.push(`/c/${environmentId}/workflow`);
  };

  const setNewQuickSearch = (newQuickSearch: string) => {
    setQuickSearch(newQuickSearch);
  };

  useEffect(() => {
    const getTotalFiltered = async (): Promise<void> => {
      setTotalFiltered(undefined);

      if (!environment) {
        return;
      }

      try {
        const response = await api.get(`/zc/${environment.id}/order/`, {
          params: {
            offset: 0,
            limit: 1,
            ...(filters.attendanceId && { id: filters.attendanceId }),
            ...((filters.contact || (quickSearch && quickSearch !== '')) && {
              client: quickSearch || filters.contact,
            }),
            ...(filters.responsible && { location: filters.responsible }),
            ...(filters.attendance && {
              order: filters.attendance.slice(0, 100),
            }),
            ...(filters.expired === true && {
              expired: true,
            }),
            ...(filters.timeCreated && {
              time_created__gt: filters.timeCreated[0],
              time_created__lte: filters.timeCreated[1],
            }),
            ...(filters.timeStatus && {
              time_status__gt: filters.timeStatus[0],
              time_status__lte: filters.timeStatus[1],
            }),
            ...(filters.timeLastUpdated && {
              time_last_updated__gt: filters.timeLastUpdated[0],
              time_last_updated__lte: filters.timeLastUpdated[1],
            }),
            ...(filters.attendanceIdStart && {
              id__gt: filters.attendanceIdStart,
            }),
            ...(filters.attendanceIdEnd && {
              id__lte: filters.attendanceIdEnd,
            }),
            ...(filters.expired === true && {
              expired: true,
            }),
          },
        });

        const { data } = response;

        if (!isEmpty(filters)) {
          setTotalFiltered(data.count);
        }
      } catch (error) {
        setTotalFiltered(undefined);
      }
    };
    getTotalFiltered();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const [totalFiltered, setTotalFiltered] = useState<number | undefined>(
    undefined,
  );

  const setNewFilters = (newFiltersInput: FiltersProps) => {
    const formattedNewFiltersInput = {
      ...newFiltersInput,
      ...(newFiltersInput.expired === true
        ? { expired: true }
        : { expired: undefined }),
    };
    localStorage.setItem(
      '@ZapForm:workflowFilters',
      JSON.stringify(formattedNewFiltersInput),
    );
    setFilters(formattedNewFiltersInput);
  };

  const cleanFilters = () => {
    localStorage.removeItem('@ZapForm:workflowFilters');
    setFilters({} as FiltersProps);
  };

  const addNewSelectedCard = (newSelectedCard: number) => {
    if (selectedCards.includes(newSelectedCard)) {
      return;
    }

    setSelectedCards([...selectedCards, newSelectedCard]);
  };

  const setNewListSelectedCards = (newListSelectedCards: Array<number>) => {
    const newListSelectedCardsFiltered = newListSelectedCards.filter(
      card => !selectedCards.includes(card),
    );

    setSelectedCards([...selectedCards, ...newListSelectedCardsFiltered]);
  };

  const setListSelectedCards = (newListSelectedCards: Array<number>) => {
    setSelectedCards(newListSelectedCards);
  };

  const removeSelectedCard = (selectedCardRemoved: number) => {
    const newSelectedCards = selectedCards.filter(
      card => card !== selectedCardRemoved,
    );

    setSelectedCards(newSelectedCards);
  };

  const removeAllSelectedCards = () => {
    setSelectedCards([]);
  };

  const onMoveCard = async (cardId: string, statusCode: string) => {
    try {
      await api.post(`/zc/${environmentId}/order/${cardId}/change_status/`, {
        next_status_code: statusCode,
      });
      refetch();
    } catch (error) {
      toast.error(intl.get('attendance.update.error_change_status'));
    }
  };

  const moveCard = async (cardId: string, status: StatusProps) => {
    if (status.status_type === 'C') {
      modal.confirm({
        title: `${intl.get('attendance.finish_attendance')} #${cardId}`,
        content: <ModalFinishAttendance attendanceId={Number(cardId)} />,
        width: '60vw',
        onOk: () => {
          onMoveCard(cardId, status.code);
        },
        okText: intl.get('buttons.save'),
      });
    } else {
      onMoveCard(cardId, status.code);
    }
  };

  return (
    <WorkflowContext.Provider
      value={{
        layoutBoard,
        changeLayoutBoard,
        filters,
        totalFiltered,
        setNewFilters,
        cleanFilters,
        quickSearch,
        setNewQuickSearch,
        selectedCards,
        setNewListSelectedCards,
        setListSelectedCards,
        addNewSelectedCard,
        removeSelectedCard,
        removeAllSelectedCards,
        moveCard,
      }}
    >
      {children}
      {contextHolder}
    </WorkflowContext.Provider>
  );
};
function useWorkflow(): WorkflowContextData {
  const context = useContext(WorkflowContext);

  if (!context) {
    throw new Error('useWorkflow must be used within an WorkflowProvider');
  }

  return context;
}

export { WorkflowProvider, useWorkflow };
