import React from 'react';
import { Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

export function formatStatus(
  value:
    | 'APPROVED'
    | 'IN_APPEAL'
    | 'PENDING'
    | 'REJECTED'
    | 'PENDING_DELETION'
    | 'DELETED'
    | 'DISABLED'
    | 'PAUSED'
    | 'LIMIT_EXCEEDED',
): JSX.Element {
  switch (value) {
    case 'APPROVED':
      return <Tag color="green">Aprovado</Tag>;
    case 'IN_APPEAL':
      return <Tag color="orange">Em apelação</Tag>;
    case 'PENDING':
      return <Tag color="orange">Pendente</Tag>;
    case 'REJECTED':
      return <Tag color="red">Rejeitado</Tag>;
    case 'PENDING_DELETION':
      return <Tag color="red">Exclusão pendente</Tag>;
    case 'DELETED':
      return <Tag color="red">Excluído</Tag>;
    case 'DISABLED':
      return <Tag color="red">Desativado</Tag>;
    case 'PAUSED':
      return <Tag color="blue">Pausado</Tag>;
    case 'LIMIT_EXCEEDED':
      return <Tag color="red">Limite excedido</Tag>;
    default:
      return <Tag>Desconhecido</Tag>;
  }
}

export function formatRejectedReason(
  value:
    | 'ABUSIVE_CONTENT'
    | 'INVALID_FORMAT'
    | 'NONE'
    | 'PROMOTIONAL'
    | 'TAG_CONTENT_MISMATCH'
    | 'SCAM'
    | 'INCORRECT_CATEGORY',
): JSX.Element {
  switch (value) {
    case 'ABUSIVE_CONTENT':
      return <Tag color="red">Conteúdo abusivo detectado</Tag>;
    case 'INVALID_FORMAT':
      return <Tag color="red">Formato inválido detectado</Tag>;
    case 'NONE':
      return <Tag>Nenhuma violação detectada</Tag>;
    case 'PROMOTIONAL':
      return <Tag color="red">Conteúdo promocional detectado</Tag>;
    case 'TAG_CONTENT_MISMATCH':
      return (
        <Tag color="red">Incompatibilidade entre tag e conteúdo detectada</Tag>
      );
    case 'SCAM':
      return <Tag color="red">Possível tentativa de fraude detectada</Tag>;
    case 'INCORRECT_CATEGORY':
      return (
        <>
          <Tooltip
            title={
              <>
                Este modelo inclui conteúdo que não corresponde à categoria que
                você selecionou. Você pode editar o modelo e reenviá-lo para
                análise.{' '}
                <Link
                  to={{
                    pathname: `https://developers.facebook.com/docs/whatsapp/message-templates/guidelines`,
                  }}
                  target="_blank"
                >
                  Saiba mais
                </Link>
              </>
            }
          >
            <Tag color="red">Conteúdo que não corresponde à categoria</Tag>
          </Tooltip>
        </>
      );
    default:
      return <Tag color="red">Violação desconhecida</Tag>;
  }
}

export function formatComponentsType(
  value: 'HEADER' | 'BODY' | 'FOOTER',
): JSX.Element {
  switch (value) {
    case 'HEADER':
      return <Tag>Cabeçalho</Tag>;
    case 'FOOTER':
      return <Tag>Rodapé</Tag>;
    case 'BODY':
      return <Tag>Corpo</Tag>;
    default:
      return <Tag>Desconhecido</Tag>;
  }
}

export function formatCategory(
  value: 'UTILITY' | 'MARKETING' | 'AUTHENTICATION',
): JSX.Element {
  switch (value) {
    case 'UTILITY':
      return (
        <Tooltip
          title="Mensagens sobre uma transação específica, uma conta, um
      pedido ou uma solicitação de cliente."
        >
          <Tag color="blue">Serviços</Tag>
        </Tooltip>
      );
    case 'MARKETING':
      return (
        <Tooltip
          title="Promoções ou informações sobre sua empresa, produtos ou
      serviços. Ou qualquer mensagem que não seja relevante ou
      de autenticação."
        >
          <Tag color="green">Marketing</Tag>
        </Tooltip>
      );
    case 'AUTHENTICATION':
      return (
        <Tooltip
          title="Senhas descartáveis que seus clientes usam para autenticar
      uma transação ou login."
        >
          <Tag>Autenticação</Tag>
        </Tooltip>
      );
    default:
      return <Tag>Desconhecido</Tag>;
  }
}
