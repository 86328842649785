import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

interface MessageStatusVariationsProps {
  [propName: string]: FlattenSimpleInterpolation;
}

interface ContainerProps {
  status: 'r' | 'w' | 's' | 'a' | 'd';
}

const messageStatusVariations: MessageStatusVariationsProps = {
  r: css`
    color: var(--gray-7);
  `,
  s: css`
    color: var(--blue-color);
  `,
  w: css`
    color: var(--gray-7);
  `,
  d: css`
    color: var(--gray-7);
  `,
  a: css`
    color: var(--blue-color);
  `,
};

export const Container = styled.div<ContainerProps>`
  font-size: 1.4rem;
  ${props => messageStatusVariations[props.status || 'r']}
`;
