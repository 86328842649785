import {
  BellTwoTone,
  NotificationTwoTone,
  UnlockTwoTone,
} from '@ant-design/icons';
import { Card, Input, Segmented, Select, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ICategory, ILanguage, INewMessageTemplate } from '..';

const { Title, Paragraph } = Typography;

interface IOneProps {
  data: INewMessageTemplate;
  onChangeName: (name: string) => void;
  onChangeCategory: (category: ICategory) => void;
  onChangeLanguage: (language: ILanguage) => void;
}

const One: React.FC<IOneProps> = ({
  data,
  onChangeName,
  onChangeCategory,
  onChangeLanguage,
}) => {
  // const [nameValue, setNameValue] = useState('');

  const handleInputChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue
      .toLowerCase() // Converter para minúsculas
      .replace(/\s+/g, '_') // Substituir espaços por underscores
      .replace(/[^\w\s]/g, ''); // Remover caracteres especiais
    // setNameValue(sanitizedValue);
    onChangeName(sanitizedValue);
  };

  return (
    <>
      <Card title="Categoria">
        <Paragraph type="secondary">
          Escolha uma categoria que melhor descreva seu modelo de mensagem.
          <Link
            to={{
              pathname:
                'https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines/',
            }}
            target="_blank"
          >
            Saiba mais sobre categorias
          </Link>
        </Paragraph>
        <Segmented
          onChange={value => onChangeCategory(value.toString() as ICategory)}
          block
          size="large"
          options={[
            {
              label: (
                <div
                  style={{
                    padding: '0.5rem',
                    whiteSpace: 'normal',
                    lineHeight: '1.5rem',
                  }}
                >
                  <NotificationTwoTone style={{ fontSize: '34px' }} />
                  <Title level={4}>Marketing</Title>
                  <div style={{ textAlign: 'start' }}>
                    <Paragraph>
                      Promoções ou informações sobre sua empresa, produtos ou
                      serviços. Ou qualquer mensagem que não seja relevante ou
                      de autenticação.
                    </Paragraph>
                    <Paragraph type="secondary">
                      Exemplos: mensagens de boas-vindas, boletins informativos,
                      ofertas, cupons, catálogos, novos horários do
                      estabelecimento
                    </Paragraph>
                  </div>
                </div>
              ),
              value: 'MARKETING',
            },
            {
              label: (
                <div
                  style={{
                    padding: '0.5rem',
                    whiteSpace: 'normal',
                    lineHeight: '1.5rem',
                  }}
                >
                  <BellTwoTone style={{ fontSize: '34px' }} />
                  <Title level={4}>Serviços</Title>
                  <div style={{ textAlign: 'start' }}>
                    <Paragraph>
                      Mensagens sobre uma transação específica, uma conta, um
                      pedido ou uma solicitação de cliente.
                    </Paragraph>
                    <Paragraph type="secondary">
                      Exemplo: confirmações de pedidos, atualizações de conta,
                      recibos, lembretes de horas marcadas, cobrança
                    </Paragraph>
                  </div>
                </div>
              ),
              value: 'UTILITY',
            },
            {
              label: (
                <div
                  style={{
                    padding: '0.5rem',
                    whiteSpace: 'normal',
                    lineHeight: '1.5rem',
                  }}
                >
                  <UnlockTwoTone style={{ fontSize: '34px' }} />
                  <Title level={4}>Autenticação</Title>
                  <div style={{ textAlign: 'start' }}>
                    <Paragraph>
                      Senhas descartáveis que seus clientes usam para autenticar
                      uma transação ou login.
                    </Paragraph>
                    <Paragraph type="secondary">
                      Exemplos: senha descartável, código de recuperação da
                      conta
                    </Paragraph>
                  </div>
                </div>
              ),
              value: 'AUTHENTICATION',
            },
          ]}
          defaultValue={data.category}
        />
      </Card>

      <Card title="Nome">
        <Paragraph type="secondary">
          Dê um nome para o seu modelo de mensagem.
        </Paragraph>

        <Input
          placeholder="Insira o nome do modelo de mensagem..."
          value={data.name}
          onChange={handleInputChangeName}
          size="large"
        />
      </Card>
      <Card title="Idioma">
        <Paragraph>
          Escolha idiomas para o seu modelo de mensagem. Você pode excluir ou
          adicionar mais idiomas depois.
        </Paragraph>

        <Select
          style={{ width: '100%' }}
          placeholder="Selecionar idioma"
          options={[
            { value: 'pt_BR', label: 'Português (BR)' },
            { value: 'es', label: 'Espanõl' },
          ]}
          size="large"
          onChange={value => onChangeLanguage(value)}
          defaultValue={data.language}
        />
      </Card>
    </>
  );
};

export default One;
